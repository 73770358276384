import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Lottie from "react-lottie";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import YuJaButton from "../../components/standardization/YuJaButton";
import { Spin } from "../../components/standardization/YuJaLoading";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import {
  ALL_COURSE_LABEL,
  ATTENDANCE_ABSENT_RESULT,
  ATTENDANCE_FILTER_LABEL,
  ATTENDANCE_GRADEBOOK_DROPDOWN,
  ATTENDANCE_NOT_GRADED_RESULT, ATTENDANCE_NOT_GRADED_TITLE,
  ATTENDANCE_PLACEHOLDER,
  ATTENDANCE_PRESENT_RESULT,
  BUTTON,
  CLOSE_MODAL,
  CORRECT,
  COURSE_FILTER_LABEL,
  COURSE_PLACEHOLDER,
  FIXED_ANSWER,
  GET_RESULT_TEXT,
  INCORRECT,
  LMS_GRADEBOOK_SYNC,
  MENU_LIST,
  NOT_GRADED,
  PLEASE_SELECT_POLL,
  POLL_TEXT,
  POLL_TITLE_DROPDOWN_LENGTH,
  POLL_TYPE,
  QUESTION_TYPES,
  SURVEY,
  TEXTBOX,
  VIEW_TAKEN_POLLS_TITLE,
  YOUR_ANSWER_TITLE
} from "../../utils/constants";
import { getPriority } from "../../utils/helpers";
import { ReactComponent as Ungraded} from "../../images/notGradedAttendance.svg";
import TakenPollTable from "./TakenPollTable";
import styles from "./ViewTakenPollsPage.module.css";
import absent from "./animations/absent.json";
import present from "./animations/present.json";

export default function ViewTakenPollsPage(props) {
  const { getStudentResultByPoll, getAudienceResultByUser, getAttendancesByCourse} = ResponseApiCalls();
  const { getCourseByUser } = CourseApiCalls();
  
  const[, getHostResource] = useLocalStorageNew(window.location.hostname, {});
  let institutionId = getHostResource().institutionId;
  const isNarrow = useMobileAccess(1200);
  const [publishedPollDict, setPublishedPollDict] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  // const [selectedPollUniqueKey, setSelectedPollUniqueKey] = useState("select");
  const [selectedPoll, setSelectedPoll] = useState({});
  const [selectedPollInitialized, setSelectedPollInitialized] = useState(false);
  const [selectedAttendanceInitialized, setSelectedAttendanceInitialized] = useState(false);
  const [isPresent, setIsPresent] = useState(false);
  const [isGraded, setIsGraded] = useState(false);
  const [selectedPollLoading, setSelectedPollLoading] = useState(false);
  const [numberSort, setNumberSort] = useState(0);
  const [typeSort, setTypeSort] = useState(1);
  const [statusSort, setStatusSort] = useState(1);
  // const [showLongTitle, setShowLongTitle] = useState(false);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [modalData, setModalData] = useState("");
  const [pollsLoading, setPollsLoading] = useState(false);
  const [pollList, setPollList] = useState([]);
  const [, getSession] = useLocalStorageNew("session", {});
  const session = getSession();
  const location = useLocation();
  const [courseOptions, setCourseOptions] = useState([]);
  const [loadingCourses, setCourseLoading] = useState(true);
  const [courseFilter, setCourseFilter] = useState(null);
  const [attendanceOptions, setAttendanceOptions] = useState([]);
  const [loadingAttendances, setAttendanceLoading] = useState(true);
  const [attendanceFilter, setAttendanceFilter] = useState(null);
  const [selectedAttendanceLoading, setSelectedAttendanceLoading] = useState(true);

  useEffect(() => {
    props.setPageIdentifier(MENU_LIST.ACTIVITIES_STUDENT.name);
    
    const handleWindowResize = () => {
      props.setPageIdentifier(MENU_LIST.ACTIVITIES_STUDENT.name);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const checkGraded = (poll, grades) => {
    let pollCode = poll.sk.split("#")[2];
    let grade = grades[pollCode];
    if (!poll.questions) {
      return false
    }
    if (grade && poll.lmsAttempt) {
      for (const result of poll.questions) {
        let questionResponse = result;
        if (questionResponse.attempts && Object.entries(questionResponse.attempts).length > 0 && poll.lmsAttempt === LMS_GRADEBOOK_SYNC.RECENT.value) {
          const entries = Object.values(questionResponse.attempts);
          const lastEntry = entries[entries.length - 1];
          if (lastEntry.isGraded === false) {
            return false; 
          }
        }
        else if (questionResponse.attempts && Object.entries(questionResponse.attempts).length > 0 && poll.lmsAttempt === LMS_GRADEBOOK_SYNC.FIRST.value) {
          const entries = Object.values(questionResponse.attempts);
          const lastEntry = entries[0];
          if (lastEntry.isGraded === false) {
            return false; 
          }
        }
        else if (questionResponse.attempts && Object.entries(questionResponse.attempts).length > 0 && poll.lmsAttempt === LMS_GRADEBOOK_SYNC.HIGHEST.value) {
          const entries = Object.values(grade);
          let highest = 0;
          let highest_index = 0;
          for (let i = 0; i < entries.length; i++) {
            let score = parseInt(parseFloat(entries[i].score), 10);
            if (score > highest) {
              highest = score;
              highest_index = i;
            }
          }
          const curr_entries = Object.values(questionResponse.attempts);
          const lastEntry = curr_entries[highest_index];
          if (lastEntry.isGraded === false) {
            return false; 
          }
        }
    }
    return true; 
    }
    else {
      for (const result of poll.questions) {
        let questionResponse = result;
        if (!questionResponse.isGraded) {
          return false; 
        }
        else if (questionResponse.attempts) {
          const entries = Object.values(questionResponse.attempts);
          const lastEntry = entries[0];
          if (lastEntry.isGraded === false) {
            return false; 
          }
        }
      }
      return true;
  }
  };

  const getPollData = async () => {
    if (!session.userId) {
      setPollList([]);
      console.log("error with login details");
      return;
    }

    setPollsLoading(true);
    let {publishedPolls, grades} = await getAudienceResultByUser(institutionId, session.userId, true);
    setPollsLoading(false);
    publishedPolls = publishedPolls.map(p => {
      p.pollKey = p.pk.split("#")[1];
      p.pollCode = p.sk.split("#")[2];
      return p;
    });
    // if (session.courseId) {
    //   publishedPolls = publishedPolls.filter(poll => (poll.index2Pk === "C#"+session.courseId));
    // }
    publishedPolls = publishedPolls.filter(poll => (
      !(
        poll.pollShareMode === "schedule" &&
        new Date() < new Date(poll.endTime)
      )
    ));

    for(let element of  publishedPolls) {
      if (element.pollType === POLL_TYPE.SURVEY) {
        element.studentResult = null;
        continue;
      }
      let isGraded = checkGraded(element, grades);
      element.studentResult = isGraded;
    }

    publishedPolls = publishedPolls.filter(poll => poll.pollType !== POLL_TYPE.ATTENDANCE && (poll.pollType === POLL_TYPE.SURVEY || poll.studentResult));

    const publishedPollDict = publishedPolls.reduce((obj, item) => {
      return {
        ...obj,
        [item["pollKey"] + "#" + item["pollCode"]]: item,
      };
    }, {});

    setPollList(publishedPolls);
    setPublishedPollDict(publishedPollDict);
    return {publishedPolls, publishedPollDict};
    // setPollData(res.data);
  };

  const getAttendanceData = async () => {
    setAttendanceLoading(true);
    if (!session.userId) {
      setPollList([]);
      console.log("error with login details");
      return;
    }

    let {publishedPolls, grades} = await getAudienceResultByUser(institutionId, session.userId, true);
    setAttendanceLoading(false);
    publishedPolls = publishedPolls.map(p => {
      p.pollKey = p.pk.split("#")[1];
      p.pollCode = p.sk.split("#")[2];
      return p;
    });
    // if (session.courseId) {
    //   publishedPolls = publishedPolls.filter(poll => (poll.index2Pk === "C#"+session.courseId));
    // }
    publishedPolls = publishedPolls.filter(poll => (
      !(
        poll.pollShareMode === "schedule" &&
        new Date() < new Date(poll.endTime)
      )
    ));

    publishedPolls = publishedPolls.filter(poll => poll.pollType === POLL_TYPE.ATTENDANCE);

    const publishedPollDict = publishedPolls.reduce((obj, item) => {
      return {
        ...obj,
        [item["pollKey"] + "#" + item["pollCode"]]: item,
      };
    }, {});

    setAttendanceOptions(publishedPolls.map((key) => {
      return {
        value: `${key.pollKey}#${key.pollCode}`,
        label: ((key.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (key.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : key.pollTitle) +
          " (" + key.pollCode + ")",
        pollTitle: key.pollTitle,
        trimmedTitle: key.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH,
        pollType: POLL_TYPE.ATTENDANCE
      }
    }));
    if (location && location.state && location.state.pollKey && location.state.uniqueCode) {
      const key = location.state.pollKey + "#" + location.state.uniqueCode;
      setAttendanceFilter(key);
      chooseAttendance(key);
    }

    setPublishedPollDict(publishedPollDict);
    return {publishedPolls, publishedPollDict};
  };

  const getCourses = async () => {
    setCourseLoading(true);
    const session = getSession();
    let res;
    if (session.idpType && session.intType) {
      res = await getCourseByUser(session.idpType.toLowerCase(), session.intType.toLowerCase());
    } else {
      res = await getCourseByUser();
    }
    res.sort(function (a, b) {
      return new Date(Date.parse(b.updateTime)) - new Date(Date.parse(a.updateTime));
    });

    console.log(res);
    setCourseOptions(res.map(course => ({
      label: `${course.courseCode} - ${course.courseName}`,
      value: course.courseId,
      ...course
    })));
    setCourseLoading(false);
  };

  const choosePoll = async (option) => {
    setSelectedOption(option);
    setSelectedPollInitialized(true);
    setSelectedPollLoading(true);
    // setSelectedPollUniqueKey(option.value);

    const publishedPoll = publishedPollDict[option.value];
    if (!publishedPoll) {
      return;
    }

    const { questions, synced } = await getStudentResultByPoll(publishedPoll.pollKey, publishedPoll.pollCode, session.userId);
    publishedPoll.questions = questions;
    publishedPoll.synced = synced;
    publishedPoll.pollType = publishedPoll.pollType ? publishedPoll.pollType : POLL_TYPE.GRADED_POLL;
    setSelectedPoll(publishedPoll);
    setSelectedPollLoading(false);
  };

  const chooseAttendance = async (attendanceOption) => {
    setSelectedAttendanceInitialized(true);
    setSelectedAttendanceLoading(true);
    
    console.log("publishedPollDict", publishedPollDict);
    console.log("attendanceOption", attendanceOption);
    const publishedPoll = publishedPollDict[attendanceOption];
    console.log("publishedPoll", publishedPoll);
    if (!publishedPoll) {
      setIsPresent(false);
      setIsGraded(false);
      setTimeout(() => setSelectedAttendanceLoading(false), 5);
      // notifyError("Attendance not found; must be absent");
      return;
    }

    const { questions=[] } = await getStudentResultByPoll(publishedPoll.pollKey, publishedPoll.pollCode, session.userId);
    // publishedPoll.questions = questions;
    // publishedPoll.synced = synced;
    // publishedPoll.pollType = publishedPoll.pollType ? publishedPoll.pollType : POLL_TYPE.GRADED_POLL;
    setIsGraded(questions.length && questions[0].isGraded);
    setIsPresent(questions.length && questions[0].isCorrect);
    setSelectedAttendanceLoading(false);
  };

  const chooseCourse = async (option) => {
    console.log("courseOption", option);
    console.log("courseId", option.courseId);
    setCourseFilter(option);
    setAttendanceFilter("");
    const res = await getAttendancesByCourse(option.courseId);
    // const res = JSON.parse(`[
    //   {"pk":"P#9f0e8a42-936a-4937-9e86-593b51503346","sk":"PUB#UC#374640","index2Pk":"C#5ab6d38e-3a75-4622-9bd2-af74cf0bed52","index2Sk":"U#20329ed7-a4c7-4586-9492-40990ffee25b#9f0e8a42-936a-4937-9e86-593b51503346","index4Pk":"19-03-2024","index4Sk":"2024-03-19T23:04:05.532Z","index5Pk":"I#b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615#PUB#UC","index5Sk":"UC#374640","pollTimeLimit":1800,"liveQuestionNo":-1,"liveQuestionTime":-1,"pollShareMode":"unknown","pollTitle":"ATTENDANCE IN COURSE","totalQuestions":1,"pollType":"ATTENDANCE","showAnswerOnViewer":false,"anonymousJoin":false,"recordAttempt":"all","lmsAttempt":"first","maxAttempts":-1,"lmsHomeUrl":"https://panotesting.instructure.com","getReadyAllTimeLimit":10,"geofence":{},"syncLms":true},
    //   {"pk":"P#cf771094-05a5-491b-89f0-b546d07313cf","sk":"PUB#UC#579810","index2Pk":"C#5ab6d38e-3a75-4622-9bd2-af74cf0bed52","index2Sk":"U#20329ed7-a4c7-4586-9492-40990ffee25b#cf771094-05a5-491b-89f0-b546d07313cf","index4Pk":"18-03-2024","index4Sk":"2024-03-18T17:53:38.354Z","index5Pk":"I#b6f0e9d1-1844-4c7c-9b7c-9588e3cb6615#PUB#UC","index5Sk":"UC#579810","pollState":"STOPPED","pollTimeLimit":1800,"liveQuestionNo":1,"liveQuestionTime":1710780818354,"pollShareMode":"shareEach","pollTitle":"attendance","totalQuestions":1,"pollType":"ATTENDANCE","showAnswerOnViewer":false,"anonymousJoin":false,"recordAttempt":"all","lmsAttempt":"first","maxAttempts":-1,"lmsHomeUrl":"https://panotesting.instructure.com","getReadyAllTimeLimit":10,"geofence":{"lat":43.7595724,"lng":-79.3465447,"rad":10000},"syncLms":true}
    // ]`);
    console.log("attendances", res);
    setAttendanceOptions(res.map((key) => {
      const pollKey = key.pk.split("#")[1];
      const pollCode = key.sk.split("#")[2];
      return {
        value: `${pollKey}#${pollCode}`,
        label: ((key.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (key.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : key.pollTitle) +
          " (" + pollCode + ")",
        pollTitle: key.pollTitle,
        trimmedTitle: key.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH,
        pollType: POLL_TYPE.ATTENDANCE
      }
    }));
  };

  const columns = [
    { Header: 'Number', accessor: 'serialNo', width: 150 },
    { Header: 'Question', accessor: 'queTitle', width: 300 },
    { Header: 'Type', accessor: 'questionType', width: 160 },
    { Header: 'Status', accessor: 'isCorrect', width: 160 },
    { Header: 'Your Answer', accessor: 'selectedOption', width: 200 },
    // { Header: 'Correct Answer', accessor: 'correctAnswers', width: isMobileScreen ? 100 : 120 },
  ];

  useEffect(async () => {
    if (location?.state?.pollType === POLL_TYPE.ATTENDANCE) {
      setSelectedAttendanceInitialized(false);
      await getAttendanceData();
      await getCourses();
    } else {
      const { publishedPollDict } = await getPollData();
      if (location && location.state && location.state.pollKey && location.state.uniqueCode) {
        const key = location.state.pollKey + "#" + location.state.uniqueCode;
        // setSelectedPollUniqueKey(key);
        const publishedPoll = publishedPollDict[key];
        if (!publishedPoll) {
          return;
        }
        const pollTitle = publishedPoll.pollTitle;
        const label = ((pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : pollTitle) + " (" + location.state.uniqueCode + ")";
        setSelectedOption({ value: key, label: label, pollTitle: pollTitle, trimmedTitle: pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH, pollType: publishedPoll.pollType });

        setSelectedPollInitialized(false);
        const { questions, synced } = await getStudentResultByPoll(publishedPoll.pollKey, publishedPoll.pollCode, session.userId);
        publishedPoll.questions = questions;
        publishedPoll.synced = synced;
        setSelectedPoll(publishedPoll);
        setSelectedPollInitialized(true);
      }
    }
  }, [location]);

  const sortNumber = () => {
    if(!selectedPoll) {
      return;
    }
    let qs = selectedPoll.questions;
    if (numberSort === 0) {
      qs = qs.sort(function (a, b) {
        return b.serialNo - a.serialNo;
      });
    } else {
      qs = qs.sort(function (a, b) {
        return a.serialNo - b.serialNo;
      });
    
    }
    setSelectedPoll({...selectedPoll, questions: qs});
    setNumberSort(1-numberSort);
  };

  const sortType = () => {
    if (!selectedPoll) {
      return;
    }
    let qs = selectedPoll.questions;
    qs = qs.sort(function (a, b) {
      const typeA = a.questionType;
      const typeB = b.questionType;

      const priorityA = getPriority(typeA);
      const priorityB = getPriority(typeB);

      if (priorityA < priorityB) return -1 * typeSort;
      if (priorityA > priorityB) return 1 * typeSort;

      if (typeA < typeB) return -1 * typeSort;
      if (typeA > typeB) return 1 * typeSort;
      return 0;
    });
    setSelectedPoll({...selectedPoll, questions: qs});
    setTypeSort(-typeSort);
  };

  const getStatus = (question) => {
    if (selectedPoll.pollType.toUpperCase() === POLL_TYPE.SURVEY) {
      return "";
    }
    const {questionType, isCorrect, isGraded} = question;
    if (questionType === QUESTION_TYPES.SA.name) {
      if (isCorrect) {
        return CORRECT;
      }
      if (isGraded) {
        return INCORRECT;
      }
      if (!question.hasOwnProperty("selectedOption") || question.selectedOption === FIXED_ANSWER.UNANSWERED) {
        return FIXED_ANSWER.UNANSWERED;
      }
      return NOT_GRADED;
    }
    if (isCorrect) {
      return CORRECT;
    }
    if (!question.hasOwnProperty("selectedOption") || question.selectedOption === FIXED_ANSWER.UNANSWERED) {
      return FIXED_ANSWER.UNANSWERED;
    }
    return INCORRECT;
  }

  const sortStatus = () => {
    if (!selectedPoll) {
      return;
    }
    let qs = selectedPoll.questions;
    qs = qs.sort(function (a, b) {
      const statusA = getStatus(a).toLowerCase();
      const statusB = getStatus(b).toLowerCase();

      const priorityA = getPriority(statusA);
      const priorityB = getPriority(statusB);

      if (priorityA < priorityB) return -1 * statusSort;
      if (priorityA > priorityB) return 1 * statusSort;

      if (statusA < statusB) return -1 * statusSort;
      if (statusA > statusB) return 1 * statusSort;
      return 0;
    });
    setSelectedPoll({...selectedPoll, questions: qs});
    setStatusSort(-statusSort);
  }

  return (location?.state?.pollType === POLL_TYPE.ATTENDANCE ? <>
    <div className={styles.attendancesContainer} id="taken-attendances-container">
      <div className={styles.filterRow}>
        <div className={styles.filterContainer}>
          <div tabIndex={0} role={TEXTBOX} style={isNarrow ? { marginRight: 0, width: 85, textAlign: "right" } : { marginRight: 10 }}>{COURSE_FILTER_LABEL}</div>
          {/* <YuJaDropdown
            ariaLabel={courseFilter + ALL_COURSE_LABEL}
            data={courseOptions}
            getOptionLabel={item => `${item.courseCode} - ${item.courseName}`}
            getOptionValue={item => item.courseId}
            onChange={chooseCourse}
            value={courseFilter}
            containerStyle={{ marginRight: 15, minWidth: 200 }}
            style={{ padding: "8px 15px" }}
            disabled={loadingCourses}
            placeholder={SELECT_TEXT}
          /> */}
          
          <div className={styles.attendanceSelectWrapper}>
          <Select
            menuPlacement="auto"
            menuPosition="fixed"
            className={`${styles.select} ${styles.courseSelect}`}
            isLoading={loadingCourses}
            options={courseOptions}
            onChange={chooseCourse}
            value={courseFilter}
            placeholder={COURSE_PLACEHOLDER}
            id="gradebookSelectCourse"
            aria-label={courseFilter + ALL_COURSE_LABEL}
          />
          </div>
        </div>

        <div className={styles.filterContainer}>
          <div tabIndex={0} role={TEXTBOX} style={isNarrow ? { marginRight: 0, width: 85, textAlign: "right" } : { marginRight: 10 }}>{ATTENDANCE_FILTER_LABEL}</div>
          {/* <YuJaDropdown
            ariaLabel={attendanceFilter + ALL_ATTENDANCES_LABEL}
            data={attendanceOptions}
            getOptionLabel={item => item.label}
            getOptionValue={item => item.value}
            onChange={option => setAttendanceFilter(option.value)}
            value={attendanceFilter}
            containerStyle={{ marginRight: 15, minWidth: 200 }}
            style={{ padding: "8px 15px" }}
            disabled={loadingAttendances}
            placeholder={SELECT_TEXT}
          /> */}
          
          <div className={styles.attendanceSelectWrapper}>
          <Select
            menuPlacement="auto"
            menuPosition="fixed"
            className={styles.select}
            isLoading={loadingAttendances}
            options={attendanceOptions}
            onChange={option => setAttendanceFilter(option)}
            value={attendanceFilter}
            placeholder={ATTENDANCE_PLACEHOLDER}
            id="gradebookSelectAttendance"
            aria-label={ATTENDANCE_GRADEBOOK_DROPDOWN}
          />
          </div>
        </div>

        <YuJaButton
          type="primary"
          onClick={() => chooseAttendance(attendanceFilter.value)}
          style={{ fontSize: 14, fontWeight: 400, marginLeft: isNarrow ? 0 : 15, minWidth: 90 }}
          disabled={!attendanceFilter}
        >
          {GET_RESULT_TEXT}
        </YuJaButton>
      </div>

      {selectedAttendanceInitialized && <div className={styles.attendanceResultContainer}>
        {selectedAttendanceLoading ?
          <div className={styles.attendanceLoadingContainer}>
            <Spin tip="Loading..." size="large" spinning={true} />
          </div> : <>
          {isGraded ? 
            <Lottie
              options={{ loop: false, autoplay: true, animationData: isPresent && isGraded ? present : absent, rendererSettings: { preserveAspectRatio: "xMidYMid slice" } }}
              height={isPresent ? (isNarrow ? 200 : 300) : (isNarrow ? 100 : 150)}
              width={isPresent ? (isNarrow ? 200 : 300) : (isNarrow ? 100 : 150)}
              isClickToPauseDisabled={true}
            /> : <Ungraded style={{marginLeft: "auto", marginRight: "auto"}}/>
          }
            <div
              tabIndex={0}
              role={TEXTBOX}
              className={styles.attendanceResultText}
              style={{
                marginTop: isPresent ? (isNarrow ? 0 : 25) : (isNarrow ? 25 : 50),
                fontSize: isNarrow ? 18 : 24,
                lineHeight: isNarrow ? "normal" : "28px"
              }}
            >
              {isPresent && isGraded && ATTENDANCE_PRESENT_RESULT}
              {!isPresent && isGraded && ATTENDANCE_ABSENT_RESULT}

              {!isGraded &&
                <>
                  <div style={{fontWeight: "700", marginBottom: 20}}>
                    {ATTENDANCE_NOT_GRADED_TITLE}
                  </div>
                  {ATTENDANCE_NOT_GRADED_RESULT}
                </>
              }
              {/*{isPresent && isGraded ? ATTENDANCE_PRESENT_RESULT : !isPresent && isGraded ?  ATTENDANCE_ABSENT_RESULT : ATTENDANCE_NOTGRADED_RESULT}*/}
            </div>
          </>
        }
      </div>}
    </div>
  </> :
    <>
      <Modal
				id="answer-modal"
				show={showAnswerModal}
				onHide={() => setShowAnswerModal(false)}
				aria-labelledby="contained-modal-title-vcenter"
				backdrop={true}
				dialogClassName="answer-modal"
      >
				<Spin tip="Loading..." size="large" spinning={false}>
					<ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL} onClick={() => setShowAnswerModal(false)} className="modal-close" />
					<Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<Modal.Title tabIndex={0} role={TEXTBOX}>
							{YOUR_ANSWER_TITLE}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ wordBreak: "break-word" }}>
						{<div tabIndex={0} className={styles.answer}>{modalData}</div>}
					</Modal.Body>
				</Spin>
			</Modal>
      <div className={styles.container}>
        <div className={styles.titleBox}>
          <div className={styles.titleBoxIcon} />
          <div tabIndex={0} role={TEXTBOX} className={styles.titleBoxTitle}>{VIEW_TAKEN_POLLS_TITLE}</div>
        </div>

        {/* <div style={{ margin: "2%", width: "100%" }}>
          <Form >
            <Form.Label>Please select a poll</Form.Label>
            <Form.Select name="pollName"
              value={selectedPollUniqueKey}
              onChange={choosePoll}>
              <option id="sel" value="select">select...</option>
              {pollList.map((key) => {
                return <option
                  value={`${key.pollKey}#${key.pollCode}`}
                  key={`${key.pollKey}#${key.pollCode}`}>
                  {key.pollTitle} - {key.pollCode}
                </option>
              })}
            </Form.Select>
          </Form>
        </div> */}

        <div className={styles.searchBox}>
          <label tabIndex={0} htmlFor="react-select-3-input" role={TEXTBOX} style={{ marginRight: "10px" }}>{PLEASE_SELECT_POLL}</label>
          <Select
            className={styles.select}
            isLoading={pollsLoading}
            options={pollList.map((key) => {
              return {
                value: `${key.pollKey}#${key.pollCode}`,
                label: ((key.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH) ? (key.pollTitle.slice(0, POLL_TITLE_DROPDOWN_LENGTH) + '...') : key.pollTitle) +
                          " (" + key.pollCode + ")",
                pollTitle: key.pollTitle,
                trimmedTitle: key.pollTitle.length > POLL_TITLE_DROPDOWN_LENGTH,
                pollType: key.pollType ? key.pollType : POLL_TYPE.GRADED_POLL
              }
            })}
            onChange={choosePoll}
            value={selectedOption}
          />
        </div>
        
        {!!selectedOption && !!selectedOption.pollTitle && <div className="d-flex justify-content-center">
          <div tabIndex={0} role={TEXTBOX} className={styles.pollTitleTitle}>{selectedOption.pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY : POLL_TEXT} Title:</div>
          <div tabIndex={0} role={TEXTBOX} className={styles.pollTitleText}>{selectedOption.pollTitle}</div>
        </div>}

        {selectedPollInitialized &&
          <TakenPollTable
            institutionId={institutionId}
            selectedPoll={selectedPoll}
            columns={columns}
            setShowAnswerModal={setShowAnswerModal}
            setModalData={setModalData}  
            selectedPollLoading={selectedPollLoading}
            numberSort={numberSort}
            sortNumber={sortNumber}
            typeSort={typeSort}
            sortType={sortType}
            statusSort={statusSort}
            sortStatus={sortStatus}
          />
        }
      </div>
    </>
  );
}
import React, {useEffect, useState} from "react";
import {Draggable} from "react-beautiful-dnd";

import DragDropIcon from "../../images/drag_drop.svg";
import {
    CHARACTERS_TEXT,
    DELETE_OPT_BTN, DRAG_TITLE, LATEX_REG_EXP,
    MC_ANSWER_LENGTH, OPTION_LABEL,
    TOGGLE_MENU_BAR_TEXT,
} from "../../utils/constants";
import {notifyInfo, stripHTMLTags} from "../../utils/helpers";
import {updateAnswers, updateCorrectAnswers} from "../../utils/questionUpdate";
import {addOptionToQuestion} from "../../utils/questionUtils";
import {
    ADD_OPTIONS_INFO,
} from "../../utils/toast-message-constants";
import "../styles.css";
import UploadOptionImage from "../UploadOptionImage";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import {EditorContent, useEditor} from "@tiptap/react";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";
import classes from "../../components/Tiptap/Tiptap.module.css";
import {ReactComponent as TOGGLE} from "../../components/Tiptap/images/format.svg";
import {ReactComponent as DELETE} from "../../images/cancel.svg";
import {menuBarCollapsedStyle, menuBarExpandedStyle, TipTapMenuBar} from "../../components/Tiptap/TipTapMenuBar";
import {Color} from "@tiptap/extension-color";
import TextStyle from '@tiptap/extension-text-style';
import Highlight from "@tiptap/extension-highlight";

export default function MCQuestionOption({
                                             pollKey,
                                             changePollKey,
                                             optionData,
                                             selectedQuestion,
                                             questions,
                                             setQuestions,
                                             questionBankId,
                                             index,
                                             maxSize,
                                             warningShowed,
                                             setWarningShowed,
                                             questionBuilder = false,
                                             disabled = false,
                                         }) {
    const {isNarrow} = useCheckMainContainerNarrow();
    const {serialNo, optionsMap, correctAnswers, weightage, optionsImageMap = {}} = selectedQuestion;
    const gradeEnable = !!weightage;
    const [isDraggable, setIsDraggable] = useState(!questionBuilder);
    const {choice, optionValue} = optionData;
    const selectedQuestionIndex = serialNo - 1;

    const [collapsed, setCollapsed] = useState(true);

    let editor = useEditor({
        shouldRerenderOnTransaction: true,
        editorProps: {
            transformPastedHTML(text) {
                console.log(1234)
                let cleanText = stripHTMLTags(text);
                let replace = cleanText.replace(/[\n\r]+/g, '');
                return replace;
            },

            transformPastedText(text) {
                console.log(4321)
                let cleanText = stripHTMLTags(text);
                let replace = cleanText.replace(/[\n\r]+/g, '');
                return replace;
            },


        },
        extensions: [
            Underline,
            StarterKit,
            Placeholder.configure({
                placeholder: OPTION_LABEL + " " + choice.toUpperCase(),
            }),
            CharacterCount.configure({limit: MC_ANSWER_LENGTH}),
            TextStyle,
            Color,
            Highlight,
            // Mathematics.configure({
            //     regex: LATEX_REG_EXP,
            // }),
        ],
        onUpdate: ({editor}) => {
            handleAnswerChange(editor.getHTML(), choice);
            if (index + 1 === maxSize) {
                const newQuestions = addOptionToQuestion(
                    selectedQuestion,
                    questions
                );
                if (newQuestions) {
                    setQuestions(newQuestions);
                } else if (!warningShowed && !!setWarningShowed) {
                    setWarningShowed(true);
                    notifyInfo(ADD_OPTIONS_INFO);
                }
            }
        },
        content: optionValue,
    }, [serialNo]);

    useEffect(() => {
        if (optionValue !== editor.getHTML()) {
            editor.commands.setContent(optionValue);
        }

    }, [optionValue, editor]);


    const handleAnswerChange = (input, itemKey) => {
        setQuestions(
            updateAnswers(input, itemKey, selectedQuestionIndex, questions)
        );
    };
    const handleCorrectAnswerChange = (input) => {
        setQuestions(updateCorrectAnswers(input, selectedQuestionIndex, questions));
    };

    const handleDeleteOption = () => {
        let newQuestions = [];

        questions.forEach((q) => {
            if (q.serialNo === serialNo) {
                let updatedQuestion = {...q};
                let newOptionsMap = {};
                Object.entries(q.optionsMap).forEach(([key, value]) => {
                    if (key !== choice) {
                        if (key > choice) {
                            const newKey = String.fromCharCode(key.charCodeAt(0) - 1);
                            newOptionsMap[newKey] = value;
                        } else {
                            newOptionsMap[key] = value;
                        }
                    }
                });

                updatedQuestion.optionsMap = newOptionsMap;
                updatedQuestion.correctAnswers = q.correctAnswers.includes(choice) ? q.correctAnswers.filter(answer => answer !== choice) : q.correctAnswers;


                if (!!q.optionsImageMap) {
                    let newOptionsImageMap = {};
                    Object.entries(q.optionsImageMap).forEach(([key, value]) => {
                        if (key !== choice) {
                            if (key > choice) {
                                const newKey = String.fromCharCode(key.charCodeAt(0) - 1);
                                newOptionsImageMap[newKey] = value;
                            } else {
                                newOptionsImageMap[key] = value;
                            }
                        }
                    });
                    updatedQuestion.optionsImageMap = newOptionsImageMap;
                }

                // updatedQuestion["image" + choice] = null;
                newQuestions.push(updatedQuestion);
            } else {
                newQuestions.push({...q});
            }
        });
        setQuestions(newQuestions);
    };

    return (
        <Draggable
            draggableId={choice}
            index={index}
            key={choice}
            isDragDisabled={!isDraggable}
        >
            {(provided, snapshot) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                            gap: isNarrow || questionBuilder ? 5 : 10
                        }}
                    >
                        {!questionBuilder &&
                            <div
                                {...provided.dragHandleProps}
                                style={{width: (questionBuilder && disabled) ? 0 : 15, flexShrink: 0}}
                                title={(questionBuilder && disabled) ? "" : DRAG_TITLE}
                                tabIndex={-1}
                            >
                                <img src={DragDropIcon} alt="drag drop icon" width={15} height={25}/>
                            </div>

                        }
                        <span style={{
                            flexShrink: 0,
                            width: 20,
                            display: "flex",
                            justifyContent: "center",
                            fontSize: isNarrow || questionBuilder ? 16 : 20
                        }}>
                          {choice.toUpperCase()}
                        </span>

                        <span
                            style={{
                                flex: 1,
                                minWidth: 0,
                                border: "1px solid #858687",
                                borderRadius: 3,
                                display: "flex",
                                alignItems: "center",
                                background: "#FFF"
                            }}
                        >
                            {gradeEnable &&
                                <YuJaCheckbox
                                    size={20}
                                    tabIndex={0}
                                    disabled={disabled}
                                    checked={correctAnswers.includes(choice)}
                                    onClick={() => handleCorrectAnswerChange(choice)}
                                    style={{margin: questionBuilder || isNarrow ? 5 : 10, cursor: "pointer"}}
                                />
                            }
                            <span
                                style={{
                                    flex: 1,
                                    minWidth: 0,
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "relative",
                                    borderLeft: gradeEnable ? '1px solid #858687' : undefined
                                }}
                            >
                                <TipTapMenuBar
                                    editor={editor}
                                    style={collapsed ? menuBarCollapsedStyle : menuBarExpandedStyle}
                                />
                                <EditorContent editor={editor} style={{cursor: "text"}} className={classes.editorContentBox}/>
                                {!!editor.storage.characterCount.characters() &&
                                    <span className={classes.characterCountBox}>
                                        {editor.storage.characterCount.characters()} {CHARACTERS_TEXT}
                                    </span>
                                }
                            </span>
                        </span>

                        <div style={{display: "flex", gap: isNarrow || questionBuilder ? 5 : 10, alignItems: "center"}}>
                            <TOGGLE
                                style={{
                                    cursor: "pointer",
                                    width: isNarrow || questionBuilder ? 20 : 30,
                                    height: isNarrow || questionBuilder ? 20 : 30,
                                }}
                                title={TOGGLE_MENU_BAR_TEXT}
                                onClick={() => setCollapsed(!collapsed)}
                            />
                            <UploadOptionImage
                                optionsImageMap={optionsImageMap}
                                pollKey={pollKey}
                                changePollKey={changePollKey}
                                questionBuilder={questionBuilder}
                                disabled={disabled}
                                questions={questions}
                                setQuestions={setQuestions}
                                selectedQuestion={selectedQuestion}
                                optionData={optionData}
                                questionBankId={questionBankId}
                                setIsDraggable={setIsDraggable}
                            />

                            {(!questionBuilder || !disabled) && Object.keys(optionsMap).length > 1 &&
                                <DELETE
                                    style={{
                                        cursor: "pointer",
                                        width: isNarrow || questionBuilder ? 20 : 30,
                                        height: isNarrow || questionBuilder ? 20 : 30,
                                    }}
                                    title={DELETE_OPT_BTN}
                                    onClick={handleDeleteOption}
                                />
                            }
                        </div>
                    </div>
                </div>
            )}
        </Draggable>
    );
}

import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { usePPTWebView } from "../../hooks/usePPTWebView";
import CIQuestionOption from "./CIQuestionOption";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";
import {intToChar, reorderKeysByDragDrop} from "../../utils/helpers";


export default function CIQuestion({
                                     setQuestions,
                                     questions,
                                     selectedQuestion,
                                     pollType,
                                     pollKey,
                                     currQueDisplay,
                                     questionBankId=null, 
                                     
}) {
  const { optionsMap, serialNo } = selectedQuestion;
  const {isNarrow} = useCheckMainContainerNarrow();

  const handleAnswerOrderChange = (result) => {
      if (!result.destination || result.source.index === result.destination.index)  {
          return;
      }

    let newQuestions = [];
    questions.forEach((q) => {
        if (q.serialNo !== serialNo) {
            newQuestions.push(q);
        } else {
            const questionCopy = JSON.parse(JSON.stringify(q));
            let reorderKeys = reorderKeysByDragDrop(result.source.index, result.destination.index, Object.keys(questionCopy.optionsMap));
            let newOptionsMap = [];
            let newCorrectAnswers = [];
            for (let i = 0; i < reorderKeys.length; i++) {
                newOptionsMap[i] = questionCopy.optionsMap[parseInt(reorderKeys[i])];
                if (questionCopy.correctAnswers.includes(parseInt(reorderKeys[i]))) {
                    newCorrectAnswers.push(i);
                }
            }

            questionCopy.optionsMap = newOptionsMap;
            questionCopy.correctAnswers = newCorrectAnswers.sort();
            newQuestions.push(questionCopy);

        }
    });
    setQuestions(newQuestions);
  };


  return (
    <>
      <div className="container question-main-container" style={{margin: "0px", padding: "0px", maxWidth: "100%"}}>
          <DragDropContext onDragEnd={handleAnswerOrderChange}>
            <Droppable droppableId="droppable-multiple-choice-question">
              {(provided, snapshot) => (
                  <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      {...provided.dropHandleProps}
                      className="droppable-multiple-choice-question-options"
                      style={{marginTop: "10px", display: "flex", flexDirection: "column", gap: isNarrow ? 10 : 25}}
                  >
                  {optionsMap.map((option, index) => {
                    return (
                        <div key={index}>
                          <CIQuestionOption
                              optionData={option}
                              selectedQuestion={selectedQuestion}
                              questions={questions}
                              setQuestions={setQuestions}
                              index={index}
                              pollType={pollType}
                          />
                        </div>
                    );
                  })}
                  </div>
              )}
            </Droppable>
          </DragDropContext>
      </div>
    </>
  );
}

import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { ReactComponent as ARROW_UP } from "./images/arrow-up.svg";
import { ReactComponent as ARROW_DOWN } from "./images/arrow-down.svg";
import { ReactComponent as ARROW } from "../../images/arrow_right.svg";
import { ReactComponent as DRAG_DROP_ICON } from "../../images/drag-new.svg";
import {
  ASCII_CODE_A,
  ASCII_CODE_SMALL_A,
  BUTTON,
  CONFIRM_UNANSWERED_SUBMIT_CONTENT,
  CONFIRM_UNANSWERED_SUBMIT_TITLE,
  CORRECT_MODAL_BODY,
  CORRECT_MODAL_HEADER,
  DEFAULT_ENTRIES_PER_PARTICIPANT,
  DEFAULT_IMAGE_ALT,
  FITB_ANSWER_LENGTH,
  FIXED_ANSWER,
  INCORRECT_MODAL_BODY,
  INCORRECT_MODAL_HEADER,
  MCSSoptLower,
  OE_PLACE_HOLDER,
  POINTS_TEXT,
  PREMISE,
  QUESTION_TYPES,
  QUIZ_STATE,
  RESPONSE,
  SA_MODAL_BODY,
  SA_MODAL_HEADER,
  SA_PLACE_HOLDER,
  SUBMITTED_MODAL_TEXT,
  SUBMITTED_TOAST_TITLE,
  SUBMIT_TEXT,
  THUMBS_DOWN,
  THUMBS_UP,
  TIMEUP_MODAL_BODY,
  TIMEUP_MODAL_HEADER,
  SCREEN_TYPE,
  RESPONSIVE_SIDE_BAR_POINT,
  QUESTION,
  CLICK_IMAGE_TO_MARK_TEXT,
  YUJA_DROPDOWN_SIZE,
  PROFANITY_CENSOR_CONFIRMATION, PROFANITY_CENSOR_CONFIRMATION_TITLE
} from "../../utils/constants";
import {
  checkUnansweredNew,
  handleUserAns,
  newPageLog, notifyError,
  notifyInfo,
} from "../../utils/helpers";
import {decodeCIParam, encodeCIParam, getOption4CI, questionCodeToName} from "../../utils/questionUtils";
import CountdownTimer from "./CountdownTimer";
import "./styles.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import classes from "./PollReady.module.css";
import reactStringReplace from "react-string-replace";
import ImageViewer from "./ImageViewer";
import WordCloudEntries from "./WordCloudEntries";
import OpenEndedResultNew from "./openEndedResultNew";
import {LatexHTMLInline} from "../../components/Tiptap/latexParser";
import {useProfanityFilter} from "../../hooks/useProfanityFilter";
import GeneralConfirmationModal from "../../components/modals/GeneralConfirmationModal";

export default React.memo(PollReady, (prevProps, nextProps) => {
  return (prevProps.pollInfo === nextProps.pollInfo && prevProps.currentQuestion === nextProps.currentQuestion);
});

function PollReady({
                     pollInfo,
                     currentQuestion = {},
                     handleQuestionComplete,
                     handleSAQuestionComplete,
                     state,
                     stopped,
                     institutionId,
                    //  quesShareCount,
                     changeCurQue,
                     websocketState,
                     allSharecount,
                     totalQuestion,
                     setCountdownContent
}) {
  const {width: windowWidth, type: windowType, isTouchAble} = useWindowDimensions();
  const {checkResponseProfane} = useProfanityFilter();

  const { pollKey, uniqueCode, userName, pollTitle, showAnswerOnViewer} = pollInfo;
  const { question, questionEndTime, questionResponse = {} } = currentQuestion;
  const {
    queTitle,
    correctAnswers,
    optionsMap,
    optionsImageMap={},
    questionType,
    weightage = 0,
    timeLimit = 0,
    entries = DEFAULT_ENTRIES_PER_PARTICIPANT,
    serialNo,
    imageAlt,
    hasBlankRestriction = false
  } = question;
  const gradeEnabled = !!weightage;
  const timed = !!timeLimit;

  const { selectedOption = null, isCorrect = null } = questionResponse;
  const [isResponseCorrect, setIsResponseCorrect] = useState(isCorrect);
  const [modalShow, setModalShow] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const imageRef = useRef(null);
  // const timeoutRedirectRef = useRef(null);
  const { getAudienceResponseByUser, handleSubmitIndividualResponse, updateReaction } = ResponseApiCalls();
  const [thumbup, setThumbUp] = useState(false);
  const [thumbdown, setThumbDown] = useState(false);
  const [shownEntries, setShownEntries] = useState(entries); // (isNarrow || entries == 1 ? 1 : 2);
  const [alertShow, setAlertShow] = useState(false);
  const [profanityConfirmationShow, setProfanityConfirmationShow] = useState(false);
  const [clickedSub, setClickedSub] = useState(false);
  // const [hoveringThumbUp, setHoveringThumbUp] = useState(false);
  // const [hoveringThumbDown, setHoveringThumbDown] = useState(false);
  const [isTimeOut, setIsTimeOut] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [pollPanelOpen, setPollPanelOpen] = useState(false);
  const pollPanelRef = useRef();
  const [curAnswer, setCurAnswer] = useState(() => {
    if (!checkUnansweredNew(questionType, selectedOption)) {
      return selectedOption;
    }

    //FITB
    if (questionType === QUESTION_TYPES.FITB.name) {
      const ans = {};
      for (const key of Object.keys(correctAnswers)) {
        ans[key] = "";
      }
      return ans;
    } else if (questionType === QUESTION_TYPES.WC.name) {
      const ans = {};
      for (let i = 0; i < entries; i++) {
        ans[i] = "";
      }
      return ans;
    } else if (questionType === QUESTION_TYPES.MH.name) {
      const ans = {};
      for (let i = 0; i < correctAnswers.length; i++) {
        ans[i] = "";
      }
      return ans;
    } else if (questionType === QUESTION_TYPES.RK.name) {
      return optionsMap.filter(option => option !== "");
    } else if (!gradeEnabled && questionType === QUESTION_TYPES.MCSS.name) {
      return [];
    }else {
      return FIXED_ANSWER.UNANSWERED;
    }
  });

  const [isAnsweredState, setIsAnsweredState] = useState(!!selectedOption);
  // BEGIN VARIABLE INIT FOR QUESTION TYPES
  let MCSSelements = [];
  let newOptionsMap = questionType === QUESTION_TYPES.MCSS.name ? optionsMap : {};
  const MCSSnum = Object.keys(newOptionsMap).length;

  let TFelements = ["TF0", "TF1"];
  const TFnum = 2;
  const TFopt = ["True", "False"];

  let FITBElements = [];
  let idx = -1;

  if (questionType === QUESTION_TYPES.MCSS.name) {
    for (let i = 0; i < MCSSnum; i++) {
      MCSSelements.push("MCSS" + i);
    }
  } else if (questionType === QUESTION_TYPES.FITB.name) {
    for (const key of Object.keys(correctAnswers)) {
      FITBElements.push(key);
    }
  }


  // END VARIBALE INIT

  useEffect(() => {
    newPageLog("PollReady.js");

  }, []);

  if (document.getElementById("root") !== null) {
    document.getElementById("root").style.backgroundImage = "none";
  }
  // END DOCUMENT FORMATTING

  // const enableInput = () => {
  //   console.log("enableInput", questionType, gradeEnabled, curAnswer, isAnsweredState);
  //   if (questionType === QUESTION_TYPES.MCSS.name) {
  //     for (let i = 0; i < MCSSnum; i++) {
  //       if (document.getElementById("MCSS" + i) !== null)
  //         document.getElementById("MCSS" + i).disabled = false;
  //     }
  //     if (gradeEnabled && !isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED) {
  //       document.getElementById("sub-btn").style.display = "block";
  //     }

  //     if (!gradeEnabled && !isAnsweredState && !!curAnswer && curAnswer.length) {
  //       document.getElementById("sub-btn").style.display = "block";
  //     }
  //   } else if (questionType === QUESTION_TYPES.TF.name) {
  //     for (let i = 0; i < TFnum; i++) {
  //       document.getElementById("TF" + i).disabled = false;
  //     }

  //     if (!isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED) {
  //       document.getElementById("sub-btn").style.display = "block";
  //     }
  //   } else if (questionType === QUESTION_TYPES.SA.name) {
  //     document.getElementById("SA-IN").disabled = false;
  //     if (!isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED && Object.keys(curAnswer).length) {
  //       document.getElementById("sub-btn").style.display = "block";
  //     }
  //   } else if (questionType === QUESTION_TYPES.FITB.name) {
  //     for (const key of Object.keys(correctAnswers)) {
  //       document.getElementById(key).disabled = false;
  //     }
  //     if (!isAnsweredState) {
  //       document.getElementById("sub-btn").style.display = checkBlanks(curAnswer) ? "none" : "block";
  //     }
  //   } else if (questionType === QUESTION_TYPES.CI.name) {
  //     setCanInput(true);
  //     if (!isAnsweredState && !!curAnswer && curAnswer !== FIXED_ANSWER.UNANSWERED && Object.keys(curAnswer).length) {
  //       document.getElementById("sub-btn").style.display = "block";
  //     }

  //     //for multiple click survey
  //     // if (pollType === POLL_TYPE.SURVEY && !isAnsweredState && !!curAnswer && curAnswer.length) {
  //     //   document.getElementById("sub-btn").style.display = "block";
  //     // }
  //   } else if (questionType === QUESTION_TYPES.WC.name) {
  //     for (let i = 0; i < entries; i++) {
  //       document.getElementById(`WC-IN-${i}`).disabled = false;
  //     }
  //     if (!isAnsweredState) {
  //       document.getElementById("sub-btn").style.display = checkBlankAnswered(curAnswer) ? "none" : "block";
  //     }
  //   }
  // };

  // const disableInput = () => {
  //   if (document.getElementById("sub-btn")) {
  //     document.getElementById("sub-btn").style.display = "none";
  //   }
  //
  //   if (questionType === QUESTION_TYPES.MCSS.name) {
  //     for (let i = 0; i < MCSSnum; i++) {
  //       if (document.getElementById("MCSS" + i) !== null) {
  //         document.getElementById("MCSS" + i).disabled = true;
  //       }
  //     }
  //   } else if (questionType === QUESTION_TYPES.TF.name) {
  //     for (let i = 0; i < TFnum; i++) {
  //       document.getElementById("TF" + i).disabled = true;
  //     }
  //   } else if (questionType === QUESTION_TYPES.SA.name) {
  //     document.getElementById("SA-IN").disabled = true;
  //   } else if (questionType === QUESTION_TYPES.FITB.name) {
  //     for (const key of Object.keys(correctAnswers)) {
  //       document.getElementById(key).disabled = true;
  //     }
  //   } else if (questionType === QUESTION_TYPES.CI.name) {
  //     setCanInput(false);
  //   } else if (questionType === QUESTION_TYPES.WC.name) {
  //     for (let i = 0; i < entries; i++) {
  //       if(document.getElementById(`WC-IN-${i}`)) {
  //         document.getElementById(`WC-IN-${i}`).disabled = true;
  //       }
  //     }
  //   } else if (questionType === QUESTION_TYPES.MH.name) {
  //     for (let element of document.getElementsByName("MH-dropdown")) {
  //       element.disabled = false;
  //     }
  //   }
  // };

  useEffect(() => {
    if (isResponseCorrect === null) {
      return;
    }

    if (!gradeEnabled) {
      handleQuestionComplete();
      return;
    }


    const isTimeUp = stopped || isTimeOut;

    if (windowType === SCREEN_TYPE.MOBILE) {
      setModalShow(true);
      if (isTimeUp) {
        if (questionType === QUESTION_TYPES.SA.name) {
          setModalMessage(TIMEUP_MODAL_HEADER + ". " + SA_MODAL_BODY);
        } else {
          setModalMessage(TIMEUP_MODAL_HEADER + ". " + TIMEUP_MODAL_BODY);
        }
      } else {
        if (!clickedSub) {
          return;
        }

        if (questionType === QUESTION_TYPES.SA.name) {
          setModalMessage(SA_MODAL_BODY);
        } else if (gradeEnabled && showAnswerOnViewer && isResponseCorrect) {
          setModalMessage(CORRECT_MODAL_HEADER + ". " + CORRECT_MODAL_BODY);
        } else if (gradeEnabled && showAnswerOnViewer && !isResponseCorrect) {
          setModalMessage(INCORRECT_MODAL_HEADER + ". " + INCORRECT_MODAL_BODY);
        } else {
          setModalMessage(SUBMITTED_MODAL_TEXT);
        }
      }
    } else {
      if (isTimeUp) {
        if (questionType === QUESTION_TYPES.SA.name) {
          notifyInfo(SA_MODAL_BODY, true, TIMEUP_MODAL_HEADER);
        } else {
          notifyInfo(TIMEUP_MODAL_BODY, true, TIMEUP_MODAL_HEADER);
        }
      } else {
        if (!clickedSub) {
          return;
        }

        if (questionType === QUESTION_TYPES.SA.name) {
          notifyInfo(SA_MODAL_BODY, true, SA_MODAL_HEADER);
        } else if (gradeEnabled && showAnswerOnViewer && isResponseCorrect) {
          notifyInfo(CORRECT_MODAL_BODY, true, CORRECT_MODAL_HEADER);
        } else if (gradeEnabled && showAnswerOnViewer && !isResponseCorrect) {
          notifyInfo(INCORRECT_MODAL_BODY, true, INCORRECT_MODAL_HEADER);
        } else {
          notifyInfo(SUBMITTED_MODAL_TEXT, true, SUBMITTED_TOAST_TITLE);
        }
      }
    }

    if (state === QUIZ_STATE.POLL_TIME) {
      if (questionType === QUESTION_TYPES.SA.name) {
        document.body.classList.add("not-graded");
      } else if (isResponseCorrect) {
        document.body.classList.add("correct-ans");
      } else {
        document.body.classList.add("wrong-ans");
      }
    }

    return () => {
      if (questionType === QUESTION_TYPES.SA.name) {
        document.body.classList.remove("not-graded");
      } else if (isResponseCorrect) {
        document.body.classList.remove("correct-ans");
      } else {
        document.body.classList.remove("wrong-ans");
      }
    };
  }, [isResponseCorrect, isTimeOut]);

  useEffect(() => {
    if (!setCountdownContent) {
      return;
    }

    if (windowWidth <= RESPONSIVE_SIDE_BAR_POINT) {
      setCountdownContent(
          <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: 5}}>
            {!timed &&
                <span className={classes.navBarTitle}>{pollTitle}</span>
            }
            {!!timed &&
                <CountdownTimer
                    duration={timeLimit}
                    questionEndTime={questionEndTime}
                    setIsTimeOut={setIsTimeOut}
                    state={state}
                />
            }

            {windowType === SCREEN_TYPE.MOBILE && !pollPanelOpen &&
                <ARROW_DOWN style={{cursor: "pointer", flexShrink: 0}} onClick={() => setPollPanelOpen(true)}/>
            }

            {windowType === SCREEN_TYPE.MOBILE && !!pollPanelOpen &&
                <ARROW_UP style={{cursor: "pointer", flexShrink: 0}}/>
            }

            {!!pollPanelOpen &&
                <div ref={pollPanelRef} className={classes.pollPanelContainer}>
                  <span className={classes.cardTitle}>{pollTitle}</span>

                  {/*<div style={{display: "flex", gap: 5}}>*/}
                  {/* <span className={classes.badge} style={{backgroundColor: "#F8DCDB"}}>*/}
                  {/*   {questions.length} Questions*/}
                  {/* </span>*/}
                  {/*  <span className={classes.badge} style={{backgroundColor: "#D3DDF2"}}>*/}
                  {/*    {questions.filter(q => !!q.weightage).reduce(function (result, q) {*/}
                  {/*      return result + q.weightage;*/}
                  {/*    }, 0)} Points*/}
                  {/* </span>*/}
                  {/*</div>*/}
                </div>
            }
          </div>
      );
    } else {
      setCountdownContent(null);
    }
  }, [setCountdownContent, pollPanelOpen, windowWidth, questionEndTime, state, timed]);

  const autoScroll = () => {
    if (window.innerHeight >= 480 || window.innerWidth <= window.innerHeight) {
      return;
    }
    const mainContainer = document.getElementById("everything");
    const questionContainer = document.getElementById("question-container");
    if (!mainContainer || !questionContainer) {
      return;
    }
    const questionContainerTop = questionContainer.getBoundingClientRect().top;
    const mainContainerTop = mainContainer.getBoundingClientRect().top;
    const scrollDistance = questionContainerTop - mainContainerTop;
    mainContainer.scrollTo({
      top: mainContainer.scrollTop + scrollDistance,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTimeout(autoScroll, 100);
  }, [window.innerWidth, window.innerHeight, state]);

  const handleType = (e) => {
    e.stopPropagation();
    if (isAnsweredState) {
      return;
    }
    if (questionType === QUESTION_TYPES.SA.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById("SA-IN").value = "";
        setCurAnswer(FIXED_ANSWER.UNANSWERED);
      } else {
        const ans = {};
        ans["0"] = e.target.value.toString();
        setCurAnswer(ans);
      }
      // if (gradeEnabled) {
      //   document.getElementById("sub-btn").style.display = e.target.value === "" ? "none" : "block";
      // }
    } else if (questionType === QUESTION_TYPES.OE.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById("OE-IN").value = "";
        setCurAnswer(FIXED_ANSWER.UNANSWERED);
      } else {
        const ans = {};
        ans["0"] = e.target.value.toString();
        setCurAnswer(ans);
      }
      // if (gradeEnabled) {
      //   document.getElementById("sub-btn").style.display = e.target.value === "" ? "none" : "block";
      // }
    }  else if (questionType === QUESTION_TYPES.FITB.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById(e.target.id).value = "";
      }
      const copy = curAnswer === FIXED_ANSWER.UNANSWERED ? {} : { ...curAnswer };
      copy[e.target.getAttribute("id")] = e.target.value.toString();
      setCurAnswer(copy);
      // if (gradeEnabled) {
      //   document.getElementById("sub-btn").style.display = checkBlanks(copy) ? "none" : "block";
      // }
    }
  };

  const handleChooseOption = (optIdx) => {
    if (isAnsweredState) {
      return;
    }
    if (questionType === QUESTION_TYPES.MCSS.name) {
      let temp = [];
      // disabled grade question or correct answers only includes option
      if (!gradeEnabled || (!!correctAnswers && correctAnswers.length > 1)) {
        if (Array.isArray(curAnswer)) {
          temp = JSON.parse(JSON.stringify(curAnswer));
        } else if (curAnswer !== FIXED_ANSWER.UNANSWERED) {
          temp = [curAnswer];
        }
        if (temp.includes(optIdx)) {
          temp.splice(temp.indexOf(optIdx), 1);
        } else {
          temp.push(optIdx);
        }
      } else {
        temp.push(optIdx);
      }
      // document.getElementById("sub-btn").style.display = temp.length ? "block" : "none";
      setCurAnswer(temp);
    } else {
      // document.getElementById("sub-btn").style.display = "block";
      setCurAnswer(optIdx);
    }

  };

  const handleClickImage = (e) => {
    if (isAnsweredState || !isLoaded) {
      return;
    }

    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    const encodedX = encodeCIParam(x, imageRef.current.width);
    const encodedY = encodeCIParam(y, imageRef.current.height);
    const option = getOption4CI(encodedX, encodedY, optionsMap);
    setCurAnswer(option === FIXED_ANSWER.UNANSWERED
        ? FIXED_ANSWER.UNANSWERED
        : {x: encodedX, y: encodedY, selected: option});
    // document.getElementById("sub-btn").style.display = "block";
  }

  // const handleMultipleClick = (e) => {
  //   if (!isLoaded || !canInput) {
  //     return;
  //   }

  //   const x = e.nativeEvent.offsetX;
  //   const y = e.nativeEvent.offsetY;

  //   const encodedX = encodeCIParam(x, imageRef.current.width);
  //   const encodedY = encodeCIParam(y, imageRef.current.height);
  //   const option = getOption4CI(encodedX, encodedY, optionsMap);
  //   let curAnswerCopy = JSON.parse(JSON.stringify(curAnswer));

  //   curAnswerCopy.push(option === FIXED_ANSWER.UNANSWERED
  //       ? FIXED_ANSWER.UNANSWERED
  //       : {x: encodedX, y: encodedY, selected: option});

  //   if (curAnswerCopy.includes(FIXED_ANSWER.UNANSWERED)) {
  //     curAnswerCopy = curAnswerCopy.filter(answer => answer !== FIXED_ANSWER.UNANSWERED);
  //   }
  //   setCurAnswer(curAnswerCopy);
  //   document.getElementById("sub-btn").style.display = "block";
  // }

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (pollPanelOpen && !!pollPanelRef.current && !!pollPanelRef.current
          && !pollPanelRef.current.contains(event.target)
      ) {
        setPollPanelOpen(false);
      }
    };

    if (pollPanelOpen) {
      document.addEventListener('click', handleGlobalClick, true);
    }
    return () => {
      document.removeEventListener('click', handleGlobalClick)
    };
  }, [pollPanelOpen]);


  useEffect(() => {
    if (!!questionResponse && !!Object.entries(questionResponse).length) {
      return;
    }
    const liveQuestion = JSON.parse(JSON.stringify(question));
    const answer = {
      serialNo: liveQuestion.serialNo,
      questionType: liveQuestion.questionType,
      queTitle: liveQuestion.queTitle,
      optionsMap: liveQuestion.optionsMap,
      isCorrect: false,
      attemptsCount: 1,
      selectedOption: FIXED_ANSWER.UNANSWERED,
      correctAnswers: liveQuestion.correctAnswers,
    };

    handleSubmitIndividualResponse(pollInfo, gradeEnabled, answer, true).then();

  }, []);


  useEffect(() => {
    let timeOutId = 0;
    if (stopped || (timed && isTimeOut)) {
      if (isAnsweredState === false) {
        handleSub(true);
      }

      timeOutId = setTimeout(() => {
        if (questionType === QUESTION_TYPES.SA.name && gradeEnabled) {
          handleSAQuestionComplete();
        } else {
          handleQuestionComplete();
        }
      }, 5000);
    }

    return () => {
      if (!!timeOutId) {
        clearTimeout(timeOutId);
      }
    }
  }, [stopped, timed, isTimeOut]);

  const alertConfig = {
    title: CONFIRM_UNANSWERED_SUBMIT_TITLE,
    okText: SUBMIT_TEXT,
    submit: () => {
      setAlertShow(false);
      handleSub();
    },
    close:  () => setAlertShow(false),
  };

  const confirmationConfig = {
    title: PROFANITY_CENSOR_CONFIRMATION_TITLE,
    close:  () => setProfanityConfirmationShow(false),
  };

  const handleClickSub = (e) => {
    e.stopPropagation();
    if (!weightage && !isAnsweredState && checkUnansweredNew(questionType, curAnswer)) {
      setAlertShow(true);
    } else {
      setClickedSub(true);
      handleSub();
    }
  };

  const handleSub = async (needEnd=false) => {
    let copy = JSON.parse(JSON.stringify(curAnswer));
    if (checkUnansweredNew(questionType, copy)) {
      copy = FIXED_ANSWER.UNANSWERED;
    }

    if (needEnd) {//silently remove profanity words
      let correction = checkResponseProfane(questionType, copy);
      copy = !!correction ? correction : copy;
      setCurAnswer(copy);
    } else {
      let correction = checkResponseProfane(questionType, copy);
      if (!!correction) {
        setProfanityConfirmationShow(true);
        setCurAnswer(correction);
        return;
      }
    }

    const isCorr = handleUserAns(question, copy);
    setIsAnsweredState(true);
    setIsResponseCorrect(isCorr);
    await handleSubmitResponse(copy, isCorr);
    const curQueCopy = JSON.parse(JSON.stringify(currentQuestion));
    curQueCopy.questionResponse = { selectedOption: copy, isCorrect: isCorr };
    changeCurQue(curQueCopy);
    // handleMCTFHighlight();
  };

  const handleSubmitResponse = async (ansIn, isCorr) => {
    const liveQuestion = question;
    const answer = {
      serialNo: liveQuestion.serialNo,
      questionType: liveQuestion.questionType,
      queTitle: liveQuestion.queTitle,
      optionsMap: liveQuestion.optionsMap,
      isCorrect: isCorr,
      attemptsCount: 1,
      selectedOption: ansIn,
      correctAnswers: liveQuestion.correctAnswers,
    };

    await handleSubmitIndividualResponse(pollInfo, gradeEnabled, answer);
  };

  const handleReaction = async (reaction) => {
    if (reaction === 1) {
      if(thumbdown === true) {
        setThumbDown(false);
      }
      setThumbUp(true);

    }
    else {
      if(thumbup === true) {
        setThumbUp(false);
      }
      setThumbDown(true);
    }

    const reactionMap = {};
    let allShareArray = JSON.parse(allSharecount.replace(/null/g, "null"));
    reactionMap[allShareArray[serialNo - 1]] = [reaction];
    const questionReactionMap = {};
    questionReactionMap[serialNo] = reactionMap;
    await updateReaction(pollKey, uniqueCode, questionReactionMap, null , serialNo);
  }

  useEffect(async () => {
    if (!allSharecount) {
      return;
    }
    let res = await getAudienceResponseByUser(userName, uniqueCode);
    if(!res.hasOwnProperty("data")) {
      res.data = [];
    }
    let currentPollRes = res.data;
    let isSet = false;
    console.log(currentPollRes);
    let allShareArray = JSON.parse(allSharecount.replace(/null/g, "null"));
    for (const tempResult of currentPollRes) {
      if (tempResult.reactionMap && tempResult.reactionMap[serialNo]) {
        if (tempResult.reactionMap[serialNo][allShareArray[serialNo - 1]][0] === "1") {
          setThumbUp(true);
          setThumbDown(false);
          isSet= true;
        } else if (tempResult.reactionMap[serialNo][allShareArray[serialNo - 1]][0] === "2") {
          setThumbDown(true);
          setThumbUp(false);
          isSet= true;
        }
      }  else if (tempResult.reaction && tempResult.reaction[serialNo]) {
        if (tempResult.reaction[serialNo][0] === "1") {
          setThumbUp(true);
          setThumbDown(false);
          isSet= true;
        } else if (tempResult.reaction[serialNo][0] === "2") {
          setThumbDown(true);
          setThumbUp(false);
          isSet= true;
        }
      }
    }
    if (!isSet) {
      setThumbUp(false);
      setThumbDown(false);
    }
  }, [allSharecount]);


  const onLoad = () => {
    setIsLoaded(true);
  }

  // const handleMouseEnterThumbUp = (reaction) => {
  //   if (reaction === 1) {
  //     setHoveringThumbUp(true);
  //   }
  //   else if (reaction === 2) {
  //     setHoveringThumbDown(true)
  //   }
  // };

  // const handleMouseLeaveThumbUp = (reaction) => {
  //   if (reaction === 1) {
  //     setHoveringThumbUp(false);
  //   }
  //   else if (reaction === 2) {
  //     setHoveringThumbDown(false)
  //   }
  // };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceKey = result.source.index;
    const destinationKey = result.destination.index;

    let newSelectedOption = [ ...curAnswer ];
    const [removed] = newSelectedOption.splice(sourceKey, 1);
    newSelectedOption.splice(destinationKey, 0, removed);
    setCurAnswer(newSelectedOption);
  };

  // const onMouseEnter = (idx) => {
  //   document.getElementById(`coordinate-close${idx}`).style.display = "block";
  // }

  // const onMouseLeave = (idx) => {
  //   document.getElementById(`coordinate-close${idx}`).style.display = "none";
  // }

  // const onDelete = (idx) => {
  //   const curAnswerCopy = JSON.parse(JSON.stringify(curAnswer));
  //   curAnswerCopy.splice(idx, 1);
  //   if (!curAnswerCopy.length) {
  //     document.getElementById("sub-btn").style.display = "none";
  //   }
  //   setCurAnswer(curAnswerCopy);
  // }
  const renderTitle = () => {
    if (questionType !== QUESTION_TYPES.FITB.name) {
      return <LatexHTMLInline>{queTitle}</LatexHTMLInline>;
    }

    let blankNameArr = Object.keys(correctAnswers).map(str => `\\(${str}\\)`);
    const regex = new RegExp(`(${blankNameArr.join("|")})`, "g");

    return (
        <span style={{marginRight: 5, lineHeight: "unset"}}>
           {reactStringReplace(queTitle, regex, (blank, i) => {

             const match = !!blank ? blank.match(/\(([^)]+)\)/) : null;
             if (!match) {
               return "";
             }
             const blankName = match[1];

             return (
                 <input
                     key={i}
                     id={blankName}
                     className={`${classes.FITBInputBox} ${!!isAnsweredState ? classes.notAllowed : ""}`}
                     disabled={!!isAnsweredState}
                     onChange={handleType}
                     maxLength={FITB_ANSWER_LENGTH}
                     placeholder={SA_PLACE_HOLDER}
                     type={"text"}
                     value={checkUnansweredNew(questionType, curAnswer)
                     || !curAnswer.hasOwnProperty(blankName)
                           ? ""
                           : curAnswer[blankName]
                     }
                 />
             );
           })}
       </span>
    );

  }



  return (
      <div
          className={`${classes.parentContainer} ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}
          style={{paddingTop: windowType !== SCREEN_TYPE.MOBILE ? 10 : undefined}}
      >
        <GeneralConfirmationModal
            content={PROFANITY_CENSOR_CONFIRMATION}
            show={profanityConfirmationShow}
            config={confirmationConfig}
        />
        <YuJaGeneralAlert
            show={alertShow}
            setModalShow={setAlertShow}
            content={CONFIRM_UNANSWERED_SUBMIT_CONTENT}
            config={alertConfig}
        />
        <div className={`${classes.mainCard} ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}>
          {windowType !== SCREEN_TYPE.MOBILE &&
              <div className={classes.cardHeader}>
                <span className={classes.cardTitle}>{pollTitle}</span>
                <span/>
              </div>
          }
          <div className={classes.cardBody}>
            <div className={classes.mainContainer}>
              <div className={classes.mainContainerBody}>
                <div className={classes.mainContainerRow}>
                  <span style={{fontSize: 18, fontWeight: "500", lineHeight: "22px"}}>
                    {QUESTION} {serialNo}
                  </span>
                  {!!gradeEnabled &&
                      <span
                          className={classes.badge}
                          style={{width: "fit-content", padding: "0 10px", backgroundColor: "#E4E3F6"}}
                      >
                        {weightage} {POINTS_TEXT}
                      </span>
                  }
                  <span style={{flex: 1}}/>
                  <span
                      className={`${classes.thumbUpIcon} ${thumbup ? classes.active : ""}`}
                      onClick={() => handleReaction(1)}
                      tabIndex={0}
                      aria-label={THUMBS_UP}
                      role={BUTTON}
                  />
                  <span
                      className={`${classes.thumbDownIcon} ${thumbdown ? classes.active : ""}`}
                      onClick={() => handleReaction(2)}
                      tabIndex={0}
                      aria-label={THUMBS_DOWN}
                      role={BUTTON}
                  />
                  {!!timed && windowWidth > RESPONSIVE_SIDE_BAR_POINT &&
                      <>
                        <span/>
                        <CountdownTimer
                            duration={timeLimit}
                            questionEndTime={questionEndTime}
                            setIsTimeOut={setIsTimeOut}
                            state={state}
                        />
                      </>
                  }
                </div>
                <div className={classes.mainContainerRow}>
                  <span style={{color: "#6B6B6B"}}>
                    {questionCodeToName(questionType)}
                  </span>
                </div>
                <div
                    className={`${
                        !!question.imageURL && questionType !== QUESTION_TYPES.CI.name && windowType === SCREEN_TYPE.DESKTOP
                            ? classes.gridLayout
                            : classes.straightLayout
                    }`}
                >
                  <div className={classes.mainContainerRow} style={{fontSize: 16}}>
                    {renderTitle()}
                  </div>

                  {!!question.imageURL && questionType !== QUESTION_TYPES.CI.name &&
                      <div className={classes.imageBox}>
                        {!question.directLinkEvp &&
                            <ImageViewer alt={imageAlt} imageURL={question.imageURL}/>
                        }
                        {!!question.directLinkEvp &&
                            <iframe allowFullScreen style={{width: 270, height: 270}} src={question.imageURL}/>
                        }
                      </div>
                  }
                  {questionType === QUESTION_TYPES.CI.name &&
                      <>
                        <span
                            className={classes.mainContainerRow}
                            style={{fontWeight: "600", justifyContent: "center", marginTop: 10}}
                        >
                          {CLICK_IMAGE_TO_MARK_TEXT}
                        </span>
                        <div className={classes.imageContainer}
                             style={{width: 320, height: 320, alignSelf: "center"}}
                        >
                          <div style={{position: "relative", maxWidth: "100%", maxHeight: "100%"}}>
                            <img
                                style={{maxWidth: "100%", maxHeight: "100%"}}
                                ref={imageRef}
                                src={question.imageURL}
                                onLoad={onLoad}
                                onClick={handleClickImage}
                                alt={DEFAULT_IMAGE_ALT}
                                tabIndex={0}
                                aria-label={imageAlt ? imageAlt : DEFAULT_IMAGE_ALT}
                            />
                            {isLoaded
                                && !!curAnswer
                                && curAnswer.hasOwnProperty("x")
                                && curAnswer.hasOwnProperty("y")
                                &&
                                <div
                                    className={classes.coordinate}
                                    style={{
                                      position: "absolute",
                                      top: `${decodeCIParam(curAnswer.y, imageRef.current.height) - 8}px`,
                                      left: `${decodeCIParam(curAnswer.x, imageRef.current.width) - 8}px`
                                    }}
                                />
                            }
                          </div>
                        </div>
                      </>
                  }


                  <div className={classes.optionsContainer}>
                    {questionType === QUESTION_TYPES.MCSS.name &&
                        <>
                          {MCSSelements.map((value, index) => {
                            const optIdx = String.fromCharCode(ASCII_CODE_SMALL_A + index);
                            const optIdxDisplay = String.fromCharCode(ASCII_CODE_A + index);
                            const isSelectedOpt = (!!curAnswer && optIdx === curAnswer)
                                || (Array.isArray(curAnswer) && curAnswer.includes(MCSSoptLower[index]));

                            return (
                                <div
                                    key={index}
                                    tabIndex={0}
                                    className={`${classes.optionBar} 
                                    ${!isTouchAble ? classes.notTouchAble : ""}
                                    ${isSelectedOpt ? classes.active : ""} 
                                    ${ isAnsweredState ? classes.notAllowed : ""}`}
                                    onClick={() => handleChooseOption(optIdx)}
                                >
                                  <span
                                      className={`${classes.optionIndex} ${isSelectedOpt ? classes.active : ""}`}
                                      id={"MCSSLetter" + index}
                                  >
                                    {optIdxDisplay}
                                  </span>
                                  {!!optionsImageMap[optIdx]?.imageURL &&
                                      <ImageViewer
                                          size={38}
                                          showEnlargeByDefault={true}
                                          imageURL={optionsImageMap[optIdx]?.imageURL}
                                      />
                                  }
                                  <LatexHTMLInline>{newOptionsMap[optIdx]}</LatexHTMLInline>
                                </div>
                            );
                          })}
                        </>
                    }

                    {questionType === QUESTION_TYPES.SA.name &&
                        <textarea
                            className={`${classes.shortAnswerTextArea} ${ isAnsweredState ? classes.notAllowed : ""}`}
                            disabled={!!isAnsweredState}
                            id="SA-IN"
                            name="sa-in"
                            placeholder={SA_PLACE_HOLDER}
                            value={checkUnansweredNew(questionType, curAnswer) ? "" : curAnswer["0"]}
                            onChange={handleType}
                        />
                    }
                    {questionType === QUESTION_TYPES.TF.name &&
                        <>
                          {TFopt.map((value, index) => {
                            const isSelectedOpt = !!curAnswer && value === curAnswer;
                            return (
                                <div
                                    key={index}
                                    tabIndex={0}
                                    className={`${classes.optionBar} 
                                    ${!isTouchAble ? classes.notTouchAble : ""}
                                    ${isSelectedOpt ? classes.active : ""} 
                                    ${ isAnsweredState ? classes.notAllowed : ""}`}
                                    onClick={() => handleChooseOption(value)}
                                >
                                  {value}
                                </div>

                            );
                          })}
                        </>
                    }
                    {questionType === QUESTION_TYPES.WC.name &&
                        <WordCloudEntries
                            currEntries={entries}
                            shownEntries={shownEntries}
                            setShownEntries={setShownEntries}
                            currentSelectedOption={curAnswer}
                            setCurrentSelectedOption={setCurAnswer}
                            isAnsweredState={isAnsweredState}
                        />
                    }

                    {questionType === QUESTION_TYPES.MH.name &&
                        <div style={{ display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                          {Array.isArray(correctAnswers) &&
                              correctAnswers.filter(i => i.length > 1).map((element, index) =>
                                  <div
                                      key={index}
                                      id={"Option" + index}
                                      className={windowWidth > RESPONSIVE_SIDE_BAR_POINT ? classes.MHGridContainer : classes.MHVerticalContainer}
                                  >
                                    {!(windowWidth > RESPONSIVE_SIDE_BAR_POINT) && index !== 0 &&
                                        <div className={classes.MHDivider}/>
                                    }

                                    {!(windowWidth > RESPONSIVE_SIDE_BAR_POINT) &&
                                        <div style={{fontWeight: "700", fontSize: 15}}> {PREMISE} </div>
                                    }
                                    <div className={classes.MHPremise}>
                                      <LatexHTMLInline>{element[0]}</LatexHTMLInline>
                                    </div>
                                    {windowWidth > RESPONSIVE_SIDE_BAR_POINT && <ARROW/>}
                                    {!(windowWidth > RESPONSIVE_SIDE_BAR_POINT) &&
                                        <div style={{fontWeight: "700", fontSize: 15}}> {RESPONSE} </div>
                                    }

                                    <YuJaDropdown
                                        size={YUJA_DROPDOWN_SIZE.LARGE}
                                        data={optionsMap}
                                        getOptionLabel={option => <LatexHTMLInline>{option.length > 1 ? option[1] : option[0]}</LatexHTMLInline>}
                                        getOptionValue={option => option.length > 1 ? option[1] : option[0]}
                                        value={curAnswer[index]}
                                        placeholder={"Select an option"}
                                        onChange={(option) => {
                                          let newAnswer = {[index]: option.length > 1 ? option[1] : option[0]};
                                          if (curAnswer !== FIXED_ANSWER.UNANSWERED) {
                                            newAnswer = {
                                              ...curAnswer,
                                              [index]: option.length > 1 ? option[1] : option[0]
                                            };
                                          }

                                          setCurAnswer(newAnswer);
                                        }}
                                        listStyle={{borderColor: "#E6E6E6"}}
                                        itemStyle={{borderColor: "#E6E6E6", color: "#4D4D4D"}}
                                        style={{
                                          color: !!curAnswer[index] ? "#000" : "#4D4D4D",
                                          border: "1px solid #E6E6E6",
                                          boxShadow: "0 0 1px 0 #00000033",
                                        }}
                                        arrowSize={"24px"}
                                    />
                                  </div>
                              )
                          }
                        </div>
                    }

                    {questionType === QUESTION_TYPES.RK.name &&
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="droppable-selected-options">
                            {(provided) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.droppableProps}
                                    {...provided.dropHandleProps}
                                    style={{display: "flex", flexDirection: "column", gap: 15}}
                                >
                                  {curAnswer.filter(i => i !== "").map((value, index) => (
                                      <Draggable draggableId={"selected option" + index} key={"selected option" + index} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                              <div className={`${classes.RKOptionContainer} ${!!snapshot.isDragging ? classes.active : ""}`}>
                                                <DRAG_DROP_ICON/>
                                                <div className={`${classes.optionIndex} ${!!snapshot.isDragging ? classes.active : ""}`}>{index + 1}</div>
                                                <LatexHTMLInline>{value}</LatexHTMLInline>
                                              </div>
                                            </div>
                                        )}
                                      </Draggable>
                                  ))}
                                </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                    }

                    {questionType === QUESTION_TYPES.OE.name &&
                        <>
                           <textarea
                               id="OE-IN"
                               name="oe-in"
                               className={`${classes.shortAnswerTextArea} ${!!isAnsweredState ? classes.notAllowed : ""}`}
                               disabled={!!isAnsweredState}
                               style={{flexShrink: 0}}
                               placeholder={OE_PLACE_HOLDER}
                               value={checkUnansweredNew(questionType, curAnswer)
                                   ? ""
                                   : curAnswer["0"]
                               }
                               onChange={handleType}
                           />
                          <OpenEndedResultNew
                              pollKey={pollKey}
                              uniqueCode={uniqueCode}
                              question={question}
                              queTitle={queTitle}
                          />
                        </>
                    }

                  </div>
                </div>
              </div>
              <div className={classes.mainContainerFooter}>
                <span style={{flex: 1}}/>
                {!isAnsweredState &&
                    <YuJaButton
                        onClick={handleClickSub}
                        style={{width: 100, height: 36, flexShrink: 0, fontSize: 15}}
                    >
                      {SUBMIT_TEXT}
                    </YuJaButton>
                }
              </div>
            </div>
          </div>
        </div>

        {modalShow &&
            <div style={{backgroundColor: '#282828', borderRadius: 8, padding: 15, flexDirection: "column", display: "flex", alignItems: "center", width: "90%", position: "absolute", left: "5%", bottom: "5%"}}>
              <div style={{display: "flex", alignItems: "center", width: "100%", gap: 5}}>
                <div tabIndex={0} style={{ fontSize: 13, color: "#FFFFFF", textAlign: "left", flex: 1, minWidth: 0}}>
                  {modalMessage}
                </div>
                <div tabIndex={0} style={{ fontSize: 13, color: "#6793FA", }} onClick={() => setModalShow(false)}>
                  Close
                </div>
              </div>
            </div>
        }
      </div>
  );
}

import classes from "./ClassResult.module.css";
import styles from "./styles.module.css";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import React from "react";
import {checkUnansweredNew} from "../../utils/helpers";
import {ASCII_CODE_A, QUESTION_TYPES, RESPONSIVE_SIDE_BAR_POINT} from "../../utils/constants";
import ImageViewer from "./ImageViewer";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ReactComponent as CORRECT_ICON } from "../../images/grade-question-correct-icon.svg";
import { ReactComponent as INCORRECT_ICON } from "../../images/grade-question-incorrect-icon.svg";
import { ReactComponent as ARROW } from "../../images/arrow_right.svg";
import {LatexHTMLInline} from "../../components/Tiptap/latexParser";

export default function RenderYourResponse({
                                              questionData,
                                              showAnswerOnViewer,
                                              curUserResponses=[],
                                              dataLoading
}) {
    let {width: windowWidth} = useWindowDimensions();
    const {weightage, correctAnswers, serialNo, questionType, optionsImageMap={}, optionsMap} = questionData;
    let queResponse = !!curUserResponses
        ? curUserResponses.find(q => q.serialNo === serialNo)
        : null;
    queResponse = !!queResponse ? queResponse : {};
    let {selectedOption} = queResponse;
    let shouldShowCorrect = !!weightage && !!showAnswerOnViewer;

    if (!!dataLoading) {
        return (
            <>
                <span className={classes.label}>
                    <span>Your Responses</span>
                </span>

                {!!dataLoading &&
                    <div className={styles.optionContainer}>
                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                            <Skeleton borderRadius={4} height={40} width={"min(80vw, 500px)"}
                                      style={{position: "relative", borderRadius: "10px"}}/>
                        </SkeletonTheme>
                    </div>
                }

            </>
        );
    }

    if (checkUnansweredNew(questionType, selectedOption)) {
        return (
            <>
                <span tabIndex={0} className={classes.label}>Your Response</span>
                <div tabIndex={0} className={classes.yourResponseContainer}>-</div>
            </>
        );
    }


    return (
        <>
            <span tabIndex={0} className={classes.label}>Your Response</span>
            {questionType === QUESTION_TYPES.MCSS.name && !Array.isArray(selectedOption) &&
                <div
                    tabIndex={0}
                    className={`${classes.yourResponseContainer}
                        ${!!shouldShowCorrect && correctAnswers.includes(selectedOption) ? classes.correct : ""}
                        ${!!shouldShowCorrect && !correctAnswers.includes(selectedOption) ? classes.incorrect : ""}
                        `}
                >
                    {!!shouldShowCorrect && correctAnswers.includes(selectedOption) &&
                        <span className={`${classes.indicator} ${classes.correct}`}>
                                <CORRECT_ICON/>
                            </span>
                    }

                    {!!shouldShowCorrect && !correctAnswers.includes(selectedOption) &&
                        <span className={`${classes.indicator} ${classes.incorrect}`}>
                                <INCORRECT_ICON/>
                            </span>
                    }

                    <span className={classes.optionIndex}>
                        {selectedOption.toString().toUpperCase()}
                    </span>
                    {!!optionsImageMap[selectedOption]?.imageURL &&
                        <ImageViewer
                            size={38}
                            showEnlargeByDefault={true}
                            imageURL={optionsImageMap[selectedOption]?.imageURL}
                        />
                    }
                    <LatexHTMLInline>{optionsMap[selectedOption]}</LatexHTMLInline>
                </div>
            }

            {questionType === QUESTION_TYPES.MCSS.name && Array.isArray(selectedOption) &&
                (selectedOption.map((option, i) =>
                    <div
                        tabIndex={0}
                        key={i}
                        className={`${classes.yourResponseContainer} 
                            ${!!shouldShowCorrect && correctAnswers.includes(option) ? classes.correct : ""}
                            ${!!shouldShowCorrect && !correctAnswers.includes(option) ? classes.incorrect : ""}
                            `}
                    >
                        {!!shouldShowCorrect && correctAnswers.includes(option) &&
                            <span className={`${classes.indicator} ${classes.correct}`}>
                                    <CORRECT_ICON/>
                                </span>
                        }

                        {!!shouldShowCorrect && !correctAnswers.includes(option) &&
                            <span className={`${classes.indicator} ${classes.incorrect}`}>
                                    <INCORRECT_ICON/>
                                </span>
                        }
                        <span className={classes.optionIndex}>
                                {option.toString().toUpperCase()}
                            </span>
                        {!!optionsImageMap[option]?.imageURL &&
                            <ImageViewer
                                size={38}
                                showEnlargeByDefault={true}
                                imageURL={optionsImageMap[option]?.imageURL}
                            />
                        }
                        <LatexHTMLInline>{optionsMap[option]}</LatexHTMLInline>
                    </div>
                ))}
            {questionType === QUESTION_TYPES.TF.name &&
                <div
                    tabIndex={0}
                    className={`${classes.yourResponseContainer}
                        ${!!shouldShowCorrect && correctAnswers === selectedOption ? classes.correct : ""}
                        ${!!shouldShowCorrect && correctAnswers !== selectedOption ? classes.incorrect : ""}
                        `}
                >
                    {!!shouldShowCorrect && correctAnswers === selectedOption &&
                        <span className={`${classes.indicator} ${classes.correct}`}>
                                <CORRECT_ICON/>
                            </span>
                    }

                    {!!shouldShowCorrect && correctAnswers !== selectedOption &&
                        <span className={`${classes.indicator} ${classes.incorrect}`}>
                                <INCORRECT_ICON/>
                            </span>
                    }
                    <span aria-label={selectedOption} >
                        {selectedOption}
                    </span>
                </div>
            }

            {questionType === QUESTION_TYPES.CI.name &&
                <div
                    tabIndex={0}
                    className={`${classes.yourResponseContainer}
                        ${!!shouldShowCorrect && correctAnswers.includes(parseInt(selectedOption.selected)) ? classes.correct : ""}
                        ${!!shouldShowCorrect && !correctAnswers.includes(parseInt(selectedOption.selected)) ? classes.incorrect : ""}
                        `}
                >
                    {!!shouldShowCorrect && correctAnswers.includes(parseInt(selectedOption.selected)) &&
                        <span className={`${classes.indicator} ${classes.correct}`}>
                                <CORRECT_ICON/>
                            </span>
                    }

                    {!!shouldShowCorrect && !correctAnswers.includes(parseInt(selectedOption.selected)) &&
                        <span className={`${classes.indicator} ${classes.incorrect}`}>
                                <INCORRECT_ICON/>
                            </span>
                    }
                    {!isNaN(parseInt(selectedOption.selected)) &&
                        <span className={classes.optionIndex}>
                                {String.fromCharCode(parseInt(selectedOption.selected) + ASCII_CODE_A)}
                            </span>
                    }

                    <LatexHTMLInline>
                        {optionsMap[selectedOption.selected]?.text ? optionsMap[selectedOption.selected]?.text : "Other"}
                    </LatexHTMLInline>
                </div>
            }

            {questionType === QUESTION_TYPES.SA.name &&
                <div tabIndex={0} className={classes.yourResponseContainer}>
                    {selectedOption["0"]}
                </div>
            }

            {questionType === QUESTION_TYPES.OE.name &&
                <div tabIndex={0} className={classes.yourResponseContainer}>
                    {selectedOption["0"]}
                </div>
            }

            {questionType === QUESTION_TYPES.FITB.name &&
                (Object.keys(selectedOption).map((blankName, i) =>
                    <div
                        tabIndex={0}
                        key={i}
                        className={`${classes.yourResponseContainer}
                            ${!!shouldShowCorrect && correctAnswers[blankName] === selectedOption[blankName] ? classes.correct : ""}
                            ${!!shouldShowCorrect && correctAnswers[blankName] !== selectedOption[blankName] ? classes.incorrect : ""}
                            `}
                    >
                        {!!shouldShowCorrect && correctAnswers[blankName] === selectedOption[blankName] &&
                            <span className={`${classes.indicator} ${classes.correct}`}>
                                    <CORRECT_ICON/>
                                </span>
                        }

                        {!!shouldShowCorrect && correctAnswers[blankName] !== selectedOption[blankName] &&
                            <span className={`${classes.indicator} ${classes.incorrect}`}>
                                    <INCORRECT_ICON/>
                                </span>
                        }
                        <span aria-label={selectedOption[blankName] ? selectedOption[blankName] : "-"}>
                            {selectedOption[blankName] ? selectedOption[blankName] : "-"}
                        </span>
                    </div>
                ))
            }

            {questionType === QUESTION_TYPES.MH.name &&
                <div style={{ display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                    {correctAnswers.map((element, index) => {
                        if (element.length === 1) {
                            return <></>;
                        }

                        let ans = selectedOption[`${index}`];
                        let isCorrect = null;
                        if (shouldShowCorrect) {
                            isCorrect = ans === element[1];
                        }

                        return (
                            <div
                                key={index}
                                className={
                                    windowWidth > RESPONSIVE_SIDE_BAR_POINT
                                        ? classes.MHGridContainer
                                        : classes.MHVerticalContainer
                                }
                            >
                                {!(windowWidth > RESPONSIVE_SIDE_BAR_POINT) && index !== 0 &&
                                    <div className={classes.MHDivider}/>
                                }

                                <div tabIndex={0} className={classes.MHPremise}>
                                    <LatexHTMLInline>{element[0]}</LatexHTMLInline>
                                </div>
                                {windowWidth > RESPONSIVE_SIDE_BAR_POINT && <ARROW/>}
                                <div
                                    tabIndex={0}
                                    style={{minHeight: 47}}
                                    className={`${classes.yourResponseContainer}
                                        ${isCorrect === true ? classes.correct : ""}
                                        ${isCorrect === false ? classes.incorrect : ""}
                                        `}
                                >
                                    {isCorrect === true  &&
                                        <span className={`${classes.indicator} ${classes.correct}`}>
                                                <CORRECT_ICON/>
                                            </span>
                                    }

                                    {isCorrect === false &&
                                        <span className={`${classes.indicator} ${classes.incorrect}`}>
                                                <INCORRECT_ICON/>
                                            </span>
                                    }
                                    <LatexHTMLInline>{ans ? ans : "-"}</LatexHTMLInline>
                                </div>
                            </div>
                        );
                    })}
                </div>
            }

            {questionType === QUESTION_TYPES.WC.name &&
                <div tabIndex={0} className={classes.yourResponseContainer}>
                    <div>
                        {Object.values(selectedOption).map((value , index) =>
                            <div key={index}>- {value}</div>
                        )}
                    </div>
                </div>
            }

            {questionType === QUESTION_TYPES.RK.name && (selectedOption.map((value, index) =>
                <div tabIndex={0} key={index} className={classes.yourResponseContainer}>
                    <span className={classes.optionIndex}>
                        {index + 1}
                    </span>
                    <LatexHTMLInline>{value}</LatexHTMLInline>
                </div>
            ))}
        </>
    );
}
import {checkUnansweredNew, handleUserAns} from "../../utils/helpers";
import React from "react";
import classes from "./ClassResult.module.css";
import {ASCII_CODE_A, CORRECT_ANSWER, QUESTION_TYPES, RESPONSIVE_SIDE_BAR_POINT} from "../../utils/constants";
import ImageViewer from "./ImageViewer";
import { ReactComponent as CORRECT_ICON } from "../../images/grade-question-correct-icon.svg";
import { ReactComponent as ARROW } from "../../images/arrow_right.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {LatexHTMLInline} from "../../components/Tiptap/latexParser";

export default function RenderCorrectAnswer({
                                               questionData,
                                               showAnswerOnViewer,
                                               curUserResponses=[],
                                               dataLoading
}) {
    let {width: windowWidth} = useWindowDimensions();
    const {weightage, correctAnswers, serialNo, questionType, optionsImageMap={}, optionsMap} = questionData;

    let curQueResponse = !!curUserResponses
        ? curUserResponses.find(q => q.serialNo === serialNo)
        : null;
    curQueResponse = !!curQueResponse ? curQueResponse : {};
    let {selectedOption} = curQueResponse;

    let shouldShowCorrect = !!weightage && !!showAnswerOnViewer;

    if (!!dataLoading) {
        return <></>;
    }


    if (!shouldShowCorrect
        || (!checkUnansweredNew(questionType, selectedOption)
            && handleUserAns(questionData, selectedOption)
        )
    ) {
        return <></>;
    }

    return (
        <>
            <span tabIndex={0} className={classes.label}>{CORRECT_ANSWER}</span>
            {questionType === QUESTION_TYPES.MCSS.name && (correctAnswers.map((key, index) =>
                <div key={index} tabIndex={0} className={`${classes.yourResponseContainer} ${classes.correct}`}>
                    <span className={`${classes.indicator} ${classes.correct}`}>
                        <CORRECT_ICON/>
                    </span>

                    <span className={classes.optionIndex}>
                        {key.toString().toUpperCase()}
                    </span>
                    {!!optionsImageMap[key]?.imageURL &&
                        <ImageViewer size={38} imageURL={optionsImageMap[key]?.imageURL}/>
                    }
                    <LatexHTMLInline>{optionsMap[key]}</LatexHTMLInline>
                </div>
            ))}
            {questionType === QUESTION_TYPES.TF.name &&
                <div tabIndex={0} className={`${classes.yourResponseContainer} ${classes.correct}`}>
                        <span className={`${classes.indicator} ${classes.correct}`}>
                            <CORRECT_ICON/>
                        </span>
                    {correctAnswers}
                </div>
            }
            {questionType === QUESTION_TYPES.CI.name && (correctAnswers.map((key, index) =>
                <div tabIndex={0} key={index} className={`${classes.yourResponseContainer} ${classes.correct}`}>
                    <span className={`${classes.indicator} ${classes.correct}`}>
                        <CORRECT_ICON/>
                    </span>
                    <span className={classes.optionIndex}>
                        {String.fromCharCode(key + ASCII_CODE_A)}
                    </span>
                    <LatexHTMLInline>{optionsMap[key].text}</LatexHTMLInline>
                </div>
            ))}

            {questionType === QUESTION_TYPES.FITB.name && (Object.keys(correctAnswers).map((blankName, index) =>
                <div tabIndex={0} key={index} className={`${classes.yourResponseContainer} ${classes.correct}`}>
                        <span className={`${classes.indicator} ${classes.correct}`}>
                            <CORRECT_ICON/>
                        </span>
                    {correctAnswers[blankName]}
                </div>
            ))}

            {questionType === QUESTION_TYPES.MH.name &&
                <div style={{display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                    {correctAnswers.map((element, index) => {
                        if (element.length === 1) {
                            return <></>;
                        }

                        let ans = selectedOption[`${index}`];
                        let isCorrect = null;
                        if (shouldShowCorrect) {
                            isCorrect = ans === element[1];
                        }

                        if (!!isCorrect) {
                            return <></>;
                        }

                        return (
                            <div
                                key={index}
                                tabIndex={0}
                                className={
                                    windowWidth > RESPONSIVE_SIDE_BAR_POINT
                                        ? classes.MHGridContainer
                                        : classes.MHVerticalContainer
                                }
                            >
                                {!(windowWidth > RESPONSIVE_SIDE_BAR_POINT) && index !== 0 &&
                                    <div className={classes.MHDivider}/>
                                }

                                <div className={classes.MHPremise}>
                                    <LatexHTMLInline>{element[0]}</LatexHTMLInline>
                                </div>
                                {windowWidth > RESPONSIVE_SIDE_BAR_POINT && <ARROW/>}
                                <div
                                    style={{minHeight: 47}}
                                    className={`${classes.yourResponseContainer} ${classes.correct}`}
                                >
                                    <span className={`${classes.indicator} ${classes.correct}`}>
                                        <CORRECT_ICON/>
                                    </span>
                                    <LatexHTMLInline>{element[1]}</LatexHTMLInline>
                                </div>
                            </div>
                        );
                    })}
                </div>
            }
        </>
    );
}
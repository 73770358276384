import "bootstrap/dist/css/bootstrap.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { ResponseApiCalls } from "../../apis/ResponseApiCalls";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { YuJaGlobalState } from "../../utils/LoadingContext";
import {
  ANSWERED,
  ASCII_CODE_A,
  ASCII_CODE_SMALL_A, ATTENTION_TEXT,
  BUTTON,
  CLICK_IMAGE_TO_MARK_TEXT,
  DEFAULT_ENTRIES_PER_PARTICIPANT,
  DEFAULT_IMAGE_ALT,
  FITB_ANSWER_LENGTH,
  FIXED_ANSWER, IN_PROGRESS,
  MCSSoptLower,
  MERGED_MODE, MODAL_CANCEL_TEXT,
  OE_PLACE_HOLDER, POINTS_TEXT,
  POLL_BREAK_DURATION_ATTENTION_FORMAT,
  POLL_TEXT,
  PREMISE, PROFANITY_CENSOR_CONFIRMATION, PROFANITY_CENSOR_CONFIRMATION_TITLE, QUESTION,
  QUESTION_TYPES,
  RESPONSE, RESPONSIVE_SIDE_BAR_POINT,
  SA_PLACE_HOLDER, SCREEN_TYPE, SUBMIT_CONFIRM_TEXT,
  SUBMIT_TEXT,
  THUMBS_DOWN,
  THUMBS_UP, UNANSWERED, YES_TEXT, YUJA_DROPDOWN_SIZE,
} from "../../utils/constants";
import {

  checkUnansweredNew,
  fillFormatText,
  handleUserAns,
  newPageLog, notifyError,
} from "../../utils/helpers";
import {decodeCIParam, encodeCIParam, getOption4CI, questionCodeToName} from "../../utils/questionUtils";
import CountdownTimerAll from "./CountdownTimerAll";
import "./styles.css";

import classes from "./PollReady.module.css";

import parse from "html-react-parser";
import { ReactComponent as ICIcon } from "../../images/clickable_grey.svg";
import { ReactComponent as FITBIcon } from "../../images/fitb_grey.svg";
import { ReactComponent as MHIcon } from "../../images/matching_grey.svg";
import { ReactComponent as MCSSIcon } from "../../images/mcss_grey.svg";
import { ReactComponent as SAIcon } from "../../images/sa_grey.svg";
import { ReactComponent as OEIcon } from "../../images/OpenEndedGrey.svg";
import { ReactComponent as TFIcon } from "../../images/tf_grey.svg";
import { ReactComponent as WCIcon } from "../../images/wcloud_grey.svg";
import { ReactComponent as RKIcon } from "../../images/RankingIconGrey.svg";
import { ReactComponent as HALF_CIRCLE } from "./images/half-circle.svg";
import { ReactComponent as ARROW } from "../../images/arrow_right.svg";
import { ReactComponent as DRAG_DROP_ICON } from "../../images/drag-new.svg";
import { ReactComponent as EXPAND_ICON } from "./images/expand-icon.svg";
import { ReactComponent as ARROW_UP } from "./images/arrow-up.svg";
import { ReactComponent as ARROW_DOWN } from "./images/arrow-down.svg";
import { ReactComponent as Megaphone } from "../../images/megaphone.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ImageViewer from "./ImageViewer";
import reactStringReplace from "react-string-replace";
import WordCloudEntries from "./WordCloudEntries";
import OpenEndedResultNew from "./openEndedResultNew";
import YuJaButton from "../../components/standardization/YuJaButton";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import {YuJaTooltip} from "../../components/standardization/YuJaTooltip";
import styles from "./styles.module.css";
import {LatexHTMLInline} from "../../components/Tiptap/latexParser";
import {useProfanityFilter} from "../../hooks/useProfanityFilter";
import GeneralConfirmationModal from "../../components/modals/GeneralConfirmationModal";



export default React.memo(PollReadyAll, (prevProps, nextProps) => {
  return (
    prevProps.pollInfo === nextProps.pollInfo &&
    prevProps.currentQuestion === nextProps.currentQuestion
  );
});

function PollReadyAll({
                        pollInfo,
                        currentPoll = {},
                        pollLiveQuestionIdx,
                        setPollLiveQuestionIdx,
                        handleUserCompletePoll,
                        handlePollComplete,
                        stoppedAll,
                        state,
                        institutionId,
                        changeCurPoll,
                        websocketState,
                        setCountdownContent,
                        handleReattempt, 
                        allowReattempt,
                        maxAttempts,
}) {
  const {width: windowWidth, type: windowType, isTouchAble} = useWindowDimensions();
  const {checkResponseProfane} = useProfanityFilter();


  // const isMobile = useMobileAccess(480);
  const { getAudienceResponseByUser, updateReaction, handleSubmitMultiResponse } = ResponseApiCalls();

  // const [currQuestionIndex, setCurrQuestionIndex] = useState(pollLiveQuestionNo > 0 ? Number(pollLiveQuestionNo) - 1 : 0);
  const { pollKey, uniqueCode, userName, pollTitle, attemptsCount, pollBreakDuration } = pollInfo;
  const { questions, pollEndTime, rawQueResponses=[], timeLimit } = currentPoll;
  const timed = !!pollEndTime;
  const curIdx = pollLiveQuestionIdx >= questions.length ? questions.length - 1 : pollLiveQuestionIdx;

  const [currQueTitle, setCurrQueTitle] = useState(questions[curIdx].queTitle);
  const [currCorrectAnswers, setCurrCorrectAnswers] = useState(questions[curIdx].correctAnswers);
  // const [currOptionsMap, setCurrOptionsMap] = useState(questions[0].optionsMap);
  const [currQuestionType, setCurrQuestionType] = useState(questions[curIdx].questionType);
  const [currHasBlankRestriction, setCurrHasBlankRestriction] = useState(questions[curIdx].hasBlankRestriction);
  const [currWeightage, setCurrWeightage] = useState(questions[curIdx].weightage);
  const [currSerialNo, setCurrSerialNo] = useState(questions[curIdx].serialNo);
  const [currEntries, setCurrEntries] = useState(questions[curIdx].entries);
  // const [currSuffix, setCurrSuffix] = useState(questions[curIdx].suffix);
  const [currGradeEnabled, setCurrGradeEnabled] = useState(!!questions[curIdx].weightage);
  const [imageURL, setImageURL] = useState("");
  const [, getSession] = useLocalStorageNew("session", {});
  const {isOnline} = useContext(YuJaGlobalState);
  const session = getSession();
  const [isLoaded, setIsLoaded] = useState(false);
  const [queResponses, setQueResponses] = useState(rawQueResponses);
  const [skippingAlertShow, setSkippingAlertShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [pollBreakAlert, setPollBreakAlert] = useState(POLL_BREAK_DURATION_ATTENTION_FORMAT);
  const [profanityConfirmationShow, setProfanityConfirmationShow] = useState(false);
  const imageRef = useRef(null);
  const wcInputValRef = useRef(null);
  const [skippingAlertContent, setSkippingAlertContent] = useState("");
  const [firstSkipped, setFirstSkipped] = useState("");
  const [shownEntries, setShownEntries] = useState(currEntries); // (isNarrow || currEntries == 1 ? 1 : 2);
  const [thumbup, setThumbUp] = useState(false);
  const [thumbdown, setThumbDown] = useState(false);
  const [, getGuestSession] = useLocalStorageNew("guestSession", {});
  const [isVideo, setIsVideo] = useState(false);
  const hasCalledTimedUp = useRef(false);
  const [isAnsweredState, setIsAnsweredState] = useState(false);
  const [questionListOpen, setQuestionListOpen] = useState(false);
  const questionListContainerRef = useRef();

  const [pollPanelOpen, setPollPanelOpen] = useState(false);
  const pollPanelRef = useRef();

  const [currentOptionsImageMap, setCurrentOptionsImageMap] = useState({});

  const [currentSelectedOption, setCurrentSelectedOption] = useState(() => {
    if (pollLiveQuestionIdx >= questions.length) {
      return null;
    }
    const curQuestionType = questions[pollLiveQuestionIdx].questionType;
    const selectedOption = queResponses.length > pollLiveQuestionIdx ? queResponses[pollLiveQuestionIdx]?.selectedOption : null;

    if (!checkUnansweredNew(curQuestionType, selectedOption)) {
      return selectedOption;
    }


    if (curQuestionType === QUESTION_TYPES.FITB.name) {
      const ans = {};
      for (const key of Object.keys(questions[pollLiveQuestionIdx].correctAnswers)) {
        ans[key] = "";
      }
      return ans;
    } else if (curQuestionType === QUESTION_TYPES.WC.name ) {
      const ans = {};
      for (let i = 0; i < questions[pollLiveQuestionIdx].entries; i++) {
        ans[i] = "";
      }
      return ans;
    } else if(curQuestionType === QUESTION_TYPES.MH.name) {
      const ans = {};
      for (let i = 0; i < questions[pollLiveQuestionIdx].correctAnswers.length; i++) {
        ans[i] = "";
      }
      return ans;
    } else if(!currGradeEnabled && curQuestionType === QUESTION_TYPES.MCSS.name) {
      return [];
    } else if(curQuestionType === QUESTION_TYPES.RK.name) {
      return questions[pollLiveQuestionIdx].optionsMap.filter(option => option !== "");
    } else { //MCSS or SA or TF or CI
      return FIXED_ANSWER.UNANSWERED;
    }
  });

  const getCompletedQueCount = () => {
    let count = 0;
    questions.forEach((q, index) => {
      let isUnanswered = checkUnansweredNew(currQuestionType, rawQueResponses[index]?.selectedOption);
      if (!isUnanswered) {
        count++;
      }
    });
    return count;
  }
  const [completedQueCount, setCompletedQueCount] = useState(getCompletedQueCount);


  useEffect(() => {
    newPageLog("PollReadyAll.js");
  }, []);

  if (document.getElementById("root") !== null) {
    document.getElementById("root").style.backgroundImage = "none";
  }
  // END DOCUMENT FORMATTING

  useEffect(() => {
    if (!setCountdownContent) {
      return;
    }
    if (windowWidth <= RESPONSIVE_SIDE_BAR_POINT) {
      setCountdownContent(
         <div style={{height: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: 5}}>
           {!timed &&
               <span className={classes.navBarTitle}>{pollTitle}</span>
           }
           {!!timed &&
             <CountdownTimerAll
                 pollEndTime={pollEndTime}
                 timeUp={timeUp}
                 state={state}
             />
           }
           {windowType === SCREEN_TYPE.MOBILE && !pollPanelOpen &&
               <ARROW_DOWN style={{cursor: "pointer"}} onClick={() => setPollPanelOpen(true)}/>
           }

           {windowType === SCREEN_TYPE.MOBILE && !!pollPanelOpen &&
               <ARROW_UP style={{cursor: "pointer"}}/>
           }

           {!!pollPanelOpen &&
               <div ref={pollPanelRef} className={classes.pollPanelContainer}>
                 <span className={classes.cardTitle}>{pollTitle}</span>

                 <div style={{display: "flex", gap: 5}}>
                   <span className={classes.badge} style={{backgroundColor: "#F8DCDB"}}>
                     {questions.length} Questions
                   </span>
                   <span className={classes.badge} style={{backgroundColor: "#D3DDF2"}}>
                      {questions.filter(q => !!q.weightage).reduce(function (result, q) {
                        return result + q.weightage;
                      }, 0)} Points
                   </span>
                   {!!timeLimit &&
                       <span className={classes.badge} style={{backgroundColor: "#F9E4F5"}}>
                        {timeLimit / 60} Mins
                       </span>
                   }
                 </div>
               </div>
           }
         </div>
      );

    } else {
      setCountdownContent(null);
    }
  }, [setCountdownContent, pollPanelOpen, currentPoll, windowWidth, pollEndTime, state, timed]);

  const autoScroll = () => {
    if (window.innerHeight >= 480 || window.innerWidth <= window.innerHeight) {
      return;
    }

    const mainContainer = document.getElementById("main-container");
    const questionContainer = document.getElementById("question-container");
    if (!mainContainer || !questionContainer) {
      return;
    }
    const questionContainerTop = questionContainer.getBoundingClientRect().top;
    const mainContainerTop = mainContainer.getBoundingClientRect().top;
    const scrollDistance = questionContainerTop - mainContainerTop;
    mainContainer.scrollTo({
      top: mainContainer.scrollTop + scrollDistance,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    setTimeout(autoScroll, 100);
  }, [window.innerWidth, window.innerHeight, currSerialNo]);

  useEffect(() => {
    setCompletedQueCount(getCompletedQueCount());
  }, [questions, rawQueResponses]);

  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (questionListOpen && !!questionListContainerRef.current && !!questionListContainerRef.current
         && !questionListContainerRef.current.contains(event.target)
      ) {
        setQuestionListOpen(false);
      }
    };

    if (questionListOpen) {
      document.addEventListener('click', handleGlobalClick, true);
    }
    return () => {
      document.removeEventListener('click', handleGlobalClick)
    };
  }, [questionListOpen]);


  useEffect(() => {
    const handleGlobalClick = (event) => {
      if (pollPanelOpen && !!pollPanelRef.current && !!pollPanelRef.current
          && !pollPanelRef.current.contains(event.target)
      ) {
        setPollPanelOpen(false);
      }
    };

    if (pollPanelOpen) {
      document.addEventListener('click', handleGlobalClick, true);
    }
    return () => {
      document.removeEventListener('click', handleGlobalClick)
    };
  }, [pollPanelOpen]);


  useEffect(() => {
    if (pollLiveQuestionIdx >= questions.length) {
      // if (pollType === POLL_TYPE.SURVEY) {
      //   setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
      //   return;
      // } else {
      if (stoppedAll) {
        handlePollComplete();
      } else {
        handleUserCompletePoll();
      }
      return;
      // }
    }

    const curQueResponse = queResponses[pollLiveQuestionIdx];
    const curQue = questions[pollLiveQuestionIdx];


    setCurrQueTitle(curQue.queTitle);
    setCurrCorrectAnswers(curQue.correctAnswers);
    // setCurrOptionsMap(questions[currQuestionIndex].optionsMap);
    setCurrQuestionType(curQue.questionType);
    setCurrHasBlankRestriction(curQue?.hasBlankRestriction ? curQue.hasBlankRestriction : false);
    setCurrWeightage(curQue.weightage);
    setCurrSerialNo(curQue.serialNo);
    const newEntriesVal = curQue.entries ? curQue.entries : DEFAULT_ENTRIES_PER_PARTICIPANT;
    setCurrEntries(newEntriesVal);
    setShownEntries(newEntriesVal);
    // setCurrSuffix(curQue.suffix);
    setCurrGradeEnabled(!!curQue.weightage);
    setCurrentOptionsImageMap(!!curQue.optionsImageMap ? curQue.optionsImageMap : {});
    if (currSerialNo !== curQue.serialNo) {
      setIsLoaded(false);
    }

    
    if(!!curQue.directLinkEvp) {
      setImageURL(curQue.directLinkEvp);
      setIsVideo(true);
    } else {
      setImageURL(curQue.imageURL);
      setIsVideo(false);
    }

    const selectedOption = !!curQueResponse ? curQueResponse.selectedOption : null;

    if (!checkUnansweredNew(curQue.questionType, selectedOption)) {
      setCurrentSelectedOption(selectedOption);
    } else {
      if (curQue.questionType === QUESTION_TYPES.FITB.name) {
        const ans = {};
        for (const key of Object.keys(curQue.correctAnswers)) {
          ans[key] = "";
        }
        setCurrentSelectedOption(ans);
      } else if (curQue.questionType === QUESTION_TYPES.WC.name ) {
        const ans = {};
        for (let i = 0; i < curQue.entries; i++) {
          ans[i] = "";
        }
        setCurrentSelectedOption(ans);
      } else if(curQue.questionType === QUESTION_TYPES.MH.name) {
        const ans = {};
        for (let i = 0; i < curQue.correctAnswers.length; i++) {
          ans[i] = "";
        }
        setCurrentSelectedOption(ans);
      } else if (!curQue.weightage && curQue.questionType === QUESTION_TYPES.MCSS.name) {
         setCurrentSelectedOption([]);
      } else if (curQue.questionType === QUESTION_TYPES.RK.name) {
        setCurrentSelectedOption(curQue.optionsMap.filter(option => option !== ""));
      } else { //MCSS or SA or TF or CI
        setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
      }
    }
  }, [pollLiveQuestionIdx, queResponses]);


  const handleType = (e) => {
    e.stopPropagation();

    if (currQuestionType === QUESTION_TYPES.SA.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById("SA-IN").value = "";
        setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
      } else {
        const ans = {};
        ans["0"] = e.target.value.toString();
        setCurrentSelectedOption(ans);
      }
    } else if (currQuestionType === QUESTION_TYPES.OE.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById("OE-IN").value = "";
        setCurrentSelectedOption(FIXED_ANSWER.UNANSWERED);
      } else {
        const ans = {};
        ans["0"] = e.target.value.toString();
        setCurrentSelectedOption(ans);
      }
    } else if (currQuestionType === QUESTION_TYPES.FITB.name) {
      if (e.target.value.toString().length === 0) {
        document.getElementById(e.target.id).value = "";
      }
      const copy = currentSelectedOption === FIXED_ANSWER.UNANSWERED ? {} : { ...currentSelectedOption };
      copy[e.target.id] = e.target.value.toString();
      setCurrentSelectedOption(copy);
    }
  };


  const handleChooseOption = (optIdx) => {
    if (currQuestionType === QUESTION_TYPES.MCSS.name) {
      let temp = [];
      //disabled grade question or correct answers only includes option
      if (!currGradeEnabled || (!!currCorrectAnswers && currCorrectAnswers.length > 1)) {
        if (Array.isArray(currentSelectedOption)) {
          temp = JSON.parse(JSON.stringify(currentSelectedOption));
        } else if (currentSelectedOption !== FIXED_ANSWER.UNANSWERED) {
          temp = [currentSelectedOption];
        }

        if (temp.includes(optIdx)) {
          temp.splice(temp.indexOf(optIdx), 1);
        } else {
          temp.push(optIdx);
        }
      } else {
        temp.push(optIdx);
      }
      setCurrentSelectedOption(temp);
    } else {
      setCurrentSelectedOption(optIdx);
    }
  };

  const handleClickImage = (e) => {
    if (!isLoaded) {
      return;
    }
    const {optionsMap} = questions[pollLiveQuestionIdx];

    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;

    const encodedX = encodeCIParam(x, imageRef.current.width);
    const encodedY = encodeCIParam(y, imageRef.current.height);
    const option = getOption4CI(encodedX, encodedY, optionsMap);
    setCurrentSelectedOption(option === FIXED_ANSWER.UNANSWERED
        ? FIXED_ANSWER.UNANSWERED
        : {x: encodedX, y: encodedY, selected: option});
  }


  const switchSerialNo = async (serialNo, isSubmit=false) => {
    let copy = JSON.parse(JSON.stringify(currentSelectedOption));

    if (checkUnansweredNew(currQuestionType, copy)) {
      copy = FIXED_ANSWER.UNANSWERED;
    }

    let correction = checkResponseProfane(currQuestionType, copy);
    if (!!correction) {
      setProfanityConfirmationShow(true);
      setCurrentSelectedOption(correction);
      return;
    }


    let answersMap  = JSON.parse(JSON.stringify(queResponses));

    //build cur question response
    const isCorr = handleUserAns(questions[pollLiveQuestionIdx], copy);
    const curAnswer = {
      serialNo: questions[pollLiveQuestionIdx].serialNo,
      questionType: questions[pollLiveQuestionIdx].questionType,
      queTitle: questions[pollLiveQuestionIdx].queTitle,
      optionsMap: questions[pollLiveQuestionIdx].optionsMap,
      isCorrect: isCorr,
      attemptsCount: attemptsCount,
      selectedOption: copy,
      correctAnswers: questions[pollLiveQuestionIdx].correctAnswers,
    };
    answersMap[pollLiveQuestionIdx] = curAnswer;
    await handleSubmitResponse(serialNo, [curAnswer]);
    setQueResponses(answersMap);
    const currentPollCopy = JSON.parse(JSON.stringify(currentPoll));
    currentPollCopy.rawQueResponses = answersMap;
    changeCurPoll(currentPollCopy);

    //submit
    if (serialNo > questions.length || isSubmit) {
      if (stoppedAll) { //in additional time after manually stopped, should redirect to class result page
        handlePollComplete();
      // } else if (pollType === POLL_TYPE.SURVEY) {
      //   setPageState(SURVEY_ALL_PAGE_STATE.RESULT);
      } else {
        handleUserCompletePoll();
      }
    }

    setPollLiveQuestionIdx(serialNo - 1);
  }

  useEffect(() => {
    let answerState = true;
    for (let i = 0; i < questions.length; i++) {
      if (i !== pollLiveQuestionIdx) {
        const r = queResponses[i];
        if (!r || !Object.entries(r).length || checkUnansweredNew(questions[i].questionType, r.selectedOption)) {
          answerState = false;
        }
      } 
      else if (i === pollLiveQuestionIdx) {
        if (!currentSelectedOption || checkUnansweredNew(currQuestionType, currentSelectedOption)) {
          answerState = false;
        }
      }
    }
    setIsAnsweredState(answerState);
}, [queResponses, currentSelectedOption])

  const submit = async () => {
    const skippedQueArr = [];
    //check all the questions if it's unanswered.
    for (let i = 0; i < questions.length - 1; i++) {
      if (i === currSerialNo - 1) {
        continue;
      }
      const r = queResponses[i];
      if (!r || !Object.entries(r).length || checkUnansweredNew(questions[i].questionType, r.selectedOption)) {
        skippedQueArr.push(questions[i].serialNo);
      }
    }
    if (checkUnansweredNew(currQuestionType, currentSelectedOption)) {
      skippedQueArr.push(questions[questions.length - 1].serialNo);
    }
    if (skippedQueArr.length) { // && pollType !== POLL_TYPE.SURVEY
      setFirstSkipped(skippedQueArr[0]);
      setSkippingAlertContent(`You have ${skippedQueArr.length} unanswered questions. Are you sure you want to submit?`);
      setSkippingAlertShow(true);
    } else {
      setConfirmShow(true);
    }


    // await handleSub(SUB_QUESTION_TYPE.NEXT);
    // await switchSerialNo(currSerialNo + 1, isAnsweredState);
  }


  const handleReaction = async (reaction) => {
    if ((reaction === 1 && !!thumbup) || (reaction === 2 && !!thumbdown)) {
      return;
    }

    if (reaction === 1) {
      setThumbDown(false);
      setThumbUp(true);
    } else {
      setThumbUp(false);
      setThumbDown(true);
    }
    // let sessionUserName = getSession().userId ? getSession().userId : getGuestSession()["$"+ uniqueCode]?.GuestId;
    // const res = await setStudentReaction(pollKey, uniqueCode, sessionUserName , reaction, currSerialNo);
    let reactions = {};
    reactions[currSerialNo] = [reaction];
    let attemptReactions = {};
    attemptReactions[attemptsCount] = reactions;
    await updateReaction(pollKey, uniqueCode, attemptReactions, attemptsCount, null);
  }


  // Fill no-responding question with un-answer response
  useEffect(() => {
    const answersCopy = JSON.parse(JSON.stringify(queResponses));
    const placeholderAnswerArr = [];
    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const r = queResponses[i];
      if (!r || !Object.entries(r).length) {
        const answer = {
          serialNo: question.serialNo,
          questionType: question.questionType,
          queTitle: question.queTitle,
          optionsMap: question.optionsMap,
          isCorrect: false,
          attemptsCount: attemptsCount,
          selectedOption: FIXED_ANSWER.UNANSWERED,
          skippedQuestion: false,
          correctAnswers: question.correctAnswers,
        }
        answersCopy[i] = answer;
        placeholderAnswerArr.push(answer);
      }
    }
    setQueResponses(answersCopy);
    if (placeholderAnswerArr.length) {
      handleSubmitMultiResponse(pollInfo, 1, questions, placeholderAnswerArr, true);
    }

  }, []);

  useEffect(() => {
    if(stoppedAll) {
      const text = fillFormatText(POLL_BREAK_DURATION_ATTENTION_FORMAT, {
        pollType: MERGED_MODE, // pollType === POLL_TYPE.GRADED_POLL ? GRADED_POLL : SURVEY,
        time: Math.floor(pollBreakDuration / 60) > 0
            ? `${Math.floor(pollBreakDuration / 60)} minutes and ${pollBreakDuration % 60} seconds`
            : `${pollBreakDuration % 60} seconds`,
      });
      setPollBreakAlert(text);
      timeUp(false);
    }
  }, [stoppedAll]);

  useEffect(() => {
    return () => {
      if(hasCalledTimedUp.current) {
        setPollLiveQuestionIdx(questions.length);
      }
    }
  }, []);

  useEffect(async () => {
    if (!isOnline) {
      setThumbUp(false);
      setThumbDown(false);
      return;
    }

    let res = await getAudienceResponseByUser(userName, uniqueCode);
    if(!res.hasOwnProperty("data")) {
      res.data = [];
    }
    // let sessionUserName = getSession().userId ? getSession().userId : getGuestSession()["$"+ uniqueCode]?.GuestId;
    let currentPollRes = res.data;
    let isSet = false;
    for (const tempResult of currentPollRes) {
    if (tempResult.reactionMap && tempResult.reactionMap[attemptsCount] && tempResult.reactionMap[attemptsCount][currSerialNo]) {
      if (tempResult.reactionMap[attemptsCount][currSerialNo][0] == "1") {
        setThumbUp(true);
        setThumbDown(false);
        isSet= true;
        // await setStudentReaction(pollKey, uniqueCode, sessionUserName , "1", currSerialNo);
      }
      else if (tempResult.reactionMap[attemptsCount][currSerialNo][0] == "2") {
        setThumbDown(true);
        setThumbUp(false);
        isSet= true;
        // await setStudentReaction(pollKey, uniqueCode, sessionUserName , "2", currSerialNo);
      }
    }
    else if (tempResult.reaction && tempResult.reaction[currSerialNo]) {
      if (tempResult.reaction[currSerialNo][0] == "1") {
        setThumbUp(true);
        setThumbDown(false);
        isSet= true;
      } else if (tempResult.reaction[currSerialNo][0] == "2") { 
        setThumbDown(true);
        setThumbUp(false);
        isSet= true;
      }
    } 
    }

    if (!isSet) {
        setThumbUp(false);
        setThumbDown(false);
    }
  }, [currSerialNo, isOnline]);


  const timeUp = async (needEnd=true) => {
    let copy = JSON.parse(JSON.stringify(currentSelectedOption));
    if (checkUnansweredNew(currQuestionType, copy)) {
      copy = FIXED_ANSWER.UNANSWERED;
    }

    if (needEnd) {//silently remove profanity words
      let correction = checkResponseProfane(currQuestionType, copy);
      copy = !!correction ? correction : copy;
    } else {
      let correction = checkResponseProfane(currQuestionType, copy);
      if (!!correction) {
        setProfanityConfirmationShow(true);
        setCurrentSelectedOption(correction);
        return;
      }
    }

    let answersMap  = JSON.parse(JSON.stringify(queResponses));
    if (pollLiveQuestionIdx < questions.length) {
      // const answersMap = getFilledQuestionResponses();
      //current question
      const curQue = questions[pollLiveQuestionIdx];
      const isCorr = handleUserAns(curQue, currentSelectedOption);
      const curAnswer = {
        serialNo: curQue.serialNo,
        questionType: curQue.questionType,
        queTitle: curQue.queTitle,
        optionsMap: curQue.optionsMap,
        isCorrect: isCorr,
        // attemptsCount: attemptsCount,
        selectedOption: copy,
        skippedQuestion: false,
        correctAnswers: curQue.correctAnswers,
      };

      answersMap[pollLiveQuestionIdx] = curAnswer;
      await handleSubmitResponse(questions.length + 1, [curAnswer]);
      setQueResponses(answersMap);
      const currentPollCopy = JSON.parse(JSON.stringify(currentPoll));
      currentPollCopy.rawQueResponses = answersMap;
      changeCurPoll(currentPollCopy);
    }

    hasCalledTimedUp.current = true;

    if (needEnd) {
      setPollLiveQuestionIdx(questions.length);
      handlePollComplete();
    }

  }

  const handleSubmitResponse = (curQuestionNo, answersMap) => {
    handleSubmitMultiResponse(pollInfo, curQuestionNo, questions, answersMap);

  };

  const onLoad = () => {
    setIsLoaded(true);
  }

  const skippingAlertConfig = {
    title: `${SUBMIT_TEXT} ${POLL_TEXT}`,
    okText: SUBMIT_TEXT,
    submit: async () => {
      setSkippingAlertShow(false);
      await switchSerialNo(currSerialNo + 1);
    },
    close:  async () => {
      setSkippingAlertShow(false);
      if ( questions.length !== firstSkipped) {
        await switchSerialNo(firstSkipped);
       }
    },
  };


  const confirmConfig = {
    title: `${SUBMIT_TEXT} ${POLL_TEXT}`,
    okText: YES_TEXT,
    cancelText: MODAL_CANCEL_TEXT,
    buttonWidth: 90,
    submit: async () => {
      setConfirmShow(false);
      await switchSerialNo(currSerialNo + 1, true);
    },
    close:  async () => {
      setConfirmShow(false);
    },
  };


  const profanityConfirmationConfig = {
    title: PROFANITY_CENSOR_CONFIRMATION_TITLE,
    close:  () => setProfanityConfirmationShow(false),
  };


  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceKey = result.source.index;
    const destinationKey = result.destination.index;

    let newSelectedOption = [ ...currentSelectedOption ];
    const [removed] = newSelectedOption.splice(sourceKey, 1);
    newSelectedOption.splice(destinationKey, 0, removed);
    setCurrentSelectedOption(newSelectedOption);
  };


  // BEGIN VARIABLE INIT FOR QUESTION TYPES
  let MCSSelements = [];
  let newOptionsMap = pollLiveQuestionIdx < questions.length && questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.MCSS.name ?
      questions[pollLiveQuestionIdx].optionsMap : {};
  if ( pollLiveQuestionIdx < questions.length && questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.RK.name) {
    newOptionsMap = questions[pollLiveQuestionIdx].optionsMap;
  }
  const MCSSnum = Object.keys(newOptionsMap).length;

  let TFelements = ["TF0", "TF1"];
  const TFopt = ["True", "False"];

  let FITBElements = [];
  let idx = -1;

  if (pollLiveQuestionIdx < questions.length) {
    if (questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.MCSS.name) {
      for (let i = 0; i < MCSSnum; i++) {
        MCSSelements.push(QUESTION_TYPES.MCSS.name + i);
      }
    } else if (questions[pollLiveQuestionIdx].questionType === QUESTION_TYPES.FITB.name && currCorrectAnswers) {
      for (const key of Object.keys(currCorrectAnswers)) {
        FITBElements.push(key);
      }
    }
  }


  const renderTitle = () => {
    if (currQuestionType !== QUESTION_TYPES.FITB.name) {
      return <LatexHTMLInline>{questions[pollLiveQuestionIdx]?.queTitle}</LatexHTMLInline>;
    }

    let blankNameArr = Object.keys(currCorrectAnswers).map(str => `\\(${str}\\)`);
    const regex = new RegExp(`(${blankNameArr.join("|")})`, "g");

    return (
        <span style={{marginRight: 5, lineHeight: "unset"}}>
           {reactStringReplace(currQueTitle, regex, (blank, i) => {

             const match = !!blank ? blank.match(/\(([^)]+)\)/) : null;
             if (!match) {
               return "";
             }
             const blankName = match[1];

             return (
                 <input
                     key={i}
                     id={blankName}
                     className={classes.FITBInputBox}
                     onChange={handleType}
                     maxLength={FITB_ANSWER_LENGTH}
                     placeholder={SA_PLACE_HOLDER}
                     type={"text"}
                     value={checkUnansweredNew(currQuestionType, currentSelectedOption)
                        || !currentSelectedOption.hasOwnProperty(blankName)
                           ? ""
                           : currentSelectedOption[blankName]
                     }
                 />
             );
           })}
       </span>
    );

  }

  return (
      <div className={`${classes.parentContainer} ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}>
        <YuJaGeneralAlert
            show={skippingAlertShow}
            setModalShow={setSkippingAlertShow}
            content={skippingAlertContent}
            config={skippingAlertConfig}
            handleclose={() => {
              setSkippingAlertShow(false)
            }}
        />

        <YuJaGeneralAlert
            show={confirmShow}
            setModalShow={setConfirmShow}
            content={SUBMIT_CONFIRM_TEXT}
            config={confirmConfig}
            handleclose={() => {
              setConfirmShow(false)
            }}
        />

        <GeneralConfirmationModal
            content={PROFANITY_CENSOR_CONFIRMATION}
            show={profanityConfirmationShow}
            config={profanityConfirmationConfig}
        />

        <div
            className={classes.progressBarContainer}
            style={{marginBottom: windowType === SCREEN_TYPE.MOBILE ? 0 : undefined}}
        >
            {windowType !== SCREEN_TYPE.MOBILE &&
                <span
                    className={classes.progressBar}
                    style={{width: `${(completedQueCount / questions.length) * 100}%`}}
                >
                    <span
                        tabIndex={0}
                        className={`${classes.progressBarDesc} 
                        ${completedQueCount === 0 ? classes.left : (completedQueCount === questions.length ? classes.right : "")}`}
                    >
                      {completedQueCount}/{questions.length} Questions
                    </span>
              </span>
            }

          {windowType === SCREEN_TYPE.MOBILE &&
              <span
                  className={classes.progressBar}
                  style={{width: `${(completedQueCount / questions.length) * 100}%`}}
              >
              </span>
          }
        </div>


        {!!stoppedAll &&
            <div className={classes.pollBreakBar}>
              <Megaphone  />
              <strong >{ATTENTION_TEXT}</strong>
              <span style={{flex: 1}}>{pollBreakAlert}</span>
            </div>
        }

        <div className={`${classes.mainCard} ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}>
          {windowType !== SCREEN_TYPE.MOBILE &&
              <div className={classes.cardHeader}>
                <span tabIndex={0} className={classes.cardTitle}>{pollTitle}</span>
                <span/>
                <span tabIndex={0} className={classes.badge} style={{backgroundColor: "#F8DCDB"}}>
                  {questions.length} Questions
                </span>
                <span tabIndex={0} className={classes.badge} style={{backgroundColor: "#D3DDF2"}}>
                  {questions.filter(q => !!q.weightage).reduce(function (result, q) {
                    return result + q.weightage;
                  }, 0)} Points
                </span>

                {!!timeLimit &&
                    <span tabIndex={0} className={classes.badge} style={{backgroundColor: "#F9E4F5"}}>
                        {timeLimit / 60} Mins
                       </span>
                }
                <span style={{flex: 1}}/>
                {!!timed && windowWidth > RESPONSIVE_SIDE_BAR_POINT &&
                    <CountdownTimerAll
                        pollEndTime={pollEndTime}
                        timeUp={timeUp}
                        state={state}
                    />
                }
              </div>
          }

          <div className={classes.cardBody}>
            <div
                ref={questionListContainerRef}
                className={`${classes.questionListContainer} 
                ${windowType === SCREEN_TYPE.TABLET ? classes.tablet : ""} 
                ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}
                style={{width: windowType === SCREEN_TYPE.MOBILE ? (questionListOpen ? 88 : 0) : undefined}}
            >
              {questions.map((que, index) => {
                let isUnanswered = checkUnansweredNew(currQuestionType, rawQueResponses[index]?.selectedOption);
                let tooltipText = "";
                if (!isUnanswered) {
                  tooltipText = `${QUESTION_TYPES[que.questionType].description}: ${ANSWERED}`;
                } else if (index === pollLiveQuestionIdx) {
                  tooltipText = `${QUESTION_TYPES[que.questionType].description}: ${IN_PROGRESS}`;
                } else {
                  tooltipText = `${QUESTION_TYPES[que.questionType].description}: ${UNANSWERED}`;
                }

                return (
                    <div
                        key={index}
                        className={`${classes.questionListItem} 
                        ${index === pollLiveQuestionIdx ? classes.active : ""}
                        ${windowType === SCREEN_TYPE.MOBILE ? classes.mobile : ""}`}
                        onClick={() => switchSerialNo(index + 1)}
                    >
                      <YuJaTooltip placement={"right"} text={tooltipText}>
                        <span
                            className={`
                            ${classes.questionListQuestionTypeCircle} 
                            ${!isUnanswered ? classes.fullCircle : ""}
                          `}
                        >
                          {que.questionType === QUESTION_TYPES.MCSS.name &&
                              <MCSSIcon alt="MCSS Icon" style={{width: 18, height: 18}}/>}
                            {que.questionType === QUESTION_TYPES.SA.name &&
                                <SAIcon alt="SA Icon" style={{width: 18, height: 18}}/>}
                            {que.questionType === QUESTION_TYPES.OE.name &&
                                <OEIcon alt="QA Icon" style={{width: 18, height: 18}}/>}
                            {que.questionType === QUESTION_TYPES.TF.name &&
                                <TFIcon alt="TF Icon" style={{width: 18, height: 18}}/>}
                            {que.questionType === QUESTION_TYPES.FITB.name &&
                                <FITBIcon alt="FITB Icon" style={{width: 18, height: 18}}/>}
                            {que.questionType === QUESTION_TYPES.CI.name &&
                                <ICIcon alt="CI Icon" style={{width: 18, height: 18}}/>}
                            {que.questionType === QUESTION_TYPES.WC.name &&
                                <WCIcon alt="WC Icon" style={{width: 18, height: 18}}/>}
                            {que.questionType === QUESTION_TYPES.MH.name &&
                                <MHIcon alt="MH Icon" style={{width: 18, height: 18}}/>}
                            {que.questionType === QUESTION_TYPES.RK.name &&
                                <RKIcon alt="RK Icon" style={{width: 18, height: 18}}/>}
                            {!!isUnanswered && index === pollLiveQuestionIdx &&
                                <HALF_CIRCLE style={{position: "absolute", left: "calc(50% - 2px)"}}/>
                            }
                        </span>
                      </YuJaTooltip>


                      <span tabIndex={0} className={classes.questionListItemText}>
                        {windowType === SCREEN_TYPE.DESKTOP ? `Question ${index + 1}` : `Q${index + 1}`}
                      </span>
                    </div>
                );
              })}
            </div>


            <div className={classes.mainContainer}>
              <div className={classes.mainContainerBody}>
                <div className={classes.mainContainerRow}>
                  <span style={{fontSize: 18, fontWeight: "500", lineHeight: "22px"}}>
                    {QUESTION} {pollLiveQuestionIdx + 1}
                  </span>
                  {!!questions[pollLiveQuestionIdx]?.weightage &&
                      <span
                          className={classes.badge}
                          style={{width: "fit-content", padding: "0 10px", backgroundColor: "#E4E3F6"}}
                      >
                        {questions[pollLiveQuestionIdx].weightage} {POINTS_TEXT}
                      </span>
                  }

                  <span style={{flex: 1}}/>
                  <span
                      className={`${classes.thumbUpIcon} ${thumbup ? classes.active : ""}`}
                      onClick={() => handleReaction(1)}
                      tabIndex={0}
                      aria-label={THUMBS_UP}
                      role={BUTTON}
                  />
                  <span
                      className={`${classes.thumbDownIcon} ${thumbdown ? classes.active : ""}`}
                      onClick={() => handleReaction(2)}
                      tabIndex={0}
                      aria-label={THUMBS_DOWN}
                      role={BUTTON}
                  />

                  {windowType === SCREEN_TYPE.MOBILE &&
                      <span
                          className={classes.expandIcon}
                        style={{marginLeft: 5}}
                        onClick={() => setQuestionListOpen(true)}
                    >
                      <EXPAND_ICON/>
                    </span>
                  }

                </div>
                <div className={classes.mainContainerRow}>
                  <span style={{color: "#6B6B6B"}}>
                    {questionCodeToName(questions[pollLiveQuestionIdx]?.questionType)}
                  </span>
                </div>

                <div
                    className={`${
                      !!imageURL && currQuestionType !== QUESTION_TYPES.CI.name && windowType === SCREEN_TYPE.DESKTOP
                          ? classes.gridLayout 
                          : classes.straightLayout
                    }`}
                >
                  <div className={classes.mainContainerRow} style={{fontSize: 16}}>
                    {renderTitle()}
                  </div>
                  {!!imageURL && currQuestionType !== QUESTION_TYPES.CI.name &&
                      <div className={classes.imageBox}>
                        {!isVideo &&
                          <ImageViewer alt={questions[curIdx].imageAlt} imageURL={imageURL}/>
                        }
                        {isVideo &&
                            <iframe allowFullScreen style={{width: 270, height: 270}} src={imageURL}/>
                        }
                      </div>
                  }
                  {currQuestionType === QUESTION_TYPES.CI.name &&
                      <>
                        <span
                            className={classes.mainContainerRow}
                            style={{fontWeight: "600", justifyContent: "center", marginTop: 10}}
                        >
                          {CLICK_IMAGE_TO_MARK_TEXT}
                        </span>
                        <div className={classes.imageContainer}
                             style={{width: 320, height: 320, alignSelf: "center"}}
                        >
                          <div style={{position: "relative", maxWidth: "100%", maxHeight: "100%"}}>
                            <img
                                style={{maxWidth: "100%", maxHeight: "100%"}}
                                ref={imageRef}
                                src={imageURL}
                                onLoad={onLoad}
                                onClick={handleClickImage}
                                alt={DEFAULT_IMAGE_ALT}
                                tabIndex={0}
                                aria-label={questions[curIdx].imageAlt ? questions[curIdx].imageAlt : DEFAULT_IMAGE_ALT}
                            />
                            {isLoaded
                                && !!currentSelectedOption
                                && currentSelectedOption.hasOwnProperty("x")
                                && currentSelectedOption.hasOwnProperty("y")
                                &&
                                <div
                                    className={classes.coordinate}
                                    style={{
                                      position: "absolute",
                                      top: `${decodeCIParam(currentSelectedOption.y, imageRef.current.height) - 8}px`,
                                      left: `${decodeCIParam(currentSelectedOption.x, imageRef.current.width) - 8}px`
                                    }}
                                />
                            }
                          </div>
                        </div>
                      </>
                  }

                  <div className={classes.optionsContainer}>
                    {currQuestionType === QUESTION_TYPES.MCSS.name &&
                        <>
                          {MCSSelements.map((value, index) => {
                            const optIdx = String.fromCharCode(ASCII_CODE_SMALL_A + index);
                            const optIdxDisplay = String.fromCharCode(ASCII_CODE_A + index);
                            const isSelectedOpt = (!!currentSelectedOption && optIdx === currentSelectedOption)
                                || (Array.isArray(currentSelectedOption) && currentSelectedOption.includes(MCSSoptLower[index]));

                            return (
                              <div
                                  key={index}
                                  tabIndex={0}
                                  className={`${classes.optionBar} 
                                  ${!isTouchAble ? classes.notTouchAble : ""}
                                  ${isSelectedOpt ? classes.active : ""}`}
                                  onClick={() => handleChooseOption(optIdx)}
                              >
                                  <span
                                      className={`${classes.optionIndex} ${isSelectedOpt ? classes.active : ""}`}
                                      id={"MCSSLetter" + index}
                                  >
                                    {optIdxDisplay}
                                  </span>

                                {!!currentOptionsImageMap[optIdx]?.imageURL &&
                                    <ImageViewer
                                        size={38}
                                        showEnlargeByDefault={true}
                                        imageURL={currentOptionsImageMap[optIdx]?.imageURL}
                                    />
                                }
                                <LatexHTMLInline>{newOptionsMap[optIdx]}</LatexHTMLInline>
                              </div>
                            );
                          })}
                        </>
                    }

                    {currQuestionType === QUESTION_TYPES.SA.name &&
                        <textarea
                            className={classes.shortAnswerTextArea}
                            id="SA-IN"
                            name="sa-in"
                            placeholder={SA_PLACE_HOLDER}
                            value={checkUnansweredNew(currQuestionType, currentSelectedOption)
                                  ? ""
                                  : currentSelectedOption["0"]
                            }
                            onChange={handleType}
                        />
                    }

                    {currQuestionType === QUESTION_TYPES.TF.name &&
                      <>
                        {TFopt.map((value, index) => {
                          const isSelectedOpt = !!currentSelectedOption && value === currentSelectedOption;
                          return (
                              <div
                                  key={index}
                                  tabIndex={0}
                                  className={`${classes.optionBar} 
                                  ${!isTouchAble ? classes.notTouchAble : ""} 
                                  ${isSelectedOpt ? classes.active : ""}`}
                                  onClick={() => handleChooseOption(value)}
                              >
                                {value}
                              </div>

                          );
                        })}
                      </>
                    }

                    {currQuestionType === QUESTION_TYPES.WC.name &&
                        <WordCloudEntries
                            currEntries={currEntries}
                            shownEntries={shownEntries}
                            setShownEntries={setShownEntries}
                            currentSelectedOption={currentSelectedOption}
                            setCurrentSelectedOption={setCurrentSelectedOption}
                        />
                    }

                    {currQuestionType === QUESTION_TYPES.MH.name &&
                      <div style={{ display: "flex", width: "100%", flexDirection: "column", gap: 10}}>
                        {Array.isArray(questions[curIdx].correctAnswers) &&
                          questions[curIdx].correctAnswers.filter(i => i.length > 1).map((element, index) =>
                            <div
                                key={index}
                                id={"Option" + index}
                                className={windowWidth > RESPONSIVE_SIDE_BAR_POINT ? classes.MHGridContainer : classes.MHVerticalContainer}
                            >
                              {!(windowWidth > RESPONSIVE_SIDE_BAR_POINT) && index !== 0 &&
                                  <div className={classes.MHDivider}/>
                              }

                              {!(windowWidth > RESPONSIVE_SIDE_BAR_POINT) &&
                                  <div style={{fontWeight: "700", fontSize: 15}}> {PREMISE} </div>
                              }
                              <div className={classes.MHPremise}>
                                <LatexHTMLInline>{element[0]}</LatexHTMLInline>
                              </div>
                              {windowWidth > RESPONSIVE_SIDE_BAR_POINT && <ARROW/>}
                              {!(windowWidth > RESPONSIVE_SIDE_BAR_POINT) &&
                                  <div style={{fontWeight: "700", fontSize: 15}}> {RESPONSE} </div>
                              }

                              <YuJaDropdown
                                  size={YUJA_DROPDOWN_SIZE.LARGE}
                                  data={questions[curIdx].optionsMap}
                                  getOptionLabel={option => <LatexHTMLInline>{option.length > 1 ? option[1] : option[0]}</LatexHTMLInline>}
                                  getOptionValue={option => option.length > 1 ? option[1] : option[0]}
                                  value={currentSelectedOption[index]}
                                  placeholder={"Select an option"}
                                  textStyle={{wordBreak: "break-word"}}
                                  onChange={(option) => {
                                    let newAnswer = {[index]: option.length > 1 ? option[1] : option[0]};
                                    if (currentSelectedOption !== FIXED_ANSWER.UNANSWERED) {
                                      newAnswer = {
                                        ...currentSelectedOption,
                                        [index]: option.length > 1 ? option[1] : option[0]
                                      };
                                    }

                                    setCurrentSelectedOption(newAnswer);
                                  }}
                                  listStyle={{borderColor: "#E6E6E6"}}
                                  itemStyle={{borderColor: "#E6E6E6", color: "#4D4D4D"}}
                                  style={{
                                    color: !!currentSelectedOption[index] ? "#000" : "#4D4D4D",
                                    border: "1px solid #E6E6E6",
                                    boxShadow: "0 0 1px 0 #00000033",
                                  }}
                                  arrowSize={"24px"}
                              />
                            </div>
                          )
                        }
                      </div>
                    }

                    {currQuestionType === QUESTION_TYPES.RK.name &&
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable-selected-options">
                          {(provided) => (
                              <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  {...provided.dropHandleProps}
                                  style={{display: "flex", flexDirection: "column", gap: 15}}
                              >
                                {currentSelectedOption.filter(i => i !== "").map((value, index) => (
                                    <Draggable draggableId={"selected option" + index} key={"selected option" + index} index={index}>
                                      {(provided, snapshot) => (
                                          <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                          >
                                              <div className={`${classes.RKOptionContainer} ${!!snapshot.isDragging ? classes.active : ""}`}>
                                                <DRAG_DROP_ICON/>
                                                <div className={`${classes.optionIndex} ${!!snapshot.isDragging ? classes.active : ""}`}>{index + 1}</div>
                                                <LatexHTMLInline>{value}</LatexHTMLInline>
                                              </div>
                                          </div>
                                      )}
                                    </Draggable>
                                ))}
                              </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    }

                    {currQuestionType === QUESTION_TYPES.OE.name &&
                        <>
                           <textarea
                               id="OE-IN"
                               name="oe-in"
                               className={classes.shortAnswerTextArea}
                               style={{flexShrink: 0}}
                               placeholder={OE_PLACE_HOLDER}
                               value={checkUnansweredNew(currQuestionType, currentSelectedOption)
                                     ? ""
                                     : currentSelectedOption["0"]
                               }
                               onChange={handleType}
                           />
                          <OpenEndedResultNew
                              pollKey={pollKey}
                              uniqueCode={uniqueCode}
                              question={questions[curIdx]}
                              queTitle={currQueTitle}
                          />
                        </>
                    }
                  </div>
                </div>
              </div>
              <div className={classes.mainContainerFooter}>
                <YuJaButton
                    disabled={pollLiveQuestionIdx === 0}
                    onClick={() => switchSerialNo(currSerialNo - 1)}
                    type={"secondary"}
                    style={{width: 100, height: 36, flexShrink: 0, fontSize: 15}}
                >
                  Previous
                </YuJaButton>

                <span style={{flex: 1}}/>

                {pollLiveQuestionIdx === questions.length - 1 ?
                    <YuJaButton
                        onClick={submit}
                        disabled={pollLiveQuestionIdx > questions.length - 1}
                        style={{width: 100, height: 36, flexShrink: 0, fontSize: 15}}
                    >
                      Submit
                    </YuJaButton>
                    :
                    <YuJaButton
                        onClick={() => switchSerialNo(currSerialNo + 1)}
                        disabled={pollLiveQuestionIdx > questions.length - 1}
                        style={{width: 100, height: 36, flexShrink: 0, fontSize: 15}}
                    >
                      Next
                    </YuJaButton>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

import "antd/dist/antd.min.css";
import 'rc-datepicker/lib/style.css';
import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import {PollApiCalls} from "../../apis/PollApiCalls";
import {Spin} from "../../components/standardization/YuJaLoading";
import {ReactComponent as CaretDown} from "../../images/caret_down.svg";
import {ReactComponent as CaretUp} from "../../images/caret_up.svg";
import {ReactComponent as DetailsIcon} from "../../images/details.svg";
import {ReactComponent as ModalClose} from "../../images/modal_close.svg";
import {ReactComponent as NotificationIcon} from "../../images/notification.svg";
import {
    ACTIVATE_TIME,
    DETAILS_TEXT,
    EMAIL_NOTIFICAITON_LABEL_PREFIX,
    EMAIL_NOTIFICAITON_LABEL_SUFFIX,
    EMAIL_NOTIFICAITON_SUBTITLE,
    EMAIL_NOTIFICAITON_TEXT,
    EMAIL_NOTIFICATION_OPTIONS,
    MODAL_CLOSE_TEXT,
    MODAL_SAVE_TEXT,
    NOTIFICATION_DROPDOWN_PLACEHOLDER,
    POLL_TIME_LIMIT_DESCRIPTION,
    SCHEDULE_POLL_EDIT_MODAL_MESSAGE,
    SCHEDULE_POLL_EDIT_MODAL_TITLE,
    TEXTBOX,
    YUJA_DROPDOWN_SIZE
} from "../../utils/constants";
import {getShortName, notifyError, removePrefix, smoothScrollToBottom} from "../../utils/helpers";
import {timePollCustomToSeconds, timeSecondsToCustomNew, validCustomPollTime} from "../../utils/questionUtils";
import {
    INVALID_SCHEDULE_END_DATE_ERROR,
    INVALID_SCHEDULE_END_TIME_ERROR,
    INVALID_SCHEDULE_START_TIME_ERROR,
    INVALID_TIME_LIMIT_ERROR,
    MISSING_SCHEDULE_START_END_TIME_ERROR,
    PAST_NOTIFICATION_ERROR,
    SCHEDULE_POLL_START_END_GAP_ERROR
} from "../../utils/toast-message-constants";
import DatePickerComponentNew from "../datePickerComponent";
import YuJaButton from "../standardization/YuJaButton";
import {YuJaDropdown} from "../standardization/YuJaDropdown";
import "./modal.css";


export default function EditDateModal({
                                          setModalShow,
                                          show,
                                          pollKey,
                                          pollUniqueCode,
                                          setDate = null,
                                          setPollEnd = null,
                                          inCourse
                                      }) {
    const {editPublishedPollDataFromKey, getPublishedPollDataFromKey} = PollApiCalls();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [notificationTime, setNotificationTime] = useState(-1);
    const [selectedNotificationTime, setSelectedNotificationTime] = useState(null);
    const [reminderOptions, setReminderOptions] = useState(EMAIL_NOTIFICATION_OPTIONS);
    const [pollTimeLimit, setPollTimeLimit] = useState("00:30:00");
    const [pollTimeLimitH, setPollTimeLimitH] = useState("00");
    const [pollTimeLimitM, setPollTimeLimitM] = useState("30");
    const [pollTimeLimitS, setPollTimeLimitS] = useState("00");
    const isLoading = false;

    const [disableEditStart, setDisableEditStart] = useState(false);
    const [disableEditEnd, setDisableEditEnd] = useState(false);


    // const handlePollTimeChangeH = (e) => {
    //     let hours = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
    //     setPollTimeLimitH(hours);

    //     if(hours === "") {
    //       hours = "00";
    //     } else if(hours.length === 1) {
    //       hours = "0"+hours;
    //     }
    //     setPollTimeLimit(hours + pollTimeLimit.substring(2));
    //   };

    // const handlePollTimeChangeM = (e) => {
    //   let mins = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
    //   setPollTimeLimitM(mins);


    //   if(mins === "") {
    //     mins = "00";
    //   } else if(mins.length === 1) {
    //     mins = "0"+mins;
    //   }
    //   setPollTimeLimit(pollTimeLimit.substring(0,3) + mins + pollTimeLimit.substring(5));
    // };

    const handlePollTimeChangeM = (e) => {
        let mins = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0, 2);
        setPollTimeLimitM(mins);

    };

    const handlePollTimeonBlurM = (e) => {
        let mins = pollTimeLimitM;
        const isValidNumber = /^\d+$/.test(pollTimeLimitM);
        if (isValidNumber && !isNaN(parseInt(pollTimeLimitM, 10))) {
            let unroundedMins = (mins < 10 ? "0" + mins.toString() : mins.toString());
            let hours = Math.floor(parseInt(mins, 10) / 60);
            mins = parseInt(mins, 10) % 60;
            if (hours + parseInt(pollTimeLimitH, 10) > 24) {
                unroundedMins = (mins < 10 ? "0" + mins.toString() : mins.toString());
            }
            hours = hours + parseInt(pollTimeLimitH, 10) > 24 ? 24 : hours + parseInt(pollTimeLimitH, 10);

            if (mins === "") {
                mins = "00";
            } else if (mins.length === 1) {
                mins = "0" + mins;
            }
            setPollTimeLimitM((mins < 10 ? "0" : "") + mins)
            setPollTimeLimitH((hours < 10 ? "0" : "") + hours);
            setPollTimeLimit(pollTimeLimit.substring(0, 3) + unroundedMins + ":00");
            if (parseInt(pollTimeLimitM, 10) === 0) {
                setPollTimeLimitM("00");
            }
        } else {
            setPollTimeLimitM("00");
        }
    }

    const handlePollTimeChangeH = (e) => {
        let hours = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0, 2);
        setPollTimeLimitH(hours);
    };

    const handlePollTimeonBlurH = (e) => {
        let hours = pollTimeLimitH;
        const isValidNumber = /^\d+$/.test(pollTimeLimitH);
        if (isValidNumber && !isNaN(parseInt(pollTimeLimitH, 10)) && parseInt(pollTimeLimitH, 10) <= 24) {

            if (hours === "") {
                hours = "00";
            } else if (hours.length === 1) {
                hours = "0" + hours;
            }
            setPollTimeLimitH(hours);
            setPollTimeLimit(hours + pollTimeLimit.substring(2));
            if (parseInt(pollTimeLimitH, 10) === 0) {
                setPollTimeLimitH("00");
            }
        } else {
            setPollTimeLimitH("00");
        }
    }

    // const handlePollTimeChangeS = (e) => {
    //   let secs = e.target.value.length <= 2 ? e.target.value : e.target.value.substring(0,2);
    //   setPollTimeLimitS(secs);

    //   if(secs === "") {
    //     secs = "00";
    //   } else if(secs.length === 1) {
    //     secs = "0"+secs;
    //   }
    //   setPollTimeLimit(pollTimeLimit.substring(0,6) + secs);
    // };


    const handleClose = () => {
        setModalShow(false);
    };

    useEffect(async () => {
        setNotificationTime(-1);
        setReminderOptions(EMAIL_NOTIFICATION_OPTIONS);
        if (pollKey && pollUniqueCode) {
            const result = await getPublishedPollDataFromKey(removePrefix(pollKey), pollUniqueCode.substring(7));
            if (result.poll && result.poll.startTime && result.poll.endTime) {
                let timeString = timeSecondsToCustomNew(result.poll.pollTimeLimit);
                setPollTimeLimit(timeString);
                setPollTimeLimitH(timeString.substring(0, 2));
                setPollTimeLimitM(timeString.substring(3, 5));
                setPollTimeLimitS(timeString.substring(6));

                const tempStartDate = new Date(result.poll.startTime);
                const tempEndDate = new Date(result.poll.endTime);
                const now = new Date();

                if (now > tempStartDate) {
                    setDisableEditStart(true);
                }
                if (now > tempEndDate) {
                    setDisableEditEnd(true);
                }
                if (result.poll.reminderTime) {
                    const tempReminderDate = new Date(result.poll.reminderTime);
                    const notifTime = tempStartDate - tempReminderDate;
                    const notifTimeSeconds = notifTime / 1000;
                    const roundedNotifTime = Math.round(notifTimeSeconds / 100) * 100;
                    // console.log(roundedNotifTime);
                    setSelectedNotificationTime(roundedNotifTime);
                    setNotificationTime(roundedNotifTime);
                }
            }
        }
    }, [pollKey, pollUniqueCode]);

    useEffect(() => {
        if (notificationTime !== selectedNotificationTime) return;
        // console.log(notificationTime);

        smoothScrollToBottom(document.querySelector(".details-content"));
    }, [notificationTime]);

    useEffect(() => {
        const now = new Date();
        const timeToStart = startDate - now;
        const filteredReminderOptions = EMAIL_NOTIFICATION_OPTIONS.filter(option => ((option.value * 1000) <= timeToStart));
        setReminderOptions(filteredReminderOptions);
        if (now < startDate && !filteredReminderOptions.map(option => option.value).includes(notificationTime)) {
            setNotificationTime(-1);
        }
    }, [startDate]);

    const handleSubmit = async () => {
        let body = {};
        let pollKeyResult = removePrefix(pollKey);
        let pollCode = pollUniqueCode.substring(7);
        if (startDate) {
            body.startTime = startDate;
        }
        if (endDate) {
            body.endTime = endDate;
        }
        if (!startDate && !endDate) {
            notifyError(MISSING_SCHEDULE_START_END_TIME_ERROR);
            return;
        }
        if (endDate < startDate) {
            notifyError(INVALID_SCHEDULE_END_DATE_ERROR);
            return;
        }
        if (!disableEditEnd && endDate < new Date()) {
            notifyError(INVALID_SCHEDULE_END_TIME_ERROR);
            return;
        }
        if (!disableEditStart && startDate < new Date()) {
            notifyError(INVALID_SCHEDULE_START_TIME_ERROR);
            return;
        }
        if (endDate > startDate.getTime() + 30 * 24 * 60 * 60 * 1000) {
            notifyError(SCHEDULE_POLL_START_END_GAP_ERROR);
            return;
        }

        body.pollTimeLimit = timePollCustomToSeconds(pollTimeLimit);
        if ((endDate - startDate) / 1000 < body.pollTimeLimit) {
            notifyError(INVALID_TIME_LIMIT_ERROR);
            return;
        }
        console.log(body);
        if (setDate) {
            setDate(startDate);
        }
        if (setPollEnd) {
            setPollEnd(endDate);
        }
        body.shortName = getShortName();
        const timeToStart = startDate - new Date();
        if (notificationTime && notificationTime !== -1 && timeToStart < notificationTime * 1000) {
            notifyError(PAST_NOTIFICATION_ERROR);
            return;
        }
        body.reminderTime = notificationTime ? notificationTime : -1;
        await editPublishedPollDataFromKey(body, pollKeyResult, pollCode);

        // if (!!courseId) {
        //   await notifySchedule(pollKeyResult, pollCode, {
        //     emailType: "edit",
        //     courseId: courseId,
        //     pollTitle: pollTitle,
        //     startTime: startDate,
        //     endTime: endDate,
        //     // pollTimeLimit: pTimeLimit,
        //   });
        // }
        setModalShow(false);
    }


    const validateForm = () => {
        setPollTimeLimitH(pollTimeLimit.substring(0, 2));
        setPollTimeLimitM(pollTimeLimit.substring(3, 5));
        setPollTimeLimitS(pollTimeLimit.substring(6));
        if (!validCustomPollTime(pollTimeLimit)) {
            document.getElementById("error2").innerText = "Please enter a valid poll time that is 10+ seconds!";
            return false;
        }
        return true;
    };

    return (
        <Modal
            id="form-modal"
            show={show}
            onHide={handleClose}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={isLoading ? "static" : true}
            dialogClassName={"schedule-modal-dialog"}
        >
            <Spin tip="Loading..." size="large" spinning={isLoading}>
                <Modal.Header className="schedule-modal-header">
                    <ModalClose onClick={() => setModalShow(false)} className="schedule-modal-close"/>
                    <Modal.Title
                        className="schedule-modal-title"
                        id="contained-modal-title-vcenter"
                    >
                        {SCHEDULE_POLL_EDIT_MODAL_TITLE}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="schedule-modal-body">
                    <div className="details-card">
                        <div className="details-title-container">
                            <DetailsIcon className="details-title-icon"/>
                            <div className="details-title-text">
                                {DETAILS_TEXT}
                            </div>
                        </div>
                        <div
                            className="details-content"
                            style={{paddingRight: 5, height: 230, overflow: "auto"}}
                        >
                            <div style={{color: "#000", fontSize: 14, marginBottom: "23px"}}>
                                {SCHEDULE_POLL_EDIT_MODAL_MESSAGE}
                            </div>
                            <DatePickerComponentNew startDate={startDate} endDate={endDate} setStartDate={setStartDate}
                                                    setEndDate={setEndDate} pollKey={pollKey}
                                                    pollUniqueCode={pollUniqueCode}/>
                            <div style={{
                                width: "100%",
                                display: "flex",
                                gap: 10,
                                alignItems: "center",
                                margin: "25px 0 10px 0"
                            }}>
                                <div
                                    tabIndex={0}
                                    role={TEXTBOX}
                                    aria-label={ACTIVATE_TIME}
                                    style={{
                                        color: "#000",
                                        fontSize: 14,
                                        fontWeight: "400",
                                        display: "flex",
                                    }}
                                >
                                    {POLL_TIME_LIMIT_DESCRIPTION}
                                </div>
                                <div style={{
                                    width: 116,
                                    position: "relative",
                                    display: "flex",
                                    flexDirection: "row",
                                    border: "1px solid #D0D0D0",
                                    borderRadius: 3
                                }}>
                                    <div
                                        style={{
                                            display: "grid",
                                            gridTemplateColumns: "minmax(0, 1fr) minmax(0, 1fr)",
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            transform: "translate(0px, -100%)"
                                        }}
                                    >
                                        <div style={{fontSize: 13, fontWeight: "400", textAlign: "center"}}>
                                            Hours
                                        </div>
                                        <div style={{fontSize: 13, fontWeight: "400", textAlign: "center"}}>
                                            Minutes
                                        </div>
                                    </div>

                                    <input
                                        aria-label={pollTimeLimitH + " hours"}
                                        role={TEXTBOX}
                                        className="time-input"
                                        type="text"
                                        maxLength={2}
                                        value={pollTimeLimitH}
                                        placeholder="HH"
                                        onChange={handlePollTimeChangeH}
                                        onBlur={handlePollTimeonBlurH}
                                        style={{
                                            color: "#565656",
                                            fontSize: 13,
                                            width: "55px",
                                            height: "26px",
                                            borderColor: "transparent"
                                        }}
                                    />
                                    <div style={{
                                        alignItems: "center",
                                        justifyContent: "center",
                                        display: "flex",
                                        fontSize: 13,
                                        height: "26px"
                                    }}> :
                                    </div>
                                    <input
                                        aria-label={pollTimeLimitM + " minutes"}
                                        role={TEXTBOX}
                                        className="time-input"
                                        type="text"
                                        maxLength={2}
                                        value={pollTimeLimitM}
                                        placeholder="MM"
                                        onChange={handlePollTimeChangeM}
                                        onBlur={handlePollTimeonBlurM}
                                        style={{
                                            color: "#565656",
                                            fontSize: "13.5px",
                                            width: "55px",
                                            height: "26px",
                                            border: "none"
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="notification-container">
                                <div className="notification-title-container" style={{borderBottom: "none"}}>
                                    <NotificationIcon className="notification-title-icon"/>
                                    <div className="notification-title-text">
                                        {EMAIL_NOTIFICAITON_TEXT}
                                    </div>
                                    {notificationTime === -1 ?
                                        <CaretDown
                                            tabIndex={0}
                                            className="caret-icon"
                                            onClick={() => setNotificationTime(selectedNotificationTime)}
                                        /> :
                                        <CaretUp
                                            tabIndex={0}
                                            className="caret-icon"
                                            onClick={() => setNotificationTime(-1)}
                                        />
                                    }
                                </div>
                                {notificationTime !== -1 &&
                                    <div className="notification-content">
                                        <div className="notification-subtext">
                                            {EMAIL_NOTIFICAITON_SUBTITLE}
                                        </div>
                                        <div className="notification-label-container">
                                            <div className="notification-label-text">
                                                {EMAIL_NOTIFICAITON_LABEL_PREFIX}
                                            </div>
                                            <YuJaDropdown
                                                size={YUJA_DROPDOWN_SIZE.SMALL}
                                                data={startDate < new Date() ? EMAIL_NOTIFICATION_OPTIONS : reminderOptions}
                                                value={notificationTime}
                                                disabled={!inCourse || reminderOptions.length === 0 || startDate < new Date()}
                                                onChange={option => {
                                                    setSelectedNotificationTime(option.value);
                                                    setNotificationTime(option.value);
                                                }}
                                                // onOpen={() => smoothScrollToBottom(document.querySelector(".details-content"))}
                                                placeholder={NOTIFICATION_DROPDOWN_PLACEHOLDER}
                                                style={{width: 100, height: 35, fontSize: 14}}
                                                isNotificationDropdown={true}
                                                isEditNotificationDropdown={true}
                                            />
                                            <div className="notification-label-text">
                                                {EMAIL_NOTIFICAITON_LABEL_SUFFIX}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="schedule-modal-footer">
                    <YuJaButton
                        onClick={handleClose}
                        type="secondary"
                        style={{margin: 0, borderRadius: 4.21, fontSize: 14}}
                    >
                        {MODAL_CLOSE_TEXT}
                    </YuJaButton>
                    <YuJaButton
                        onClick={(e) => {
                            e.preventDefault();
                            if (validateForm()) {
                                handleSubmit();
                            }
                        }}
                        style={{margin: 0, borderRadius: 4.21, fontSize: 14}}
                    >
                        {MODAL_SAVE_TEXT}
                    </YuJaButton>
                </Modal.Footer>
            </Spin>
        </Modal>
    )
}
import { React, useEffect, useRef, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Rnd } from "react-rnd";
import { useLocation, useParams } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls.js";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown.js";
import { YuJaTooltip } from "../../components/standardization/YuJaTooltip.js";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as First } from "../../images/First.svg";
import { ReactComponent as Last } from "../../images/Last.svg";
import { ReactComponent as Next } from "../../images/Next.svg";
import { ReactComponent as Prev } from "../../images/Prev.svg";
import ArrowIcon from "../../images/arrow_right.svg";
import { ReactComponent as COORDINATE } from "../../images/coordiante.svg";
import { ReactComponent as DisabledFirst } from "../../images/disabled_first.svg";
import { ReactComponent as DisabledLast } from "../../images/disabled_last.svg";
import { ReactComponent as DisabledNext } from "../../images/disabled_next.svg";
import { ReactComponent as DisabledPrev } from "../../images/disabled_prev.svg";
import { ReactComponent as NoResultIllustration } from "../../images/no_result.svg";
import { ReactComponent as ThumbDown } from "../../images/openEndedThumbDown.svg";
import { ReactComponent as ThumbUp } from "../../images/openEndedThumbUp.svg";
import { ReactComponent as ThumbUpWhite } from "../../images/thumbupnormal.svg";
import { ReactComponent as ThumbDownWhite } from "../../images/thumbdownnormal.svg";
import ClickableImage from "../../questions/CI/ClickableImage.js";
import MHQuestion from "../../questions/MH/MHQuestion.js";
import SAQuestion from "../../questions/SA/SAQuestion.js";
import {
    ASCII_CODE_A,
    ATTENDANCE,
    BLANK_REGEX,
    BLANK_REGEX_NEW,
    BLANK_REGEX_WRAPPED,
    BLANK_REGEX_WRAPPED_NEW,
    BUTTON,
    CI_COORDINATE_SIZE,
    DEFAULT_IMAGE_ALT,
    DEFAULT_TOTAL_QUESTIONS,
    FIXED_ANSWER,
    HIDE_CORRECT_ANSWER_TOOLTIP_TEXT,
    NO_RESPONSES_TEXT,
    OPEN_ENDED_DISLIKE_SORT,
    OPEN_ENDED_LIKE_SORT,
    OPEN_ENDED_TIME_SORT,
    OPTION_PLACEHOLDER_PREFIX,
    OPTION_PLACEHOLDER_SUFFIX,
    POLL_SHARE_MODE,
    POLL_TYPE,
    QUESTION_IMAGE_ID,
    QUESTION_TITLE_PLACEHOLDER,
    QUESTION_TYPES, RANK_TEXT, RIGHT_PANEL_TABS,
    SELECT_QUESTION_ARIA,
    SHOW_CORRECT_ANSWER_TOOLTIP_TEXT,
    SORT_BY_TEXT,
    TEXTBOX,
    TYPE_CORRECT_ANSWER,
    WORD_CLOUD_DATA
} from "../../utils/constants";
import {scrollToBottom, stripHTMLTags} from "../../utils/helpers";
import { decodeCIParam, questionCodeToName } from "../../utils/questionUtils";
import ClassResultRank from "../JoinQuizPage/ClassResultRank.js";
import WordCloudResult from "../JoinQuizPage/WordCloudResult";
import QuestionBarChart from "./QuestionBarChart.js";
import { ReactComponent as HideCorrectIcon } from "./images/HideCorrectAnswer.svg";
import { ReactComponent as ShowCorrectIcon } from "./images/ShowCorrectAnswer.svg";
import "./styles.css";
import styles from "./SharePollPage.module.css"
import parse from 'html-react-parser';
import sharePollPageStyles from "./SharePollPage.module.css";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {LatexHTMLInline, LatexInline} from "../../components/Tiptap/latexParser";

export default function ViewQuestions({
    countData=[],
    reactionCountData=[],
    data=[], setData=()=>{}, dataRef,
    userSettings,
    firstLoad, setFirstLoad,
    defaultView, setDefaultView, currentView,
    geofence, setGeofence,
    syncLms, setSyncLms,
    mapInitialized, setMapInitialized,
    loading=true, initialized=false,
    localState,
    currentQuestion, setCurrentQuestion,
    selectedQuestion, setSelectedQuestion,
    points,
     disabled,
    pollShareMode,
    showAnswer, setShowAnswer,
}) {
    let {width: windowWidth} = useWindowDimensions();
    const location = useLocation();
    const [newItems, setNewItems] = useState([]);
    const [newFITBItems, setNewFITBItems] = useState([]);
    const [questionImageURL, setQuestionImageURL] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [setSAResult, getSAResult] = useLocalStorageNew("SAResult", []);
    const [setFITBResult, getFITBResult] = useLocalStorageNew("FITBResult", []);
    const [setWordCloudData, ] = useLocalStorageNew(WORD_CLOUD_DATA, {});
    const imageRef = useRef(null);
    const refMH = useRef(null);
    const timeRef = useRef(null);
    const { pollKey, pollCode } = useParams();
    const [pastCorrAns, setPastCorrAns] = useState(0);
    const [thumbUpCount, setThumbUpCount] = useState(0);
    const [thumbDownCount, setThumbDownCount] = useState(0);
    const [animateThumbUp, setAnimateThumbUp] = useState(false);
    const [animateThumbDown, setAnimateThumbDown] = useState(false);
    const isNarrow = useMobileAccess(1200);
    const [isSwitching, setIsSwitching] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [sortOption, setSortOption] = useState(OPEN_ENDED_TIME_SORT);
    // const [customPoints, setCustomPoints] = useState(0);
    // const [customTimeLimit, setCustomTimeLimit] = useState(0);
    // const [customEntries, setCustomEntries] = useState(0);
    // const [customRequireName, setCustomRequireName] = useState(userSettings && userSettings[USER_SETTINGS.POLL_REQUIRED_NAME]);
    const [currentQuestionInput, setCurrentQuestionInput] = useState(currentQuestion);
    const [skeletonData, setSkeletonData] = useState({});
    const [matchingQuestions, setMatchingQuestions ]= useState([]);
    const [currentSpotlightIndex, setCurrentSpotlightIndex] = useState(0);
    const [displaySpotlightAnimation, setDisplaySpotlightAnimation] = useState(false);
    
    const [isFocused, setIsFocused] = useState(false);

    const {poll={}, questions=[]} = data;
    const {pollType=''} = poll;

    const handleFocus = () => {
        setIsFocused(true);
    };
    // const pointsRef = useRef();
    // const timeLimitRef = useRef();
    // const entriesRef = useRef();

    let currQues = null;
    if(!loading && initialized) {
        currQues = data.questions[currentQuestion-1];
    }

    const onChangeCustomQuestions = (e) => {
        setCurrentQuestionInput(e.target.value);
    };

    useEffect(() => {
        if (location && location.state && location.state.pollData) {
            let newData = {
                poll: location.state.pollData,
                questions: [...Array(location.state.pollData.totalQuestions)]
            };
            setSkeletonData(newData);
        }
    }, [location]);

    useEffect(() => {
        setCurrentQuestionInput(currentQuestion);
    }, [currentQuestion])


    useEffect(() => {
        let data = countData[currentQuestion - 1];
        if (!data || data.length === 0) {
            return; 
        }
        
        const intervalId = setInterval(() => {
                setDisplaySpotlightAnimation(true);
                setTimeout(() => {
                    setDisplaySpotlightAnimation(false);
                    setCurrentSpotlightIndex(prevIndex => (prevIndex + 1) % data.length);
                }, 2000)
        }, 5000); // Change every 5 seconds
        
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [countData, currentQuestion, questions]);

    const handleQuestionBlur = (e) => {
        setIsFocused(false);
        const isValidNumber = /^\d+$/.test(currentQuestionInput);
        if(isValidNumber && !isNaN(parseInt(currentQuestionInput, 10)) && parseInt(currentQuestionInput, 10) <= data?.questions?.length  && parseInt(currentQuestionInput, 10) >= 1)
        {
            setCurrentQuestion(parseInt(currentQuestionInput, 10))
        }
        else {
            setCurrentQuestionInput(currentQuestion)
        }
      }

    function updateNewItems(arr1) {
        const SAResult = getSAResult();
        const list = [];

        if (arr1.length >= SAResult.length) {
            const smallerLength = Math.min(arr1.length, SAResult.length);
            const length_diff = Math.abs(arr1.length - SAResult.length);
            for (let i = 0; i < smallerLength; i++) {
                if (arr1[i + length_diff] !== FIXED_ANSWER.UNANSWERED && SAResult[i]["0"] != FIXED_ANSWER.UNANSWERED && arr1[i + length_diff]["0"] !== SAResult[i]["0"]) {
                    list.push(arr1[i + length_diff]);
                }
            }
            const remainingArray = arr1.length >= SAResult.length ? arr1 : SAResult;
            for (let i = 0; i < length_diff; i++) {
                if (remainingArray[i] !== FIXED_ANSWER.UNANSWERED) {
                    list.push(remainingArray[i]);
                }
            }
        }
        else {
            for (let i = 0; i < arr1.length; i++) {
                if (arr1[i] !== FIXED_ANSWER.UNANSWERED) {
                    list.push(arr1[i]);
                }
            }
        }

        setNewItems(list);
    }

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            if (questions.length < currentQuestion || countData.length < currentQuestion) {
                return;
            }
            const {questionType} = questions[currentQuestion - 1];
            if (![QUESTION_TYPES.FITB.name, QUESTION_TYPES.SA.name].includes(questionType)) {
                return;
            }
            const curCountData = JSON.parse(JSON.stringify(countData[currentQuestion - 1]));
            if (questionType === QUESTION_TYPES.FITB.name) {
                setNewFITBItems(curCountData.map(item => item.map((key) => `"${key}"`).join(", ")));
            } else if (questionType === QUESTION_TYPES.SA.name) {
                setNewItems(curCountData);
            }


        }, 1500);

        return () => {
            if (!!timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [countData, currentQuestion, questions]);

    useEffect(() => {
        setNewFITBItems([]);
        setNewItems([]);

    }, [currentQuestion]);


    function updateFITBItems(arr1) {
        if (!arr1) {
            return;
        }
        const FITBResult = getFITBResult();
        const list = [];

        if (arr1.length >= FITBResult.length) {
          const smallerLength = Math.min(arr1.length, FITBResult.length);
          const length_diff = Math.abs(arr1.length - FITBResult.length);

          for (let i = 0; i < smallerLength; i++) {
            if (arr1[i + length_diff] != FIXED_ANSWER.UNANSWERED && FITBResult[i] != FIXED_ANSWER.UNANSWERED){
                if (Object.keys(arr1[i + length_diff]).lenth !== Object.keys(FITBResult[i])) {
                    list.push(arr1[i + length_diff]);
                }
                else {
                    const keys = Object.keys(arr1[i + length_diff]);
                    let valuesMatch = true;

                    for (let j = 0; j < keys.length; j++) {
                        const key = keys[j];
                        if (arr1[i + length_diff][key] !== FITBResult[i][key]) {
                        valuesMatch = false;
                        break;
                    }
                    }

                    if (!valuesMatch) {
                        list.push(arr1[i + length_diff]);
                    }
            }
          }
        }

        const remainingArray = arr1.length >= FITBResult.length ? arr1 : FITBResult;
          for (let i = 0; i < length_diff; i++) {
            if (remainingArray[i] !== FIXED_ANSWER.UNANSWERED) {
              list.push(remainingArray[i]);
            }
          }
        } else {
          for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== FIXED_ANSWER.UNANSWERED) {
              list.push(arr1[i]);
            }
          }
        }

        setNewFITBItems(list);
    }

    const handleQuestionKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleQuestionBlur();
        }
      }
    useEffect(() => {
        setSAResult([]);
        setFITBResult([]);
        setWordCloudData({});
        // setCustomPoints(currQues && currQues.weightage ? currQues.weightage : userSettings[USER_SETTINGS.QUESTION_POINTS]);
        // if (pollType === POLL_TYPE.ATTENDANCE) {
        //     setCustomTimeLimit(timeSecondsToCustomNew(currQues && currQues.timeLimit ? currQues.timeLimit : userSettings[USER_SETTINGS.ATTENDANCE_DURATION]));
        // } else {
        //     setCustomTimeLimit(timeSecondsToCustomNewMinutes(currQues && currQues.timeLimit ? currQues.timeLimit : 0));
        // }
        // setCustomEntries(currQues && currQues.entries ? currQues.entries : userSettings[USER_SETTINGS.QUESTION_ENTRIES]);
        // setCustomRequireName(data?.poll?.anonymousJoin !== undefined ? data.poll.anonymousJoin : userSettings[USER_SETTINGS.POLL_REQUIRED_NAME]);
    }, [currQues, selectedQuestion]);

    const onLoad = () => {
        setIsLoaded(true);
    }

    useEffect(() => {
        setAnimateThumbUp(true);
      }, [thumbUpCount]);

    const onAnimationEndThumbUp = () => {
        setAnimateThumbUp(false);
    };

    useEffect(() => {
        setAnimateThumbDown(true);
      }, [thumbDownCount]);

    const onAnimationEndThumbDown = () => {
        setAnimateThumbDown(false);
    };


    const shuffleArray = (array) => {
        let shuffledArray = [...array];
        for (let i = shuffledArray.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }
        return shuffledArray;
      };
      
      function transformQuestions(questions) {
        return questions.map(question => {
          if (question.serialNo === currentQuestion) {
            const correctAnswers = question.optionsMap;
            const optionsMapArray = Array.from(correctAnswers); // Assuming optionsMap is an array-like object
      
            // Shuffle the indices
            let indices = optionsMapArray.map((_, index) => index);
            let shuffledIndices = shuffleArray(indices);
      
            // Ensure shuffled indices are different from original
            while (shuffledIndices.every((val, index) => val === indices[index])) {
              shuffledIndices = shuffleArray(indices);
            }
      
            // Create the shuffled optionsMap
            let shuffledOptionsMap = []
            for (let i = 0; i < shuffledIndices.length; i++) {
                if (correctAnswers[shuffledIndices[i]].length > 1) {
                shuffledOptionsMap[i] = [correctAnswers[i][0], correctAnswers[ shuffledIndices[i]][1]];
                } 
                else {
                    shuffledOptionsMap[i] = [correctAnswers[i][0]]
                }
            }
      
            return {
              ...question,
              correctAnswers: correctAnswers,
              optionsMap: shuffledOptionsMap
            };
          }
          else {
            return {
                ...question
            }
        }
        });
      };

    useEffect(() => {
        if (!defaultView && currentView === RIGHT_PANEL_TABS.QUESTION_BUILDER.name ) {
            return;
        }

        const {pollShareMode} = poll;
        if (reactionCountData.length >= currentQuestion
            && !!pollShareMode
        ) {
            const reaction = reactionCountData[currentQuestion - 1];
            setThumbUpCount(reaction.thumbUp);
            setThumbDownCount(reaction.thumbDown);

        }
        // if(localState === "SHARED_ALL") {
        //     setThumbDownCount(0);
        //     setThumbUpCount(0);
        // }
    }, [poll, currentQuestion, localState, reactionCountData]);

    const renderTitle = () => {
        if (!currQues?.questionType) {
            return (<span />);
        }
        if (currQues.questionType !== QUESTION_TYPES.FITB.name) {
          return (
              <span tabIndex={0} style={{ fontSize: 18}}>
                  {(defaultView || currQues.queTitle)
                      ? <LatexHTMLInline>{currQues.queTitle}</LatexHTMLInline>
                      : QUESTION_TITLE_PLACEHOLDER
                  }
              </span>
          );
        }
        else {
            let currIndex = 0;
            return (<>
                <div tabIndex={0} className={styles.questionTitleFITBText} style={{ height: "auto"}}>
                    {!defaultView && currentView === RIGHT_PANEL_TABS.QUESTION_BUILDER.name && !currQues.queTitle ?
                        QUESTION_TITLE_PLACEHOLDER :
                        currQues.queTitle.split(currQues?.hasBlankRestriction ? BLANK_REGEX_WRAPPED_NEW : BLANK_REGEX_WRAPPED).map((str, i) => { // /(\(.*?\))/g
                            let re = new RegExp(currQues?.hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX); // /\([^\(\)]+\)/g
                            if (!re.test(str)) {
                                const oneLine = str.replace(/[\r\n]/g, " @newLine ");
                                const wordArr = oneLine ? oneLine.split(" ") : [];
                                return (
                                    <>
                                        {wordArr.map((s, idx) => (<>
                                            {s === '@newLine' &&
                                                <div key={idx} style={{ width: "100%" }}></div>
                                            }
                                            {!!s && s !== '@newLine' &&
                                                <div key={idx} style={{ marginRight: "5px" }}>{s}</div>
                                            }
                                        </>))}
                                    </>
                                );
                            } else {
                                // const id = currIndex;
                                currIndex++;
                                return (<>
                                    {Object.entries(currQues.correctAnswers)[currIndex - 1][1].length === 1 &&
                                        <div
                                            className="fitbBlankContainers"
                                            style={!!showAnswer ? {border: "3px solid #268726"} : {}}
                                        >
                                            <span
                                                className="fitbBlankWrap"
                                                style={ { visibility: !!showAnswer ? "visible" : "hidden" }}
                                            >
                                                {Object.entries(currQues.correctAnswers)[currIndex - 1][1]}
                                            </span>
                                        </div>
                                    }
                                    {Object.entries(currQues.correctAnswers)[currIndex - 1][1].length > 1 &&
                                        <div
                                            className="fitbBlankContainers"
                                            style={!!showAnswer ? {border: "3px solid #268726"} : {}}
                                        >
                                            <span
                                                className="fitbBlankWrap"
                                                style={ { visibility: !!showAnswer ? "visible" : "hidden" }}
                                            >
                                                {renderAllCorrectOptions(currIndex - 1)}
                                            </span>
                                        </div>
                                    }
                                </>);
                            }
                        })
                    }
                </div>
            </>);
        }
    };

    const renderAllCorrectOptions = (index) => {
        let allOpts = "";
        for(let element of Object.entries(currQues.correctAnswers)[index][1]) {
            if(allOpts !== "") {
                allOpts += " / " + element;
            }
            else {
                allOpts += element;
            }
        }
        return allOpts;
    };


    const renderQuestionOptions = () => {
        const question = questions[currentQuestion - 1];
        const curCountData = countData[currentQuestion - 1];
        let transformedQuestions = questions;
        let matchingquestion =  questions[currentQuestion - 1];
        if (!!question && !!question.questionType && question.questionType === QUESTION_TYPES.MH.name && !!question.correctAnswers && question.correctAnswers != null) {
            transformedQuestions =  questions.map(question => {
                if (question.serialNo === currentQuestion && question.correctAnswers && question.optionsMap) {
                return {
                  ...question,
                  correctAnswers: question.optionsMap,
                  optionsMap: question.correctAnswers
                };
                }
                else {
                    return {
                        ...question
                    }
                }
              });
              matchingquestion = transformedQuestions[currentQuestion - 1];
        }
        if (!question) {
            return (<div />);
        }

        if (pollType === POLL_TYPE.ATTENDANCE) {
            return (
                <div tabIndex={0} className={styles.optionsText}>
                    {question?.correctAnswers[0] ? question.correctAnswers[0] : TYPE_CORRECT_ANSWER}
                </div>
            );
        } else if (question.questionType === QUESTION_TYPES.MCSS.name || question.questionType === QUESTION_TYPES.TF.name || question.questionType === QUESTION_TYPES.CI.name) {
            if (defaultView && !!curCountData) {
                return (
                    <QuestionBarChart
                        showAnswer={showAnswer}
                        question={question}
                        chartData={curCountData}
                    />
                );
            }

            if (question.questionType === QUESTION_TYPES.CI.name) {
                return (
                    <div>
                        {question?.optionsMap && question.optionsMap.map((entry, index) => {
                            const isCorrect = question.correctAnswers.includes(index);
                            return (<div className={styles.optionContainer} key={index}>
                                <div tabIndex={0} className={styles.option} id={"Option" + index} style={{paddingLeft: 0}}>
                                    <div
                                        id={"OptionPart1" + index}
                                        className={`${styles.optionsText} ${styles.optionsIndex}`}
                                        style={(!!showAnswer && isCorrect) ? {color: "#268726", fontWeight: "700"} : {}}
                                    >
                                        {String.fromCharCode(index + ASCII_CODE_A)}
                                    </div>

                                    <div
                                        id={"OptionPart2" + index}
                                        className={styles.optionsText}
                                        style={(!!showAnswer && isCorrect) ? {color: "#268726", fontWeight: "700"} : {}}
                                    >
                                        <LatexHTMLInline>{entry.text}</LatexHTMLInline>
                                    </div>

                                </div>
                            </div>
                        )})}
                    </div>
                );
            }

            return (
                <div className={styles.chartContainerSharePoll} >
                    {question?.optionsMap && Object.entries(question.optionsMap).map((entry, index) => {
                        const isCorrect = question.questionType === QUESTION_TYPES.TF.name
                            ? question.correctAnswers === entry[1]
                            : question.correctAnswers.includes(entry[0]);
                        return (
                        <div className={styles.optionContainer} key={index}>
                            <div className={styles.option} id={"Option" + index} style={{paddingLeft: 0}}>
                                {question.questionType !== QUESTION_TYPES.TF.name &&
                                    <div
                                        id={"OptionPart1" + index}
                                        className={`${styles.optionsText} ${styles.optionsIndex}`}
                                        style={(!!showAnswer && isCorrect) ? {color: "#268726", fontWeight: "700"} : {}}
                                    >
                                        {entry[0] ? entry[0].toUpperCase() : String.fromCharCode(index + ASCII_CODE_A)}
                                    </div>
                                }

                                <div
                                    id={(question.questionType === QUESTION_TYPES.TF.name ? "OptionPart1" : "OptionPart2") + index}
                                    className={styles.optionsText}
                                    style={(!!showAnswer && isCorrect) ? {color: "#268726", fontWeight: "700"} : {}}
                                >
                                    <LatexHTMLInline>{entry[1]}</LatexHTMLInline>
                                </div>
                                
                                {/* <div className="questionPanelData">{updatedValue.length > index ? updatedValue[index].value : 0}</div> */}
                            </div>
                        </div>
                    )})}
                </div>
            );
        } else if (question.questionType === QUESTION_TYPES.MH.name) {
            if (!defaultView && !showAnswer) {
                return (<div ref={refMH}>
                    <MHQuestion
                        questions={questions}
                        setQuestions={(qs) => setData({ ...data, questions: qs })}
                        selectedQuestion={question}
                        scrollToBottom={() => scrollToBottom(refMH)}
                        questionBuilder={true}
                        disabled={disabled}
                    />
                </div>);
            }

            return (
                <div style={{ overflow: "auto" }}>
                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                        {!!question.imageURL && isNarrow &&
                            <img
                                ref={imageRef}
                                style={{
                                    maxWidth: "450px", maxHeight: "100%", mixBlendMode: "normal",
                                    boxShadow: "0px 2.59px 9.065px 1.295px rgba(0, 0, 0, 0.25)",
                                    borderRadius: "12px"
                                }}

                                src={question.imageURL}
                                onLoad={onLoad}
                                alt="Question"
                            />
                        }
                    </div>

                    {!!currQues?.weightage && showAnswer &&
                        <div style={{ display: "flex" }}>
                            <div>
                                {!!question.correctAnswers && question.correctAnswers.map((element, index) => (
                                    <>
                                        {element.length > 1 &&
                                            <div
                                                key={"Correct" + index}
                                                id={"CorrectOption" + index}
                                                className="questionPanelOptionsContainer"
                                                style={{ marginBottom: "0px", gap: "20px" }}
                                            >
                                                <div
                                                    tabIndex={0}
                                                    className={sharePollPageStyles.optionsText}
                                                    id={"CorrectPart1" + index}
                                                    style={{
                                                        marginRight: "0px", border: "3px solid #909090", padding: "10px",
                                                        borderRadius: "3px", minWidth: "200px", flex: 1
                                                    }}
                                                ><LatexHTMLInline>{element[0]}</LatexHTMLInline></div>
                                                <div
                                                    tabIndex={0}
                                                    className={sharePollPageStyles.optionsText}
                                                    id={"CorrectPart2" + index}
                                                    style={{
                                                        border: "3px solid green", padding: "10px", borderRadius: "3px",
                                                        minWidth: "200px", flex: 1
                                                    }}
                                                ><LatexHTMLInline>{element[1]}</LatexHTMLInline></div>
                                            </div>
                                        }
                                    </>
                                ))}
                            </div>
                        </div>
                    }
                    {!!currQues?.weightage && !showAnswer &&
                        <div style={{ display: "flex", overflow: "auto" }}>
                            <div >
                                {question.optionsMap.map((element, index) => (
                                    <div key={"Normal" + index} id={"NormalOption" + index} className="questionPanelOptionsContainer" style={{ marginBottom: "0px", gap: "10px" }}>
                                        {element.length > 1 ?
                                            <>
                                                <div
                                                    tabIndex={0}
                                                    className={sharePollPageStyles.optionsText}
                                                    id={"NormalPart1" + index}
                                                    style={{
                                                        marginRight: "0px", border: ".5px solid #909090", padding: "10px",
                                                        borderRadius: "3px", minWidth: "200px", flex: 1
                                                    }}
                                                ><LatexHTMLInline>{element[0]}</LatexHTMLInline></div>
                                                <img
                                                    src={ArrowIcon}
                                                    alt="Arrow icon"
                                                    className="questions-page-icons cursor-pointer"
                                                    style={{ width: "30px", margin: "0px 0px 0px 10px" }}
                                                /> </> : <> <div className="questionPanelAnswersText" id={"NormalPart1" + index} style={{ marginRight: "0px", padding: "10px", borderRadius: "3px", minWidth: "200px", flex: 1 }}></div>
                                                <img
                                                    className="questions-page-icons cursor-pointer"
                                                    style={{ width: "30px", margin: "0px 0px 0px 10px" }}
                                                /> </>}
                                        <div
                                            tabIndex={0}
                                            className={sharePollPageStyles.optionsText}
                                            id={"NormalPart2" + index}
                                            style={{
                                                border: ".5px solid #909090", padding: "10px", borderRadius: "3px",
                                                minWidth: "200px", flex: 1
                                            }}
                                        ><LatexHTMLInline>{element.length > 1 ? element[1] : element[0]}</LatexHTMLInline></div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }
                </div>
            )
        } else if (question.questionType === QUESTION_TYPES.RK.name) {
            if (defaultView && !!curCountData && !!Object.entries(curCountData).length) {
                return (
                    <ClassResultRank
                        chartData={curCountData}
                    />
                );
            }
            return (
                <div className={styles.chartContainerSharePoll} >
                    {question?.optionsMap && question.optionsMap.filter(e => !!e).map((entry, index) => (
                        <div className={styles.optionContainer} key={index}>
                            <div className={styles.option} id={"Option" + index} style={{paddingLeft: 0}}>
                                <span
                                    id={"OptionPart1" + index}
                                    className={sharePollPageStyles.optionsText}
                                    tabIndex={0}
                                    style={{display: "flex", gap: 5}}
                                >
                                    <span>{RANK_TEXT} {index + 1}:</span>
                                    <LatexHTMLInline>{entry}</LatexHTMLInline>
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else if(question.questionType === QUESTION_TYPES.SA.name) {
            const totalItems = curCountData?.length;
            let currentResponses = [];
            if (curCountData && curCountData.length >= 3) {
            for (let i = 0; i < 4; i++) {
                const index = (currentSpotlightIndex + i) % totalItems;
                currentResponses.push(curCountData[index]);
            }
            } else if (curCountData && curCountData.length === 2) {
                for (let i = 0; i < 2; i++) {
                    const index = (currentSpotlightIndex + i) % totalItems;
                    currentResponses.push(curCountData[index]);
                }
            } else {
                currentResponses = curCountData;
            }

            if (!defaultView || !curCountData) {
                return (<></>);
            }

            return (
                <>
                    {/* {currentResponse.length === 3 && currentResponses.map((item, index) => {
                         let backgroundColor = "#BCBCBC"; // Default color
                         let fontColor = "#000000";
                         if (currentResponses.length === 1) {
                           backgroundColor = "#BCBCBC"; // Dark for 1 item
                           fontColor =  "#4C4C4C"
                         } else if (currentResponses.length === 2) {
                           backgroundColor = index === 0 ? "#CACACA" : "#BCBCBC"; // Light for first, dark for second
                           fontColor = index === 0 ? "#000000": "#4C4C4C";
                         } else if (currentResponses.length === 3) {
                           backgroundColor = index === 1 ? "#BCBCBC" : "#CACACA"; // Dark for second, light for first and third
                           fontColor = index === 1 ?  "#4C4C4C" : "#000000";
                         }

                         return (
                        <div
                            className= {`questionPanelAnswersText ${!newItems.includes(item) ? 'resultAnimation' : ''}`}
                            style={{ marginTop: "20px", fontSize: "20px", fontWeight: 600, backgroundColor: backgroundColor, color: fontColor, width: "fit-content", padding: "15px", borderRadius: "9px", width: "647px"}}
                        >
                            {item}
                        </div>
                         );
                    }
                    )} */}
                    {curCountData.length > 0  && currentResponses && currentResponses.length === 1 &&
                        <div tabIndex={0} className= {`questionPanelAnswersText spotlightBig ${!newItems.includes(currentResponses[0]) ? 'resultAnimation' : ''}`}>
                        {currentResponses[0]}
                        </div>
                    }

                    {curCountData.length > 0   && currentResponses && currentResponses.length === 2 &&
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "700px"}}>
                        <div
                            tabIndex={0}
                            className= {`questionPanelAnswersText spotlightBig ${displaySpotlightAnimation? 'spotlightMovesDown' : ''}`}
                            style={{display: "flex", alignItems: "center"}}
                        >
                        {currentResponses[0]}
                        </div>
                        <div
                            tabIndex={0}
                            className={`questionPanelAnswersText spotlightSmallBottom ${displaySpotlightAnimation? 'spotlightMovesUp' : ''}`}
                        >
                            {currentResponses[1]}
                        </div>
                        </div>
                    }
                    {curCountData.length > 0 && currentResponses && currentResponses.length === 4 &&
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%"}}>
                        <div
                            className={`questionPanelAnswersText spotlightSmallTop`}
                            style={{backgroundColor: displaySpotlightAnimation? "#FFFFFF": "#dddddd", color: displaySpotlightAnimation? "#FFFFFF": "#575757"}}
                        >
                            {currentResponses[0]}
                        </div>
                        <div tabIndex={0} className= {`questionPanelAnswersText spotlightBig ${displaySpotlightAnimation? 'spotlightMovesUpMiddle' : ''}`}>
                        {currentResponses[1]}
                        </div>
                        <div tabIndex={0} className= {`questionPanelAnswersText spotlightSmallBottom ${displaySpotlightAnimation? 'spotlightMovesUp' : ''}`}>
                        {currentResponses[2]}
                        </div>
                        <div
                            tabIndex={0}
                            className= {`questionPanelAnswersText spotlightSmallBottom ${displaySpotlightAnimation? 'resultAnimation' : ''}`}
                            style={{display: displaySpotlightAnimation? "": "none", marginTop: "-33px"}}
                        >
                            {currentResponses[3]}
                        </div>
                        </div>
                    }
                </>
            );
        } else if(question.questionType === QUESTION_TYPES.OE.name) {
            if (!defaultView || !curCountData) {
                return (<></>);
            }

            if (!curCountData.length) { // No Responses Yet
                return (<div style={{ padding: "5px", height: "100%" }}>
                    <div style={{ backgroundColor: "#FFFFFF", border: "1px solid #E8EDF1", boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "7.39px", padding: 20, width: "100%", overflowY: "auto", height: "100%" }}>
                        <div style={{ width: "100%", height: "100%", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center", gap: 5 }}>
                            <NoResultIllustration width={240} height={125} />
                            <div style={{ fontSize: 18, marginTop: 10, fontWeight: 600 }}>
                                {NO_RESPONSES_TEXT}
                            </div>
                        </div>
                    </div>
                </div>);
            }

            let sortedLikeData = curCountData.slice();
            let sortedDisLikeData = curCountData.slice();
            if (curCountData.length >= 1) {
                sortedLikeData.sort((valueA, valueB) => {
                    return valueB["openEndedCount"][1] - valueA["openEndedCount"][1];
                });

                console.log(sortedLikeData);

                sortedDisLikeData.sort((valueA, valueB) => {
                    return valueB["openEndedCount"][2] - valueA["openEndedCount"][2];
                });

                console.log(sortedDisLikeData);
            }

            return (
                <div style={{ padding: "5px", height: "100%" }}>
                    <div style={{ backgroundColor: "#FFFFFF", border: "1px solid #E8EDF1", boxShadow: "0px 0px 4px 0px #00000040", borderRadius: "7.39px", padding: 20, width: "100%", overflowY: "auto", height: "100%" }}>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "end", width: "100%", flex: 1 }}>
                                <div style={{ fontSize: 16, color: "#0000000" }}> {SORT_BY_TEXT}</div>
                                <YuJaDropdown
                                    data={[OPEN_ENDED_TIME_SORT, OPEN_ENDED_LIKE_SORT, OPEN_ENDED_DISLIKE_SORT]}
                                    getOptionLabel={item => item}
                                    getOptionValue={item => item}
                                    onChange={option => setSortOption(option)}
                                    value={sortOption}
                                    containerStyle={{ width: 130 }}
                                    style={{ padding: "8px 15px" }}
                                />
                            </div>
                        </div>
                        {sortOption === OPEN_ENDED_TIME_SORT && curCountData && curCountData.map((item) => (
                            <div
                                tabIndex={0}
                                style={{
                                    marginTop: "20px",
                                    border: "1px solid #E8EDF1",
                                    color: "#000", borderRadius: "7.4px",
                                    minHeight: '94px', padding: 10, wordBreak: "break-word",
                                    boxShadow: "0px 0px 4px 0px #00000040"
                                }}
                            >
                                {`${item["selectedOption"]}`}
                                <div style={{ borderRadius: "200px", gap: "20px", backgroundColor: "#ECEAF1", display: "flex", justifyContent: "space-between", height: 35, width: 165, marginTop: 10, padding: "5px 20px" }}>
                                    <div style={{ display: "flex", gap: '5px' }}>

                                        <div>  <ThumbUp /></div>
                                        <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {item["openEndedCount"] ? item["openEndedCount"][1] : 0} </div>
                                    </div>

                                    <div style={{ display: "flex", gap: '5px' }}>
                                        <div> <ThumbDown /></div>
                                        <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {item["openEndedCount"] ? item["openEndedCount"][2] : 0} </div>
                                    </div>
                                </div>
                            </div>
                        )
                        )}
                        {sortOption === OPEN_ENDED_LIKE_SORT && sortedLikeData && sortedLikeData.map((item) => (
                            <div
                                tabIndex={0}
                                style={{
                                    marginTop: "20px",
                                    border: "1px solid #E8EDF1",
                                    color: "#000", borderRadius: "7.4px",
                                    minHeight: '94px', padding: 10, wordBreak: "break-word",
                                    boxShadow: "0px 0px 4px 0px #00000040"
                                }}
                            >
                                {`${item["selectedOption"]}`}
                                <div style={{ borderRadius: "200px", gap: "20px", backgroundColor: "#ECEAF1", display: "flex", justifyContent: "space-between", height: 35, width: 135, marginTop: 10, padding: "5px 20px" }}>
                                    <div style={{ display: "flex", gap: '5px' }}>

                                        <div>  <ThumbUp /></div>
                                        <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {item["openEndedCount"] ? item["openEndedCount"][1] : 0} </div>
                                    </div>

                                    <div style={{ display: "flex", gap: '5px' }}>
                                        <div> <ThumbDown /></div>
                                        <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {item["openEndedCount"] ? item["openEndedCount"][2] : 0} </div>
                                    </div>
                                </div>
                            </div>
                        )
                        )}
                        {sortOption === OPEN_ENDED_DISLIKE_SORT && sortedDisLikeData && sortedDisLikeData.map((item) => (
                            <div
                                tabIndex={0}
                                style={{
                                    marginTop: "20px",
                                    border: "1px solid #E8EDF1",
                                    color: "#000", borderRadius: "7.4px",
                                    minHeight: '94px', padding: 10, wordBreak: "break-word",
                                    boxShadow: "0px 0px 4px 0px #00000040"
                                }}
                            >
                                {`${item["selectedOption"]}`}
                                <div style={{ borderRadius: "200px", gap: "20px", backgroundColor: "#ECEAF1", display: "flex", justifyContent: "space-between", height: 35, width: 135, marginTop: 10, padding: "5px 20px" }}>
                                    <div style={{ display: "flex", gap: '5px' }}>

                                        <div>  <ThumbUp /></div>
                                        <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {item["openEndedCount"] ? item["openEndedCount"][1] : 0} </div>
                                    </div>

                                    <div style={{ display: "flex", gap: '5px' }}>
                                        <div> <ThumbDown /></div>
                                        <div style={{ fontSize: 15, fontWeight: 500, color: "#4D4D4D" }}> {item["openEndedCount"] ? item["openEndedCount"][2] : 0} </div>
                                    </div>
                                </div>
                            </div>
                        )
                        )}
                    </div>
                </div>
            );
        } else if (question.questionType === QUESTION_TYPES.FITB.name) {
            if (!defaultView || !curCountData) {
                return <></>
            }
            return (
                <div>
                    {curCountData.map((item) => {
                        const combinedString = item.map((key) => `"${key}"`).join(", ");
                            return (
                                <div
                                    className={`questionPanelAnswersText ${!newFITBItems.includes(combinedString) ? "resultAnimation" : ""
                                        }`}
                                    style={{
                                        marginTop: "20px",
                                        backgroundColor: "#f4f4f4",
                                        width: "fit-content",
                                        padding: "15px",
                                    }}
                                    key={combinedString}
                                >
                                    {combinedString}
                                </div>
                            );
                    })}
                </div>
            );
        } else if (question.questionType === QUESTION_TYPES.WC.name) {
            if (!defaultView || !curCountData) {
                return (<></>);
            }
            return (<div style={{maxWidth: "30vw"}}>
                <WordCloudResult
                    data={curCountData}
                    isSwitching={isSwitching}
                    setIsSwitching={setIsSwitching}
                />
            </div>);
        }
    };

    const renderQuestionType = () => {
        const question = questions[currentQuestion - 1];
        return (
            <div tabIndex={0} className={styles.badgeContainer} style={{marginLeft: 15}}>
                {pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE : questions[currentQuestion - 1]?.questionType ? questionCodeToName(question.questionType) : ""}
            </div>
        );
    };

    const removeHighlight = () => {
        if (pastCorrAns.length) {
            pastCorrAns.forEach(index => {
                const part1Element = document.getElementById("OptionPart1" + index);
                const part2Element = document.getElementById("OptionPart2" + index);
                if (part1Element && part2Element) {
                    part1Element.style.color = "";
                    part2Element.style.color = "";
                    part1Element.style.fontWeight = "400";
                    part2Element.style.fontWeight = "400";
                }
            });
        }
        else {
            if (document.getElementById("OptionPart1" + pastCorrAns) !== null) {
                document.getElementById("OptionPart1" + pastCorrAns).style.color = "";
                document.getElementById("OptionPart1" + pastCorrAns).style.fontWeight = "400";
            }
            if (document.getElementById("OptionPart2" + pastCorrAns) !== null) {
                document.getElementById("OptionPart2" + pastCorrAns).style.color = "";
                document.getElementById("OptionPart2" + pastCorrAns).style.fontWeight = "400";
            }
        }
    };

    useEffect(() => {
        setIsLoaded(false);
        function fetchData() {
            if (!currQues?.directLinkEvp && !currQues?.image && !currQues?.suffix) {
                setQuestionImageURL("");
                return;
            }
            if (!selectedQuestion || currQues?.serialNo !== currentQuestion || !initialized) {
                setQuestionImageURL("");
                return;
            }
            if (!!currQues.directLinkEvp && pollKey && currentQuestion) {
                // console.log(currQues.directLinkEvp)
                setQuestionImageURL(currQues.directLinkEvp);
                setIsVideo(true);
                timeRef.current = Date.now();
            } else {
                setQuestionImageURL("");
                setIsVideo(false);
            }
        }
        fetchData();
    }, [currentQuestion, data, selectedQuestion, initialized]);

    useEffect(() => {
        if(!loading && initialized && pollType === POLL_TYPE.MERGED_POLL) {
            removeHighlight();
            setShowAnswer(false);
            // handleCorrectAnswer();
        }
    }, [currentQuestion, selectedQuestion, initialized, loading]);




    const fadeIn = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.opacity = 1;
        element.style.transition = "all 0.5s ease-out";
    };

    const fadeOut = (elementId) => {
        const element = document.getElementById(elementId);
        element.style.opacity = 0;
        element.style.transition = "all 0.25s ease-in";
    };

    const translateIn = (elementId) => {
        const element = document.getElementById(elementId);
        const parentElement = document.getElementsByClassName("questionPanel")[0];
        const height = parentElement.offsetHeight;
        if (selectedQuestion) {
            element.style.marginBottom = height/3 + "px";
            setTimeout(() => {
                element.style.transition = "all 0.15s ease-out";
                element.style.opacity = 0.5;
                element.style.marginBottom = "-20px";
            }, 10);
            setTimeout(() => {
                element.style.transition = "all 0.1s ease-in";
                element.style.opacity = 1;
                element.style.marginBottom = "0px";
            }, 160);
        } else {
            element.style.marginTop = height/3 + "px";
            setTimeout(() => {
                element.style.transition = "all 0.15s ease-out";
                element.style.opacity = 0.5;
                element.style.marginTop = "-20px";
            }, 10);
            setTimeout(() => {
                element.style.transition = "all 0.1s ease-in";
                element.style.opacity = 1;
                element.style.marginTop = "0px";
            }, 160);
        }
    };

    const translateOut = (elementId) => {
        const parentElement = document.getElementsByClassName("questionPanel")[0];
        const height = parentElement.offsetHeight;
        const element = document.getElementById(elementId);
        if (selectedQuestion) {
            element.style.marginBottom = "0px";
            setTimeout(() => {
                element.style.transition = "all 0.24s ease-in";
                element.style.opacity = 0;
                element.style.marginBottom = height/3 + "px";
            }, 10);
        } else {
            element.style.marginTop = "0px";
            setTimeout(() => {
                element.style.transition = "all 0.24s ease-in";
                element.style.opacity = 0;
                element.style.marginTop = height/3 + "px";
            }, 10);
        }
    };

    useEffect(() => {
        if (firstLoad) return;
        // fadeIn(selectedQuestion ? "questionBody" : "questionsBody");
        // translateIn(selectedQuestion ? "questionFooter" : "questionsHeader");
    }, [selectedQuestion]);

    return (
        <>
            {initialized && !selectedQuestion &&
                <>
                    {/*<div*/}
                    {/*    id="questionsHeader"*/}
                    {/*    style={{*/}
                    {/*        display: "flex",*/}
                    {/*        width: "100%",*/}
                    {/*        marginBottom: "10px",*/}
                    {/*        alignItems: "center",*/}
                    {/*        gap: 15,*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <span className="gradientButtonWrap" onClick={() => setShowPollOptionsModal(true)}>*/}
                    {/*        <span className="gradientButtonContainer">*/}
                    {/*            <div className="gradientButtonContainerText">{pollType === POLL_TYPE.MERGED_POLL ? CONFIGURE_POLL_OPTIONS_TEXT : CONFIGURE_ATTENDANCE_OPTIONS_TEXT}</div>*/}
                    {/*        </span>*/}
                    {/*    </span>*/}

                    {/*</div>*/}

                    {data?.questions.map((question, index) =>
                        <div
                            key={index}
                            // className="questionRow"
                            className={styles.questionRow}
                            onClick={() => {
                                if (firstLoad) {
                                    setFirstLoad(false);
                                }
                                if (defaultView && pollShareMode !== POLL_SHARE_MODE.SHARE_ALL && pollShareMode !== POLL_SHARE_MODE.SCHEDULE) {
                                    setDefaultView(false);
                                }
                                // console.log(selectedQuestion, currentQuestion, index + 1, question.serialNo)
                                setCurrentQuestion(index + 1);
                                setSelectedQuestion(true);
                                // translateOut("questionsHeader");
                                // fadeOut("questionsBody");
                            }}
                            tabIndex={0}
                            aria-label={SELECT_QUESTION_ARIA + question?.serialNo + " " + question?.queTitle}
                        >
                            <span className={styles.questionRowIndex}>Q{question?.serialNo}</span>
                            <span className={styles.questionRowContent}>
                                <LatexInline>{question?.queTitle}</LatexInline>
                            </span>
                        </div>
                    )}
                </>
            }
            {initialized && selectedQuestion && <>
                <div id="questionBody" className={styles.questionBodyContainer}>
                    <div className={styles.questionTitleRow} id="questionPanelTitle">
                        {pollType !== POLL_TYPE.ATTENDANCE &&
                            <>
                                <span tabIndex={0} className={styles.title1st}>
                                    {currentQuestion}
                                </span>
                                <span tabIndex={0} className={styles.title2nd}>&nbsp;out of {data.questions.length}</span>
                                {renderQuestionType()}
                            </>
                        }


                        {!!currQues?.weightage && currQues?.questionType !== QUESTION_TYPES.SA.name &&
                            <YuJaTooltip
                                text={showAnswer ? HIDE_CORRECT_ANSWER_TOOLTIP_TEXT : SHOW_CORRECT_ANSWER_TOOLTIP_TEXT}
                                placement="top"
                                tooltipStyle={{ maxWidth: 400 }}
                            >
                                {showAnswer ?
                                    <div
                                        onClick={() => setShowAnswer(false)}
                                        style={{
                                            marginLeft: 15,
                                            cursor: "pointer",
                                            borderRadius: 3,
                                            width: 32,
                                            height: 32,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "1px solid #42296E",
                                            background: "#42296E"
                                        }}
                                    >
                                        <HideCorrectIcon />
                                    </div> :
                                    <div
                                        onClick={() => setShowAnswer(true)}
                                        style={{
                                            marginLeft: 15,
                                            cursor: "pointer",
                                            borderRadius: 3,
                                            width: 32,
                                            height: 32,
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            border: "1px solid #42296E",
                                            background: "#FFFFFF",
                                            // background: "linear-gradient(264.39deg, #BA54A1 10.32%, #993393 37.24%, #8D278E 49.97%, #7B298F 60.75%, #4D2E90 82.24%, #2E3191 95.16%)"
                                        }}
                                    >
                                        <ShowCorrectIcon width={18} height={18}/>
                                    </div>
                                }
                            </YuJaTooltip>
                        }
                        {pollType === POLL_TYPE.ATTENDANCE &&
                            <>
                                {renderTitle()}
                            </>
                        }
                        <div style={{flex: 1}}/>
                        {pollType !== POLL_TYPE.ATTENDANCE &&
                            <div style={{display: "flex", gap: 10}}>
                                <div
                                    tabIndex={0}
                                    className={`${styles.reactionContainer} ${animateThumbDown ? "reaction-animation" : ""}`}
                                    onAnimationEnd={onAnimationEndThumbUp}
                                >
                                    <ThumbUpWhite width={16} height={16}/>
                                    <div className={styles.reactionCount}> {thumbUpCount} </div>
                                </div>
                                <div
                                    tabIndex={0}
                                    className={`${styles.reactionContainer} ${animateThumbDown ? "reaction-animation" : ""}`}
                                    onAnimationEnd={onAnimationEndThumbDown}
                                >
                                    <ThumbDownWhite width={16} height={16}/>
                                    <div className={styles.reactionCount}> {thumbDownCount} </div>
                                </div>
                            </div>
                        }

                    </div>
                    {pollType !== POLL_TYPE.ATTENDANCE &&
                        <div style={{width: "100%"}} id="questionTitle">
                            {renderTitle()}
                        </div>
                    }

                    <div
                        style={pollType === POLL_TYPE.ATTENDANCE
                            ? {display: "flex", gap: 20, alignItems: "center", width: "100%", flex: 1,}
                            : {display: "flex", gap: 20, alignItems: "center", width: "100%"}
                        }
                    >
                        <span
                            style={questionImageURL
                                ? {flex: 5, overflowY: "auto", height: "100%",}
                                : pollType === POLL_TYPE.ATTENDANCE
                                    ? {overflowY: "auto", height: "100%", width: "100%", alignSelf: "flex-start" }
                                    : { overflowY: "auto", height: "100%", flex: 2 }
                            }
                            id="questionOptions"
                        >
                            {renderQuestionOptions()}
                        </span>
                        {pollType === POLL_TYPE.ATTENDANCE ?
                            <span id="questionOptions">
                                <SAQuestion
                                    questions={data.questions} setQuestions={(qs) => setData({ ...data, questions: qs })}
                                    mapInitialized={mapInitialized} setMapInitialized={setMapInitialized}
                                    geofence={geofence} setGeofence={setGeofence}
                                    syncLms={syncLms} setSyncLms={setSyncLms}
                                    selectedQuestion={currQues}
                                    pollType={pollType}
                                    mapView={true}
                                    questionBuilder={false}
                                    editable={currentView === RIGHT_PANEL_TABS.QUESTION_BUILDER.name && !disabled}
                                    userSettings={userSettings}
                                />
                            </span>
                            :
                        (!!currQues?.imageURL || (!!currQues?.directLinkEvp && questionImageURL)) && !(currQues?.questionType === QUESTION_TYPES.MH.name && isNarrow) && (
                            <div className="img-container-share" id={QUESTION_IMAGE_ID} >
                                {!defaultView && !disabled && currentView === RIGHT_PANEL_TABS.QUESTION_BUILDER.name && currQues?.questionType === QUESTION_TYPES.CI.name ?
                                    <ClickableImage
                                        questions={data.questions}
                                        setQuestions={qs => setData({...data, questions: qs})}
                                        selectedQuestion={currQues}
                                        imageAlt={currQues?.imageAlt}
                                        questionBuilder={true}
                                        questionImgUrl={currQues?.imageURL}
                                    />
                                    : <div className="img-inner-container-share">
                                        {!isVideo &&
                                            <img
                                                ref={imageRef}
                                                style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                    mixBlendMode: "normal",
                                                    // boxShadow: "0px 2.59px 9.065px 1.295px rgba(0, 0, 0, 0.25)",
                                                    // borderRadius: "12px"
                                                }}
                                                tabIndex={0}
                                                aria-label={currQues.imageAlt ? currQues.imageAlt : DEFAULT_IMAGE_ALT}
                                                src={currQues?.imageURL}
                                                onLoad={onLoad}
                                                alt="Question"
                                            />
                                        }
                                        {isVideo &&
                                            <iframe
                                                allowFullScreen
                                                style={{
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                    mixBlendMode: "normal",
                                                    boxShadow: "0px 2.59px 9.065px 1.295px rgba(0, 0, 0, 0.25)",
                                                    borderRadius: "12px"
                                                }}
                                                src={questionImageURL}
                                            />
                                        }
                                        {isLoaded && currQues?.questionType === QUESTION_TYPES.CI.name && (!currQues?.weightage || showAnswer) &&
                                            currQues.optionsMap.map((area, idx) =>
                                                <Rnd
                                                    key={idx}
                                                    id={`option-area-${idx}`}
                                                    size={{
                                                        width: decodeCIParam(area.width, imageRef.current.width),
                                                        height: decodeCIParam(area.height, imageRef.current.height)
                                                    }}
                                                    position={{
                                                        x: decodeCIParam(area.x, imageRef.current.width),
                                                        y: decodeCIParam(area.y, imageRef.current.height)
                                                    }}
                                                    enableResizing={false}
                                                    disableDragging={true}
                                                    bounds={"parent"}
                                                >
                                                    <div style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        border: "solid #009883 1.5px",
                                                        fontWeight: "400",
                                                        fontSize: "15px",
                                                        color: "#009883",
                                                        borderRadius: "4px",
                                                        backgroundColor: "rgba(246, 255, 254, 0.9)",
                                                        boxShadow: "0px 0px 5px 0px #00000026"
                                                    }}>
                                                        <span
                                                            style={{zIndex: "1000"}}>{String.fromCharCode(ASCII_CODE_A + idx)}</span>
                                                    </div>
                                                </Rnd>
                                            )
                                        }
                                        {isLoaded && currQues?.questionType === QUESTION_TYPES.CI.name &&
                                            points.map((point, idx) =>
                                                <COORDINATE
                                                    key={idx}
                                                    style={{
                                                        position: "absolute",
                                                        top: `${decodeCIParam(point.y, imageRef.current.height) - CI_COORDINATE_SIZE.height}px`,
                                                        left: `${decodeCIParam(point.x, imageRef.current.width) - CI_COORDINATE_SIZE.width / 2}px`
                                                    }}
                                                />
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        )
                        }
                    </div>
                </div>
                <div id="questionFooter" className={styles.questionFooterContainer}>
                    <div
                        className={currentQuestion > 1 ? styles.paginationItem : styles.paginationItemDisabled}
                        onClick={() => {
                            if (currentQuestion > 1) {
                                setCurrentQuestion(1);
                            }
                        }}
                        tabIndex={0}
                        aria-label={"First Question"}
                        role={BUTTON}
                    >
                         {currentQuestion > 1 ? <First style={{height: 12, width:12}} /> : <DisabledFirst style={{height: 12, width:12}}/>}
                    </div>
                    <div
                        className={currentQuestion > 1 ? styles.paginationItem : styles.paginationItemDisabled}
                        onClick={() => {
                            if (currentQuestion > 1) {
                                setCurrentQuestion(currentQuestion - 1);
                            }
                        }}
                        tabIndex={0}
                        aria-label={"Previous Question"}
                        role={BUTTON}
                    >
                         {currentQuestion > 1 ? <Prev style={{height: 20, width:20}}/> : <DisabledPrev style={{height: 20, width:20}}/>}
                    </div>
                    <div style={{borderRadius: "8px", padding: "5px", width: "100px", height: "32px", backgroundColor: "#0B6F85", color: "#FFFFFF",  fontSize: "14px", display: "flex", justifyContent: "end"}}>
                    <span style={{display: "flex", alignItems: "center"}}>Question:</span>
                    <input
                                tabIndex={0}
                                role={TEXTBOX}
                                min="1"
                                max="100"
                                placeholder="00"
                                value={isFocused || currentQuestionInput >= 10 ? currentQuestionInput : `0${currentQuestionInput}`}
                                onChange={onChangeCustomQuestions}
                                onFocus={handleFocus}
                                onBlur={
                                    handleQuestionBlur
                                }
                                onKeyDown={handleQuestionKeyDown}
                                style={{flex: "1", backgroundColor: "#0B6F85", textAlign: "center", width: "15px"}}
                            />
                    </div>
                    <div
                        className={currentQuestion < data?.questions?.length ? styles.paginationItem : styles.paginationItemDisabled}
                        onClick={() => {
                            if (currentQuestion < data?.questions?.length) {
                                setCurrentQuestion(currentQuestion + 1);
                            }
                        }}
                        tabIndex={0}
                        aria-label={"Next Question"}
                        role={BUTTON}
                    >
                        {currentQuestion < data?.questions?.length ? <Next style={{height: 20, width:20}}/> : <DisabledNext style={{height: 20, width:20}}/>}
                    </div>
                    <div
                        className={currentQuestion < data?.questions?.length ? styles.paginationItem : styles.paginationItemDisabled}
                        onClick={() => {
                            if (currentQuestion < data?.questions?.length) {
                                setCurrentQuestion(data?.questions?.length);
                            }
                        }}
                        tabIndex={0}
                        aria-label={"Last Question"}
                        role={BUTTON}
                    >
                       {currentQuestion < data?.questions?.length ? <Last style={{height: 12, width:12}}/> : <DisabledLast style={{height: 12, width:12}}/>}
                    </div>
                </div>
            </>}
            {!initialized && <>
                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                    {skeletonData.poll?.pollType === POLL_TYPE.ATTENDANCE ?
                        <div style={{ flex: 4, width: "100%", overflowY: "auto", paddingTop: "10px"}}>
                            <div style={{ width: "60%", paddingTop: "10px" }}>
                                <Skeleton borderRadius={10} height={22} className="skeletonQuestionRow" containerClassName="skeletonQuestionRowWrapper" />
                            </div>
                            <div style={{ width: "40%", paddingTop: "20px" }}>
                                <Skeleton borderRadius={10} height={18} className="skeletonQuestionRow" containerClassName="skeletonQuestionRowWrapper" />
                            </div>
                        </div>
                        :
                        <div style={{ flex: 4, width: "100%", overflowY: "auto", paddingTop: "10px"}}>
                            {[...Array(skeletonData.poll?.totalQuestions ? skeletonData.poll?.totalQuestions : DEFAULT_TOTAL_QUESTIONS)].map((_, index) =>
                                <div
                                    key={index}
                                    className="questionRowLoading"
                                >
                                    <Skeleton borderRadius={10} height={22} className="skeletonQuestionRow" containerClassName="skeletonQuestionRowWrapper" />
                                </div>)}
                        </div>
                    }
                </SkeletonTheme>
            </>}
        </>
    );
}

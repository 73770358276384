import React, {useEffect, useRef, useState} from "react";
import classes from "./TiptapMenu.module.css";

import { ReactComponent as UNDO } from "./images/undo.svg";
import { ReactComponent as REDO } from "./images/redo.svg";
import { ReactComponent as BOLD } from "./images/bold.svg";
import { ReactComponent as ITALIC } from "./images/italic.svg";
import { ReactComponent as UNDERLINE } from "./images/underline.svg";
import { ReactComponent as CLEAN } from "./images/clear-formating.svg";
import { ReactComponent as EXPANDED } from "./images/expanded-menu-icon.svg";
import { ReactComponent as TEXT_COLOR } from "./images/font-color-icon.svg";
import { ReactComponent as HIGHLIGHT_COLOR } from "./images/highlight-color-icon.svg";
import { ReactComponent as PREVIEW } from "./images/preview.svg";
import { ReactComponent as CART_DOWN } from "./images/cart-down.svg";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import katex from "katex";
import parse from "html-react-parser";
import {
    BOLD_TEXT, BUTTON, CLEAN_FORMAT_TEXT, CLOSE_MODAL,
    HIGHLIGHT_COLOR_TEXT,
    ITALIC_TEXT,
    LATEX_EXPRESSION_TEXT, PREVIEW_CONTENT_TEXT, PREVIEW_TEXT, REDO_TEXT,
    TEXT_COLOR_TEXT, TEXTBOX,
    UNDERLINE_TEXT, UNDO_TEXT
} from "../../utils/constants";
import Modal from "react-bootstrap/Modal";
import {LatexHTMLInline} from "./latexParser";



export const menuBarCollapsedStyle = {
    border: "none",
    paddingTop: 0,
    paddingBottom: 0,
    height: 0,
    overflow: "hidden",
    transition: "0.5s ease"
};
export const menuBarExpandedStyle = {borderBottom: '1px solid #858687', transition: "0.5s ease"};

export const TipTapMenuBar = ({ editor, ...props }) => {
    const [barWidth, setBarWidth] = useState(0);
    const barRef = useRef();
    const [menuList, setMenuList] = useState([]);
    const [restMenu, setRestMenu] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [textColor, setTextColor] = useState("#000000");
    const [highlightColor, setHighlightColor] = useState("#ffc078");
    const [previewModalShown, setPreviewModalShown] = useState(false);

    let oneItem = 10 + 32;
    let twoItems = 10 + 32 * 2 + 5;
    let threeItems = 10 + 32 * 3 + 5 * 2;
    let fourItems = 10 + 32 * 3 + 42 + 5 * 3;
    let fiveItems = 10 + 32 * 3 + 42 * 2 + 5 * 4;
    let sixItems = 10 + 32 * 4 + 42 * 2 + 5 * 5;
    let sevenItems = 10 + 32 * 5 + 42 * 2 + 5 * 6;
    let eightItems = 10 + 32 * 6 + 42 * 2 + 5 * 7;
    let nineItems = 10 + 32 * 7 + 42 * 2 + 5 * 8;
    let tenItems = 10 + 32 * 8 + 42 * 2 + 5 * 9;


    const render = () => {
        if (!barRef.current) {
            return;
        }

        let barWidth = barRef.current.clientWidth;

        let menuCopy = [
            {key: "BOLD", icon: BOLD, title: BOLD_TEXT},
            {key: "ITALIC", icon: ITALIC, title: ITALIC_TEXT},
            {key: "UNDERLINE", icon: UNDERLINE, title: UNDERLINE_TEXT},
            {key: "TEXT_COLOR", icon: TEXT_COLOR, title: TEXT_COLOR_TEXT},
            {key: "HIGHLIGHT_COLOR", icon: HIGHLIGHT_COLOR, title: HIGHLIGHT_COLOR_TEXT},
            {key: "LATEX",
                icon: () => <span style={{fontSize: 11}}>{parse(katex.renderToString('\\LaTeX'))}</span>,
                title: LATEX_EXPRESSION_TEXT
            },
            {key: "CLEAN", icon: CLEAN, title: CLEAN_FORMAT_TEXT},
            {key: "PREVIEW", icon: PREVIEW, title: PREVIEW_TEXT},
            {key: "UNDO", icon: UNDO, title: UNDO_TEXT},
            {key: "REDO", icon: REDO, title: REDO_TEXT},
        ];

        if (barWidth < twoItems) {
            setMenuList(menuCopy.splice(0, 0));
            setRestMenu(menuCopy);
            setBarWidth(oneItem);
        } else if (barWidth < threeItems) {
            setMenuList(menuCopy.splice(0, 1));
            setRestMenu(menuCopy);
            setBarWidth(twoItems);
        } else if (barWidth < fourItems) {
            setMenuList(menuCopy.splice(0, 2));
            setRestMenu(menuCopy);
            setBarWidth(threeItems);
        } else if (barWidth < fiveItems) {
            setMenuList(menuCopy.splice(0, 3));
            setRestMenu(menuCopy);
            setBarWidth(fourItems);
        } else if (barWidth < sixItems) {
            setMenuList(menuCopy.splice(0, 4));
            setRestMenu(menuCopy);
            setBarWidth(fiveItems);
        } else if (barWidth < sevenItems) {
            setMenuList(menuCopy.splice(0, 5));
            setRestMenu(menuCopy);
            setBarWidth(fiveItems);
        } else if (barWidth < eightItems) {
            setMenuList(menuCopy.splice(0, 6));
            setRestMenu(menuCopy);
            setBarWidth(fourItems);
        } else if (barWidth < nineItems) {
            setMenuList(menuCopy.splice(0, 7));
            setRestMenu(menuCopy);
            setBarWidth(threeItems);
        } else if (barWidth < tenItems) {
            setMenuList(menuCopy.splice(0, 8));
            setRestMenu(menuCopy);
            setBarWidth(twoItems);
        } else {
            setMenuList(menuCopy);
            setRestMenu([]);
            setBarWidth(0);
        }
    }

    useEffect(() => {
        render();
    }, [barRef.current?.clientWidth]);


    const onClick = (key) => {
        if (key === "UNDO") {
            if (editor.can().chain().focus().undo().run()) {
                editor.chain().focus().undo().run();
            }
        } else if (key === "REDO") {
            if (editor.can().chain().focus().redo().run()) {
                editor.chain().focus().redo().run();
            }
        } else if (key === "BOLD") {
            editor.chain().focus().toggleBold().run();
        } else if (key === "ITALIC") {
            editor.chain().focus().toggleItalic().run();
        } else if (key === "UNDERLINE") {
            editor.chain().focus().toggleUnderline().run();
        } else if (key === "LATEX") {
            const {state} = editor;
            editor.chain().focus().unsetBold().run();
            editor.chain().focus().unsetItalic().run();
            editor.chain().focus().unsetUnderline().run();
            editor.chain().focus().unsetColor().run();
            editor.chain().focus().unsetHighlight().run();

            let latexContent = '$latex  $';
            editor.commands.insertContent({
                type: 'paragraph',
                content: [{ type: 'text', text: latexContent }],
            });
            let position = state.selection.anchor;
            if (position > 1) {
                editor.commands.deleteRange({from: position, to: position + 2});
                if (editor.state.doc.content.size >=  position + latexContent.length + 2) {
                    editor.commands.deleteRange({from: position + latexContent.length, to: position + latexContent.length + 2});
                }
            }
            position = position + latexContent.length - 1
            editor.chain().focus().setTextSelection(position).run();
            editor.commands.deleteRange({from: position - 1, to: position});

        } else if (key === "CLEAN") {
            editor.chain().focus().unsetBold().run();
            editor.chain().focus().unsetItalic().run();
            editor.chain().focus().unsetUnderline().run();
            editor.chain().focus().unsetAllMarks().run();
            editor.chain().focus().unsetColor().run();
            editor.chain().focus().unsetHighlight().run();
        } else if (key === "PREVIEW") {
            setPreviewModalShown(true);
        }
    }

    const setCustomizedColor = (e, key) => {
        if (key === "TEXT_COLOR") {
            setTextColor(e.target.value);
        } else {
            setHighlightColor(e.target.value);
        }
    }


    const toggleColor = (key) => {
        if (key === "TEXT_COLOR") {
            editor.chain().focus().setColor(textColor).run();
        } else {
            editor.chain().focus().toggleHighlight({ color: highlightColor }).run();
        }
    }


    const renderMenuItems = (menuItemArr) => {
        return (menuItemArr.map(({ key, title, icon: Icon }, index) => {
            let isDisabled = false;
            let isActive = false;
            let isColorOption = key === "TEXT_COLOR" || key === "HIGHLIGHT_COLOR";
            if (key === "UNDO") {
                isDisabled = !editor.can().chain().focus().undo().run();
            } else if (key === "REDO") {
                isDisabled = !editor.can().chain().focus().redo().run();
            } else if (key === "BOLD") {
                isActive = editor.isActive('bold');
            } else if (key === "ITALIC") {
                isActive = editor.isActive('italic');
            } else if (key === "UNDERLINE") {
                isActive = editor.isActive('underline');
            }


            return (
                <span
                    key={index}
                    tabIndex={0}
                    className={`${classes.menuItem} ${isDisabled ? classes.disabled : ""} ${isActive ? classes.active : ""}`}
                    onClick={() => onClick(key)}
                    style={{width: isColorOption ? "fit-content" : undefined}}
                    title={title}
                >
                    {key === "PREVIEW" &&
                        <Icon />
                    }
                    {key !== "PREVIEW" &&
                        <Icon style={{fill: isDisabled ? "#adadad" : "#344054"}}/>
                    }

                    {isColorOption &&
                        <>
                           <span
                               onMouseEnter={e => e.stopPropagation()}
                               className={classes.selectedColorBarContainer}
                               onClick={() => toggleColor(key)}
                           >
                                <Icon style={{fill: "#344054"}}/>
                                <span
                                    className={classes.selectedColorBar}
                                    style={{background: key === "TEXT_COLOR" ? textColor : highlightColor}}
                                />
                           </span>
                            <span className={classes.colorChangeDropDown}>
                                <CART_DOWN/>
                                <input
                                    className={classes.colorPicker}
                                    onChange={e => setCustomizedColor(e, key)}
                                    type={"color"}
                                />
                            </span>
                        </>
                    }

                    </span>
            );
        }));
    }

    return (
        <div ref={barRef} className={classes.menuBar} {...props}>
            <Modal
                id="question-modal"
                show={previewModalShown}
                onHide={() => setPreviewModalShown(false)}
                aria-labelledby="contained-modal-title-vcenter"
                backdrop={true}
                dialogClassName="question-modal2"
            >
                <ModalClose tabIndex={0} role={BUTTON} aria-label={CLOSE_MODAL}
                            onClick={() => setPreviewModalShown(false)} className="modal-close" />
                <Modal.Header style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Modal.Title tabIndex={0} role={TEXTBOX}>
                        {PREVIEW_CONTENT_TEXT}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: 100}}>
                    <LatexHTMLInline>{editor.getHTML()}</LatexHTMLInline>
                </Modal.Body>
            </Modal>



            {renderMenuItems(menuList)}
            {restMenu.length > 0 &&
                <span
                    tabIndex={0}
                    className={`${classes.menuItem} ${expanded ? classes.active : ""}`}
                >
                    <EXPANDED title={"More"} style={{fill: "#344054"}} onClick={() => setExpanded(!expanded)}/>
                    {expanded &&
                        <div className={classes.expandedMenuBar} style={{width: barWidth}}>
                            {renderMenuItems(restMenu)}
                        </div>
                    }
                </span>
            }
        </div>
    )
}
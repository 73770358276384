import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useMobileAccess from "../../hooks/useMobileAccess";
import {RANK_TEXT, RANKING_COLORS, TEXTBOX} from "../../utils/constants";
import "./styles.css";
import styles from "./styles.module.css";
import {LatexHTMLInline} from "../../components/Tiptap/latexParser";

export default function ClassResultRank({chartData, isLoading=false}) {
    const [maxData, setmaxData] = useState(0);
    const [sortedKeys, setSortedKeys] = useState([]);
    const [sortedValues, setSortedValues ]  = useState([]);
    const isMobile = useMobileAccess(480);
    useEffect(() => {
        if (chartData) {
            const sortedEntries= Object.entries(chartData)
            .sort(([, valueA], [, valueB]) => valueB - valueA);
            const newsortedKeys = [];
            const newsortedValues = [];
            for (const [key, value] of sortedEntries) {
                newsortedKeys.push(key);
                newsortedValues.push(value);
            }
            setSortedKeys(newsortedKeys);
            setSortedValues(newsortedValues);

            setmaxData(newsortedValues.length > 0 ? newsortedValues[0] : 0);
        }
       
    }, [chartData]);

    const setBar = () => {
        setTimeout(() => {
            sortedValues.forEach((entry, index) => {
                document.getElementById(`option${index}`).style.width = `${!!maxData && maxData !== 0 ? (entry / maxData) * 100 : 0}%`;
            });
        }, 500);
    }

    useEffect (() => {
        if(maxData !== 0 && sortedKeys.length > 0 && sortedValues.length > 0) {
            setBar();
        }

    }, [maxData, sortedKeys, sortedValues])

    return (
        <div className={styles.chartContainer} onLoad={setBar}>
            {!!chartData && !isLoading &&
                sortedKeys.map((entry, index) => (
                    <div className={styles.optionContainer} key={index}>
                        <div className={styles.option}>
                            <div
                                tabIndex={0}
                                style={{textWrap: "nowrap"}}
                                role={TEXTBOX}
                                aria-label={`Rank ${index + 1}` + entry}
                                className={`${styles.optionIndex}`}
                            > {RANK_TEXT} {index + 1}</div>

                            <div id={`optionText${index}`} tabIndex={0} role={TEXTBOX} className={`${styles.optionText}`}>
                                <LatexHTMLInline>{entry}</LatexHTMLInline>
                            </div>

                            <div
                                id={`option${index}`}
                                className={`${styles.optionCountBar}`}
                                style={{backgroundColor: RANKING_COLORS[index % RANKING_COLORS.length]}}
                            />
                        </div>
                    </div>
                ))
            }
            {isLoading && Array.from({length: 4}).map((_, rowIndex) => (
                <div className={styles.optionContainer}>
                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                        <Skeleton height={55} borderRadius={10} width={isMobile ? "80vw" : "50vw"}
                                  style={{position: "relative", borderRadius: "10px"}}/>
                    </SkeletonTheme>
                </div>
            ))}
        </div>
    );
}
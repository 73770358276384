import React from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as ModalClose } from "../../images/modal_close.svg";
import YuJaButton from "../standardization/YuJaButton";
import {
    BUTTON,
    CLOSE_MODAL, MODAL, OK_TEXT,
    TEXTBOX
} from "../../utils/constants";


export default function GeneralConfirmationModal({config, content, show}) {

    return (
        <Modal
            id="form-modal"
            show={show}
            onHide={config.close}
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={true}
        >
            <ModalClose tabIndex={0} aria-label={CLOSE_MODAL} role={BUTTON} onClick={config.close} className="modal-close" />
            <Modal.Header
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Modal.Title tabIndex={0} role={TEXTBOX} aria-label={config.title + MODAL} className="modal-title" id="contained-modal-title-vcenter">
                    {config.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div tabIndex={0} role={TEXTBOX} className="form-subtext" style={{color: "#6B6B6B"}}>{content}</div>
            </Modal.Body>
            <Modal.Footer>
                <YuJaButton
                    onClick={config.close}
                    style={config.buttonWidth ? { width: config.buttonWidth } : {}}
                    wrapperStyle={{whiteSpace: "nowrap"}}
                >
                    {config.okText ? config.okText : OK_TEXT}
                </YuJaButton>
            </Modal.Footer>
        </Modal>
    );
}
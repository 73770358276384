import { CompositeDecorator, ContentState, EditorState } from "draft-js";
import React, {useEffect, useMemo, useRef, useState} from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import Lottie from "react-lottie";
import { PollApiCalls } from "../../apis/PollApiCalls";
import AddQuestionConfirmationModal from "../../components/modals/AddQuestionConfirmationModal";
import DeleteConfirmationModal from "../../components/modals/DeleteConfirmationModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaDropdown } from "../../components/standardization/YuJaDropdown";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import CIQuestionOption from "../../questions/CI/CIQuestionOption";
import FITBAnswer from "../../questions/FITB/FITBAnswer";
import FITBQuestionTitle from "../../questions/FITB/FITBQuestionTitle";
import MCQuestionOption from "../../questions/MCSS/MCQuestionOption";
import RKQuestion from "../../questions/RK/RKQuestion";
import SAQuestion from "../../questions/SA/SAQuestion";
import TFQuestionOption from "../../questions/TF/TFQuestionOption";
import {
    BLANK_REGEX,
    BLANK_REGEX_NEW,
    CHARACTERS_REMAINING,
    CI_ADD_OPTION_TEXT,
    CORRECT_ANSWER, CORRECT_ANY_DESCRIPTION, CUSTOMIZE_ENTRIES_TEXT, CUSTOMIZE_ENTRIES_TOOLTIP_TEXT,
    CUSTOMIZE_POINTS_TEXT,
    CUSTOMIZE_POINTS_TOOLTIP_TEXT,
    CUSTOMIZE_TIME_LIMIT_TEXT, CUSTOMIZE_TIME_LIMIT_TOOLTIP_TEXT,
    DISABLED_ATTENDANCE_BUILDER_CAPTION,
    DISABLED_QUESTION_BUILDER_CAPTION,
    ENTER_QUESTION_TITLE,
    FITB_QUESTION_LENGTH,
    IMAGE,
    MCSS_ADD_OPTION_TEXT,
    MEDIA,
    MH_ADD_OPTION_TEXT,
    OPTIONS,
    POLL_SHARE_MODE,
    POLL_TYPE,
    QUESTION_BUILDER_ADD_QUESTION_BUTTON,
    QUESTION_BUILDER_ADD_TOOLTIP,
    QUESTION_BUILDER_BUTTON,
    QUESTION_BUILDER_CAPTION,
    QUESTION_BUILDER_DELETE_BUTTON,
    QUESTION_BUILDER_DISCARD_BUTTON,
    QUESTION_BUILDER_UPDATE_QUESTION_BUTTON, QUESTION_LENGTH,
    QUESTION_TITLE,
    QUESTION_TITLE_CHARACTER_LIMIT,
    QUESTION_TYPE,
    QUESTION_TYPES,
    TEXTBOX,
    VIDEO_ERROR_CI
} from "../../utils/constants";
import { findWithRegex, intToChar, notifyError, notifyInfo, notifySuccess } from "../../utils/helpers";
import {
    handleAddAnswer,
    handleDeleteAnswer,
    handleUpdateAnswer,
    updateCorrectAnswers,
    updateQuestionType,
} from "../../utils/questionUpdate";
import {
    addQuestion,
    addRankToQuestion,
    checkSameQuestions, convertMHOptionMap,
    getBlankRestriction,
    questionError,
    trimQuestion
} from "../../utils/questionUtils";
import {
    ADD_OPTIONS_INFO,
    ADD_QUESTION_LOADING_ERROR,
    ADD_QUESTION_SUCCESS,
    DELETE_QUESTION_SUCCESS,
    DISCARD_QUESTION_SUCCESS,
    QUESTION_CHARACTER_LIMIT_EXCEEDED
} from "../../utils/toast-message-constants";
import addQuestionAnimation from "./animations/addQuestion.json";
import scrollDownAnimation from "./animations/seeMore.json";
import { ReactComponent as DisabledBuilderIcon } from "./images/DisabledBuilderIcon.svg";
import { ReactComponent as ArrowDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as ArrowUp } from "../../images/YuJa_arrow_up_icon.svg";
import "./styles.css";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";
import UploadQuestionImageNew from "../../questions/UploadQuestionImageNew";
import {useToaster} from "react-hot-toast";
import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";
import styles from "./SharePollPage.module.css";
import PointsInputBox from "../../components/PointsInputBox";
import TimeLimitInputBox from "../../components/TimeLimitInputBox";
import EntriesInputBox from "../../components/EntriesInputBox";
import loadingGif from "../../images/LoadingAnimation.gif";
import {YuJaRichContentEditBoxNew} from "../../components/Tiptap/YuJaRichContentEditBoxNew";

export default function QuestionBuilder({
                                            pollKey,
                                            pollCode,
                                            hasResponses, // (hasResponses && (qs.length === originalData?.questions?.length || currentQuestion !== qs.length))
                                            isSharing,
                                            data,
                                            setData,
                                            originalData, setOriginalData,
                                            defaultView, setDefaultView,
                                            firstAdd, setFirstAdd,
                                            currentQuestion, setCurrentQuestion,
                                            selectedQuestion, setSelectedQuestion,
                                            geofence, setGeofence,
                                            mapInitialized, setMapInitialized,
                                            syncLms, setSyncLms,
                                            // resetData, setResetData,
                                            shareMode,
                                            startDate,
                                            userSettings,
                                            // initialized,
                                            loading,
                                            collapsed,
                                            handleUpdateQuestion
}) {

    const {toasts} = useToaster();
    const {questions:originalQuestions=[]} = originalData;
    const {questions: qs=[]} = data;

    const setQs = (qs) => {
        let qsCopy = JSON.parse(JSON.stringify(qs));
        setData({ ...data, questions: qsCopy });
    }
    const {isNarrow} = useCheckMainContainerNarrow();
    const { addQuestionToLivePoll } = PollApiCalls();
    const [questionType, setQuestionType] = useState(QUESTION_TYPES.MCSS.name);
    const [hasBlankRestriction, setHasBlankRestriction] = useState(false);
    const [pollType, setPollType] = useState(POLL_TYPE.MERGED_POLL);
    const [questionBuilderState, setQuestionBuilderState] = useState("");
    const options = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: addQuestionAnimation,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
        };
    }, [addQuestionAnimation]);
    const scrollOptions = useMemo(() => {
        return {
            loop: true,
            autoplay: true,
            animationData: scrollDownAnimation,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" }
        };
    }, [scrollDownAnimation]);
    const [discardModalShow, setDiscardModalShow] = useState(false);
    const [addQuestionModalShow, setAddQuestionModalShow] = useState(false);
    const [disabledEditQuestion, setDisabledEditQuestion] = useState(false);
    const [showSeeMore, setShowSeeMore] = useState(false);
    const [scrollDown, setScrollDown] = useState(true);
    const [warningShowed, setWarningShowed] = useState(false);
    const [, getLocalGeofence] = useLocalStorageNew("geofence", {});
    const [, getLocalSyncLms] = useLocalStorageNew("syncLms", {});
    const [detailCardCollapsed, setDetailCardCollapsed] = useState(false);
    const [settingsCardCollapsed, setSettingsCardCollapsed] = useState(true);
    const [isSavingProgressing, setIsSavingProgressing] = useState(false);
    const questionDetailsBodyContainerRef = useRef();
    // console.log(getLocalGeofence(), geofence, getLocalSyncLms(), syncLms);
    const {checkAnyQuestionIsLoading} = useImageLoaderManager(qs, setQs);

    // START FITB
    const BlankStrategy = (contentBlock, callback) => {
        findWithRegex(hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX, contentBlock, callback);
    };

    const BlankSpan = (props) => {
        return (
            <span
                style={{
                    color: "#029ce2",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontSize: 16
                }}
                data-offset-key={props.offsetKey}
            >
                {props.children}
            </span>
        );
    };

    const [compositeDecorator,] = useState(new CompositeDecorator([
        {
            strategy: BlankStrategy,
            component: BlankSpan,
        },
    ]));

    const [editorState, setEditorState] = useState(
        EditorState.createWithContent(
            ContentState.createFromText(qs[currentQuestion - 1]?.queTitle || ""),
            compositeDecorator
        )
    );

    // const [firstStyle, setFirstStyle] = useState(true);
    // const {styles, customStyleFn} = createStyles(['font-size']);
    // const addFontSize = styles.fontSize.add;

    // useEffect(() => {
    //     if (editorState && firstStyle && questionType === QUESTION_TYPES.FITB.name) {
    //         console.log("setting FITB font size to 10");
    //         setEditorState(styles.fontSize.add(editorState, 5));
    //         setFirstStyle(false);
    //     }
    // }, [editorState, firstStyle, questionType]);
    // END FITB

    useEffect(() => {
        if (isSharing) {
            // go add question (disabled button )
            setQuestionBuilderState("DISABLED");
            return;
        }

        if (!originalData.poll) {
            return;
        }

        const {pollShareMode, endTime} = originalData.poll;
        if (originalData.pollShareMode === POLL_SHARE_MODE.UNKNOWN) {
            //go question edit
            setQuestionBuilderState(selectedQuestion ? "EDIT" : "ENABLED");
        } else if (originalData.pollShareMode === POLL_SHARE_MODE.SHARE_ALL) {
            // go add question (disabled button )
            setQuestionBuilderState("DISABLED");
        } else if (pollShareMode === POLL_SHARE_MODE.SCHEDULE) {
            if (new Date() > new Date(endTime)) {
                // if is end go add question (disabled button )
                setQuestionBuilderState("DISABLED");
            } else {
                //go question edit
                setQuestionBuilderState(selectedQuestion ? "EDIT" : "ENABLED");
            }

        } else {//share each
            if (hasResponses) {
                // go add question
                setQuestionBuilderState(pollType === POLL_TYPE.ATTENDANCE ? "DISABLED" : "ENABLED");
            } else {
                //go question edit
                setQuestionBuilderState(selectedQuestion ? "EDIT" : "ENABLED");
            }
        }
    }, [originalData, isSharing, hasResponses, selectedQuestion, pollType]);

    useEffect(() => {
        if (!data) return;
        const { poll={} } = data;
        setPollType(poll.pollType);
    }, [data]);

    useEffect(() => {
        const dataCopy = JSON.parse(JSON.stringify(data));
        setHasBlankRestriction(getBlankRestriction(dataCopy?.questions));
    }, [data]);



    useEffect(() => {
        if (!qs || qs.length < currentQuestion) return;
        let qType = qs[currentQuestion - 1]?.questionType || QUESTION_TYPES.MCSS.name;
        setQuestionType(qType);
    }, [qs, currentQuestion]);

    useEffect(() => { // set default view if shareMode is SHARE_ALL or SCHEDULE and current date is greater than startDate
        if (shareMode === POLL_SHARE_MODE.SHARE_ALL || (shareMode === POLL_SHARE_MODE.SCHEDULE && new Date() > new Date(startDate))) {
            setDefaultView(true);
        }
    }, [shareMode, startDate]);

    const handleAddQuestion = () => {
        if (loading) {
            notifyError(ADD_QUESTION_LOADING_ERROR);
            return;
        }
        if (defaultView) {
            setDefaultView(false);
        }
        const { questions=[] } = data;
        if (firstAdd) {
            let newQs = addQuestion(questions, QUESTION_TYPES.MCSS.name, userSettings, true);
            setQs(newQs);
            setCurrentQuestion(newQs.length);
            setFirstAdd(false);
        } else {
            setCurrentQuestion(questions.length);
            setQs(questions);
        }
        setSelectedQuestion(true);
    };

    const handleQuestionTypeUpdate = (newQuestionType) => {
        const q = qs[currentQuestion - 1];

        if(newQuestionType.name === QUESTION_TYPES.CI.name && q.directLinkEvp) {
            notifyError(VIDEO_ERROR_CI);
            return;
        }

        if (newQuestionType.name === QUESTION_TYPES.FITB.name) {
            // setQuestionTitle("");
        }

        let questions = updateQuestionType(newQuestionType.description, currentQuestion - 1, qs, questionType, userSettings, true);
        setQs(questions);
        // setData({ ...data, questions: questions });
        setQuestionType(newQuestionType.name);
    };

    const handleQuestionTitleUpdate = (val, plainVal) => {
        if (!!plainVal && plainVal.length > QUESTION_TITLE_CHARACTER_LIMIT) {
            let toastObj = toasts.find(toastObj => toastObj.message === QUESTION_CHARACTER_LIMIT_EXCEEDED);
            if (!toastObj) {
                notifyError(QUESTION_CHARACTER_LIMIT_EXCEEDED);
            }
        }

        let newQuestions = JSON.parse(JSON.stringify(qs));
        let newQuestion = newQuestions[currentQuestion - 1];
        newQuestion["queTitle"] = newQuestion.questionType === QUESTION_TYPES.FITB.name ? plainVal : val;
        setQs(newQuestions);
    };

    // START MCSS
    const handleAddOptionMCSS = () => {
        if (hasResponses) {
            return;
        }
        if (qs[currentQuestion - 1]?.optionsMap && Object.keys(qs[currentQuestion - 1].optionsMap).length >= 26) {
            if (!warningShowed) {
                setWarningShowed(true);
                notifyInfo(ADD_OPTIONS_INFO);
            }
            return;
        }
        let newOptions = {...qs[currentQuestion - 1]?.optionsMap};
        newOptions[intToChar(Object.keys(newOptions).length)] = "";
        let newQuestions = [...qs];
        let newQuestion = { ...newQuestions[currentQuestion - 1] };
        newQuestion["optionsMap"] = newOptions;
        newQuestions[currentQuestion - 1] = newQuestion;
        setQs(newQuestions);
        setTimeout(() => {
            const questionBuilderMain = document.querySelector(".questionBuilderMain");
            if (!questionBuilderMain) {
                return;
            }
            questionBuilderMain.scrollTo({ top: questionBuilderMain.scrollHeight, behavior: "smooth" });
        }, 10);
    };

    const handleAddOptionRK = () => {
        const curQueObj = qs[currentQuestion - 1];
        const newQuestions = addRankToQuestion(
            curQueObj,
            qs
        );
        if (newQuestions) {
            setQs(newQuestions);
        } else if (!warningShowed) {
            setWarningShowed(true);
            notifyInfo(ADD_OPTIONS_INFO);
        }

        setTimeout(() => {
            const questionBuilderMain = document.querySelector(".questionBuilderMain");
            if (!questionBuilderMain) {
                return;
            }
            questionBuilderMain.scrollTo({ top: questionBuilderMain.scrollHeight, behavior: "smooth" });
        }, 10);
    }


    // END MCSS

    //  ====================Scroll Top/Down code=======================
    // useEffect(() => {
    //     if (!!detailCardCollapsed) {
    //         setShowSeeMore(false);
    //         return;
    //     }
    //
    //     const questionBuilderMain = questionDetailsBodyContainerRef.current;
    //     if (!questionBuilderMain) {
    //         return;
    //     }
    //     setShowSeeMore(questionBuilderMain.scrollHeight > questionBuilderMain.clientHeight);
    // }, [qs, currentQuestion, selectedQuestion, questionBuilderState, detailCardCollapsed]);
    //
    // useEffect(() => {
    //     if (!!detailCardCollapsed) {
    //         setScrollDown(true);
    //         return;
    //     }
    //
    //
    //     const questionBuilderMain =questionDetailsBodyContainerRef.current;
    //     if (!questionBuilderMain) {
    //         return;
    //     }
    //
    //     setScrollDown((questionBuilderMain.scrollHeight - Math.ceil(questionBuilderMain.scrollTop)) > questionBuilderMain.clientHeight);
    //     questionBuilderMain.addEventListener("scroll", () => {
    //         setScrollDown((questionBuilderMain.scrollHeight - Math.ceil(questionBuilderMain.scrollTop)) - questionBuilderMain.clientHeight >= 3 );
    //     });
    //
    //     return () => {
    //         questionBuilderMain.removeEventListener("scroll", () => {});
    //     };
    // }, [selectedQuestion, currentQuestion, qs, questionBuilderState, detailCardCollapsed]);
    //
    // const handleScroll = () => {
    //     const questionBuilderMain =questionDetailsBodyContainerRef.current;
    //     if (!questionBuilderMain) {
    //         return;
    //     }
    //
    //     if (scrollDown) {
    //         questionBuilderMain.scrollTo({
    //             top: questionBuilderMain.scrollTop + questionBuilderMain.clientHeight,
    //             behavior: "smooth"
    //         });
    //     } else {
    //         // scroll smoothly
    //         questionBuilderMain.scrollTo({
    //             top: 0,
    //             behavior: "smooth"
    //         });
    //     }
    // };
    //  ====================Scroll Top/Down code=======================

    const handleDiscard = () => {
        if (qs.length !== originalData?.questions?.length && currentQuestion === qs.length) { // new question            
            setFirstAdd(true);
            setCurrentQuestion(qs.length - 1);
            setData({ ...originalData, questions: qs.slice(0, qs.length - 1) });
            notifySuccess(DELETE_QUESTION_SUCCESS);
        } else { // existing question
            const newQs = qs.map((q, i) => {
                if (i === currentQuestion - 1) {
                    return originalData.questions[i];
                }
                return q;
            });
            setData({ ...originalData, questions: newQs });
            // setQuestionTitle(originalData.questions[currentQuestion - 1]?.queTitle || "");
            setQuestionType(originalData.questions[currentQuestion - 1]?.questionType || QUESTION_TYPES.MCSS.name);
            notifySuccess(DISCARD_QUESTION_SUCCESS);
        }
        setDiscardModalShow(false);
    };

    const handleAddUpdate = async (updatedQuestion) => {
        if (qs.length !== originalData?.questions?.length && currentQuestion === qs.length) {
            await handleAddQuestionToLivePoll();
        } else {
            await handleUpdateQuestion(updatedQuestion);
            // if (res) {
            //     notifySuccess(EDIT_QUESTION_SUCCESS);
            // }
            setDisabledEditQuestion(false);
        }
        // setResetData(resetData + 1); // uncheck to refresh poll details in SharePollPage
        setAddQuestionModalShow(false);
    };

    const handleAddQuestionToLivePoll = async () => {
        const trimmedQs = trimQuestion(qs);
        let currentQue = trimmedQs[currentQuestion - 1];
        if (currentQue.questionType === QUESTION_TYPES.MH.name) {
            currentQue.optionsMap = convertMHOptionMap(currentQue.correctAnswers);
        }

        const res = await addQuestionToLivePoll(pollKey, pollCode, {...currentQue, pollType: pollType});
        if (!res) {
            setDisabledEditQuestion(false);
            return;
        }
        let newQs = JSON.parse(JSON.stringify(originalData.questions));
        newQs.push(currentQue);
        const originalDataCopy = JSON.parse(JSON.stringify(originalData));
        setOriginalData({ ...originalDataCopy, questions: newQs });
        setQs(newQs);
        setFirstAdd(true);
        // setQuestionType(QUESTION_TYPES.MCSS.name);
        // setQuestionTitle("");
        notifySuccess(ADD_QUESTION_SUCCESS);
        setDisabledEditQuestion(false);
    };

    const handleCorrectAnyCheck = () => {
        let newQuestions = [];
        qs.forEach((q) => {
            if (q.serialNo !== currentQuestion) {
                newQuestions.push(q);
            } else {
                const questionCopy = JSON.parse(JSON.stringify(q));
                questionCopy.correctAnyEnable = !Boolean(questionCopy.correctAnyEnable);
                newQuestions.push(questionCopy);
            }
        });
        setQs(newQuestions);
    };


    const handleWeightageUpdate = async (v) => {
        if (!v) {
            v = 0;
        } else {
            v = parseInt(v);
        }
        if (v === qs[currentQuestion - 1].weightage){
            return;
        }

        let questionsCopy = JSON.parse(JSON.stringify(qs));
        let newQuestion = questionsCopy[currentQuestion - 1];
        newQuestion.weightage = v;
        setQs(questionsCopy);
    };


    const handleTimeLimitUpdate = async (v) => {
        if (!v) {
            v = 0;
        } else {
            v = parseInt(v);
        }
        if (v === qs[currentQuestion - 1].timeLimit) {
            return;
        }

        let questionsCopy = JSON.parse(JSON.stringify(qs));
        let newQuestion = questionsCopy[currentQuestion - 1];
        newQuestion.timeLimit = v;
        setQs(questionsCopy);
    };

    const handleEntriesUpdate = async (v) => {
        if (!v) {
            v = 0;
        } else {
            v = parseInt(v);
        }
        if (v === qs[currentQuestion - 1].entries) {
            return;
        }

        let questionsCopy = JSON.parse(JSON.stringify(qs));
        let newQuestion = questionsCopy[currentQuestion - 1];
        newQuestion.entries = v;
        setQs(questionsCopy);
    };

    return (
        <>
            {questionBuilderState === "ENABLED" &&
                <>
                    <div style={{flex: 1}}/>
                    <div className="lottie-container-w-shadow" onClick={handleAddQuestion}>
                        <Lottie
                            options={options}
                            height={150}
                            width={150}
                            isClickToPauseDisabled={true}
                        />
                    </div>
                    <div className={styles.questionBuilderDefaultCaption} tabIndex={0}>{QUESTION_BUILDER_CAPTION}</div>
                    <YuJaButton
                        onClick={handleAddQuestion}
                        index={collapsed ? -1 : 0}
                    >
                        {QUESTION_BUILDER_BUTTON}
                    </YuJaButton>
                    <div style={{flex: 2}}/>
                </>
            }

            {questionBuilderState === "DISABLED" &&
                <>
                    <div style={{flex: 1}}/>
                    <DisabledBuilderIcon />
                    <div className={styles.questionBuilderDefaultCaption} tabIndex={0}>
                        {pollType === POLL_TYPE.ATTENDANCE ? DISABLED_ATTENDANCE_BUILDER_CAPTION : DISABLED_QUESTION_BUILDER_CAPTION}
                    </div>
                    {pollType !== POLL_TYPE.ATTENDANCE &&
                        <YuJaButton
                            disabled={true}
                            onClick={handleAddQuestion}
                            index={0}
                        >
                            {QUESTION_BUILDER_BUTTON}
                        </YuJaButton>
                    }
                    <div style={{flex: 1}}/>
                </>
            }

            <DeleteConfirmationModal
                show={discardModalShow}
                setShow={setDiscardModalShow}
                handleSubmit={handleDiscard}
                isNewQuestion={qs.length !== originalData?.questions?.length && currentQuestion === qs.length}
            />
            <AddQuestionConfirmationModal
                show={addQuestionModalShow}
                setShow={setAddQuestionModalShow}
                handleSubmit={handleAddUpdate}
                isNewQuestion={qs.length !== originalData?.questions?.length && currentQuestion === qs.length}
            />

            {questionBuilderState === "EDIT" &&
                <>
                    {pollType !== POLL_TYPE.ATTENDANCE
                        && (qs.length === originalData?.questions?.length || currentQuestion !== qs.length) &&
                        <div className="questionBuilderAddQuestionRow">
                            <div className="questionBuilderAddQuestionText">
                                {QUESTION_BUILDER_ADD_TOOLTIP}
                            </div>
                            <div className="lottie-container-w-shadow questionBuilderAddMiniLottie"
                                 onClick={handleAddQuestion}>
                                <Lottie
                                    options={options}
                                    height={30}
                                    width={30}
                                    isClickToPauseDisabled={true}
                                />
                            </div>
                        </div>
                    }


                    {pollType === POLL_TYPE.MERGED_POLL && !!qs[currentQuestion - 1] &&
                        <div
                            className={styles.panelCard}
                            style={{
                                flexShrink: 1,
                                maxHeight: !settingsCardCollapsed ? 300 : 48,
                                overflow: "hidden"
                            }}
                        >
                            <div
                                className={styles.panelCardHeader}
                                onClick={() => {
                                    if (settingsCardCollapsed) {
                                        setDetailCardCollapsed(true);
                                    }
                                    setSettingsCardCollapsed(!settingsCardCollapsed)
                                }}
                            >
                                <div className={styles.panelCardHeaderTitle} tabIndex={0} role={TEXTBOX}>
                                    Question Settings
                                </div>
                                {settingsCardCollapsed ? <ArrowDown/> : <ArrowUp/>}
                            </div>
                            <div className={styles.panelCardBody} style={{overflowY: "auto"}}>
                                <div style={{display: "flex", alignItems: "center", gap: 10, justifyContent: "space-between"}}>
                                    <div>
                                        <div className={styles.questionBuilderDefaultLabel}
                                             style={{marginBottom: 5}}>
                                            {CUSTOMIZE_POINTS_TEXT}
                                        </div>
                                        <div className={styles.questionBuilderDefaultDesc}>
                                            {CUSTOMIZE_POINTS_TOOLTIP_TEXT}
                                        </div>
                                    </div>
                                    <PointsInputBox
                                        containerStyle={{padding: 2, width: 80, height: 25}}
                                        style={{textAlign: "center"}}
                                        value={`${qs[currentQuestion - 1].hasOwnProperty("weightage") ? qs[currentQuestion - 1].weightage : ""}`}
                                        disabled={!qs[currentQuestion - 1].hasOwnProperty("weightage") || [QUESTION_TYPES.WC.name, QUESTION_TYPES.RK.name, QUESTION_TYPES.OE.name].includes(qs[currentQuestion - 1].questionType)}
                                        onBlur={handleWeightageUpdate}
                                    />
                                </div>

                                <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10}}>
                                    <div>
                                        <div className={styles.questionBuilderDefaultLabel}
                                             style={{marginBottom: 5}}>
                                            {CUSTOMIZE_TIME_LIMIT_TEXT}
                                        </div>
                                        <div className={styles.questionBuilderDefaultDesc}>
                                            {CUSTOMIZE_TIME_LIMIT_TOOLTIP_TEXT}
                                        </div>
                                    </div>

                                    <TimeLimitInputBox
                                        containerStyle={{padding: 2, width: 80, height: 25}}
                                        style={{textAlign: "center"}}
                                        value={`${qs[currentQuestion - 1].hasOwnProperty("timeLimit") ? qs[currentQuestion - 1].timeLimit : ""}`}
                                        disabled={!qs[currentQuestion - 1].hasOwnProperty("timeLimit")}
                                        onBlur={handleTimeLimitUpdate}
                                    />
                                </div>
                                {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.WC.name &&
                                    <div style={{display: "flex", alignItems: "center", justifyContent: "space-between", gap: 10}}>
                                        <div>
                                            <div className={styles.questionBuilderDefaultLabel}
                                                 style={{marginBottom: 5}}>
                                                {CUSTOMIZE_ENTRIES_TEXT}
                                            </div>
                                            <div className={styles.questionBuilderDefaultDesc}>
                                                {CUSTOMIZE_ENTRIES_TOOLTIP_TEXT}
                                            </div>
                                        </div>
                                        <EntriesInputBox
                                            containerStyle={{padding: 2, width: 80, height: 25}}
                                            style={{textAlign: "center", }}
                                            value={`${qs[currentQuestion - 1].hasOwnProperty("entries") ? qs[currentQuestion - 1].entries : ""}`}
                                            disabled={!qs[currentQuestion - 1].hasOwnProperty("entries")}
                                            onBlur={handleEntriesUpdate}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    <div
                        className={styles.panelCard}
                        style={{
                            flexShrink: 1,
                            maxHeight: !detailCardCollapsed ? "90%" : 48,
                            overflow: "hidden"
                        }}
                    >
                        <div className={styles.panelCardHeader}
                             onClick={() => {
                                 if (detailCardCollapsed) {
                                     setSettingsCardCollapsed(true);
                                 }
                                 setDetailCardCollapsed(!detailCardCollapsed);
                             }}>
                            <div className={styles.panelCardHeaderTitle} tabIndex={0} role={TEXTBOX}>Question Details
                            </div>
                            {detailCardCollapsed ? <ArrowDown/> : <ArrowUp/>}
                        </div>

                        <div className={styles.panelCardBody} ref={questionDetailsBodyContainerRef} style={{overflowY: "auto"}}>
                            {pollType !== POLL_TYPE.ATTENDANCE &&
                                <div>
                                    <div className={styles.questionBuilderDefaultLabel}
                                         style={{marginBottom: 5}}>
                                        {QUESTION_TYPE}
                                    </div>
                                    <YuJaDropdown
                                        data={Object.values(QUESTION_TYPES)}
                                        getOptionLabel={option => option.description}
                                        getOptionValue={option => option.name}
                                        value={questionType}
                                        textStyle={{wordBreak: "break-word"}}
                                        onChange={handleQuestionTypeUpdate}
                                        style={{
                                            fontSize: 15,
                                            padding: 8,
                                            gap: 5,
                                            height: "40px",
                                            borderRadius: "3px",
                                            borderColor: "#909090",
                                            width: "100%"
                                        }}
                                        // className="questionBuilderDropdown"
                                    />
                                </div>
                            }

                            {qs[currentQuestion - 1]?.questionType !== QUESTION_TYPES.FITB.name &&
                                <div>
                                    <div className={styles.questionBuilderDefaultLabel}
                                         style={{marginBottom: 5}}>{QUESTION_TITLE}</div>
                                    <YuJaRichContentEditBoxNew
                                        selectedQuestion={qs[currentQuestion - 1]}
                                        placeholder={ENTER_QUESTION_TITLE}
                                        setValue={handleQuestionTitleUpdate}
                                        value={qs[currentQuestion - 1].queTitle}
                                    />

                                    {/*<YuJaRichContentEditBox*/}
                                    {/*    placeholder={ENTER_QUESTION_TITLE}*/}
                                    {/*    setValue={handleQuestionTitleUpdate}*/}
                                    {/*    value={qs[currentQuestion - 1].queTitle}*/}
                                    {/*    onLoad={() => {*/}
                                    {/*        const questionBuilderMain = questionDetailsBodyContainerRef.current;*/}
                                    {/*        if (!questionBuilderMain) {*/}
                                    {/*            return;*/}
                                    {/*        }*/}
                                    {/*        setShowSeeMore(questionBuilderMain.scrollHeight > questionBuilderMain.clientHeight);*/}
                                    {/*    }}*/}
                                    {/*/>*/}
                                </div>
                            }

                            {pollType !== POLL_TYPE.ATTENDANCE &&
                                <div>
                                    <div className={styles.questionBuilderDefaultLabel}
                                         style={{marginBottom: 5}}>
                                        {questionType === QUESTION_TYPES.CI.name ? IMAGE : MEDIA}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <UploadQuestionImageNew
                                            selectedQuestion={qs[currentQuestion - 1]}
                                            questions={qs}
                                            setQuestions={setQs}
                                            pollKey={pollKey}
                                            questionBuilder={true}
                                            savePoll={handleAddUpdate}
                                        />
                                    </div>
                                </div>
                            }

                            {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.MCSS.name &&
                                <div>
                                    <div className={styles.questionBuilderDefaultLabel}
                                         style={{marginBottom: 5}}>
                                        {OPTIONS}
                                    </div>

                                    {!!qs[currentQuestion - 1]?.correctAnswers && qs[currentQuestion - 1].correctAnswers.length > 1 && !!qs[currentQuestion - 1].weightage &&
                                        <YuJaCheckbox
                                            size={20}
                                            style={{ fontSize: 13,  marginBottom: 10, marginLeft: 29, fontWeight: "500"}}
                                            checked={!!qs[currentQuestion - 1]?.correctAnyEnable}
                                            onClick={handleCorrectAnyCheck}
                                            label={CORRECT_ANY_DESCRIPTION}
                                        />
                                    }

                                    <DragDropContext>
                                        <Droppable droppableId="droppable-multiple-choice-question">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    {...provided.dropHandleProps}
                                                    className=""
                                                    id="mcq-options-container"
                                                    style={{
                                                        marginBottom: 10,
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        gap: 10
                                                    }}
                                                >
                                                    {qs[currentQuestion - 1]?.optionsMap && Object.entries(qs[currentQuestion - 1]?.optionsMap).map(([choice, optionValue], index) => {
                                                        return (
                                                            <div key={index}>
                                                                <MCQuestionOption
                                                                    optionData={{
                                                                        choice: choice,
                                                                        optionValue: optionValue
                                                                    }}
                                                                    selectedQuestion={qs[currentQuestion - 1]}
                                                                    questions={qs}
                                                                    setQuestions={setQs}
                                                                    pollKey={pollKey}
                                                                    index={index}
                                                                    pollType={pollType}
                                                                    maxSize={Object.entries(qs[currentQuestion - 1]?.optionsMap).length}
                                                                    warningShowed={warningShowed}
                                                                    setWarningShowed={setWarningShowed}
                                                                    questionBuilder={true}
                                                                />
                                                            </div>);
                                                    })}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                    <div style={{height: 5}}/>
                                    <YuJaButton style={{width: "100%", fontSize: 15}}
                                                index={collapsed ? -1 : 0} onClick={handleAddOptionMCSS}>
                                        <PlusIcon style={{
                                            width: 15,
                                            height: 15,
                                            marginRight: 5
                                        }}/>{MCSS_ADD_OPTION_TEXT}
                                    </YuJaButton>
                                </div>
                            }

                            {pollType === POLL_TYPE.ATTENDANCE &&
                                <div>
                                    <div className={styles.questionBuilderDefaultLabel}
                                         style={{marginBottom: 5}}>
                                        {CORRECT_ANSWER}
                                    </div>
                                    <SAQuestion
                                        geofence={geofence} setGeofence={setGeofence}
                                        mapInitialized={mapInitialized} setMapInitialized={setMapInitialized}
                                        syncLms={syncLms} setSyncLms={setSyncLms}
                                        questions={qs} setQuestions={setQs}
                                        selectedQuestion={qs[currentQuestion - 1]}
                                        pollType={pollType}
                                        questionBuilder={true}
                                        editable={!hasResponses}
                                        userSettings={userSettings}
                                    />
                                </div>
                            }

                            {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.TF.name &&
                                <div>
                                    {qs[currentQuestion - 1]?.optionsMap && qs[currentQuestion - 1].optionsMap.map((option, index) => {
                                        return (
                                            <TFQuestionOption
                                                key={index}
                                                option={option}
                                                selectedQuestion={qs[currentQuestion - 1]}
                                                correctAnswers={qs[currentQuestion - 1]?.correctAnswers}
                                                handleCorrectAnswerChange={(input) => setQs(updateCorrectAnswers(input, currentQuestion - 1, qs))}
                                                index={index}
                                                pollType={pollType}
                                                questionBuilder={true}
                                            />
                                        );
                                    })}
                                </div>
                            }

                            {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.FITB.name &&
                                <div>
                                    <div
                                        className={styles.questionBuilderDefaultLabel}
                                        style={{
                                            marginBottom: 5,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between"
                                        }}
                                    >
                                        {QUESTION_TITLE}
                                        <span className="fitb-char-count">
                                            {FITB_QUESTION_LENGTH - (qs[currentQuestion - 1]?.queTitle || "").length}
                                            {CHARACTERS_REMAINING}
                                        </span>
                                    </div>
                                    <FITBQuestionTitle
                                        questions={qs}
                                        setQuestions={setQs}
                                        selectedQuestion={qs[currentQuestion - 1]}
                                        editorState={editorState}
                                        setEditorState={setEditorState}
                                        compositeDecorator={compositeDecorator}
                                        setPreviewModalShow={() => {
                                        }}
                                        questionBuilder={true}
                                        hasBlankRestriction={hasBlankRestriction}
                                        // customStyleFn={customStyleFn}
                                    />
                                    {!!qs[currentQuestion - 1]?.weightage && Object.keys(qs[currentQuestion - 1]?.correctAnswers).length !== 0 && (
                                        <div style={{
                                            display: 'flex',
                                            flexWrap: "wrap",
                                            flexDirection: "column",
                                            padding: "10px 0",
                                            gap: 8
                                        }}>
                                            {qs[currentQuestion - 1]?.correctAnswers && Object.keys(qs[currentQuestion - 1]?.correctAnswers).map((key, i) => (
                                                <>
                                                    {i !== 0 &&
                                                        <div style={{borderTop: "2px dashed grey"}}/>
                                                    }
                                                    {qs[currentQuestion - 1].correctAnswers[key] && qs[currentQuestion - 1]?.correctAnswers[key].map((value, i) => (
                                                        <FITBAnswer
                                                            key={i}
                                                            index={i}
                                                            answerKey={key}
                                                            answerValue={value}
                                                            handleAddAnswer={(key) => handleAddAnswer(key, qs[currentQuestion - 1].correctAnswers, currentQuestion - 1, qs, setQs)}
                                                            handleUpdateAnswer={(key, index, text) => handleUpdateAnswer(key, index, text, qs[currentQuestion - 1].correctAnswers, currentQuestion - 1, qs, setQs)}
                                                            handleDeleteAnswer={(key, index) => handleDeleteAnswer(key, index, qs[currentQuestion - 1].correctAnswers, currentQuestion - 1, qs, setQs, editorState, setEditorState, compositeDecorator)}
                                                            questionBuilder={true}
                                                        />
                                                    ))}
                                                </>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            }

                            {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.CI.name &&
                                <div>
                                    <div className={styles.questionBuilderDefaultLabel}
                                         style={{marginBottom: 5}}>
                                        {OPTIONS}
                                    </div>
                                    {(!qs[currentQuestion - 1]?.optionsMap || !qs[currentQuestion - 1]?.optionsMap.length) &&
                                        <div className={styles.questionBuilderDefaultLabel}
                                             style={{marginBottom: 5}}>{CI_ADD_OPTION_TEXT}</div>
                                    }
                                    <DragDropContext>
                                        <Droppable droppableId="droppable-multiple-choice-question">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    {...provided.dropHandleProps}
                                                    // className="droppable-multiple-choice-question-options"
                                                    style={{display: "flex", flexDirection: "column", gap: 10}}
                                                >
                                                    {typeof qs[currentQuestion - 1]?.optionsMap === "object" && qs[currentQuestion - 1].optionsMap.map((option, index) => {
                                                        return (<div key={index}>
                                                            <CIQuestionOption
                                                                optionData={option}
                                                                selectedQuestion={qs[currentQuestion - 1]}
                                                                questions={qs}
                                                                setQuestions={setQs}
                                                                index={index}
                                                                pollType={pollType}
                                                                questionBuilder={true}

                                                            />
                                                        </div>);
                                                    })}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            }

                            {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.MH.name &&
                                <div>
                                    <div className={styles.questionBuilderDefaultLabel}
                                         style={{marginBottom: 5}}>
                                        {OPTIONS}
                                    </div>
                                    <div
                                        className={styles.questionBuilderDefaultLabel}>{MH_ADD_OPTION_TEXT}</div>
                                </div>
                            }

                            {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.OE.name &&
                                <> </>
                            }

                            {qs[currentQuestion - 1]?.questionType === QUESTION_TYPES.RK.name &&
                                <div>
                                    <div className={styles.questionBuilderDefaultLabel}
                                         style={{marginBottom: 5}}>
                                        {OPTIONS}
                                    </div>
                                    <RKQuestion
                                        questions={qs}
                                        setQuestions={setQs}
                                        selectedQuestion={qs[currentQuestion - 1]}
                                        questionBuilder={true}
                                        showAddOptionBtn={false}
                                    />
                                    <div style={{height: 15}}/>
                                    <YuJaButton style={{width: "100%", fontSize: 15}}
                                                index={collapsed ? -1 : 0} onClick={handleAddOptionRK}>
                                        <PlusIcon style={{
                                            width: 15,
                                            height: 15,
                                            marginRight: 5
                                        }}/>{MCSS_ADD_OPTION_TEXT}
                                    </YuJaButton>
                                </div>
                            }
                        </div>
                    </div>
                    <div style={{flex: 1000}}/>
                    <div className={styles.panelCardsFooter}>
                        <YuJaButton
                            disabled={
                                disabledEditQuestion || checkAnyQuestionIsLoading()
                                || (
                                    (qs.length === originalData?.questions?.length || currentQuestion !== qs.length)
                                    && (pollType !== POLL_TYPE.ATTENDANCE || (getLocalSyncLms() === syncLms && JSON.stringify(getLocalGeofence()) === JSON.stringify(geofence)))
                                    && checkSameQuestions(qs, originalQuestions, currentQuestion)
                                )
                            }
                            wrapperStyle={{fontSize: 15}}
                            onClick={() => setDiscardModalShow(true)}
                            type="delete"
                            index={collapsed ? -1 : 0}
                        >
                            {qs.length !== originalData?.questions?.length && currentQuestion === qs.length ? QUESTION_BUILDER_DELETE_BUTTON : QUESTION_BUILDER_DISCARD_BUTTON}
                        </YuJaButton>
                        <div style={{flex: 1}}/>
                        <YuJaButton
                            index={collapsed ? -1 : 0}
                            wrapperStyle={{fontSize: 15}}
                            style={{width: qs.length !== originalData?.questions?.length && currentQuestion === qs.length ? 210 : 150}}
                            disabled={
                                isSavingProgressing || disabledEditQuestion || checkAnyQuestionIsLoading()
                                || (
                                    (qs.length === originalData?.questions?.length || currentQuestion !== qs.length)
                                    && (pollType !== POLL_TYPE.ATTENDANCE || (getLocalSyncLms() === syncLms && JSON.stringify(getLocalGeofence()) === JSON.stringify(geofence)))
                                    && checkSameQuestions(qs, originalQuestions, currentQuestion)
                                )
                            }
                            onClick={() => {
                                setDisabledEditQuestion(true);
                                let {
                                    quesError,
                                    elemIndex
                                } = questionError(qs[currentQuestion - 1], pollType);
                                if (quesError) {
                                    notifyError(quesError);
                                    setDisabledEditQuestion(false);
                                    return;
                                }
                                if (qs.length !== originalData?.questions?.length && currentQuestion === qs.length) {
                                    setAddQuestionModalShow(true)
                                    setDisabledEditQuestion(false);
                                } else {
                                    setIsSavingProgressing(true);
                                    handleAddUpdate().finally(() => {
                                        setIsSavingProgressing(false);
                                    });
                                }
                            }}
                        >
                            {isSavingProgressing
                                ? <img src={loadingGif} alt={"loading..."} style={{height: 20}}/>
                                : qs.length !== originalData?.questions?.length && currentQuestion === qs.length
                                    ? QUESTION_BUILDER_ADD_QUESTION_BUTTON
                                    : QUESTION_BUILDER_UPDATE_QUESTION_BUTTON
                            }
                        </YuJaButton>
                    </div>
                </>
            }
        </>
    );
}

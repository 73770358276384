import React, { useEffect, useState } from "react";
import { Label, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { CourseApiCalls } from "../../apis/CourseApiCalls";
import {
  POLL_SHARE_MODE,
  POLL_TYPE,
  QUESTION_TYPES,
  TAB_PARTICIPANTS_ABSENT,
  TAB_PARTICIPANTS_CORRECT,
  TAB_PARTICIPANTS_INCORRECT,
  TAB_PARTICIPANTS_NO_RESPONSE,
  TAB_PARTICIPANTS_PRESENT,
  TAB_PARTICIPANTS_UNANSWERED,
  TEXTBOX,
  UNGRADED_TEXT
} from "../../utils/constants";
import { removePrefixNew } from "../../utils/helpers";
import { ReactComponent as ShiftNext } from "./images/SHIFT_Q_NEXT.svg";
import { ReactComponent as ShiftPrev } from "./images/SHIFT_Q_PREV.svg";
import "./styles.css";

export default function TabParticipantsLive({ chartData = {}, minimized, pollData, currQues, setCurrQues, localState, collapsed }) {
  const { getCourseById } = CourseApiCalls();
  const [loading, setLoading] = useState(false);
  const [correctData, setCorrectData] = useState([]);
  const [incorrectData, setIncorrectData] = useState([]);
  const [unansData, setUnansData] = useState([]);
  const [, setTotal] = useState(0);
  const [ratio, setRatio] = useState("");
  const [corrNum, setCorrNum] = useState(0);
  const [incNum, setIncNum] = useState(0);
  const [unansNum, setUnansNum] = useState(0);

  useEffect(() => {
    if (minimized || !Object.entries(pollData).length) {
      return;
    }
    getData();
  }, [chartData, pollData, localState]);

  const COLOR_SECTION = {CORRECT: "#A1D2BA", INCORRECT: "#E3A7A5", UNGRADED: "#D3DDF2", UNANSWERED: "#E6E6E6"}


  const getColor = (str) => {
    switch (str) {
      case "Correct":
        return COLOR_SECTION.CORRECT;
      case "Incorrect":
        return COLOR_SECTION.INCORRECT;
      case "No Response":
      default:
        return pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? COLOR_SECTION.UNGRADED : COLOR_SECTION.UNANSWERED;
    }
  };

  const getData = async () => {
    let correctDataTemp = [], corrNumTemp = 0;
    let incorrectDataTemp = [], incNumTemp = 0;
    let unansDataTemp = [], unansNumTemp = 0;
    let totalTemp = 0;
    let ratioTemp = "";
    const { correct, incorrect, unanswered} = chartData;
    const {questions=[]} = pollData;
    if (questions.length < currQues) {
      return;

    }
    const questionType = questions[currQues-1].questionType;

    if (questionType === QUESTION_TYPES.FITB.name || questionType === QUESTION_TYPES.SA.name) {
      let cor = {}, inc = {}, una = {};
      cor.name = TAB_PARTICIPANTS_CORRECT;
      cor.fill = getColor(TAB_PARTICIPANTS_CORRECT);
      cor.number = correct;
      inc.name = TAB_PARTICIPANTS_INCORRECT;
      inc.number = incorrect;
      inc.fill = getColor(TAB_PARTICIPANTS_INCORRECT);
      una.name = TAB_PARTICIPANTS_NO_RESPONSE;
      una.number = unanswered;
      una.fill = getColor(TAB_PARTICIPANTS_NO_RESPONSE);

      if (!loading && pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE && pollData?.poll?.index2Pk && pollData?.questions[0]?.shareCount > 0 && localState !== "SHARED_ALL" && localState !== "GET_READY_ALL") {
        setLoading(true);
        await getCourseById(removePrefixNew(pollData.poll.index2Pk)).then(res => {
          const {courseMembers=[]} = res.result;
          inc.number = courseMembers.length - cor.number;
          setLoading(false);
        });
      }
      
      totalTemp = cor.number + inc.number + una.number;
      correctDataTemp.push(cor);
      incorrectDataTemp.push(inc);
      unansDataTemp.push(una);
    } else if (questionType === QUESTION_TYPES.MCSS.name) {
      let cor = {}, inc = {}, una = {};
      cor.name = TAB_PARTICIPANTS_CORRECT;
      cor.fill = getColor(TAB_PARTICIPANTS_CORRECT);
      cor.number = correct;
      inc.name = TAB_PARTICIPANTS_INCORRECT;
      inc.number = incorrect;
      inc.fill = getColor(TAB_PARTICIPANTS_INCORRECT);
      una.name = TAB_PARTICIPANTS_NO_RESPONSE;
      una.number = unanswered;
      una.fill = getColor(TAB_PARTICIPANTS_NO_RESPONSE);

      totalTemp = cor.number + inc.number + una.number;
      correctDataTemp.push(cor);
      incorrectDataTemp.push(inc);
      unansDataTemp.push(una);
    } else if (questionType === QUESTION_TYPES.TF.name) {
      let cor = {}, inc = {}, una = {};
      cor.name = TAB_PARTICIPANTS_CORRECT;
      cor.fill = getColor(TAB_PARTICIPANTS_CORRECT);
      cor.number = correct;
      inc.name = TAB_PARTICIPANTS_INCORRECT;
      inc.number = incorrect;
      inc.fill = getColor(TAB_PARTICIPANTS_INCORRECT);
      una.name = TAB_PARTICIPANTS_NO_RESPONSE;
      una.number = unanswered;
      una.fill = getColor(TAB_PARTICIPANTS_NO_RESPONSE);


      totalTemp = cor.number + inc.number + una.number;
      correctDataTemp.push(cor);
      incorrectDataTemp.push(inc);
      unansDataTemp.push(una);
    } else if (questionType === QUESTION_TYPES.CI.name) {
      let cor = {}, inc = {}, una = {};
      cor.name = TAB_PARTICIPANTS_CORRECT;
      cor.fill = getColor(TAB_PARTICIPANTS_CORRECT);
      cor.number = correct;
      inc.name = TAB_PARTICIPANTS_INCORRECT;
      inc.number = incorrect;
      inc.fill = getColor(TAB_PARTICIPANTS_INCORRECT);
      una.name = TAB_PARTICIPANTS_NO_RESPONSE;
      una.number = unanswered;
      una.fill = getColor(TAB_PARTICIPANTS_NO_RESPONSE);

      totalTemp = cor.number + inc.number + una.number;
      correctDataTemp.push(cor);
      incorrectDataTemp.push(inc);
      unansDataTemp.push(una);
    } else if (questionType === QUESTION_TYPES.MH.name) {
      let cor = {}, inc = {}, una = {};
      cor.name = TAB_PARTICIPANTS_CORRECT;
      cor.fill = getColor(TAB_PARTICIPANTS_CORRECT);
      cor.number = correct;
      inc.name = TAB_PARTICIPANTS_INCORRECT;
      inc.number = incorrect;
      inc.fill = getColor(TAB_PARTICIPANTS_INCORRECT);
      una.name = TAB_PARTICIPANTS_NO_RESPONSE;
      una.number = unanswered;
      una.fill = getColor(TAB_PARTICIPANTS_NO_RESPONSE);


      totalTemp = cor.number + inc.number + una.number;
      correctDataTemp.push(cor);
      incorrectDataTemp.push(inc);
      unansDataTemp.push(una);
    }
    
    corrNumTemp = correctDataTemp[0].number;
    incNumTemp = incorrectDataTemp[0].number;
    unansNumTemp = unansDataTemp[0].number;
    ratioTemp = Math.round(corrNumTemp/totalTemp*100);
    correctDataTemp.push({name: "Total", number: totalTemp-corrNumTemp, fill: "#F7F7F7"});
    incorrectDataTemp.push({name: "Total", number: totalTemp-incNumTemp, fill: "#F7F7F7"});
    unansDataTemp.push({name: "Total", number: totalTemp-unansNumTemp, fill: "#F7F7F7"});
    
    
    if(correctDataTemp[0].number === 0 && correctDataTemp[1].number === 0) {
      correctDataTemp[1].number = 1;
      ratioTemp = 0;
    }
    if(incorrectDataTemp[0].number === 0 && incorrectDataTemp[1].number === 0) {
      incorrectDataTemp[1].number = 1;
    }
    if(unansDataTemp[0].number === 0 && unansDataTemp[1].number === 0) {
      unansDataTemp[1].number = 1;
    }

    setCorrectData(correctDataTemp);
    setIncorrectData(incorrectDataTemp);
    setUnansData(unansDataTemp);
    setCorrNum(corrNumTemp);
    setIncNum(incNumTemp);
    setUnansNum(unansNumTemp);
    setTotal(totalTemp);
    setRatio(ratioTemp);
  };

  return (<>
  {minimized && 
    <div style={{display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between"}}>
      <div style={{display: "flex", flexDirection: "column"}}>
        <div
            className="responsePanelNumbers"
            style={{color: COLOR_SECTION.CORRECT}}
            tabIndex={collapsed? -1 : 0}
            role={TEXTBOX}
        >{corrNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className="responselegendcircle" style={{backgroundColor: COLOR_SECTION.CORRECT}}/>
          <div className="responsePanelText" tabIndex={collapsed? -1 : 0} role={TEXTBOX}>
            {pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? TAB_PARTICIPANTS_PRESENT : TAB_PARTICIPANTS_CORRECT}
          </div>
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "column"}}>
        <div
            className="responsePanelNumbers"
            style={{color: COLOR_SECTION.INCORRECT}}
            tabIndex={collapsed? -1 : 0}
            role={TEXTBOX}
        >{incNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className="responselegendcircle" style={{backgroundColor: COLOR_SECTION.INCORRECT}}/>
          <div className="responsePanelText" tabIndex={collapsed? -1 : 0} role={TEXTBOX}>
            {pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? TAB_PARTICIPANTS_ABSENT : TAB_PARTICIPANTS_INCORRECT}
          </div>
        </div>
      </div>
      <div style={{display: "flex", flexDirection: "column"}}>
        <div
            className="responsePanelNumbers"
            style={{color: pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? COLOR_SECTION.UNGRADED : COLOR_SECTION.UNANSWERED}}
            tabIndex={collapsed? -1 : 0}
            role={TEXTBOX}
        >{unansNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span
              className="responselegendcircle"
              style={{backgroundColor: pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? COLOR_SECTION.UNGRADED : COLOR_SECTION.UNANSWERED}}
          />
          <div className="responsePanelText" tabIndex={collapsed? -1 : 0} role={TEXTBOX}>
            {pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? UNGRADED_TEXT : TAB_PARTICIPANTS_UNANSWERED}
          </div>
        </div>
      </div>
    </div>
  }
  {!minimized &&
    <div style={{display: "flex", width: "100%", alignItems: "center"}}>
      <div style={{width: "50%", display: "flex", flexDirection: "column"}}>
        <div
            className="responsePanelNumbers"
            style={{color: COLOR_SECTION.CORRECT}}
            tabIndex={collapsed? -1 : 0}
            role={TEXTBOX}
        >{corrNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className="responselegendcircle" style={{backgroundColor: COLOR_SECTION.CORRECT}}/>
          <div className="responsePanelText" tabIndex={collapsed? -1 : 0} role={TEXTBOX}>
            {pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? TAB_PARTICIPANTS_PRESENT : TAB_PARTICIPANTS_CORRECT}
          </div>
        </div>
        <div className="responsePanelNumbers" style={{color: COLOR_SECTION.INCORRECT, marginTop: "20px"}} tabIndex={collapsed? -1 : 0} role={TEXTBOX}>{incNum}</div>
        <div style={{display: "flex", alignItems: "center"}}>
          <span className="responselegendcircle" style={{backgroundColor: COLOR_SECTION.INCORRECT}}></span>
          <div className="responsePanelText" tabIndex={collapsed? -1 : 0} role={TEXTBOX}>
            {pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? TAB_PARTICIPANTS_ABSENT : TAB_PARTICIPANTS_INCORRECT}
          </div>
        </div>

          <div
              className="responsePanelNumbers"
              style={{ color: pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? COLOR_SECTION.UNGRADED : COLOR_SECTION.UNANSWERED, marginTop: "20px" }}
              tabIndex={collapsed? -1 : 0}
              role={TEXTBOX}
          >{unansNum}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span
                className="responselegendcircle"
                style={{ backgroundColor: pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? COLOR_SECTION.UNGRADED : COLOR_SECTION.UNANSWERED }}
            />
            <div className="responsePanelText" tabIndex={collapsed? -1 : 0} role={TEXTBOX} >
              {pollData?.poll?.pollType === POLL_TYPE.ATTENDANCE ? UNGRADED_TEXT : TAB_PARTICIPANTS_UNANSWERED}
            </div>
          </div>

      </div>
      <div style={{width: "70%", height: "100%", display: "flex", flexDirection: "column", gap: 10}}>
        <ResponsiveContainer className={([POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollData.poll.pollShareMode) && pollData.questions.length > 1) ? "resultsCont1" : "resultsCont2"}>
          <PieChart>

              <Pie
                data={unansData}
                innerRadius={40}
                outerRadius={50}
                paddingAngle={2}
                dataKey="number"
              />

            <Pie
                data={incorrectData}
                innerRadius={52}
                outerRadius={62}
                paddingAngle={2}
                dataKey="number"
            />
            <Pie
                data={correctData}
                innerRadius={64}
                outerRadius={74}
                paddingAngle={2}
                dataKey="number"
            >
              <Label className="responsePanelPercent" position="center">{ratio+"%"}</Label>
            </Pie>
          </PieChart>
        </ ResponsiveContainer>
        {[POLL_SHARE_MODE.SHARE_ALL, POLL_SHARE_MODE.SCHEDULE].includes(pollData.poll.pollShareMode) && pollData.questions.length > 1 &&
          <div style={{display: "flex", alignItems: "center", justifyContent: "center"}} className="shiftQText">
            <ShiftPrev style={{marginRight: "10px", cursor: "pointer"}} onClick={() => {setCurrQues(currQues === 1 ? 1 : currQues-1)}}/>Question {currQues}
            <ShiftNext style={{marginLeft: "10px", cursor: "pointer"}} onClick={() => {setCurrQues(currQues === pollData.questions.length ? currQues : currQues+1)}}/>
          </div>
        }
      </div>
    </div>
  }
  </>);
}

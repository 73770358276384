import styles from './GradeQuestion.module.css';
import {ReactComponent as GO_BACK} from "../../images/go-back-purple.svg";
import {decodeCIParam, getResponseStatus, questionCodeToName} from "../../utils/questionUtils";
import React, {useEffect, useRef, useState} from "react";
import {
    ANSWER_TEXT,
    ASCII_CODE_A,
    BLANK_REGEX, CORRECT_ANSWER, CUSTOMIZE_POINTS_TEXT,
    DEFAULT_IMAGE_ALT,
    FIXED_ANSWER,
    GRADE_BOOK_RESPONSE_STATUS, GRADE_CORRECTION_TEXT,
    GRADE_QUESTION_EXPLAIN_TEXT,
    GRADE_QUESTION_TEXT, GRADE_TEXT,
    MODAL_CANCEL_TEXT,
    NON_GRADABLE_QUESTION_EXPLAIN_TEXT,
    NON_GRADABLE_QUESTION_TEXT, POLL_SCORE_TEXT,
    POLL_TYPE, QUESTION,
    QUESTION_TYPES,
    QUESTIONS_HEADER, RANK_TEXT, REAL_SCORE_COLUMN_NAME, RESPONSIVE_SIDE_BAR_POINT,
    SEARCH_BY_PARTICIPANT_TEXT,
    STUDENTS_ATTEMPT_LABEL,
    TAB_PARTICIPANTS_CORRECT, TAB_PARTICIPANTS_INCORRECT,
    UNABLE_GRADE_QUESTION_EXPLAIN_TEXT,
    UNABLE_GRADE_QUESTION_TEXT,
    UNANSWERED,
    UNSAVED_CHANGES_CONTENT_TEXT,
    UNSAVED_CHANGES_TEXT,
    YES_TEXT,
} from "../../utils/constants";
import {
    getAnswer,
    handleUserAns, hasWeightage,
    notifyError,
    notifySuccess,
    stripHTMLTags
} from "../../utils/helpers";
import YuJaButton from "../../components/standardization/YuJaButton";
import { ReactComponent as SAVE } from "../../images/Save.svg";
import {YuJaDropdown} from "../../components/standardization/YuJaDropdown";
import { ReactComponent as NON_POSTED_ICON } from "../../images/grade-question-not-posted-icon.svg";
import { ReactComponent as NON_GRADABLE_ICON } from "../../images/grade-question-non-gradable-icon.svg";
import { ReactComponent as UN_ANSWERED_ICON } from "../../images/grade-question-unanswered-icon.svg";
import { ReactComponent as CORRECT_ICON } from "../../images/grade-question-correct-icon.svg";
import { ReactComponent as INCORRECT_ICON } from "../../images/grade-question-incorrect-icon.svg";
import { ReactComponent as ARROW_LEFT_ICON } from "../../images/arrow-left-purple.svg";
import { ReactComponent as ARROW_RIGHT_ICON } from "../../images/arrow-right-purple.svg";

import {YuJaTooltip} from "../../components/standardization/YuJaTooltip";
import {YuJaRadioBox} from "../../components/standardization/YuJaRadiobox";
import reactStringReplace from "react-string-replace";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import {GRADE_QUESTION_ERROR, GRADE_QUESTION_SUCCESS} from "../../utils/toast-message-constants";
import {ResponseApiCalls} from "../../apis/ResponseApiCalls";
import loadingGif from "../../images/LoadingAnimation.gif";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import ReactDOM from "react-dom";
import {LatexHTMLInline, LatexInline} from "../../components/Tiptap/latexParser";


export default function GradeQuestionNew({
                                             institutionId,
                                             pollKey,
                                             pollCode,
                                             pollType,
                                             loadData,
                                             open=false,
                                             setOpen,
                                             questions,
                                             responses,
                                             userId,
                                             attemptNo,
                                         }) {
    const {width} = useWindowDimensions();
    const { gradeQuestionStudents} = ResponseApiCalls();

    const parentContainerRef = useRef();
    const [curUserId, setCurUserId] = useState(userId);
    const [curResponse, setCurResponse] = useState(responses.find(r => r.userId === userId));
    const [curAttemptNo, setCurAttemptNo] = useState(attemptNo);
    const students = responses.map(r => ({ label: `${r.firstName}${r.lastName ? " " + r.lastName : ""}`, value: `${r.userId}` }));
    let totalScore = questions.filter(q => !!q.weightage).reduce(function (result, q) {
        return result + q.weightage;
    }, 0);

    const [selectedSerialNo, setSelectedSerialNo] = useState(1);
    const [selectedQuestion, setSelectedQuestion] = useState(questions.find(q => q.serialNo === selectedSerialNo));
    const [questionImageURL, setQuestionImageURL] = useState("");
    const [isVideo, setIsVideo] = useState(false);
    const [imageWidth, setImageWidth] = useState(1);
    const [imageHeight, setImageHeight] = useState(1);
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedQuestionResponseStatus, setSelectedQuestionResponseStatus] = useState({});
    const [changes, setChanges] = useState({});
    const [alertGradeShow, setGradeAlertShow] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [scored, setScored] = useState("0");
    const [percentage, setPercentage] = useState("0%")


    const gradeAlertConfig = {
        title: UNSAVED_CHANGES_TEXT,
        okText: YES_TEXT,
        cancelText: MODAL_CANCEL_TEXT,
        submit: async () => {
            setChanges({});
            setGradeAlertShow(false);
            setOpen(false);
        },
        close: async () => {
            setGradeAlertShow(false);
        },
        buttonWidth: "77px"
    };

    const onLoad = (e) => {
        setIsLoaded(true);
        setImageWidth(e.target.width);
        setImageHeight(e.target.height);
    }


    useEffect(() => {
        const container = document.getElementsByTagName('main')[0];

        if (!container) {
            return;
        }

        if (!!open) {
            // Find all elements with tabindex="0" inside the container
            const tabbableElements = container.querySelectorAll('[tabindex="0"]');

            // Set tabindex="-1" for each element
            tabbableElements.forEach(element => {
                element.setAttribute('tabindex', '-1');
            });

        }

        return () => {
            const tabbableElements = container.querySelectorAll('[tabindex="-1"]');

            // Set tabindex="-1" for each element
            tabbableElements.forEach(element => {
                element.setAttribute('tabindex', '0');
            });
        }


    }, [open]);

    useEffect(() => {
        let response = responses.find(r => r.userId === curUserId);
        setCurResponse(response);
    }, [curUserId]);


    useEffect(() => {
        let que = questions.find(q => q.serialNo === selectedSerialNo);
        setSelectedQuestion(que);
        setIsLoaded(false);
        if(que.directLinkEvp) {
            setQuestionImageURL(que.directLinkEvp);
            setIsVideo(true);
        } else {
            setQuestionImageURL(que.imageURL);
            setIsVideo(false);
        }
    }, [selectedSerialNo]);

    useEffect(() => {
        let scored = (!!curResponse.attempts
            && !!curResponse.attempts.hasOwnProperty(`${curAttemptNo}`)
            && curResponse.attempts[curAttemptNo].hasOwnProperty("correctCount")
            && curResponse.attempts[curAttemptNo].correctCount)
            ? curResponse.attempts[curAttemptNo].correctCount
            : '0';

        setScored(`${scored}/${totalScore}`);
        setPercentage((!!curResponse.attempts
            && !!curResponse.attempts.hasOwnProperty(`${curAttemptNo}`)
            && curResponse.attempts[curAttemptNo].hasOwnProperty("score")
            && parseFloat(curResponse.attempts[curAttemptNo].score))
            ? `${curResponse.attempts[curAttemptNo].score}`
            : '0');
    }, [curResponse, curAttemptNo]);


    useEffect(() => {
        const {serialNo} = selectedQuestion;
        const {attempts=[]} = curResponse;
        let attempt = attempts[curAttemptNo];
        let actualAnswer = !!attempt ? attempt[`A${serialNo}`] : undefined;
        let formattedResponse = !!attempt ? attempt[`Q${serialNo}`] : undefined;

        let status = getResponseStatus(pollType, selectedQuestion, actualAnswer, formattedResponse);
        setSelectedQuestionResponseStatus(status);
    }, [selectedQuestion, curAttemptNo, curResponse]);

    const renderAnswer = () => {
        const { serialNo, questionType, weightage, optionsMap, optionsImageMap={}, correctAnswers=[]} = selectedQuestion;
        const {attempts=[]} = curResponse;
        const answer = attempts.hasOwnProperty(`${curAttemptNo}`) ? attempts[curAttemptNo][`A${serialNo}`] : null;
        const isAnswered = selectedQuestionResponseStatus.key !== GRADE_BOOK_RESPONSE_STATUS.UNANSWERED.key
            && !!answer
            && answer !== FIXED_ANSWER.UNANSWERED;

        const gradeEnabled = !!weightage && pollType?.toUpperCase() !== POLL_TYPE.SURVEY;
        const isActualCorrect = handleUserAns(selectedQuestion, isAnswered ? answer : FIXED_ANSWER.UNANSWERED);
        const showCorrectAnswer = gradeEnabled && !isActualCorrect;



        if (selectedQuestionResponseStatus.key === GRADE_BOOK_RESPONSE_STATUS.NOT_POSTED.key) {
            return (
                <div>
                    <span tabIndex={0} className={styles.regularLabel}>{ANSWER_TEXT}: </span>
                    <span tabIndex={0} className={styles.regularText}>-</span>
                </div>
            );
        }

        switch (questionType) {
            case QUESTION_TYPES.MCSS.name:
                return (
                    <>
                        <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 8}}>
                            <span tabIndex={0} className={styles.regularLabel}>{ANSWER_TEXT}: </span>
                            {Object.keys(optionsMap).map((optIdx, index) => {
                                const isSelected = !!answer ? (Array.isArray(answer) ? answer.includes(optIdx) : answer === optIdx) : false;
                                if (!!gradeEnabled) {
                                    return (
                                        <div
                                            tabIndex={0}
                                            key={index}
                                            className={`${styles.optionBar} 
                                        ${isSelected ? (correctAnswers.includes(optIdx) ? styles.correct : styles.incorrect) : ""}`}
                                        >
                                            {!!isSelected && correctAnswers.includes(optIdx) &&
                                                <span className={`${styles.indexIconContainer} ${styles.correct}`}>
                                                <CORRECT_ICON/>
                                            </span>
                                            }

                                            {!!isSelected && !correctAnswers.includes(optIdx) &&
                                                <span className={`${styles.indexIconContainer} ${styles.incorrect}`}>
                                                <INCORRECT_ICON/>
                                            </span>
                                            }

                                            {!isSelected &&
                                                <span className={styles.indexIconContainer}/>
                                            }
                                            <LatexInline>{optionsMap[optIdx]}</LatexInline>



                                            {optionsImageMap.hasOwnProperty(optIdx) && !!optionsImageMap[optIdx].imageURL &&
                                                <div className="MCSS-opt-image-container" style={{width: 30, height: 30, borderRadius: 3}} >
                                                    <img
                                                        tabIndex={0}
                                                        alt={`Option ${optIdx} image preview`}
                                                        src={`${optionsImageMap[optIdx].imageURL}`}
                                                        style={ { maxWidth: "100%", maxHeight: "100%"}}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            tabIndex={0}
                                            key={index}
                                            className={`${styles.optionBar} ${isSelected ? styles.selected : ""}`}
                                        >
                                        <span className={`${styles.indexIconContainer} ${isSelected ? styles.selected : ""}`}>
                                            {optIdx.toUpperCase()}
                                        </span>
                                            <LatexInline>{optionsMap[optIdx]}</LatexInline>
                                        </div>
                                    );
                                }
                            })}
                        </div>

                        {!!showCorrectAnswer &&
                            <div style={{display: "flex", gap: 5}}>
                                <span tabIndex={0} className={styles.regularLabel}>{CORRECT_ANSWER}: </span>
                                <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                    {correctAnswers
                                        .sort((a, b) => a.localeCompare(b))
                                        .map((optIdx, index) =>
                                            <span tabIndex={0} key={index} className={styles.regularText}
                                                  style={{fontWeight: "500"}}
                                            >
                                                {optIdx.toUpperCase()}. <LatexInline>{optionsMap[optIdx]}</LatexInline>
                                            </span>
                                        )}
                                </div>
                            </div>
                        }
                    </>
                );
            case QUESTION_TYPES.TF.name:
                return (
                    <>
                        <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 8}}>
                            <span tabIndex={0} className={styles.regularLabel}>{ANSWER_TEXT}: </span>
                            {optionsMap.map((option, index) => {
                                const isSelected = !!answer ? answer === option : false;
                                if (!!gradeEnabled) {
                                    return (
                                        <div
                                            tabIndex={0}
                                            key={index}
                                            className={`${styles.optionBar} 
                                        ${isSelected ? (correctAnswers === option ? styles.correct : styles.incorrect) : ""}`}
                                        >
                                            {!!isSelected && correctAnswers === option &&
                                                <span className={`${styles.indexIconContainer} ${styles.correct}`}>
                                                    <CORRECT_ICON/>
                                                </span>
                                            }

                                            {!!isSelected && !correctAnswers === option &&
                                                <span className={`${styles.indexIconContainer} ${styles.incorrect}`}>
                                                    <INCORRECT_ICON/>
                                                </span>
                                            }

                                            {!isSelected &&
                                                <span className={styles.indexIconContainer}/>
                                            }
                                            {option}
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div
                                            tabIndex={0}
                                            key={index}
                                            className={`${styles.optionBar} ${isSelected ? styles.selected : ""}`}
                                        >
                                            {option}
                                        </div>
                                    );
                                }
                            })}
                        </div>

                        {!!showCorrectAnswer &&
                            <div style={{display: "flex", gap: 5}}>
                                <span tabIndex={0} className={styles.regularLabel}>{CORRECT_ANSWER}: </span>
                                <span tabIndex={0} className={styles.regularText} style={{fontWeight: "500"}}>{correctAnswers}</span>
                            </div>
                        }
                    </>
                );
            case QUESTION_TYPES.SA.name:
                return (
                    <span
                        className={styles.regularLabel}
                        style={{gap: 5, display: "flex", alignItems: "center"}}
                    >
                        <span tabIndex={0}>{ANSWER_TEXT}:</span>
                        {!isAnswered &&
                            <span
                                tabIndex={0}
                                className={styles.optionBar}
                                style={{width: "fit-content", color: "#000"}}
                            >
                                <span className={styles.indexIconContainer} style={{width: 24, height: 24}}>
                                    <UN_ANSWERED_ICON style={{width: 18, height: 18}}/>
                                </span>
                                {UNANSWERED}
                            </span>
                        }
                        {!!isAnswered &&
                            <span tabIndex={0} className={styles.regularText}>
                                {getAnswer(curResponse, curAttemptNo, selectedQuestion.serialNo)}
                            </span>
                        }
                    </span>
                );
            case QUESTION_TYPES.OE.name:
                return (
                    <span
                        className={styles.regularLabel}
                        style={{gap: 5, display: "flex", alignItems: "center"}}
                    >
                        <span tabIndex={0}>{ANSWER_TEXT}:</span>
                        {!isAnswered &&
                            <span
                                tabIndex={0}
                                className={styles.optionBar}
                                style={{width: "fit-content", color: "#000"}}
                            >
                                <span className={styles.indexIconContainer} style={{width: 24, height: 24}}>
                                    <UN_ANSWERED_ICON style={{width: 18, height: 18}}/>
                                </span>
                                {UNANSWERED}
                            </span>
                        }
                        {!!isAnswered &&
                            <span tabIndex={0} className={styles.regularText}>
                                {getAnswer(curResponse, curAttemptNo, selectedQuestion.serialNo)}
                            </span>
                        }
                    </span>
                );
            case QUESTION_TYPES.FITB.name:
                let blankNameArr = Object.keys(correctAnswers).map(str => `\\(${str}\\)`);
                const regex = new RegExp(`(${blankNameArr.join("|")})`, "g");
                return (
                    <>
                       <span className={styles.regularLabel} style={{marginRight: 5, lineHeight: "unset"}}>
                          <span tabIndex={0}>{ANSWER_TEXT}:</span>
                          <span className={styles.regularText} style={{lineHeight: "unset"}}>
                               {reactStringReplace(selectedQuestion.queTitle, regex, (blank, i, o) => {
                                   const match = !!blank ? blank.match(/\(([^)]+)\)/) : null;
                                   if (!match) {
                                       return "";
                                   }
                                   const blankName = match[1];

                                   if (!isAnswered) {
                                       return (
                                           <span style={{display: "inline-block", verticalAlign: "middle"}}>
                                               <span
                                                   tabIndex={0}
                                                   className={styles.optionBar}
                                                   style={{width: "fit-content", padding: "5px 10px", margin: 2}}
                                               >
                                                   <span className={styles.indexIconContainer}>
                                                        <UN_ANSWERED_ICON style={{width: 16, height: 16}}/>
                                                    </span>
                                                   {UNANSWERED}
                                               </span>
                                           </span>
                                       );
                                   } else if (correctAnswers[blankName].includes(answer[blankName])) {
                                       return (
                                           <span style={{display: "inline-block", verticalAlign: "middle"}}>
                                               <span
                                                   tabIndex={0}
                                                   className={`${styles.optionBar} ${styles.correct}`}
                                                   style={{width: "fit-content", padding: "5px 10px", margin: 2}}
                                               >
                                                   <span className={`${styles.indexIconContainer} ${styles.correct}`}>
                                                       <CORRECT_ICON/>
                                                   </span>
                                                   {answer[blankName]}
                                               </span>
                                           </span>
                                       );
                                   } else {
                                       return (
                                           <span style={{display: "inline-block", verticalAlign: "middle"}}>
                                               <span
                                                   tabIndex={0}
                                                   className={`${styles.optionBar} ${styles.incorrect}`}
                                                   style={{width: "fit-content", padding: "5px 10px", margin: 2}}
                                               >
                                                   <span className={`${styles.indexIconContainer} ${styles.incorrect}`}>
                                                       <INCORRECT_ICON/>
                                                   </span>
                                                   {answer[blankName]}
                                               </span>
                                           </span>
                                       );
                                   }
                               })}
                          </span>
                       </span>

                       {!!showCorrectAnswer &&
                           <div style={{display: "flex", gap: 5}}>
                               <span tabIndex={0} className={styles.regularLabel}>{CORRECT_ANSWER}: </span>
                               <span style={{display: "flex", flexDirection: "column", gap: 5}}>
                                   {Object.keys(correctAnswers).map((key, index) => (
                                       <span key={index} tabIndex={0} className={styles.regularText} style={{fontWeight: "500"}}>
                                           {"Blank " + (index + 1)}. {correctAnswers[key].join(", ")}
                                       </span>
                                   ))}
                               </span>

                           </div>
                       }
                   </>
               );
            case QUESTION_TYPES.CI.name:
                return (
                    <>
                        <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 8}}>
                            <span tabIndex={0} className={styles.regularLabel}>{ANSWER_TEXT}: </span>
                            {optionsMap.map((option, index) => {
                                const isSelected = !!answer ? (answer.selected === `${index}`) : false;
                                if (!!gradeEnabled) {
                                    return (
                                        <div
                                            tabIndex={0}
                                            key={index}
                                            className={`${styles.optionBar} 
                                            ${isSelected ? (correctAnswers.includes(index) ? styles.correct : styles.incorrect) : ""}`}
                                        >
                                            {!!isSelected && correctAnswers.includes(index) &&
                                                <span className={`${styles.indexIconContainer} ${styles.correct}`}>
                                                <CORRECT_ICON/>
                                            </span>
                                            }

                                            {!!isSelected && !correctAnswers.includes(index) &&
                                                <span className={`${styles.indexIconContainer} ${styles.incorrect}`}>
                                                <INCORRECT_ICON/>
                                            </span>
                                            }

                                            {!isSelected &&
                                                <span className={styles.indexIconContainer}/>
                                            }

                                            <LatexInline>{option.text}</LatexInline>

                                            {optionsImageMap.hasOwnProperty(answer) && !!optionsImageMap[answer].imageURL &&
                                                <div className="MCSS-opt-image-container" style={{width: 30, height: 30, borderRadius: 3}} >
                                                    <img
                                                        tabIndex={0}
                                                        alt={`Option ${answer} image preview`}
                                                        src={`${optionsImageMap[answer].imageURL}`}
                                                        style={ { maxWidth: "100%", maxHeight: "100%"}}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div key={index} tabIndex={0} className={`${styles.optionBar} ${isSelected ? styles.selected : ""}`}>
                                            <span className={`${styles.indexIconContainer} ${isSelected ? styles.selected : ""}`}>
                                                {String.fromCharCode(ASCII_CODE_A + index)}
                                            </span>
                                            <LatexInline>{option.text}</LatexInline>
                                        </div>
                                    );
                                }
                            })}

                            {!!answer && answer.selected === "other" &&
                                <div tabIndex={0} className={`${styles.optionBar} ${!!gradeEnabled ? styles.incorrect : styles.selected}`}>
                                    {!!gradeEnabled &&
                                        <span className={`${styles.indexIconContainer} ${styles.incorrect}`}>
                                            <INCORRECT_ICON/>
                                        </span>
                                    }
                                    {"Other"}
                                </div>
                            }
                        </div>
                        {!!showCorrectAnswer &&
                            <div style={{display: "flex", gap: 5}}>
                                <span tabIndex={0} className={styles.regularLabel}>{CORRECT_ANSWER}: </span>
                                <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                    {correctAnswers.map((optIdx, index) =>
                                        <span tabIndex={0} key={index} className={styles.regularText} style={{fontWeight: "500"}}>
                                            {String.fromCharCode(ASCII_CODE_A + optIdx)}. <LatexInline>{optionsMap[optIdx].text}</LatexInline>
                                        </span>
                                    )}
                                </div>
                            </div>
                        }
                    </>
                );
            case QUESTION_TYPES.MH.name:
                return (
                    <>
                        <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 8}}>
                            <div style={{display: "flex", gap: 10}}>
                                <span tabIndex={0} className={styles.regularLabel} style={{flex: 1}}>
                                    Premises:
                                </span>
                                <span tabIndex={0} className={styles.regularLabel} style={{flex: 1}}>
                                    Responses:
                                </span>
                            </div>

                            <div style={{display: "flex", gap: 10}}>
                                <div style={{flex: 1, display: "flex", flexDirection: "column", gap: 8}}>
                                    {correctAnswers.filter(i => i.length > 1).map((key, index) =>
                                        <div tabIndex={0} key={index} className={styles.optionBar}>
                                            <span className={styles.indexIconContainer}>{index + 1}</span>
                                            <LatexInline>{key[0]}</LatexInline>
                                        </div>
                                    )}
                                </div>
                                <div style={{flex: 1, display: "flex", flexDirection: "column", gap: 8}}>
                                    {correctAnswers.filter(i => i.length > 1).map((key, index) =>
                                        <div
                                            tabIndex={0}
                                            key={index}
                                            className={`${styles.optionBar} 
                                            ${!!isAnswered ? (answer.hasOwnProperty(index) && key[1] === answer[index] ? styles.correct : styles.incorrect) : ""}`}
                                        >
                                            {!!isAnswered && answer.hasOwnProperty(index) && key[1] === answer[index] &&
                                                <span className={`${styles.indexIconContainer} ${styles.correct}`}>
                                                    <CORRECT_ICON/>
                                                </span>
                                            }

                                            {!!isAnswered && answer.hasOwnProperty(index) && key[1] !== answer[index] &&
                                                <span className={`${styles.indexIconContainer} ${styles.incorrect}`}>
                                                    <INCORRECT_ICON/>
                                                </span>
                                            }

                                            {(!isAnswered || !answer.hasOwnProperty(index)) &&
                                                <span className={styles.indexIconContainer}/>
                                            }
                                            {!!isAnswered && answer.hasOwnProperty(index)
                                                ? <LatexInline>{!!answer[index] ? answer[index] : "-"}</LatexInline>
                                                : "-"
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>

                            {!!showCorrectAnswer &&
                                <div style={{display: "flex", gap: 5}}>
                                    <span tabIndex={0} className={styles.regularLabel}>{CORRECT_ANSWER}: </span>
                                    <div style={{display: "flex", flexDirection: "column", gap: 5}}>
                                        {correctAnswers.filter(i => i.length > 1).map((key, index) =>
                                            <span
                                                tabIndex={0}
                                                key={index}
                                                className={styles.regularText}
                                                style={{fontWeight: "500"}}
                                            >
                                                <LatexInline>{key[0]}</LatexInline>
                                                &nbsp;&nbsp;->&nbsp;&nbsp;
                                                <LatexInline>{key[1]}</LatexInline>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                );
            case QUESTION_TYPES.RK.name:
                return (
                    <>
                        <span className={styles.regularLabel} style={{gap: 5, display: "flex", alignItems: "center"}}>
                            <span tabIndex={0}>{ANSWER_TEXT}:</span>
                            {!isAnswered &&
                                <span tabIndex={0} className={styles.optionBar} style={{width: "fit-content", color: "#000"}}>
                                    <span className={styles.indexIconContainer} style={{width: 24, height: 24}}>
                                        <UN_ANSWERED_ICON style={{width: 18, height: 18}}/>
                                    </span>
                                    {UNANSWERED}
                                </span>
                            }
                        </span>
                        {isAnswered &&
                            <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 8}}>
                                {answer.map((key, index) =>
                                    <div tabIndex={0} key={index} className={styles.optionBar}>
                                        <span
                                            className={`${styles.indexIconContainer}`}
                                            style={{width: "auto", padding: 5}}
                                        >
                                            {RANK_TEXT + " " + (index + 1)}
                                        </span>
                                        <LatexInline>{key}</LatexInline>
                                    </div>
                                )}
                            </div>
                        }
                    </>
                );
            case QUESTION_TYPES.WC.name:

                return (
                    <>
                        <span className={styles.regularLabel} style={{gap: 5, display: "flex", alignItems: "center"}}>
                            <span tabIndex={0}>{ANSWER_TEXT}:</span>
                            {!isAnswered &&
                                <span tabIndex={0} className={styles.optionBar} style={{width: "fit-content", color: "#000"}}>
                                    <span className={styles.indexIconContainer} style={{width: 24, height: 24}}>
                                        <UN_ANSWERED_ICON style={{width: 18, height: 18}}/>
                                    </span>
                                    {UNANSWERED}
                                </span>
                            }
                        </span>
                        {isAnswered &&
                            <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 8}}>
                                {Object.values(answer).map((key, index) =>
                                    <div tabIndex={0} key={index} className={styles.optionBar}>
                                        <span className={`${styles.indexIconContainer}`}
                                              style={{width: "auto", padding: 5}}>
                                            {"Entry " + (index + 1)}
                                        </span>
                                        {key}
                                    </div>
                                )}
                            </div>
                        }
                    </>
                );
            default:
                return <></>;
        }
    };

    const renderGradeCorrection = () => {
        const { questionType, weightage, serialNo} = selectedQuestion;
        if (!weightage || questionType === QUESTION_TYPES.SA.name) {
            return <></>;
        }


        const {attempts=[]} = curResponse;
        const answer = attempts.hasOwnProperty(`${curAttemptNo}`) ? attempts[curAttemptNo][`A${serialNo}`] : null;
        const isAnswered = selectedQuestionResponseStatus.key !== GRADE_BOOK_RESPONSE_STATUS.UNANSWERED.key
            && !!answer
            && answer !== FIXED_ANSWER.UNANSWERED;
        const isActualCorrect = handleUserAns(selectedQuestion, isAnswered ? answer : FIXED_ANSWER.UNANSWERED);

        // const isActualCorrect = checkAnswerCorrect();
        let gradeCorrect = isCorrect();


        if (gradeCorrect !== undefined && isActualCorrect !== gradeCorrect) {
            return (
                <div style={{width: "100%", display: "flex", flexDirection: "column", gap: 8}}>
                    <span tabIndex={0} className={styles.regularLabel}>{GRADE_CORRECTION_TEXT}:</span>
                    <span tabIndex={0} className={styles.regularText}>
                        {!!isActualCorrect ? TAB_PARTICIPANTS_CORRECT : TAB_PARTICIPANTS_INCORRECT} -> {!!gradeCorrect ? TAB_PARTICIPANTS_CORRECT : TAB_PARTICIPANTS_INCORRECT}
                    </span>
                </div>
            );
        }
    }



    const RenderScoreBadge = (question, placement="right") => {
        const {serialNo, weightage} = question;
        const {attempts=[]} = curResponse;
        let attempt = attempts[curAttemptNo];
        let actualAnswer = !!attempt ? attempt[`A${serialNo}`] : undefined;
        let formattedResponse = !!attempt ? attempt[`Q${serialNo}`] : undefined;

        let status = getResponseStatus(pollType, question, actualAnswer, formattedResponse);

        if (status.key === GRADE_BOOK_RESPONSE_STATUS.CORRECT.key) {
            return (
                <YuJaTooltip
                    text={status.description}
                    placement={placement}
                    tooltipStyle={{maxWidth: 400}}
                >
                    <span className={`${styles.generalBadge} ${styles.correctColor}`}>
                        {REAL_SCORE_COLUMN_NAME}: {weightage}
                    </span>
                </YuJaTooltip>
            );
        } else if (status.key === GRADE_BOOK_RESPONSE_STATUS.INCORRECT.key) {
            return (
                <YuJaTooltip
                    text={status.description}
                    placement={placement}
                    tooltipStyle={{maxWidth: 400}}
                >
                    <span className={`${styles.generalBadge} ${styles.inCorrectColor}`}>
                        {REAL_SCORE_COLUMN_NAME}: 0
                    </span>
                </YuJaTooltip>
            );
        } else if (status.key === GRADE_BOOK_RESPONSE_STATUS.NON_GRADABLE.key) {
            return (
                <YuJaTooltip
                    text={status.description}
                    placement={placement}
                    tooltipStyle={{maxWidth: 400}}
                >
                    <span className={`${styles.generalBadge} ${styles.nonGradableColor}`} style={{width: 40}}>
                        <NON_GRADABLE_ICON/>
                    </span>
                </YuJaTooltip>
            );
        } else if (status.key === GRADE_BOOK_RESPONSE_STATUS.UN_GRADED.key) {
            return (
                <YuJaTooltip
                    text={status.description}
                    placement={placement}
                    tooltipStyle={{maxWidth: 400}}
                >
                    <span className={`${styles.generalBadge} ${styles.ungradedColor}`}>
                        {REAL_SCORE_COLUMN_NAME}: 0
                    </span>
                </YuJaTooltip>
            );
        } else if (status.key === GRADE_BOOK_RESPONSE_STATUS.UNANSWERED.key) {
            return (
                <YuJaTooltip
                    text={status.description}
                    placement={placement}
                    tooltipStyle={{maxWidth: 400}}
                >
                    <span className={`${styles.generalBadge} ${styles.unansweredColor}`} style={{width: 40}}>
                        <UN_ANSWERED_ICON/>
                    </span>
                </YuJaTooltip>
            );
        } else if (status.key === GRADE_BOOK_RESPONSE_STATUS.NOT_POSTED.key) {
            return (
                <YuJaTooltip
                    text={status.description}
                    placement={placement}
                    tooltipStyle={{maxWidth: 400}}
                >
                    <span className={`${styles.generalBadge} ${styles.notPostedColor}`} style={{width: 40}}>
                        <NON_POSTED_ICON/>
                    </span>
                </YuJaTooltip>
            );
        } else {
            return <></>;
        }

    }


    const checkAnswerCorrect = () => {
        const {questionType} = selectedQuestion;


        if (selectedQuestionResponseStatus.key === GRADE_BOOK_RESPONSE_STATUS.UN_GRADED.key) {
            return undefined;
        }

        if (selectedQuestionResponseStatus.key === GRADE_BOOK_RESPONSE_STATUS.CORRECT.key) {
            return true;
        }

        if (selectedQuestionResponseStatus.key === GRADE_BOOK_RESPONSE_STATUS.INCORRECT.key) {
            return false;
        }

        if (questionType === QUESTION_TYPES.SA.name) {
            return undefined;
        }

        if (selectedQuestionResponseStatus.key === GRADE_BOOK_RESPONSE_STATUS.UNANSWERED.key) {
            return false;
        }

        return undefined
    }


    const isCorrect = () => {
        const {serialNo} = selectedQuestion;

        if (!!changes
            && changes.hasOwnProperty(`${curUserId}`)
            && changes[curUserId].hasOwnProperty(`${serialNo}`)
            && changes[curUserId][serialNo].hasOwnProperty(`${curAttemptNo}`)
        ) {
            return changes[curUserId][serialNo][curAttemptNo];
        }



        return checkAnswerCorrect();
    }


    const grade = (isCorrect) => {
        const {serialNo} = selectedQuestion;
        if (isCorrect === changes[curUserId]?.[serialNo]?.[curAttemptNo]) {
            return;
        }

        let responseIsCorrect = checkAnswerCorrect();

        let copy = JSON.parse(JSON.stringify(changes));
       if (isCorrect !== responseIsCorrect) {
           setChanges({
               ...copy,
               [curUserId]: {
                   ...copy[curUserId],
                   [serialNo]: {
                       ...copy[curUserId]?.[serialNo],
                       [curAttemptNo]: isCorrect,
                   },
               },
           });
           return;
       }

       if (!!copy[curUserId]?.[serialNo] && copy[curUserId]?.[serialNo].hasOwnProperty(`${curAttemptNo}`)) {
           delete copy[curUserId][serialNo][curAttemptNo];
           if (!Object.keys(copy[curUserId][serialNo]).length) {
               delete copy[curUserId][serialNo];
           }

           if (!Object.keys(copy[curUserId]).length) {
               delete copy[curUserId];
           }

           setChanges(copy);
       }
    };

    const canPrevious = () => {
        return students[0].value !== curUserId;
    }

    const canNext = () => {
        return students[students.length - 1].value !== curUserId;
    }

    const goPrevious = () => {
        let idx = students.map(stu => stu.value).indexOf(curUserId);
        setCurUserId(students[idx - 1].value);
        setCurAttemptNo(1);
    }

    const goNext = () => {
        let idx = students.map(stu => stu.value).indexOf(curUserId);
        setCurUserId(students[idx + 1].value);
        setCurAttemptNo(1);
    }


    const goBack = () => {
        if (Object.keys(changes).length === 0) {
            setOpen(false);
            return;
        }

        setGradeAlertShow(true);
    }


    const onSave = async () => {
        if (!changes || Object.keys(changes).length === 0 ) {
            return;
        }

        setIsSaving(true);
        try {
            let selectedOptions = {};
            for (const user of Object.keys(changes)) {
                let response = responses.find(r => r.userId === user);
                if (!response) {
                    continue;
                }
                for (const questionNo of Object.keys(changes[user])) {
                    for (const attemptNo of Object.keys(changes[user][questionNo])) {
                        selectedOptions = {
                            ...selectedOptions,
                            [user]: {
                                ...selectedOptions[user],
                                [questionNo]: {
                                    ...selectedOptions[user]?.[questionNo],
                                    [attemptNo]: {
                                        // answer: getAnswer(response, attemptNo, questionNo), // Fetch answer for the attempt and question
                                        isCorrect: changes[user][questionNo][attemptNo], // Use correctness information from changedCorrect state
                                    },
                                },
                            },
                        };
                    }
                }
            }
            await gradeQuestionStudents(institutionId, pollKey, pollCode, changes, questions, selectedOptions);
            setChanges({});
            setOpen(false);
            notifySuccess(GRADE_QUESTION_SUCCESS);
            await loadData();
        } catch (error) {
            console.log(error);
            notifyError(GRADE_QUESTION_ERROR);
        } finally {
            setIsSaving(false);
        }
    };


    const renderFooter = () => {
        if (GRADE_BOOK_RESPONSE_STATUS.NOT_POSTED.key === selectedQuestionResponseStatus.key) {
            return (
                <div style={{flex: 1, minWidth: 0}}>
                    <div tabIndex={0} className={styles.regularLabel}>{UNABLE_GRADE_QUESTION_TEXT}</div>
                    <div style={{height: 5}}/>
                    <div tabIndex={0} className={styles.regularText} style={{
                        color: "#6B6B6B",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                    }}>
                        {UNABLE_GRADE_QUESTION_EXPLAIN_TEXT}
                    </div>
                </div>
            );
        }

        if (!selectedQuestion.weightage) {
            return (
                <div style={{flex: 1, minWidth: 0}}>
                    <div tabIndex={0} className={styles.regularLabel}>{NON_GRADABLE_QUESTION_TEXT}</div>
                    <div style={{height: 5}}/>
                    <div tabIndex={0} className={styles.regularText} style={{
                        color: "#6B6B6B",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                    }}>
                        {NON_GRADABLE_QUESTION_EXPLAIN_TEXT}
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <div style={{flex: 1, minWidth: 0}}>
                        <div tabIndex={0} className={styles.regularLabel}>{GRADE_QUESTION_TEXT}</div>
                        <div style={{height: 5}}/>
                        <div
                            tabIndex={0}
                            className={styles.regularText}
                            style={{
                                color: "#6B6B6B",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis"
                            }}
                        >
                            {GRADE_QUESTION_EXPLAIN_TEXT}
                        </div>
                    </div>
                    <YuJaRadioBox
                        value="correct"
                        name="isCorrect"
                        label={"Correct"}
                        onClick={() => grade(true)}
                        checked={isCorrect() === true}
                    />
                    <YuJaRadioBox
                        value="incorrect"
                        name="isCorrect"
                        label={"Incorrect"}
                        onClick={() => grade(false)}
                        checked={isCorrect() === false}
                    />
                </>
            );
        }
    }


    return (
        <GradeQuestionWrapper>
            <YuJaGeneralAlert
                show={alertGradeShow}
                setModalShow={setGradeAlertShow}
                content={UNSAVED_CHANGES_CONTENT_TEXT}
                config={gradeAlertConfig}
            />

            {open &&
                <div ref={parentContainerRef} className={styles.mask}>
                    <div className={styles.parentContainer}>
                        <div className={styles.containerRow} style={{gap: 10}}>
                            <span tabIndex={0} className={styles.goBack} onClick={goBack}>
                                <GO_BACK style={{flexShrink: 0}}/>
                                <span style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>Back</span>
                            </span>

                            <span style={{flex: 1}}/>

                            <YuJaButton
                                onlyIcon={true}
                                disabled={!canPrevious()}
                                onClick={goPrevious}
                                style={{
                                    width: 32, height: 32, marginRight: 10,
                                    display: "flex", alignItems: "center", justifyContent: "center"
                                }}
                            >
                                <ARROW_LEFT_ICON/>
                            </YuJaButton>

                            <YuJaDropdown
                                style={{width: 250}}
                                size={"small"}
                                data={students}
                                value={curUserId}
                                searchAble={true}
                                searchPlaceholder={SEARCH_BY_PARTICIPANT_TEXT}
                                onChange={option => {
                                    setCurUserId(option.value);
                                    setCurAttemptNo(1);
                                }}
                            />

                            <YuJaButton
                                onlyIcon={true}
                                disabled={!canNext()}
                                onClick={goNext}
                                style={{
                                    width: 32, height: 32, marginLeft: 10,
                                    display: "flex", alignItems: "center", justifyContent: "center"
                                }}
                            >
                                <ARROW_RIGHT_ICON/>
                            </YuJaButton>


                            <span style={{flex: 1}}/>
                            {!!hasWeightage(questions) && pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                                <YuJaButton
                                    onlyIcon={true}
                                    disabled={isSaving || !Object.keys(changes).length}
                                    style={{
                                        width: 90,
                                        height: 35,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                    onClick={onSave}
                                >
                                    {isSaving
                                        ?
                                        <img src={loadingGif} alt={"loading..."} style={{height: 20}}/>
                                        :
                                        <>
                                            <SAVE style={{marginRight: 5}}/> Save
                                        </>
                                    }
                                </YuJaButton>
                            }
                        </div>
                        <div className={styles.containerRow} style={{gap: 10}}>
                            {!!curResponse.hasOwnProperty("attempts") && !!Object.keys(curResponse.attempts).length &&
                                <>
                                    <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontWeight: "500"}}>
                                        {STUDENTS_ATTEMPT_LABEL}
                                    </span>
                                    <YuJaDropdown
                                        data={Object.keys(curResponse.attempts).filter(key => key !== '0').map(i => parseInt(i))}
                                        placeholder={"Select an Attempt"}
                                        getOptionLabel={option => "Attempt " + option}
                                        getOptionValue={option => option}
                                        value={curAttemptNo}
                                        onChange={(option) => setCurAttemptNo(option)}
                                        style={{width: 150}}
                                        size={"small"}
                                    />
                                    <div style={{flex: 1}}/>
                                    <span
                                        className={styles.generalBadge}
                                        style={{
                                            width: 140,
                                            backgroundColor: "#F8DCDB",
                                            flexShrink: 0
                                        }}
                                    >
                                        {QUESTIONS_HEADER}: {questions.length}
                                    </span>


                                    {!!hasWeightage(questions) && pollType.toUpperCase() !== POLL_TYPE.SURVEY
                                        && width > RESPONSIVE_SIDE_BAR_POINT &&
                                        <span
                                            className={styles.generalBadge}
                                            style={{
                                                width: "fit-content",
                                                padding: "0 10px",
                                                backgroundColor: "#E4E3F6",
                                                flexShrink: 0
                                            }}
                                        >
                                           {POLL_SCORE_TEXT}: {scored}
                                        </span>
                                    }

                                    {!!hasWeightage(questions) && pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                                        <span
                                            className={styles.generalBadge}
                                            style={{
                                                width: 140,
                                                whiteSpace: "nowrap",
                                                padding: "0 10px",
                                                backgroundColor: "#F9E4F5",
                                                flexShrink: 0
                                            }}
                                        >
                                            {GRADE_TEXT}: {`${percentage}%`}
                                        </span>
                                    }
                                </>
                            }

                            {(!curResponse.hasOwnProperty("attempts") || !Object.keys(curResponse.attempts).length) &&
                                <>
                                    <span style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
                                        Student's attempt
                                    </span>
                                    <YuJaDropdown
                                        data={[1]}
                                        placeholder={"Select an Attempt"}
                                        getOptionLabel={option => "Attempt " + option}
                                        getOptionValue={option => option}
                                        value={curAttemptNo}
                                        onChange={(option) => setCurAttemptNo(option)}
                                        style={{width: 150}}
                                        size={"small"}
                                    />
                                    <div style={{flex: 1}}/>
                                    <span className={styles.generalBadge}
                                          style={{width: 140, backgroundColor: "#F8DCDB", flexShrink: 0}}>
                                        {QUESTIONS_HEADER}: {questions.length}
                                    </span>
                                    {!!hasWeightage(questions) && pollType.toUpperCase() !== POLL_TYPE.SURVEY
                                        && width > RESPONSIVE_SIDE_BAR_POINT &&
                                        <span
                                            className={styles.generalBadge}
                                            style={{
                                                width: "fit-content",
                                                padding: "0 10px",
                                                backgroundColor: "#E4E3F6",
                                                flexShrink: 0
                                            }}
                                        >
                                           {POLL_SCORE_TEXT}: 0/{totalScore}
                                        </span>
                                    }

                                    {!!hasWeightage(questions) && pollType.toUpperCase() !== POLL_TYPE.SURVEY &&
                                        <span
                                            className={styles.generalBadge}
                                            style={{
                                                width: 140,
                                                padding: "0 10px",
                                                backgroundColor: "#F9E4F5",
                                                flexShrink: 0
                                            }}
                                        >
                                            {GRADE_TEXT}: 0%
                                        </span>
                                    }
                                </>
                            }
                        </div>

                        <div className={styles.body}>
                            <div className={styles.questionList}>
                                {questions.map((que, index) => (
                                    <div
                                        tabIndex={0}
                                        key={index}
                                        className={`${styles.questionCard} ${que.serialNo === selectedSerialNo ? styles.active : ""}`}
                                        onClick={() => setSelectedSerialNo(que.serialNo)}
                                    >
                                        <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                                            <span className={styles.questionNoLabel}
                                                  style={{flexShrink: 0}}>Question {que.serialNo}</span>
                                            <div style={{flex: 1}}/>
                                            {RenderScoreBadge(que)}
                                        </div>
                                        <div className={styles.questionType}>{questionCodeToName(que.questionType)}</div>
                                    </div>
                                ))}
                            </div>

                            <div style={{flex: 1, minWidth: 0, display: "flex", flexDirection: "column", height: "100%"}}>
                                <div className={styles.questionMainContainer}>
                                    <div className={styles.questionMainContainerRow} style={{gap: 10}}>
                                        <span
                                            tabIndex={0}
                                            className={styles.questionNoLabel}>{QUESTION} {selectedQuestion.serialNo}</span>
                                        <span style={{flex: 1}}/>
                                        {!!selectedQuestion.weightage &&
                                            <span
                                                tabIndex={0}
                                                className={styles.generalBadge}
                                                style={{
                                                    width: "fit-content",
                                                    padding: "0 10px",
                                                    backgroundColor: "#F8E4DD",
                                                    flexShrink: 0
                                                }}
                                            >
                                               {CUSTOMIZE_POINTS_TEXT}: {selectedQuestion.weightage}
                                            </span>
                                        }
                                        {RenderScoreBadge(selectedQuestion, "left")}
                                    </div>

                                    <span tabIndex={0} className={styles.questionType}>
                                        {questionCodeToName(selectedQuestion.questionType)}
                                    </span>


                                    <span tabIndex={0} className={styles.regularText} style={{display: "flex", gap: 5, alignItems: "center"}}>
                                        <span className={styles.regularLabel} >Question:</span>
                                        {selectedQuestion.questionType !== QUESTION_TYPES.FITB.name &&
                                            <LatexInline>{selectedQuestion.queTitle}</LatexInline>
                                        }

                                        {selectedQuestion.questionType === QUESTION_TYPES.FITB.name &&
                                            <>{selectedQuestion.queTitle.replace(BLANK_REGEX, "______")}</>
                                        }
                                    </span>


                                    {questionImageURL && selectedQuestion.questionType === QUESTION_TYPES.CI.name &&
                                        <div className={`${styles.questionImgContainer}`}>
                                            <div style={{position: "relative"}}>
                                                {!isLoaded &&
                                                    <SkeletonTheme color="rgba(237, 237, 237, 1)"
                                                                   highlightColor="lightgrey">
                                                        <div >
                                                            <Skeleton borderRadius={10} height={150} width={200}/>
                                                        </div>
                                                    </SkeletonTheme>
                                                }

                                                <img
                                                    style={{maxWidth: "100%", maxHeight: "100%", visibility: isLoaded ? undefined : "hidden"}}
                                                    tabIndex={0}
                                                    aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}
                                                    src={questionImageURL}
                                                    alt="Question"
                                                    onLoad={onLoad}
                                                />

                                                {isLoaded && selectedQuestion.optionsMap.map((area, idx) =>
                                                    <div key={idx} style={{
                                                        width: decodeCIParam(area.width, imageWidth),
                                                        height: decodeCIParam(area.height, imageHeight),
                                                        left: decodeCIParam(area.x, imageWidth),
                                                        top: decodeCIParam(area.y, imageHeight),
                                                        position: "absolute",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        border: "solid #009883 1.5px",
                                                        borderRadius: "4px",
                                                        color: "#009883",
                                                        fontWeight: "400",
                                                        fontSize: "15px",
                                                        backgroundColor: "rgba(246, 255, 254, 0.9)",
                                                        boxShadow: "0px 0px 5px 0px #00000026"
                                                    }}>
                                                        {String.fromCharCode(ASCII_CODE_A + idx)}
                                                    </div>
                                                )}
                                            </div>


                                        </div>
                                    }

                                    {questionImageURL && selectedQuestion.questionType !== QUESTION_TYPES.CI.name && !isVideo &&
                                        <div className={`${styles.questionImgContainer}`}>
                                            {!isLoaded &&
                                                <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                                                    <Skeleton borderRadius={10} height={150} width={200} />
                                                </SkeletonTheme>
                                            }

                                            {!isVideo &&
                                                <img
                                                    style={{maxWidth: "100%", maxHeight: "100%", display: isLoaded ? undefined : "none"}}
                                                    tabIndex={0}
                                                    aria-label={selectedQuestion.imageAlt ? selectedQuestion.imageAlt : DEFAULT_IMAGE_ALT}
                                                    src={questionImageURL}
                                                    alt="Question"
                                                    onLoad={onLoad}
                                                />
                                            }
                                        </div>
                                    }

                                    {!!questionImageURL && !!isVideo &&
                                        <>
                                            {!isLoaded &&
                                                <SkeletonTheme color="rgba(237, 237, 237, 1)"
                                                               highlightColor="lightgrey">
                                                    <Skeleton borderRadius={10} height={150} width={200}/>
                                                </SkeletonTheme>
                                            }
                                            <iframe allowFullScreen src={questionImageURL} width={350} height={250}
                                                    style={{display: isLoaded ? undefined : "none"}}
                                                    onLoad={onLoad}/>
                                        </>
                                    }

                                    {renderAnswer()}

                                    {renderGradeCorrection()}
                                </div>

                                <div className={styles.questionMainContainerFooter}>
                                    {renderFooter()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </GradeQuestionWrapper>
    );
}


const GradeQuestionWrapper = ({children}) => {
    const [wrapper, ] = useState(() => {
        const div = document.createElement("div");
        div.id = `YuJa_Grade_Question_${Date.now().toString()}`;
        return div;

    });
    useEffect(() => {
        const rootEle = document.getElementById('root');
        rootEle.appendChild(wrapper);

        return () => {
            if (wrapper) {
                wrapper.remove();
            }
        }
    }, []);



    return ReactDOM.createPortal(children, wrapper);

}
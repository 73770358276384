import React, {useEffect} from "react";
import { Draggable } from "react-beautiful-dnd";
import DragDropIcon from "../../images/drag_drop.svg";
import {
  CHARACTERS_TEXT,
  DRAG_TITLE,
  LATEX_REG_EXP,
  MC_ANSWER_LENGTH,
  PREMISE,
} from "../../utils/constants";
import { stripHTMLTags} from "../../utils/helpers";
import "../styles.css";
import { PremiseRadio } from "./PremiseRadio";
import {convertMHOptionMap} from "../../utils/questionUtils";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";
import {EditorContent, useEditor} from "@tiptap/react";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";
import { menuBarExpandedStyle, TipTapMenuBar} from "../../components/Tiptap/TipTapMenuBar";
import classes from "../../components/Tiptap/Tiptap.module.css";
import {Color} from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";

export default function Premise({
  optionData,
  selectedQuestion,
  questions,
  setQuestions,
  index,
  questionBuilder,
  disabled
}) {
  const {isNarrow} = useCheckMainContainerNarrow();
  const { serialNo, } = selectedQuestion;
  const selectedQuestionIndex = serialNo - 1;

  const handleAnswerChange = (input, index) => {
    let copyOfQuestions = JSON.parse(JSON.stringify(questions));
    let newCorrectAnswers = copyOfQuestions[selectedQuestionIndex].correctAnswers;
    newCorrectAnswers[index][0] = input;
    if (!!questionBuilder) {
      copyOfQuestions[selectedQuestionIndex].optionsMap = convertMHOptionMap(newCorrectAnswers);
    }
    setQuestions(copyOfQuestions);
  };


  const editor = useEditor({
    shouldRerenderOnTransaction: true,
    editorProps: {
      transformPastedHTML(text) {
        return stripHTMLTags(text);
      },
    },
    extensions: [
      Underline,
      StarterKit,
      Placeholder.configure({
        placeholder:  PREMISE + " "  + (index + 1),
      }),
      CharacterCount.configure({limit: MC_ANSWER_LENGTH}),
      TextStyle,
      Color,
      Highlight,
      // Mathematics.configure({
      //   regex: LATEX_REG_EXP,
      // }),
    ],
    onUpdate: ({editor}) => {
      handleAnswerChange(editor.getHTML(), index);
    },
    content: !!optionData && !!optionData[0] ? optionData[0] : "",
  }, [serialNo]);

  useEffect(() => {
    if (!!optionData && optionData[0] !== editor.getHTML()) {
      editor.commands.setContent(optionData[0]);
    }

  }, [optionData, editor]);


  return (optionData.length > 1 &&
    <div tabIndex={0} style={{display: "flex", width: "100%"}}>
      <Draggable draggableId={"premise" + index} index={index} key={"premise" + index}
                 isDragDisabled={questionBuilder && disabled}>
        {(provided, snapshot) => (
            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}
                 style={{width: "100%", ...provided.draggableProps.style}}>
              <div style={{display: "flex", flex: 1, alignItems: "center"}}>
                <div
                    {...provided.dragHandleProps}
                    style={{
                      marginRight: 10,
                      display: "flex",
                      justifyContent: "center"
                    }}
                    title={(questionBuilder && disabled) ? "" : DRAG_TITLE}
                    tabIndex={-1}
                >
                  {(!questionBuilder || !disabled) &&
                      <img src={DragDropIcon} alt="drag drop icon"/>}
                </div>
                <PremiseRadio
                    index={index}
                />
                <span
                    style={{
                      flex: 1,
                      minWidth: 0,
                      position: "relative",
                      border: "1px solid #858687",
                      borderRadius: 3,
                      display: "flex",
                      flexDirection: "column",
                      background: "#FFF"
                    }}
                >
                  <TipTapMenuBar
                      editor={editor}
                      style={menuBarExpandedStyle}
                  />
                  <EditorContent editor={editor} style={{cursor: "text"}} className={classes.editorContentBox}/>
                          {!!editor.storage.characterCount.characters() &&
                              <span className={classes.characterCountBox}>
                        {editor.storage.characterCount.characters()} {CHARACTERS_TEXT}
                      </span>
                          }
                </span>


                {/*<YuJaTextBox*/}
                {/*    disabled={questionBuilder && disabled}*/}
                {/*    name={"question-text"}*/}
                {/*    label={PREMISE_SELECTED + (index + 1)}*/}
                {/*    containerStyle={{width: "100%", fontSize: 16}}*/}
                {/*    placeholder={`Premise ${index + 1}`}*/}
                {/*    value={optionData[0]}*/}
                {/*    maxLength={MC_ANSWER_LENGTH}*/}
                {/*    onChange={(e) => {*/}
                {/*      if (e.target.value.length <= MC_ANSWER_LENGTH) {*/}
                {/*        handleAnswerChange(e.target.value, index);*/}
                {/*      } else {*/}
                {/*        notifyError(MC_CI_ANSWER_LENGTH_EXCEEDED);*/}
                {/*        handleAnswerChange(e.target.value.substring(0, MC_ANSWER_LENGTH), index);*/}
                {/*      }*/}
                {/*    }}*/}
                {/*    showRemaining={true}*/}
                {/*/>*/}
              </div>
            </div>
        )}
      </Draggable>
    </div>
  );
}
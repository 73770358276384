import parse from "html-react-parser";
import reactStringReplace from "react-string-replace";
import {stripHTMLTags} from "../../utils/helpers";
import classes from "./LatexParser.module.css"
import {LATEX_REG_EXP} from "../../utils/constants";
import katex from "katex";


const cleanMathHTML = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    doc.body.querySelectorAll("*").forEach((node) => {
        let nodeText = node.textContent.trim();
        if (/^\$latex\s([^$]+)\$$/.exec(nodeText)) {
            node.replaceWith(document.createTextNode(nodeText));
        }
    });

    return doc.body.innerHTML.trim();
}

export const LatexHTMLInline = ({children}) => {
    if (!children) {
        return <></>;
    }

    let text = cleanMathHTML(children);


    let replace = reactStringReplace(text, LATEX_REG_EXP, (exp, i) => {
        let elementStr = "";
        try {
            elementStr = katex.renderToString(stripHTMLTags(exp));
        } catch (e) {
            elementStr = stripHTMLTags(exp);
        }
        return elementStr;
    });

    return (
        <span className={classes.latexContainer}>
            {parse(replace.join(' '))}
        </span>
    );
}

export const LatexInline = ({children}) => {
    if (!children) {
        return <></>;
    }

    let text = cleanMathHTML(children);

    return reactStringReplace(stripHTMLTags(text), LATEX_REG_EXP, (exp, i) => {
        let elementStr = "";
        try {
            elementStr = katex.renderToString(exp);
        } catch (e) {
            elementStr = exp;
        }
        return parse(elementStr);
    });

}


export const convertToPlainString = (text) => {
    if (!text) {
        return "";
    }

    return reactStringReplace(cleanMathHTML(text), LATEX_REG_EXP, (exp, i) => {
        return exp;
    }).join('');
}
import {useState} from "react";
import {Filter} from "bad-words";
import {checkUnansweredNew} from "../utils/helpers";
import {QUESTION_TYPES} from "../utils/constants";

export const useProfanityFilter = (placeHolder="*") => {
    const [wordsFilter, ] = useState(new Filter());

    const filter = (text="") => {
        // wordsFilter.
        wordsFilter.placeHolder = placeHolder;
        return wordsFilter.clean(text);
    }

    const checkResponseProfane = (questionType, selectedOption) => {
        if (checkUnansweredNew(questionType, selectedOption)) {
            return null;
        }

        let hasProfane = false;
        wordsFilter.placeHolder = placeHolder;
        let copy = JSON.parse(JSON.stringify(selectedOption));
        if (questionType === QUESTION_TYPES.SA.name || questionType === QUESTION_TYPES.OE.name) {
            let cleanedText = wordsFilter.clean(copy["0"]);
            if (cleanedText !== copy["0"]) {
                hasProfane = true;
                copy["0"] = cleanedText;
            }
        } else if (questionType === QUESTION_TYPES.WC.name || questionType === QUESTION_TYPES.FITB.name) {
            for (const key of Object.keys(copy)) {
                let cleanedText = wordsFilter.clean(copy[key]);
                if (cleanedText !== copy[key]) {
                    hasProfane = true;
                    copy[key] = cleanedText;
                }
            }

        }

        if (hasProfane) {
            return copy;
        } else {
            return null;
        }

    }

    return {filter, checkResponseProfane};
}
import React, { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useMobileAccess from "../../hooks/useMobileAccess";
import {TEXTBOX} from "../../utils/constants";
import "./styles.css";
import styles from "./styles.module.css";
import classes from "./ClassResult.module.css";
import classResultClasses from "./ClassResult.module.css";
import {LatexHTMLInline} from "../../components/Tiptap/latexParser";

export default function ClassResultRankNew({question, chartData, isLoading=false, isExpanded}) {
    const {serialNo} = question;
    const [maxData, setMaxData] = useState(0);
    const [sortedKeys, setSortedKeys] = useState([]);
    const [sortedValues, setSortedValues ]  = useState([]);
    const [containerHeight, setContainerHeight] = useState(0);
    const isMobile = useMobileAccess(480);
    useEffect(() => {
        if (chartData) {
            const sortedEntries= Object.entries(chartData)
            .sort(([, valueA], [, valueB]) => valueB - valueA);
            const newsortedKeys = [];
            const newsortedValues = [];
            for (const [key, value] of sortedEntries) {
                newsortedKeys.push(key);
                newsortedValues.push(value);
            }
            setSortedKeys(newsortedKeys);
            setSortedValues(newsortedValues);

            setMaxData(newsortedValues.length > 0 ? newsortedValues[0] : 0);
        }
       
    }, [chartData]);

    const setBar = () => {
        sortedValues.forEach((entry, index) => {
            let bar = document.getElementById(`rk-${serialNo}-count-bar-${index}`);
            if (!!bar) {
                bar.style.width = `${!!maxData && maxData !== 0 ? (entry / maxData) * 100 : 0}%`;
            }
        });
    }

    const resetBar = () => {
        sortedValues.forEach((entry, index) => {
            let bar = document.getElementById(`rk-${serialNo}-count-bar-${index}`);
            if (!!bar) {
                bar.style.width = "0";
            }
        });
    }

    useEffect(() => {
        let height = 0;

        if (!!isLoading) {
            height = 300;
        } else if (!!sortedKeys) {
            height = sortedKeys.length * 120;
        } else {
            height = 300;
        }

        setContainerHeight(height);
    }, [isLoading, sortedKeys]);

    useEffect(() => {
        if (isExpanded) {
            setBar();
        } else {
            resetBar();
        }
    }, [isExpanded]);





    return (
        <div className={classResultClasses.chartContainer} style={{maxHeight: !isExpanded ? 0 : containerHeight}}>
            <div className={classResultClasses.chartContainerBody}>
                {!!chartData && !isLoading &&
                    <div className={styles.chartContainer} style={{marginTop: 0}}>
                        {sortedKeys.map((entry, index) =>
                            <div key={index} className={classes.countBarContainer} style={{border: "none", boxShadow: "none"}}>
                                <div
                                    tabIndex={isExpanded ? 0 : -1}
                                    role={TEXTBOX}
                                    aria-label={`Rank ${index + 1}` + entry}
                                    className={classes.optionIndex}
                                    style={{width: 70}}
                                > Rank {index + 1}</div>
                                <div id={`optionText${index}`} tabIndex={isExpanded ? 0 : -1} role={TEXTBOX}  style={{zIndex: 1}}>
                                    <LatexHTMLInline>{entry}</LatexHTMLInline>
                                </div>
                                <div id={`rk-${serialNo}-count-bar-${index}`} className={classes.countBar}/>
                            </div>
                        )}

                    </div>
                }

                {isLoading && Array.from({ length: 4 }).map((_, rowIndex) =>
                    <div key={rowIndex} className={styles.optionContainer}>
                        <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
                            <Skeleton height={60} borderRadius={4} width= {isMobile ? "80vw" : "50vw"}/>
                        </SkeletonTheme>
                    </div>
                )}
            </div>
        </div>
    );
}
import React, {useEffect, useState} from "react";
import { Draggable } from "react-beautiful-dnd";

import DragDropIcon from "../../images/drag_drop.svg";
import {
  CHARACTERS_TEXT,
  DRAG_TITLE,
  LATEX_REG_EXP,
  MC_ANSWER_LENGTH, RESPONSE_TITLE,
} from "../../utils/constants";
import { stripHTMLTags} from "../../utils/helpers";

import "../styles.css";
import {convertMHOptionMap} from "../../utils/questionUtils";
import {EditorContent, useEditor} from "@tiptap/react";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";
import {menuBarExpandedStyle, TipTapMenuBar} from "../../components/Tiptap/TipTapMenuBar";
import classes from "../../components/Tiptap/Tiptap.module.css";
import {Color} from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";

export default function Response({
  optionData,
  selectedQuestion,
  questions,
  setQuestions,
  index,
  questionBuilder,
  disabled
}) {
  const { serialNo, correctAnswers } = selectedQuestion;
  const selectedQuestionIndex = serialNo - 1;


  const handleAnswerChange = (input, index) => {
    let copyOfQuestions = JSON.parse(JSON.stringify(questions));
    let newCorrectAnswers = copyOfQuestions[selectedQuestionIndex].correctAnswers;
    if (newCorrectAnswers[index].length > 1) {
      newCorrectAnswers[index][1] = input;
    } else {
      newCorrectAnswers[index][0] = input;
    }
    copyOfQuestions[selectedQuestionIndex].correctAnswers = newCorrectAnswers;
    if (!!questionBuilder) {
      copyOfQuestions[selectedQuestionIndex].optionsMap = convertMHOptionMap(newCorrectAnswers);
    }
    setQuestions(copyOfQuestions);
  };


  const editor = useEditor({
    shouldRerenderOnTransaction: true,
    editorProps: {
      transformPastedHTML(text) {
        return stripHTMLTags(text);
      },
    },
    extensions: [
      Underline,
      StarterKit,
      Placeholder.configure({
        placeholder:  RESPONSE_TITLE + " "  + (index + 1),
      }),
      CharacterCount.configure({limit: MC_ANSWER_LENGTH}),
      TextStyle,
      Color,
      Highlight,
      // Mathematics.configure({
      //   regex: LATEX_REG_EXP,
      // }),
    ],
    onUpdate: ({editor}) => {
      handleAnswerChange(editor.getHTML(), index);
    },
    content: !!optionData ? (correctAnswers[index].length > 1 ? optionData[1]: optionData[0]) : "",
  }, [serialNo]);

  useEffect(() => {
    let val = optionData ? (correctAnswers[index].length > 1 ? optionData[1]: optionData[0]) : "";
    if (val !== editor.getHTML()) {
      editor.commands.setContent(val);
    }

  }, [correctAnswers, optionData, editor]);



  return (
    <div tabIndex={0} style={{display: "flex", width: "100%", justifyContent: "end"}}>
    <Draggable draggableId={"response" + index} index={index} key={"response" + index} isDragDisabled={questionBuilder && disabled}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1} style={{width: "100%", ...provided.draggableProps.style} }>
          <div
              style={{display: "flex", flex: 1, alignItems: "center"}}
          >
            <div
                {...provided.dragHandleProps}
                style={{display: "flex", justifyContent: "center", marginRight: 10}}
                title={(questionBuilder && disabled) ? "" : DRAG_TITLE}
                tabIndex={-1}
            >
              {(!questionBuilder || !disabled) && <img src={DragDropIcon} alt="drag drop icon"/>}
            </div>


            <span
                style={{
                  flex: 1,
                  minWidth: 0,
                  position: "relative",
                  border: "1px solid #858687",
                  borderRadius: 3,
                  display: "flex",
                  flexDirection: "column",
                  background: "#FFF"
                }}
            >
              <TipTapMenuBar
                  editor={editor}
                  style={menuBarExpandedStyle}
              />
              <EditorContent editor={editor} style={{cursor: "text"}} className={classes.editorContentBox}/>
              {!!editor.storage.characterCount.characters() &&
                  <span className={classes.characterCountBox}>
                        {editor.storage.characterCount.characters()} {CHARACTERS_TEXT}
                      </span>
              }
            </span>
          </div>
        </div>
      )}
    </Draggable>
    </div>
  );
}
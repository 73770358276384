import "antd/dist/antd.min.css";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AuthenticationApiCalls } from "../../apis/AuthenticationApiCalls";
import { PollApiCalls } from "../../apis/PollApiCalls";
import "../../components/buttons/genButton.css";
import YuJaGeneralAlert from "../../components/modals/YuJaGeneralAlert";
import YuJaButton from "../../components/standardization/YuJaButton";
import { Spin } from "../../components/standardization/YuJaLoading";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import useMobileAccess from "../../hooks/useMobileAccess";
import BaseQuestionTitle from "../../questions/BaseQuestionTitle";
import SAQuestion from "../../questions/SA/SAQuestion";
import { useLoading } from "../../utils/LoadingContext";
import {
    ATTENDANCE_DEFAULT_QUESTION_TITLE,
    ATTENDANCE_DEFAULT_TITLE,
    ATTENDANCE_NAVBAR,
    ATTENDANCE_TITLE_PLACEHOLDER,
    AUDIENCE_RESTRICTION,
    CREATE_ATTENDANCE_LABEL,
    CREATE_POLL_PATH,
    MENU_LIST,
    DATE_TO_REPLACE,
    DEFAULT_ATTENDANCE_MANUAL_END,
    DEFAULT_ATTENDANCE_SAVED_LOCATIONS,
    DEFAULT_ATTENDANCE_TIME_LIMIT,
    DEFAULT_POLL_REQUIRED_NAME,
    DEFAULT_PPT_AUTO_SHARE,
    GRADED_POLL,
    POLL_SHARE_MODE,
    POLL_TITLE_LENGTH,
    POLL_TYPE,
    QUESTION_LENGTH,
    QUESTION_TYPES,
    SAVE_CLOSE_BUTTON_TEXT,
    SWITCH_POLL_MESSAGE,
    SWITCH_POLL_MODAL_TEXT,
    SWITCH_POLL_MODAL_TITLE,
    USER_SETTINGS,
    POLL_TIME_LIMIT_MODAL_CANCEL
} from "../../utils/constants";
import {getDefaultUserSettings, notifyError, stripHTMLTags, updateCreateMode, validateText} from "../../utils/helpers";
import { updateTitleInput } from "../../utils/questionUpdate";
import { addQuestion, chooseQuestion, questionError } from "../../utils/questionUtils";
import { ATTENDANCE_TITLE_CHARACTER_LIMIT_EXCEEDED, ATTENDANCE_TITLE_ERROR, QUESTION_CHARACTER_LIMIT_EXCEEDED, QUESTION_MAX_POINTS_ERROR } from "../../utils/toast-message-constants";
import "./styles.css";
import {useToaster} from "react-hot-toast";

export default function CreateAttendancePage({ setPageIdentifier }) {
    const { loading } = useLoading();
    const history = useHistory();
    const location = useLocation();
    const isNarrow = useMobileAccess(1100);
    const {toasts} = useToaster();

    const { saveAndPublish, fakeCall } = PollApiCalls();
    const { logout } = AuthenticationApiCalls();

    const pollKey = useRef("");

    const [, getSession] = useLocalStorageNew("session", {});
    const session = getSession();
    const [setPollTitle, getPollTitle] = useLocalStorageNew("attendanceTitle", "");
    const [setQues, getQues] = useLocalStorageNew("attendanceQuestions", []);
    // const [setType, _] = useLocalStorageNew("pollType", "");
    const [setSaveExit, getsaveExit] = useLocalStorageNew("saveExit", false);
    const [setRecentQuestions, getRecentQuestions] = useLocalStorageNew("recentAttendanceQuestions", []);
    const [setRecentTitle, getRecentTitle] = useLocalStorageNew("recentAttendanceTitle", "");
    const [setRecentGeofence, getRecentGeofence] = useLocalStorageNew("recentGeofence", {});
    const [setRecentSyncLms, getRecentSyncLms] = useLocalStorageNew("recentSyncLms", true);

    // const hostResource = useHostname(window.location.hostname);
    // const { institutionId = "" } = hostResource;
    const [title, setTitle] = useState("");
    // const [pollType, setPollType] = useState(POLL_TYPE.ATTENDANCE);
    const [questions, setQuestions] = useState([]);
    const [userSettings, setUserSettings] = useState(getDefaultUserSettings());

    const [focusSAElem, setFocusSAElem] = useState(-1);
    // const [focusMCElem, setFocusMCElem] = useState(-1);
    // const [focusTFElem, setFocusTFElem] = useState(-1);
    // const [focusFITBElem, setFocusFITBElem] = useState(-1);
    // const [focusCIElem, setFocusCIElem] = useState(-1);
    // const [focusMHElem, setFocusMHElem] = useState(-1);
    // const [focusRKElem, setFocusRKElem] = useState(-1);
    // const [focusWCElem, setFocusWCElem] = useState(-1);

    const [disableSave, setDisableSave] = useState(false);

    const [isConfirmationPending, setIsConfirmationPending] = useState(false);
    const [alertShow, setAlertShow] = useState(false);
    const [nextRouterPath, setNextRouterPath] = useState({ pathname: '', state: null });
    const [alertContent,] = useState(SWITCH_POLL_MESSAGE);

    const [currQueDisplay, _] = useState(1);
    const [syncLms, setSyncLms] = useState(true);
    const [geofence, setGeofence] = useState({});
    const [mapInitialized, setMapInitialized] = useState(false);

    if (questions.length < 1) {
        if (title === "") {
            setTitle(ATTENDANCE_DEFAULT_TITLE.replace(DATE_TO_REPLACE, new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })));
        }

        const { settings = {} } = session;
        if (!settings.hasOwnProperty(USER_SETTINGS.POLL_REQUIRED_NAME)) {
            settings[USER_SETTINGS.POLL_REQUIRED_NAME] = DEFAULT_POLL_REQUIRED_NAME;
        } else {
            settings[USER_SETTINGS.POLL_REQUIRED_NAME] = (settings[USER_SETTINGS.POLL_REQUIRED_NAME] === "true" || settings[USER_SETTINGS.POLL_REQUIRED_NAME] === true);
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_MANUAL_END)) {
            settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = DEFAULT_ATTENDANCE_MANUAL_END;
        } else {
            settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] = (settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === "true" || settings[USER_SETTINGS.ATTENDANCE_MANUAL_END] === true);
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_DURATION)) {
            settings[USER_SETTINGS.ATTENDANCE_DURATION] = DEFAULT_ATTENDANCE_TIME_LIMIT;
        } else {
            settings[USER_SETTINGS.ATTENDANCE_DURATION] = parseInt(settings[USER_SETTINGS.ATTENDANCE_DURATION], 10);
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE)) {
            settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] = DEFAULT_PPT_AUTO_SHARE;
        } else {
            settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] = (settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] === "true" || settings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE] === true);
        }
        if (!settings.hasOwnProperty(USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS)) {
            settings[USER_SETTINGS.ATTENDANCE_SAVED_LOCATIONS] = DEFAULT_ATTENDANCE_SAVED_LOCATIONS;
        }
        setUserSettings(settings);

        const qs = addQuestion([], QUESTION_TYPES.SA.name, settings, false, POLL_TYPE.ATTENDANCE);
        // qs[0].queTitle = ATTENDANCE_DEFAULT_QUESTION_TITLE;
        // qs[0].timeLimit = settings[USER_SETTINGS.ATTENDANCE_DURATION];
        // qs[0].getReadyTimeLimit = 0; // no get ready time for attendance
        setQuestions(qs);
        // setCurrQueDisplay(qs[0].serialNo);
        setGeofence({});
        setSyncLms(true);
    }

    useEffect(() => {
        setPageIdentifier(MENU_LIST.CREATE.name + ATTENDANCE_NAVBAR);
        updateCreateMode(POLL_TYPE.ATTENDANCE);
    }, []);

    const checkChanged = () => {
        const curr_ques = JSON.stringify(getRecentQuestions());
        const curr_saved = JSON.stringify(getQues());
        const defaultCorrectAnswerAttendance = '[""]';
        const defaultGeofence = "{}";
        const defaultSyncLms = true;


        const defaultTitle = ATTENDANCE_DEFAULT_TITLE.replace(DATE_TO_REPLACE, new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }));
        if (getRecentQuestions().length === 0 ||
            (getRecentTitle() ===  defaultTitle &&
                stripHTMLTags(getRecentQuestions()[0].queTitle) === ATTENDANCE_DEFAULT_QUESTION_TITLE &&
                JSON.stringify(getRecentQuestions()[0].correctAnswers) === defaultCorrectAnswerAttendance &&
                JSON.stringify(getRecentGeofence()) === defaultGeofence &&
                getRecentSyncLms() === defaultSyncLms)) {
            console.log("curr_saved is empty and recent is default");
            return false;
        } else if (curr_saved === "[]") {
            console.log("curr_saved is empty");
            return true;
        } else {
            console.log(curr_ques, curr_saved, !(curr_ques === curr_saved));
            return !(curr_ques === curr_saved);
        }
    };

    const alertConfig = {
        title: SWITCH_POLL_MODAL_TITLE,
        okText: SWITCH_POLL_MODAL_TEXT,
        cancelText: POLL_TIME_LIMIT_MODAL_CANCEL,
        submit: async () => {
            setAlertShow(false);
            setIsConfirmationPending(true);
        },
        close: async () => {
            setAlertShow(false);
        },
        buttonWidth: "90px"
    };

    useEffect(() => {
        setRecentQuestions(questions);
    }, [questions]);

    useEffect(() => {
        setRecentTitle(title);
    }, [title]);

    useEffect(() => {
        setRecentGeofence(geofence);
    }, [geofence]);

    useEffect(() => {
        setRecentSyncLms(syncLms);
    }, [syncLms]);

    useEffect(() => {
        const unblock = history.block((location, action) => {
            if (!checkChanged() || getsaveExit() === true) {
                setSaveExit(false);
                resetPoll();
                if (location && location.state && location.state.logout) {
                    logout();
                  }
                return true;
            }
            if (location.pathname && !isConfirmationPending) {
                setNextRouterPath({ pathname: location.pathname, state: location.state });
                setAlertShow(true);
                return false;
            }
            return true;
        });

        if (isConfirmationPending) {
            resetPoll();
            if (nextRouterPath.state && nextRouterPath.state.logout) {
                logout();
            } else {
                history.push(nextRouterPath.pathname, nextRouterPath.state);
                setIsConfirmationPending(false);
            }
            unblock();
        }

        return () => {
            unblock();
        };
    }, [history, isConfirmationPending, nextRouterPath]);

    useEffect(() => {
        setTimeout(() => {
            switch (questions[currQueDisplay - 1].questionType) {
                case QUESTION_TYPES.SA.name:
                    if (focusSAElem !== -1) {
                        document.getElementsByName("question-text")[focusSAElem].focus();
                        setFocusSAElem(-1);
                    }
                    break;
            }
        }, 500);
    }, [currQueDisplay, focusSAElem]); // focusMCElem, focusTFElem, focusFITBElem, focusCIElem, focusMHElem, focusWCElem, focusRKElem

    const validatePoll = (focusError = true) => {
        if (!validateText(title, POLL_TITLE_LENGTH)) {
            if (focusError) {
                notifyError(ATTENDANCE_TITLE_ERROR);
                document.getElementsByName("pollTitle")[0].focus();
            }
            return false;
        }

        let i = 0;
        for (let q of questions) {
            i++;
            if (q.weightage > 1000) {
                if (focusError) {
                    let errorWithQuestion = "Question " + i + ": " + QUESTION_MAX_POINTS_ERROR;
                    notifyError(errorWithQuestion);
                    // setCurrQueDisplay(q.serialNo);
                }
                return false;
            }
        }

        let index = 0;
        for (let q of questions) {
            index++;
            // if (focusError) setCurrQueDisplay(q.serialNo);
            let { quesError, elemIndex } = questionError(q, POLL_TYPE.ATTENDANCE);
            switch (q.questionType) {
                case QUESTION_TYPES.SA.name:
                    if (focusError) {
                        setFocusSAElem(elemIndex);
                    }
                    break;
            }

            if (quesError) {
                if (focusError) {
                    setQuestions(chooseQuestion(q.serialNo, questions));
                    notifyError(quesError);
                    return false;
                }
            }
        }
        return true;
    };

    const savePollClose = async () => {
        setDisableSave(true);
        setSaveExit(true);
        if (!validatePoll()) {
            setDisableSave(false);
            return;
        }

        let pollToAdd = {
            pollType: POLL_TYPE.ATTENDANCE,
            questions: questions,
            pollTitle: title,
            pollShareMode: POLL_SHARE_MODE.UNKNOWN,
            geofence: geofence,
            syncLms: syncLms,
            audienceRestriction: userSettings[USER_SETTINGS.POLL_REQUIRED_NAME] ? AUDIENCE_RESTRICTION.NAME_REQUIRED : AUDIENCE_RESTRICTION.UNRESTRICTED,
            manualEnd: userSettings[USER_SETTINGS.ATTENDANCE_MANUAL_END],
            attendanceDuration: userSettings[USER_SETTINGS.ATTENDANCE_DURATION],
            pptAutoShare: userSettings[USER_SETTINGS.ATTENDANCE_PPT_AUTO_SHARE]
        };

        if (!!pollKey.current) {
            pollToAdd.pollKey = pollKey.current;
        }

        let result = await saveAndPublish(pollToAdd);
        if (!result) {
            setDisableSave(false);
            return;
        }

        setDisableSave(false);
        window.onbeforeunload = undefined;
        history.push('/home');
    };

    useEffect(() => {
        return () => {
            window.onbeforeunload = undefined;
        };
    }, []);

    const resetPoll = () => {
        pollKey.current = "";
        setDisableSave(false);
        const newTitle = ATTENDANCE_DEFAULT_TITLE.replace(DATE_TO_REPLACE, new Date().toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' }));
        // console.log("resetting attendance title", newTitle);
        setPollTitle(newTitle);
        setTitle(newTitle);
        const newQs = addQuestion([], QUESTION_TYPES.SA.name, userSettings, false, POLL_TYPE.ATTENDANCE);
        // console.log("resetting questions", newQs);
        setQuestions(newQs);
        setQues(newQs);
        // setType("");
        setGeofence({});
        setSyncLms(true);
    };

    useEffect(async () => {
        if (!history.location.state || !history.location.state.previous || history.location.state.previous !== location.pathname) {
            resetPoll();
            if (history.location.state && history.location.state.pollType) {
                console.log("resetting poll type", history.location.state.pollType);
                history.replace({
                    pathname: CREATE_POLL_PATH,
                    state: { pollType: history.location.state.pollType, fromNavbar: true, previous: CREATE_POLL_PATH }
                });
            } else {
                console.log("resetting to default poll type", GRADED_POLL)
                history.replace({
                    pathname: CREATE_POLL_PATH,
                    state: { pollType: GRADED_POLL, fromNavbar: true, previous: CREATE_POLL_PATH }
                })
            };
        }
        const ques = getQues();
        if (JSON.stringify(ques) !== "[]") {
            setQuestions(ques);
        }
        // const questitle = getPollTitle(); // changing poll type or creating new poll/survey should not retain title
        // if (questitle != "") {
        //   setTitle(questitle);
        // }
        const pollTitle = getPollTitle();
        if (pollTitle && pollTitle != "") {
            setTitle(pollTitle);
        }
        // const showAnswer = getShowAnswer();
        // setShowAnswerOnViewer(showAnswer);
        if (!pollKey) {
            fakeCall().then();
        }
    }, []);

    const handleTitleUpdate = (val, plainVal) => {
        if (plainVal.length <= QUESTION_LENGTH) {
            setQuestions(updateTitleInput(val, currQueDisplay - 1, questions));
        } else {
            let toastObj = toasts.find(toastObj => toastObj.message === QUESTION_CHARACTER_LIMIT_EXCEEDED);
            if (!toastObj) {
                notifyError(QUESTION_CHARACTER_LIMIT_EXCEEDED);
            }
            // setQuestions(updateTitleInput(val.substring(0, QUESTION_LENGTH), indexToChange, questions));
        }
    };

    return (
        <>
            <YuJaGeneralAlert
                show={alertShow}
                setModalShow={setAlertShow}
                content={alertContent}
                config={alertConfig}
            />

            <Spin size="large" wrapperClassName="globalSpin" spinning={loading} tip="Loading...">
                <div className="question-edit-view-parent-container">
                    {(questions.length > 0) && <>
                        <YuJaTextBox
                            label={CREATE_ATTENDANCE_LABEL}
                            containerStyle={{
                                color: "#006DC7",
                                fontSize: isNarrow ? "18px" : "20px",

                                width: "auto",
                                padding: isNarrow ? 8 : 12,
                                fontWeight: 400,
                                borderRadius: 5
                            }}
                            placeholder={ATTENDANCE_TITLE_PLACEHOLDER}
                            inputAlign={"center"}
                            name={"pollTitle"}
                            value={title}
                            maxLength={POLL_TITLE_LENGTH}
                            onChange={(e) => {
                                if (e.target.value.length <= POLL_TITLE_LENGTH) {
                                    setTitle(e.target.value);
                                } else {
                                    notifyError(ATTENDANCE_TITLE_CHARACTER_LIMIT_EXCEEDED);
                                    setTitle(e.target.value.substring(0, POLL_TITLE_LENGTH));
                                }
                            }}
                            inputClass={"PollTitleInput"}
                        />

                        <div
                            className="create-question-body"
                            style={{
                                backgroundColor: "#FFFFFF",
                                padding: "min(2%, 20px)",
                                borderRadius: "9px",
                                border: "1px solid #858687",
                                flex: 1,
                                overflow: "auto",
                                zIndex: 1,
                            }}
                        >
                            <BaseQuestionTitle
                                selectedQuestion={questions[currQueDisplay - 1]}
                                handleTitleUpdate={handleTitleUpdate}
                                questionTitle={questions[currQueDisplay - 1].queTitle}
                                placeholder={"Question"}
                                editable={true}
                            />

                            <SAQuestion
                                questions={questions}
                                setQuestions={setQuestions}
                                selectedQuestion={questions[currQueDisplay - 1]}
                                pollType={POLL_TYPE.ATTENDANCE}
                                geofence={geofence}
                                setGeofence={setGeofence}
                                syncLms={syncLms}
                                setSyncLms={setSyncLms}
                                mapInitialized={mapInitialized}
                                setMapInitialized={setMapInitialized}
                                userSettings={userSettings}
                            />
                        </div>

                        <div style={{display: "flex", justifyContent: "center"}}>
                            <YuJaButton title={"Save & Close Attendance"} onClick={savePollClose} disabled={disableSave}
                                        style={{
                                            fontSize: 14,
                                            width: "277px",
                                            height: "35px",
                                            padding: "7.941px",
                                            borderRadius: "4.21px",
                                            border: "1.336px solid #42296E",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            display: "flex",
                                        }} label={SAVE_CLOSE_BUTTON_TEXT}>
                                {SAVE_CLOSE_BUTTON_TEXT}
                            </YuJaButton>
                        </div>
                    </>}
                </div>
            </Spin>
        </>
    );
}

import React, { useEffect, useState } from "react";
import { Col, } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import ImportQuestionModal from "../../components/modals/ImportQuestionModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import useMobileAccess from "../../hooks/useMobileAccess";
import { ReactComponent as ImportQuestion } from "../../images/ImportQuestion.svg";
import BaseQuestion from "../../questions/BaseQuestion";
import { ATTENDANCE_TITLE_PLACEHOLDER, CREATE_POLL_LABEL, IMPORT_QUESTION_TEXT, POLL_TITLE_LENGTH, POLL_TITLE_PLACEHOLDER, POLL_TYPE, QUESTION_TYPES, SAVE_CLOSE_BUTTON_TEXT, SAVE_CLOSE_POLL_TOLLTIP, SURVEY_TITLE_PLACEHOLDER, UPDATE_CLOSE_BUTTON_TEXT, UPDATE_CLOSE_POLL_TOOLTIP } from "../../utils/constants";
import { notifyError } from "../../utils/helpers";
import { POLL_TITLE_CHARACTER_LIMIT_EXCEEDED, SURVEY_TITLE_CHARACTER_LIMIT_EXCEEDED } from "../../utils/toast-message-constants";
import "./styles.css";

export default function QuestionsMain({
                                        title,
                                        setTitle,
                                        isEditPage = false,
                                        questions,
                                        setQuestions,
                                        geofence={},
                                        setGeofence=null,
                                        syncLms=false,
                                        setSyncLms=null,
                                        // savePoll,
                                        savePollClose,
                                        pollKey,
                                        changePollKey,
                                        currQueDisplay,
                                        changeCurrQueDisplay,
                                        // saveButtonDisabled,
                                        pollType,
                                        pollCode=null, 
                                        generateDefaultPollTitle=null, 
                                        savePoll=null,
                                        isSaving,
                                        disableSave,
                                        userSettings={},
                                        dataloading=false, 
                                        hasBlankRestriction=false
}) {
    const isNarrow = useMobileAccess(1100);

    const saveButtonStyle = {
        fontSize: 14,
        justifySelf: "center",
        width: 250,
        height: "35px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
    };

  const pollTitleLimit = POLL_TITLE_LENGTH;
  const [importModalShow, setImportModalShow] = useState(false); 

  useEffect(() => {
    if (!!pollType && pollType.toUpperCase() === POLL_TYPE.SURVEY){
      let questionsWithoutAnswers = questions;
      questionsWithoutAnswers.forEach((question) => {
        if (question.questionType === QUESTION_TYPES.MCSS.name || question.questionType === QUESTION_TYPES.TF.name || question.questionType === QUESTION_TYPES.SA.name) {
          question.correctAnswers = [];
        }
        // else if (question.questionType === QUESTION_TYPES.FITB.name) {
          // console.log("FITB Correct Answers", question.correctAnswers);
          // question.correctAnswers = {};
        // }
      })
      setQuestions(questionsWithoutAnswers);
     }
   }, [pollType]);

  return (
    <>
        <input id="dummyInputBox" style={{ display: "none", position: "absolute"}} />
        <ImportQuestionModal setModalShow={setImportModalShow} show={importModalShow} pollType={pollType} questions={questions} setQuestions={setQuestions} userSettings={userSettings}/>
        {!dataloading &&
            // <YuJaRichContentEditBox
            //     previewStyle={{fontSize: 20, color: "#006DC7", textAlign: "center"}}
            //     setValue={(e) => {
            //         if (e.target.value.length <= pollTitleLimit) {
            //             setTitle(e.target.value);
            //         }
            //         else {
            //             notifyError(pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_CHARACTER_LIMIT_EXCEEDED : POLL_TITLE_CHARACTER_LIMIT_EXCEEDED);
            //             setTitle(e.target.value.substring(0, POLL_TITLE_LENGTH));
            //         }
            //     }}
            //     value={title}
            // />
          <YuJaTextBox
            label={CREATE_POLL_LABEL}
            containerStyle={{
                color: "#006DC7",
                fontSize: isNarrow ? "18px" : "20px",

                width: "auto",
                padding: isNarrow ? 8 : 12,
                fontWeight: 400,
                borderRadius: 5
            }}
            placeholder= {pollType === POLL_TYPE.ATTENDANCE ? ATTENDANCE_TITLE_PLACEHOLDER : pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_PLACEHOLDER : POLL_TITLE_PLACEHOLDER}
            inputAlign={"center"}
            name={"pollTitle"}
            value={title}
            maxLength={pollTitleLimit}
            onChange={(e) => {
                if (e.target.value.length <= pollTitleLimit) {
                    setTitle(e.target.value);
                }
                else {
                    notifyError(pollType.toUpperCase() === POLL_TYPE.SURVEY ? SURVEY_TITLE_CHARACTER_LIMIT_EXCEEDED : POLL_TITLE_CHARACTER_LIMIT_EXCEEDED);
                    setTitle(e.target.value.substring(0, POLL_TITLE_LENGTH));
                }
            }}
            inputClass={"PollTitleInput"}
            showRemaining={true}
          />
        }
        {
          dataloading &&
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey" >
              <Skeleton height={49} borderRadius={10} style={{ position: "relative" }} />
            </SkeletonTheme>
        }


      <BaseQuestion
        questions={questions}
        setQuestions={setQuestions}
        currQueDisplay={currQueDisplay}
        changeCurrQueDisplay={changeCurrQueDisplay}
        selectedQuestion={questions[currQueDisplay-1]}
        pollType={pollType}
        pollKey={pollKey}
        changePollKey={changePollKey}
        isSaving={isSaving}
        userSettings={userSettings}
        isEditPage={isEditPage}
        geofence={geofence}
        setGeofence={setGeofence}
        syncLms={syncLms}
        setSyncLms={setSyncLms}
        generateDefaultPollTitle={generateDefaultPollTitle}
        pollCode={pollCode}
        savePoll={savePoll}
        dataloading={dataloading}
        hasBlankRestriction={hasBlankRestriction}
      />

        <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: 10}}>
            {!dataloading &&
                <>
                    <div>
                        {pollType !== POLL_TYPE.ATTENDANCE &&
                            <YuJaButton
                                style={{fontSize: 14, padding: 5}}
                                onClick={() => setImportModalShow(true)}
                                wrapperStyle={{whiteSpace: "nowrap"}}
                            >
                                <div style={{
                                    height: "21px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    <ImportQuestion style={{width: 24, height: 24, marginRight: 2}}/>
                                    <div style={{fontSize: 14}}> {IMPORT_QUESTION_TEXT} </div>
                                </div>
                            </YuJaButton>
                        }
                    </div>
                    <YuJaButton
                        title={isEditPage ? UPDATE_CLOSE_POLL_TOOLTIP : SAVE_CLOSE_POLL_TOLLTIP}
                        onClick={savePollClose}
                        disabled={disableSave}
                        style={saveButtonStyle}
                        wrapperStyle={{whiteSpace: "nowrap"}}
                        label={isEditPage ? UPDATE_CLOSE_BUTTON_TEXT : SAVE_CLOSE_BUTTON_TEXT}
                    >
                        {isEditPage ? UPDATE_CLOSE_BUTTON_TEXT : SAVE_CLOSE_BUTTON_TEXT}
                    </YuJaButton>
                </>
            }
        </div>
    </>
  );
}

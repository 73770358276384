import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import YuJaButton from "../../components/standardization/YuJaButton";
import useMobileAccess from "../../hooks/useMobileAccess";
import ArrowIcon from "../../images/arrow_right_new.svg";
import CancelIcon from "../../images/cancel.svg";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import { ADD_DISTRACTORS_TEXT, ADD_MATCHING_PAIR_TEXT, BUTTON, DELETE_BTN_MH, DELETE_BTN_MH_2 } from "../../utils/constants";
import {notifyError, reorderKeysByDragDrop} from "../../utils/helpers";
import {addDistractorToQuestion, addPremiseToQuestion, convertMHOptionMap} from "../../utils/questionUtils";
import "../styles.css";
import Premise from "./Premise";
import Response from "./response";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";

export default function MHQuestion({
  setQuestions,
  questions,
  selectedQuestion,
  scrollToBottom,
  questionBuilder=false,
  disabled=false
}) {
  const { serialNo, correctAnswers=[] } = selectedQuestion;
  const {isNarrow} = useCheckMainContainerNarrow();
  const [showButton, setShowButton] = useState(true);

    const handleDragStart = () => {
      setShowButton(false);
    };

    const handleDragEnd = () => {
      setShowButton(true);
    };

  const handlePremiseOrderChange = (result) => {
    if (!result.destination) return;

    const sourceKey = result.source.index;
    const destinationKey = result.destination.index;

    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let newCorrectAnswers = [];
        let premiseArr = q.correctAnswers.filter(entry => entry.length > 1).map(entry => entry[0]);
        let reorderKeys = reorderKeysByDragDrop(result.source.index, result.destination.index, Array.from(Array(premiseArr.length).keys()));
        for (let i = 0; i < q.correctAnswers.length; i++) {
          if (i < reorderKeys.length) {
            const entry = q.correctAnswers[i];
            newCorrectAnswers.push([premiseArr[reorderKeys[i]], entry[1]]);
          } else {
            newCorrectAnswers.push(q.correctAnswers[i]);
          }
        }

        let updatedQuestion = {...q, correctAnswers: newCorrectAnswers};
        if (!!questionBuilder) {
          updatedQuestion.optionsMap = convertMHOptionMap(newCorrectAnswers);
        }
        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push(q);
      }
    });
    setQuestions(newQuestions);
  };

  const handleResponseOrderChange = (result) => {
    if (!result.destination || result.source.index === result.destination.index) {
      return;
    }

    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let newCorrectAnswers = [];
        let responseArr = q.correctAnswers.map(entry => entry.length > 1 ? entry[1] : entry[0]);
        let reorderKeys = reorderKeysByDragDrop(result.source.index, result.destination.index, Array.from(Array(q.correctAnswers.length).keys()));
        for (let i = 0; i < q.correctAnswers.length; i++) {
          const entry = q.correctAnswers[i];
          if (entry.length >  1) {
            newCorrectAnswers.push([entry[0], responseArr[reorderKeys[i]]]);
          } else {
            newCorrectAnswers.push([responseArr[reorderKeys[i]]]);
          }
        }

        let updatedQuestion = {...q, correctAnswers: newCorrectAnswers};
        if (!!questionBuilder) {
          updatedQuestion.optionsMap = convertMHOptionMap(newCorrectAnswers);
        }
        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push(q);
      }
    });
    setQuestions(newQuestions);
  };

  const handleDeleteOption = (target_index ) => {
    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let newCorrectAnswers = [];
        q.correctAnswers.forEach((value, index) => {
          if (index !== target_index) {
            newCorrectAnswers.push(value);
          }
        });
        let updatedQuestion = {...q, correctAnswers: newCorrectAnswers,};
        if (!!questionBuilder) {
          updatedQuestion.optionsMap = convertMHOptionMap(newCorrectAnswers);
        }

        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push(q);
      }
    });
    setQuestions(newQuestions);
  };


  const addDistractor = () => {
    const newQuestions = addDistractorToQuestion(selectedQuestion, questions);
    if (newQuestions) {
      if (!!questionBuilder) {
        let que = newQuestions.find(q => q.serialNo === selectedQuestion.serialNo);
        que.optionsMap = convertMHOptionMap(que.correctAnswers);
      }
      setQuestions(newQuestions);
    } else {
      notifyError("More options cannot be added right now. Please try again later.");
    }
  }

  const addMatchingPair = () => {
    const newQuestions = addPremiseToQuestion(selectedQuestion, questions);
    if (newQuestions) {
      if (!!questionBuilder) {
        let que = newQuestions.find(q => q.serialNo === selectedQuestion.serialNo);
        que.optionsMap = convertMHOptionMap(que.correctAnswers);
      }
      setQuestions(newQuestions);
    } else {
      notifyError("More options cannot be added right now. Please try again later.");
    }
  }

  return (
      <div style={{display: "flex", width: "100%", margin: "15px 0", marginTop: questionBuilder ? 0 : undefined, gap: "8px"}}>
        <div style={{ width: questionBuilder ? "min(45%, 300px)" : "45%"  }}>
          <DragDropContext
              onDragStart={handleDragStart}
              onDragEnd={(result) => {
                handleDragEnd();
                handlePremiseOrderChange(result);
              }}
          >
            <Droppable droppableId="droppable-matching-question-premise">
              {(provided, snapshot) => (
                  <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      {...provided.dropHandleProps}
                      style={{padding: "0", display: "flex", flexDirection: "column", height: "100%", gap: 15}}
                  >
                    {correctAnswers && correctAnswers.map((value, index) => {
                      return (
                          <div key={index} style={{flex: 1, display: "flex", alignItems: "center"}}>
                            <div style={{display: "flex", width: "100%"}}>
                              <Premise
                                  optionData={value}
                                  selectedQuestion={selectedQuestion}
                                  questions={questions}
                                  setQuestions={setQuestions}
                                  index={index}
                                  questionBuilder={questionBuilder}
                                  disabled={disabled}
                              />
                            </div>
                          </div>
                      );
                    })}

                    {!disabled && showButton ?
                        <YuJaButton
                            title={ADD_MATCHING_PAIR_TEXT}
                            onClick={() => {
                              addMatchingPair();
                              scrollToBottom();
                            }}
                            style={{fontSize: 16, marginLeft: 28, height: 40}}
                        >
                          <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/> {ADD_MATCHING_PAIR_TEXT}
                        </YuJaButton>
                    : null}
                  </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div style={{display: "flex", flexDirection: "column", gap: 15, alignItems: "end", flexShrink: 0}}>
          {correctAnswers && correctAnswers.map((value, index) =>
              <div key={index} style={{flex: 1, display: "flex", alignItems: "center"}}>
                {value.length > 1 && (
                    <div key={index} style={{justifyContent: "center", display: "flex", alignItems: "center"}}>
                      {(correctAnswers.length > 1 || questionBuilder) && (
                   <img
                     src={ArrowIcon}
                     alt="Arrow icon"
                     className="questions-page-icons cursor-pointer"
                     style={{width: 40, height: 40}}
                   />
                 )}
               </div>
             )}
           </div>
          )}
          {!disabled && showButton ? <span style={{width: 40, height: 40}}/> : null}
        </div>

        <div style={{width: questionBuilder ? "min(45%, 300px)" : "45%" }}>
           <DragDropContext
               onDragStart={handleDragStart}
               onDragEnd={(result) => {
                 handleDragEnd();
                 handleResponseOrderChange(result);
               }}
           >
            <Droppable droppableId="droppable-matching-question-response">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  {...provided.dropHandleProps}
                  style={{padding: "0", display: "flex", flexDirection: "column", height: "100%", gap: 15}}
                >
                  {correctAnswers && correctAnswers.map((value, index) => {
                    return (
                      <div key={index} style={{ flex: 1, display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", width: "100%" }}>
                          <Response
                            optionData={value}
                            selectedQuestion={selectedQuestion}
                            questions={questions}
                            setQuestions={setQuestions}
                            index={index}
                            questionBuilder={questionBuilder}
                            disabled={disabled}
                          />
                        </div>
                      </div>
                    );
                  })}

                  {!disabled && showButton ?
                      <YuJaButton
                          title={ADD_DISTRACTORS_TEXT}
                          onClick={() => {addDistractor(); scrollToBottom()}}
                          style={{fontSize: 16, marginLeft: 28, height: 40}}
                      >
                        <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/> {ADD_DISTRACTORS_TEXT}
                      </YuJaButton>
                  : null}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <div style={{display: "flex", flexDirection: "column", gap: 15, marginLeft: 5, marginRight: "5px"}}>
          {correctAnswers && correctAnswers.map((value, index) => {
              return (
                <div key={index} style={{flex: 1, display: "flex", alignItems: "center"}}>
                  {correctAnswers.length > 1 && (!questionBuilder || !disabled) && (
                    <img
                      aria-label={DELETE_BTN_MH + (index+1) + DELETE_BTN_MH_2 + (index+1)}
                      role={BUTTON}
                      tabIndex={0}
                      onClick={() => {
                        handleDeleteOption(index);
                      }}
                      src={CancelIcon}
                      alt="delete icon"
                      title={"Delete Option"}
                      className="questions-page-icons cursor-pointer"
                      style={{ width: 30, height: 40 }}
                    />
                  )}
                </div>
              )
              }
            )
          }
          {!disabled && showButton ? <span style={{width: 30, height: 40}}/> : null}
        </div>
      </div>
  );
}
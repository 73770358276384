import React, {useEffect, useState} from "react";
import {
  DELETE_OPT_BTN,
  MC_ANSWER_LENGTH,
  OPTION_LABEL,
  LATEX_REG_EXP,
  TOGGLE_MENU_BAR_TEXT, CHARACTERS_TEXT
} from "../../utils/constants";
import {notifyInfo, stripHTMLTags} from "../../utils/helpers";
import {
  updateRankingAnswers
} from "../../utils/questionUpdate";
import {addRankToQuestion} from "../../utils/questionUtils";
import { ADD_OPTIONS_INFO } from "../../utils/toast-message-constants";
import "../styles.css";
import {EditorContent, useEditor} from "@tiptap/react";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";
import {menuBarCollapsedStyle, menuBarExpandedStyle, TipTapMenuBar} from "../../components/Tiptap/TipTapMenuBar";
import classes from "../../components/Tiptap/Tiptap.module.css";
import {ReactComponent as TOGGLE} from "../../components/Tiptap/images/format.svg";
import {ReactComponent as DELETE} from "../../images/cancel.svg";
import {Color} from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";

export default function RKQuestionOption({
  optionData,
  selectedQuestion,
  questions,
  setQuestions,
  index,
  maxSize,
  questionBuilder=false,
  disabled=false,
  warningShowed,
  setWarningShowed
}) {

  const {isNarrow} = useCheckMainContainerNarrow();
  const { choice, optionValue } = optionData;
  const { serialNo, optionsMap} = selectedQuestion;
  const selectedQuestionIndex = serialNo - 1;
  const [collapsed, setCollapsed] = useState(true);

  const editor = useEditor({
    shouldRerenderOnTransaction: true,
    editorProps: {
      transformPastedHTML(text) {
        return stripHTMLTags(text);
      },
    },
    extensions: [
      Underline,
      StarterKit,
      Placeholder.configure({
        placeholder: OPTION_LABEL + " " + (choice + 1),
      }),
      CharacterCount.configure({limit: MC_ANSWER_LENGTH}),
      TextStyle,
      Color,
      Highlight,
      // Mathematics.configure({
      //   regex: LATEX_REG_EXP,
      // }),
    ],
    onUpdate: ({editor}) => {
      handleAnswerChange(editor.getHTML(), choice);
      if (index + 1 === maxSize) {
        const newQuestions = addRankToQuestion(
            selectedQuestion,
            questions
        );
        if (newQuestions) {
          setQuestions(newQuestions);
          setTimeout(() => {
            const mcqOptionsContainer = document.getElementById("mcq-options-container");
            mcqOptionsContainer?.scroll({ top: mcqOptionsContainer.scrollHeight, behavior: "smooth" });
          }, 10);
        } else if (!warningShowed && !!setWarningShowed) {
          setWarningShowed(true);
          notifyInfo(ADD_OPTIONS_INFO);
        }
      }
    },
    content: optionValue,
  }, [serialNo]);

  useEffect(() => {
    if (optionValue !== editor.getHTML()) {
      editor.commands.setContent(optionValue);
    }

  }, [optionValue, editor]);


  const handleAnswerChange = (input, itemKey) => {
    setQuestions(
        updateRankingAnswers(input, itemKey, selectedQuestionIndex, questions)
    );
  };

  const handleDeleteOption = () => {
    let newQuestions = [];

    questions.forEach((q) => {
      if (q.serialNo === serialNo) {
        let newOptionsMap = [];
        q.optionsMap.map((value, index) => {
          if (index !== choice) {
            if (index > choice) {
              const newIndex = index - 1;
              newOptionsMap[newIndex] = value;
            } else {
              newOptionsMap[index] = value;
            }
          }
        });
        let updatedQuestion = {
          ...q,
          optionsMap: newOptionsMap,
        };
        // updatedQuestion["image" + choice] = null;
        newQuestions.push(updatedQuestion);
      } else {
        newQuestions.push({ ...q });
      }
    });
    setQuestions(newQuestions);
  };


  return (
    <div tabIndex={-1}>
      <div
          style={{
            display: "flex",
            flex: 1,
            alignItems: "center",
            gap: isNarrow || questionBuilder ? 5 : 10
          }}
      >
        <span
            style={{
              flex: 1,
              position: "relative",
              border: "1px solid #858687",
              borderRadius: 3,
              display: "flex",
              flexDirection: "column",
              background: "#FFF"
            }}
        >
          <TipTapMenuBar
              editor={editor}
              style={collapsed ? menuBarCollapsedStyle : menuBarExpandedStyle}
          />
          <EditorContent editor={editor} style={{cursor: "text"}} className={classes.editorContentBox}/>
          {!!editor.storage.characterCount.characters() &&
              <span className={classes.characterCountBox}>
                {editor.storage.characterCount.characters()} {CHARACTERS_TEXT}
              </span>
          }
        </span>

        <TOGGLE
            style={{
              cursor: "pointer",
              width: isNarrow || questionBuilder ? 20 : 30,
              height: isNarrow || questionBuilder ? 20 : 30,
            }}
            title={TOGGLE_MENU_BAR_TEXT}
            onClick={() => setCollapsed(!collapsed)}
        />
        {(!questionBuilder || !disabled) && optionsMap.length > 1 &&
            <DELETE
                style={{
                  cursor: "pointer",
                  width: isNarrow || questionBuilder ? 20 : 30,
                  height: isNarrow || questionBuilder ? 20 : 30,
                }}
                title={DELETE_OPT_BTN}
                onClick={handleDeleteOption}
            />

        }
      </div>
    </div>
  );
}
import React, {useEffect, useRef, useState} from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Container } from "react-bootstrap";
import { PollApiCalls } from "../../apis/PollApiCalls";
import "../../components/buttons/addButton.css";
import CreateQuestionProfileModal from "../../components/modals/CreateQuestionProfileModal";
import QuestionProfileModal from "../../components/modals/QuestionProfileModal";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaCheckbox } from "../../components/standardization/YuJaCheckbox";
import useHostname from "../../hooks/useHostname";
import { useLocalStorageNew } from "../../hooks/useLocalStorageNew";
import { ReactComponent as DeleteIcon } from "../../images/delete.svg";
import { ReactComponent as DuplicateIcon } from "../../images/duplicate.svg";
import { ReactComponent as PlusIcon } from "../../images/plus.svg";
import {
    ADD_QUESTION_TEXT,
    BUTTON,
    CREATE_QUESTION_PROFILE_MODAL_TITLE,
    DELETE_BTN,
    DUPLICATE_BTN,
    IMPORT_BANK_TEXT,
    QUESTION_DASHBOARD,
    QUESTION_DEFAULT_OPTIONS,
    QUESTION_TYPES,
    QUES_BANK_SAVE,
    QUES_LABEL_1,
    QUES_LABEL_2,
    QUES_LABEL_3,
    TEXTBOX, QUESTION_TEXT,
} from "../../utils/constants";
import {
    notifyError, stripHTMLTags
} from "../../utils/helpers";
import {
    addQuestion,
    deleteQuestion,
    handleQuestionOrderChange,
    questionCodeToName,
} from "../../utils/questionUtils";
import { SAVE_BANK_WARNING_MESSAGE } from "../../utils/toast-message-constants";
import "./styles.css";
import { useLoading } from "../../utils/LoadingContext";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {useImageLoaderManager} from "../../hooks/useImageLoaderManager";
import {Spin} from "../../components/standardization/YuJaLoading";
import 'katex/dist/katex.min.css';
import {convertToPlainString, LatexInline,} from "../../components/Tiptap/latexParser";

export default function CreateQuizSidebar({
  questions,
  setQuestions,
  currQueDisplay,
  setCurrQueDisplay,
  pollKey,
  changePollKey,
  questionBankId,
  userSettings,
  dataloading=false
}) {
    const {
        checkQueImagesIsLoading,
        setQuestionLoading,
        resetImages,
        setImages
    } = useImageLoaderManager(questions, setQuestions);
    const hostResource = useHostname(window.location.hostname);
    const {institutionId = ""} = hostResource;
    const [, getSession] = useLocalStorageNew("session", {});
    const { duplicateImagesOfQuestion, duplicateImagesOfQuestionBank} = PollApiCalls();
    const {loading } = useLoading()
    // const [showGradebookOptions, setShowGradebookOptions] = useState(false);

    // const [showDragIcon, setShowDragIcon] = useState(null);

    // const [dragIconShowSerialNo, setDragIconShow] = useState();

    // const [showButton, setShowButton] = useState(true);
    // const [alertShow, setAlertShow] = useState(false);
    // const [alertContent,] = useState(SWITCH_POLL_MESSAGE);
    // const [setType,] = useLocalStorageNew("pollType", "");

    const [importingModal, setImportingModal] = useState(false);
    const [profileSettingModalShow, setProfileSettingModalShow]= useState(false);
    const questionsRef = useRef([]);

    useEffect(() => {
        questionsRef.current = questions;
    }, [questions]);

    // const [currentView, setCurrentView] = useState(0);

    const handleDragStart = () => {
      // setShowButton(false);
    };

    const handleDragEnd = () => {
      // setShowButton(true);
    };

    const clickNewQuestion = (e) => {
        e.stopPropagation();
        const newQuestions = addQuestion(questions, QUESTION_TYPES.MCSS.name, userSettings);
        setQuestions(newQuestions);
        setCurrQueDisplay(newQuestions.length);
    };

    const clickDuplicate = async (selectedQuestion, e) => {
        e.stopPropagation();
        const {userId} = getSession();

        if (!institutionId || !userId) {
            return;
        }
        const {serialNo} = selectedQuestion;


        const questionsCopy = JSON.parse(JSON.stringify(questionsRef.current));
        let duplicatedQuestion = JSON.parse(JSON.stringify(selectedQuestion));
        let newQuestions = [];
        for (const q of questionsCopy) {
            if (q.serialNo === serialNo) {
                newQuestions.push({...q, checked: false});
                duplicatedQuestion.serialNo = q.serialNo + 1;
                duplicatedQuestion.checked = true;
                newQuestions.push(duplicatedQuestion);
            } else if (q.serialNo > serialNo) {
                newQuestions.push({ ...q, serialNo: q.serialNo + 1, checked: false });
            } else {
                newQuestions.push({ ...q, checked: false });
            }
        }


        const resourceMapping = setQuestionLoading(duplicatedQuestion);
        setQuestions(newQuestions);
        setCurrQueDisplay(serialNo + 1);

        if (!resourceMapping || !Object.entries(resourceMapping).length) {
            return;
        }

        //duplicate images of the question
        if (!questionBankId) {
            duplicateImagesOfQuestion(pollKey, selectedQuestion, resourceMapping).then((returnedMapping) => {
                //error
                if (!returnedMapping) {
                    resetImages(Object.values(resourceMapping));
                    return;
                }

                //set imageURL
                setImages(returnedMapping);
            });
        } else {
            duplicateImagesOfQuestionBank(questionBankId, resourceMapping).then((returnedMapping) => {
                //error
                if (!returnedMapping) {
                    resetImages(Object.values(resourceMapping));
                    return;
                }

                //set imageURL
                setImages(returnedMapping);
            });
        }
    };

    const clickDelete = (selectedQuestion, e) => {
        e.stopPropagation();
        const {serialNo} = selectedQuestion;
        const newQuestions = deleteQuestion(selectedQuestion, questions);
        setCurrQueDisplay(serialNo === 1 ? 1 : serialNo - 1);
        setQuestions(newQuestions);
    };


  const questionBankCheckbox = () => {
    let show = false;
    questions.forEach((question) => {
      if(question.queTitle !== "") {
        show = true;
      }
      else if (question.hasOwnProperty("image") && question.image !== "") {
        show = true;
      }
      else if (question.questionType == QUESTION_TYPES.MCSS.name && JSON.stringify(question.optionsMap) !== QUESTION_DEFAULT_OPTIONS.MCSS) {
        show = true;
      }
    })
    return show;
  }

  const handleCheckboxChange = () => {
    if(questionBankCheckbox()){
      setProfileSettingModalShow(true);
    }
    else {
      notifyError(SAVE_BANK_WARNING_MESSAGE);
    }
  }

  return (
      <>
          <CreateQuestionProfileModal
              setModalShow={setProfileSettingModalShow}
              show={profileSettingModalShow}
              questions={questions}
              pollKey={pollKey}
          />
          <QuestionProfileModal
              show={importingModal}
              setModalShow={setImportingModal}
              setQuestions={setQuestions}
              questions={questions}
              setCurrQueDisplay={setCurrQueDisplay}
              pollKey={pollKey}
              changePollKey={changePollKey}
              userSettings={userSettings}
          />


          <div style={{display: "flex", width: "100%", height: 45, flexShrink: 0}}>
              <div style={{
                  fontWeight: "700",
                  display: "flex",
                  fontSize: "16px",
                  lineHeight: "15px",
                  width: "100%", alignItems: "center",
                  borderTopLeftRadius: "10.2px",
                  borderTopRightRadius: "10.2px",
                  borderBottom: "0.5px solid #42296E",
                  color: "#1D1B20"
              }} tabIndex={0} role={TEXTBOX}>
                  {QUESTION_DASHBOARD}
              </div>
          </div>

          <DragDropContext
              onDragStart={handleDragStart}
              onDragEnd={(result) => {
                  const {destination = {}} = result;
                  const {index} = destination;
                  const updatedQuestions = handleQuestionOrderChange(result, questions);
                  if (updatedQuestions) {
                      setQuestions(updatedQuestions);
                      setCurrQueDisplay(index + 1);
                  }
              }}
          >
              <Droppable droppableId="droppable-sidebar">
                  {(provided, snapshot) => (
                      <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          {...provided.dropHandleProps}
                          style={{overflowY: "auto"}}
                      >
                          {!dataloading &&
                              <>
                                  {questions.map((question, index) => {
                                      const {serialNo, questionType, queTitle} = question;
                                      return (
                                          <div
                                              key={index}
                                              onClick={(event) => {
                                                  setCurrQueDisplay(serialNo);
                                              }}
                                          >
                                              <Draggable
                                                  key={String(serialNo)}
                                                  draggableId={String(serialNo)}
                                                  index={index}
                                              >
                                                  {(draggableProvided) => (
                                                      <div
                                                          className={`question-bar-container ${serialNo === currQueDisplay ? "is-cur" : ""}`}
                                                          {...draggableProvided.draggableProps}
                                                          {...draggableProvided.dragHandleProps}
                                                          ref={draggableProvided.innerRef}
                                                          aria-label={QUES_LABEL_1 + serialNo + " " + questionCodeToName(questionType) + QUES_LABEL_2 + QUES_LABEL_3}
                                                          role={TEXTBOX}
                                                          onMouseDown={() => document.getElementById("dummyInputBox").focus()}
                                                      >
                                                          <div style={{flex: 1, paddingLeft: 10}}>
                                                              <div className="sidebar-question" style={{
                                                                  whiteSpace: "nowrap",
                                                                  overflow: "hidden",
                                                                  textOverflow: "ellipsis",
                                                                  wordBreak: "keep-all",
                                                                  maxWidth: "200px"
                                                              }}>
                                                                  {!!stripHTMLTags(queTitle)
                                                                      ? <LatexInline>{queTitle}</LatexInline>
                                                                      : `${QUESTION_TEXT} ${serialNo}`}
                                                              </div>
                                                              <div className="sidebar-question-type">
                                                                  {questionCodeToName(questionType)}
                                                              </div>
                                                          </div>
                                                          {!checkQueImagesIsLoading(question) &&
                                                              <>
                                                                  <DuplicateIcon
                                                                      title={"Duplicate Question"}
                                                                      alt="duplicate icon"
                                                                      onClick={(e) => clickDuplicate(question, e)}
                                                                      style={{
                                                                          height: "28.646px",
                                                                          width: "28.646px"
                                                                      }}
                                                                      tabIndex={0}
                                                                      aria-label={DUPLICATE_BTN + (index + 1)}
                                                                      role={BUTTON}
                                                                  />
                                                                  <DeleteIcon
                                                                      title={"Delete Question"}
                                                                      alt="delete icon"
                                                                      onClick={(e) => clickDelete(question, e)}
                                                                      tabIndex={0}
                                                                      aria-label={DELETE_BTN + (index + 1)}
                                                                      role={BUTTON}
                                                                      style={{
                                                                          height: "28.646px",
                                                                          width: "28.646px"
                                                                      }}
                                                                  />
                                                              </>
                                                          }

                                                          {checkQueImagesIsLoading(question) &&
                                                              <>
                                                                  <div style={{width: 28, height: 28}}>
                                                                      <Spin size={"medium"} spinning={true}
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center"
                                                                            }}/>
                                                                  </div>
                                                                  <div style={{width: 28, height: 28}}>
                                                                      <Spin size={"medium"} spinning={true}
                                                                            style={{
                                                                                width: "100%",
                                                                                height: "100%",
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center"
                                                                            }}/>
                                                                  </div>
                                                              </>
                                                          }
                                                      </div>
                                                  )}
                                              </Draggable>
                                          </div>
                                      );
                                  })}
                              </>
                          }
                          {dataloading &&
                              <div>
                                  <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                                      <Skeleton height={20} width={156} borderRadius={10}
                                                style={{position: "relative", marginTop: 36}}/>
                                      <Skeleton height={61} width={312} borderRadius={10}/>
                                      <div style={{
                                          display: "flex",
                                          textAlign: "center",
                                          justifyContent: !questionBankId ? "space-between" : 'center',
                                          padding: "20px 0px",
                                          flexWrap: "wrap",
                                          gap: 5
                                      }}>
                                          <Skeleton height={30} width={140} borderRadius={10}/>
                                          <Skeleton height={30} width={140} borderRadius={10}/>
                                      </div>
                                  </SkeletonTheme>
                              </div>

                          }

                      </div>
                  )}
              </Droppable>
          </DragDropContext>

          <div style={{flex: 1, display: "flex", width: "100%", flexDirection: "column"}}>
              {!questionBankId &&
                  <div style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flexWrap: "wrap"
                  }}>
                      <YuJaButton
                          title={IMPORT_BANK_TEXT}
                          onClick={() => setImportingModal(true)}
                          style={{fontSize: 13}}
                      >
                          <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/> {IMPORT_BANK_TEXT}
                      </YuJaButton>
                      <YuJaButton title={ADD_QUESTION_TEXT} onClick={clickNewQuestion} style={{fontSize: 13}} disabled={loading}>
                          <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/> {ADD_QUESTION_TEXT}
                      </YuJaButton>
                  </div>
              }

              {!!questionBankId &&
                  <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                      <YuJaButton title={ADD_QUESTION_TEXT} onClick={clickNewQuestion} style={{fontSize: 13}} disabled={loading}>
                          <PlusIcon style={{width: 15, height: 15, marginRight: 2}}/> {ADD_QUESTION_TEXT}
                      </YuJaButton>
                  </div>
              }
          </div>
          {!questionBankId && !dataloading &&
              <div style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  height: "25px",
                  padding: "0px 20px",
                  fontSize: "14px"
              }}>
                  <YuJaCheckbox ariaLabel={QUES_BANK_SAVE + (profileSettingModalShow ? "checked" : "unchecked")}
                                checked={profileSettingModalShow} onClick={handleCheckboxChange}
                                label={CREATE_QUESTION_PROFILE_MODAL_TITLE} color={"#0C7086"}/>
                  {/* {pollType !== SURVEY_TEXT &&
                <YuJaButton onClick={() => setShowGradebookOptions(true)} style={{ borderRadius: "4.21px", border: "1.336px solid #42296E", height: "35px", display: "flex", alignItems: "center", fontSize: "14px" }}>
                  {CONFIGURE_GRADEBOOK_OPTIONS_TEXT}
                </YuJaButton>
              } */}
              </div>
          }
      </>
  );
}

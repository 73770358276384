import React from "react";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {YuJaRichContentEditBoxNew} from "../components/Tiptap/YuJaRichContentEditBoxNew";

export default function BaseQuestionTitle({
                                              selectedQuestion,
                                              questionTitle = "",
                                              handleTitleUpdate,
                                              placeholder,
                                              editable,
                                              dataloading = false,
}) {
    if (dataloading) {
        return (
            <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                <Skeleton height={49} borderRadius={10} style={{position: "relative", marginBottom: 22}}/>
            </SkeletonTheme>
        );
    }

    return (editable ?
            (
                <YuJaRichContentEditBoxNew
                    selectedQuestion={selectedQuestion}
                    placeholder={placeholder}
                    setValue={handleTitleUpdate}
                    value={questionTitle}
                />
            )
            : (
                <div className="text-container-without-counter">
                <span className="poll-sub-text focus-outline-none cursor-default" style={{fontSize: 18}}>
                  {placeholder}
                </span>
                </div>
            )

    );
}

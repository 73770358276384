import React, {useEffect, useState} from "react";
import { Draggable } from "react-beautiful-dnd";
import DragDropIcon from "../../images/drag_drop.svg";
import { updateCorrectAnswers} from "../../utils/questionUpdate";
import {stripHTMLTags} from "../../utils/helpers";
import {
    ASCII_CODE_A, CHARACTERS_TEXT,
    DELETE_OPT_BTN, DRAG_TITLE, LATEX_REG_EXP,
    MC_ANSWER_LENGTH, OPTION_LABEL,
    TOGGLE_MENU_BAR_TEXT,
} from "../../utils/constants";
import "../styles.css";

import {YuJaCheckbox} from "../../components/standardization/YuJaCheckbox";
import {useCheckMainContainerNarrow} from "../../hooks/useCheckMainContainerNarrow";
import {EditorContent, useEditor} from "@tiptap/react";
import Underline from "@tiptap/extension-underline";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import CharacterCount from "@tiptap/extension-character-count";
import {menuBarCollapsedStyle, menuBarExpandedStyle, TipTapMenuBar} from "../../components/Tiptap/TipTapMenuBar";
import classes from "../../components/Tiptap/Tiptap.module.css";
import {ReactComponent as TOGGLE} from "../../components/Tiptap/images/format.svg";
import {ReactComponent as DELETE} from "../../images/cancel.svg";
import {Color} from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from "@tiptap/extension-highlight";

export default function CIQuestionOption({
    optionData,
    selectedQuestion,
    questions,
    setQuestions,
    index,
    questionBuilder = false,
    disabled = false
}) {
    const {isNarrow} = useCheckMainContainerNarrow();

    const [collapsed, setCollapsed] = useState(true);
    const { serialNo, correctAnswers, weightage } = selectedQuestion;
    const gradeEnable = !!weightage;
    const selectedQuestionIndex = serialNo - 1;

    const editor = useEditor({
        shouldRerenderOnTransaction: true,
        editorProps: {
            transformPastedHTML(text) {
                return stripHTMLTags(text);
            },
        },
        extensions: [
            Underline,
            StarterKit,
            Placeholder.configure({
                placeholder: OPTION_LABEL + " " + String.fromCharCode(index + ASCII_CODE_A),
            }),
            CharacterCount.configure({limit: MC_ANSWER_LENGTH}),
            TextStyle,
            Color,
            Highlight,
            // Mathematics.configure({
            //     regex: LATEX_REG_EXP,
            // }),
        ],
        onUpdate: ({editor}) => {
            handleAnswerChange(editor.getHTML(), index);
        },
        content: optionData.text ? optionData.text : "",
    }, [serialNo]);

    useEffect(() => {
        let text = optionData.text ? optionData.text : "";
        if (text !== editor.getHTML()) {
            editor.commands.setContent(text);
        }

    }, [optionData, editor]);


    const handleAnswerChange = (input, itemKey) => {
        const copyOfQuestions = [...questions];
        const newOptionsMap = [...copyOfQuestions[selectedQuestionIndex].optionsMap];
        newOptionsMap[itemKey].text = input;
        copyOfQuestions[selectedQuestionIndex].optionsMap = newOptionsMap;
        setQuestions(copyOfQuestions);
    };

    const handleCorrectAnswerChange = (input) => {
        setQuestions(updateCorrectAnswers(input, selectedQuestionIndex, questions));
    };

    const handleDeleteOption = () => {
        let newQuestions = [];

        questions.forEach((q) => {
            if (q.serialNo === serialNo) {
                const newOptionsMap = [...q.optionsMap];
                newOptionsMap.splice(index, 1);
                let updatedQuestion = {
                    ...q,
                    optionsMap: newOptionsMap,
                    correctAnswers:
                        q.correctAnswers.includes(index) ?  q.correctAnswers.filter(item => item !== index)  : q.correctAnswers,
                };
                newQuestions.push(updatedQuestion);
            } else {
                newQuestions.push({ ...q });
            }
        });
        setQuestions(newQuestions);
    };

    return (
        <Draggable
            draggableId={`${index}`}
            index={index}
            key={index}
            isDragDisabled={questionBuilder}
        >
            {(provided) => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} tabIndex={-1}>
                    <div
                        style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                            gap: questionBuilder || isNarrow ? 5 : 10
                        }}
                    >
                        {!questionBuilder &&
                            <div
                                {...provided.dragHandleProps}
                                style={{width: (questionBuilder && disabled) ? 0 : 15, flexShrink: 0}}
                                title={DRAG_TITLE}
                                tabIndex={-1}
                            >
                                <img src={DragDropIcon} alt="drag drop icon" width={15} height={25}/>
                            </div>
                        }

                        <span
                            style={{
                                flexShrink: 0,
                                width: 20,
                                display: "flex",
                                justifyContent: "center",
                                fontSize: isNarrow || questionBuilder ? 16 : 20
                            }}
                        >{String.fromCharCode(index + ASCII_CODE_A)}</span>

                        <span
                            style={{
                                flex: 1,
                                minWidth: 0,
                                border: "1px solid #858687",
                                borderRadius: 3,
                                display: "flex",
                                alignItems: "center",
                                background: "#FFF",
                            }}
                        >
                            {gradeEnable &&
                                <YuJaCheckbox
                                    size={20}
                                    tabIndex={0}
                                    disabled={disabled}
                                    checked={correctAnswers.indexOf(index) !== -1}
                                    onClick={() => handleCorrectAnswerChange(index)}
                                    style={{margin: questionBuilder || isNarrow ? 5 : 10, cursor: "pointer"}}
                                />
                            }
                            <span
                                style={{
                                    flex: 1,
                                    minWidth: 0,
                                    display: "flex",
                                    flexDirection: "column",
                                    position: "relative",
                                    borderLeft: gradeEnable ? '1px solid #858687' : undefined
                                }}
                            >
                                <TipTapMenuBar
                                    editor={editor}
                                    style={collapsed ? menuBarCollapsedStyle : menuBarExpandedStyle}
                                />
                                <EditorContent editor={editor} style={{cursor: "text"}}
                                               className={classes.editorContentBox}/>
                                {!!editor.storage.characterCount.characters() &&
                                    <span className={classes.characterCountBox}>
                                        {editor.storage.characterCount.characters()} {CHARACTERS_TEXT}
                                    </span>
                                }
                            </span>
                        </span>

                        <TOGGLE
                            style={{
                                cursor: "pointer",
                                width: isNarrow || questionBuilder ? 20 : 30,
                                height: isNarrow || questionBuilder ? 20 : 30,
                            }}
                            title={TOGGLE_MENU_BAR_TEXT}
                            onClick={() => setCollapsed(!collapsed)}
                        />


                        {(!questionBuilder || !disabled) &&
                            <DELETE
                                style={{
                                    cursor: "pointer",
                                    width: isNarrow || questionBuilder ? 20 : 30,
                                    height: isNarrow || questionBuilder ? 20 : 30,
                                }}
                                title={DELETE_OPT_BTN}
                                onClick={handleDeleteOption}
                            />
                        }
                    </div>
                </div>
            )}
        </Draggable>
    );
}

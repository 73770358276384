import 'rc-datepicker/lib/style.css';
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import YuJaButton from "../../components/standardization/YuJaButton";
import { YuJaTextBox } from "../../components/standardization/YuJaTextBox";
import { ReactComponent as SearchIcon } from "../../images/search_bar_icon.svg";
import {
    SEARCH_TEXT,
    QUESTION_BANK_SEARCH_BAR_PLACEHOLDER
} from '../../utils/constants';
import { notifyError } from '../../utils/helpers';
import { LOADING_SEARCH_ERROR } from '../../utils/toast-message-constants';
import styles from "./QuestionBank.module.css";

export default function QuestionBankSearchForm({handleSubmit, disabled=false}) {
    const [keyword, setKeyword] = useState("");
    const clickSearch = ({key=keyword}) => {
        if (disabled) {
            notifyError(LOADING_SEARCH_ERROR);
            return;
        }
        handleSubmit({
            keyword: key,
        });
    };

    const enterKeyup = (e) => {
        if (e.key === 'Enter') {
            clickSearch();
        }
    }

    return (
        <div style={{width: "100%"}}>
                    <div className={styles.searchBox}>
                        <Form className={styles.searchFeedbackForm} onSubmit={e => e.preventDefault()} style={{width: "100%", marginLeft: "10px", marginRight: "10px", justifyContent: "center"}}
                        >
                            <YuJaTextBox
                                containerStyle={{fontSize: 12, minWidth: 150, maxWidth:500}}
                                placeholder={QUESTION_BANK_SEARCH_BAR_PLACEHOLDER}
                                onChange={e => setKeyword(e.target.value)}
                                onKeyUp={enterKeyup}
                                value={keyword}
                                before={<SearchIcon style={{marginRight: 5, width: 16}}/>}
                            />

                            <YuJaButton onClick={clickSearch}><div style={{fontSize: 14}}>{SEARCH_TEXT}</div></YuJaButton>
                        </Form>
                    </div>
        </div>
    );
}
import 'katex/dist/katex.min.css'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import CharacterCount from '@tiptap/extension-character-count'
import Placeholder from '@tiptap/extension-placeholder'
import { Color } from '@tiptap/extension-color';
import React, {useEffect} from 'react'
import {TipTapMenuBar} from "./TipTapMenuBar";
import classes from "./Tiptap.module.css";
import {stripHTMLTags} from "../../utils/helpers";
import {CHARACTERS_TEXT} from "../../utils/constants";
import TextStyle from "@tiptap/extension-text-style";
import Highlight from '@tiptap/extension-highlight';



export const YuJaRichContentEditBoxNew =  ({selectedQuestion, placeholder, value, setValue}) => {
    const {serialNo} = selectedQuestion;
    const editor = useEditor({
        shouldRerenderOnTransaction: true,
        editorProps: {
            transformPastedHTML(text) {
                return stripHTMLTags(text);
            },
        },
        extensions: [
            Underline,
            StarterKit,
            Placeholder.configure({
                placeholder,
            }),
            CharacterCount.configure({limit: 1000}),
            TextStyle,
            Color,
            Highlight.configure({ multicolor: true }),
            // Mathematics.configure({
            //     regex: LATEX_REG_EXP,
            // }),
        ],
        onUpdate: ({editor}) => {
            setValue(editor.getHTML());
        },
        content: value,
    }, [serialNo]);

    useEffect(() => {
        if (value !== editor.getHTML()) {
            editor.commands.setContent(value);
        }

    }, [value, editor]);


    return (
        (
            <div className={classes.editorBox}>
                <TipTapMenuBar editor={editor}/>
                <EditorContent editor={editor} style={{minHeight: 50}} className={classes.editorContentBox}/>
                {!!editor.storage.characterCount.characters() &&
                    <span className={classes.characterCountBox}>
                        {editor.storage.characterCount.characters()} {CHARACTERS_TEXT}
                    </span>
                }

                {/*<pre>{editor.getText()}</pre>*/}
                {/*<pre>{content}</pre>*/}
                {/*<pre>{parse(content)}</pre>*/}
                {/*<pre>{JSON.stringify(editor.state)}</pre>*/}
            </div>
        )
    )
}
import {FIXED_ANSWER, QUESTION_TYPES, SCREEN_TYPE} from "../../utils/constants";
import OpenEndedResultFromClassResult from "./OpenEndedResultFromClassResult";
import ClassResultChart from "./ClassResultChart";
import classes from "./ClassResult.module.css";
import React, {useEffect, useState} from "react";
import PremiseChart from "./PremiseChart";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import {checkUnansweredNew} from "../../utils/helpers";
import WordCloudResult from "./WordCloudResult";
import ClassResultRankNew from "./ClassResultRankNew";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { ReactComponent as ARROW_UP } from "./images/arrow-up.svg";
import { ReactComponent as ARROW_DOWN } from "./images/arrow-down.svg";

export default function RenderAllResponse({
                                               pollKey,
                                               uniqueCode,
                                               pollResults,
                                               questionData,
                                               showAnswerOnViewer,
                                               curUserResponses = [],
                                               dataLoading
}) {

    let {width: windowWidth, type: windowType} = useWindowDimensions();

    const {questionResults={}, unansweredCount=0, weightage, correctAnswers, serialNo, rawResponse=[], questionType} = questionData;
    let correctObj = questionResults[0];
    let answeredCount = 0;

    if (questionType === QUESTION_TYPES.OE.name) {
        const {responses={}} = rawResponse;

        answeredCount = Object.values(responses).filter(val => val !== FIXED_ANSWER.UNANSWERED).length

    } else {
        answeredCount = (correctObj?.total ? correctObj.total : 0) - unansweredCount
    }

    let shouldShowCorrect = !!weightage && !!showAnswerOnViewer;
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setIsExpanded(false);
    }, [dataLoading])


    if (!!dataLoading) {
        return (
            <>
                <span className={classes.label} style={{display: "flex", alignItems: "center", gap: 8}}>
                    <span>All Responses</span>
                    <SkeletonTheme color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                        <Skeleton borderRadius={6} height={20} width={30}/>
                    </SkeletonTheme>
                </span>

                {Array.from({length: 3}).map((_, rowIndex) => {
                    let skeletonWidth = windowType === SCREEN_TYPE.MOBILE
                        ? windowWidth * 0.8
                        : Math.min(windowWidth * 0.8, 500);
                    return (
                        <SkeletonTheme key={rowIndex} color="rgba(237, 237, 237, 1)" highlightColor="lightgrey">
                            <Skeleton borderRadius={4} height={40} width={skeletonWidth * (1- rowIndex * 0.2)}/>
                        </SkeletonTheme>
                    );
                })}

            </>
        );
    }

    let filteredRawResponses = [];
    if (Array.isArray(rawResponse)) {
        filteredRawResponses = rawResponse.filter(res => !checkUnansweredNew(questionType, res));
    }


    return (
        <>
            <span className={classes.label} style={{display: "flex", alignItems: "center", gap: 8}}>
                <span>All Responses</span>
                <span tabIndex={0} className={classes.totalCountBadge}>{answeredCount}</span>
                <span style={{flex: 1}}/>
                <span tabIndex={0} style={{cursor: "pointer"}} onClick={() => setIsExpanded(!isExpanded)}>
                    {isExpanded && <ARROW_UP/>}
                    {!isExpanded && <ARROW_DOWN/>}
                </span>
            </span>

            {(questionType === QUESTION_TYPES.MCSS.name
                || questionType === QUESTION_TYPES.TF.name
                || questionType === QUESTION_TYPES.CI.name)
                &&
                <ClassResultChart
                    isExpanded={isExpanded}
                    correctAnswers={questionData.correctAnswers}
                    chartData={questionData.questionType === QUESTION_TYPES.RK.name ? questionData.rawResponse : questionData.questionResults}
                    questionType={questionData.questionType}
                    numOptions={questionData.questionType === QUESTION_TYPES.MCSS.name || questionData.questionType === QUESTION_TYPES.CI.name
                        ? pollResults.length : 3}
                    gradeEnabled={!!weightage}
                    showAnswerOnViewer={showAnswerOnViewer}
                    queResponse={curUserResponses[serialNo - 1]}
                    question={questionData}
                    isLoading={dataLoading}
                />
            }

            {questionType === QUESTION_TYPES.OE.name &&
                <OpenEndedResultFromClassResult
                    isExpanded={isExpanded}
                    pollKey={pollKey}
                    uniqueCode={uniqueCode}
                    question={questionData}
                />
            }

            {questionType === QUESTION_TYPES.MH.name &&
                <div
                    className={classes.chartContainer}
                    style={{maxHeight: !isExpanded ? 0 : correctAnswers.length * 1000}}
                >
                    <div className={classes.chartContainerBody}>
                        {correctAnswers.filter(i => i.length > 1).map((key, index) =>
                            <PremiseChart
                                key={index}
                                correctAnswers={correctAnswers}
                                premise={key[0]}
                                premiseIdx={index}
                                data={questionData.rawResponse[key[0]]}
                                shouldShowCorrect={shouldShowCorrect}
                            />
                        )}
                    </div>

                </div>

            }

            {questionType === QUESTION_TYPES.FITB.name &&
                <div
                    className={classes.chartContainer}
                    style={{maxHeight: !isExpanded
                            ? 0
                            : filteredRawResponses.length * 1000}}
                >
                    <div className={classes.chartContainerBody}>
                        {filteredRawResponses.map((response, index) =>
                            <div key={index} className={classes.allResponseCard} tabIndex={isExpanded ? 0 : -1}>
                                <div>
                                    {Object.keys(response).map((key, idx) =>
                                        <div key={idx} >- {response[key]}</div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }

            {questionType === QUESTION_TYPES.SA.name &&
                <div className={classes.chartContainer} style={{maxHeight: !isExpanded ? 0 : filteredRawResponses.length * 1000}}>
                    <div className={classes.chartContainerBody} >
                        {filteredRawResponses.map((response, index) =>
                            <div key={index} tabIndex={isExpanded ? 0 : -1} className={classes.allResponseCard}>
                                {response["0"]}
                            </div>
                        )}
                    </div>
                </div>
            }

            {questionType === QUESTION_TYPES.WC.name &&
                <div className={classes.chartContainer} style={{maxHeight: !isExpanded ? 0 : 300}}>
                    <div
                        className={classes.chartContainerBody}
                        style={{
                            backgroundColor: "#F6F6F6E6",
                            border: !isExpanded ? "none" : "1px solid #E6E6E6",
                            borderRadius: 4, alignItems: "center", justifyContent: "center"
                        }}
                    >
                        <WordCloudResult data={questionData.rawResponse} />
                    </div>
                </div>
            }

            {questionType === QUESTION_TYPES.RK.name &&
                <ClassResultRankNew
                    isExpanded={isExpanded}
                    question={questionData}
                    chartData={rawResponse ? rawResponse.responses : rawResponse}
                    isLoading={dataLoading}
                />
            }
        </>
    );
}
import QRCode from "qrcode.react";
import React, {useEffect, useState} from "react";
import CopyToClipboard from 'react-copy-to-clipboard';
import {
    HOW_TO_JOIN_ACTIVITY_TITLE,
    TEXTBOX,
    UNIQUECODE_TEXT,
    VIEW_POLL_CODE_TEXT
} from "../../utils/constants";
import { notifySuccess } from "../../utils/helpers";
import { HOST_URL } from "../../utils/properties";
import { CLIPBOARD_COPY_SUCCESS, RESPONSE_LINK_SUCCESS } from "../../utils/toast-message-constants";
import { ReactComponent as DOWNLOAD_BIG } from "./images/DOWNLOAD_ICON_BIG.svg";
import { ReactComponent as ArrowDown } from "../../images/YuJa_arrow_down_icon.svg";
import { ReactComponent as ArrowUp } from "../../images/YuJa_arrow_up_icon.svg";
import { ReactComponent as CopyIcon } from "../../images/copyPoll.svg";
import "./styles.css";
import styles from "./SharePollPage.module.css";
import YuJaButton from "../../components/standardization/YuJaButton";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function ViewPollCode({data={}, loading=true, initialized=false, collapsed}) {
    const {height} = useWindowDimensions();
    const [cardCollapsed, setCardCollapsed] = useState(true);

    const hostname = window.location.hostname;
    let shortName = '';
    if (hostname.includes(HOST_URL) && hostname.includes("www")) {
      shortName = hostname.indexOf('.') >= 0 ?
          hostname.substring(hostname.indexOf('.') + 1, hostname.indexOf("engage.yuja.com") - 1) :
          hostname;
    } else {
      shortName = hostname.indexOf('.') >= 0 ?
          hostname.substring(0, hostname.indexOf('.')) :
          hostname;
    }

    const INST_URL = shortName + '.' + HOST_URL;
    const [isHovered, setIsHovered] = useState(false);


    useEffect(() => {
        setCardCollapsed(height > 810 ? (!data || !data.index3Pk) : true);
    }, [height, data]);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };


    const downloadQR = () => {
        const canvas = document.getElementById("1234");
        
        const backgroundColor = "#FFFFFF"; 
        
        const qrCodeWidth = canvas.width;
        const qrCodeHeight = canvas.height;
        
        const padding = 10; 
        
        const newWidth = qrCodeWidth + 2 * padding;
        const newHeight = qrCodeHeight + 2 * padding;
        
        const newCanvas = document.createElement("canvas");
        newCanvas.width = newWidth;
        newCanvas.height = newHeight;
        const newContext = newCanvas.getContext("2d");
        
        newContext.fillStyle = backgroundColor;
        newContext.fillRect(0, 0, newWidth, newHeight);
        
        newContext.drawImage(canvas, padding, padding, qrCodeWidth, qrCodeHeight);

        const pngUrl = newCanvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = `${data.index3Pk.substring(3)}.png`;
        
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return(
        <div
            className={styles.panelCard}
            style={{
                maxHeight: !cardCollapsed ? 300 : 48,
            }}
        >
            <div className={styles.panelCardHeader} onClick={() => setCardCollapsed(!cardCollapsed)}>
                <div className={styles.panelCardHeaderTitle} tabIndex={0} role={TEXTBOX}>{HOW_TO_JOIN_ACTIVITY_TITLE}</div>
                {cardCollapsed ? <ArrowDown/> : <ArrowUp/>}
            </div>
            <div className={styles.panelCardBody}>
                {(initialized || (data && data.index3Pk)) &&
                    <div style={{display: "flex", flexDirection: "column", gap: 15}}>
                        <div className={styles.panelCardBodySubTitle} tabIndex={0} role={TEXTBOX}>
                            {VIEW_POLL_CODE_TEXT}
                        </div>
                        <div style={{display: "flex", alignItems: "center", gap: 20, justifyContent: "center"}}>
                            <div style={{position: "relative"}}  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                <QRCode
                                    id="1234"
                                    size={120}
                                    renderAs="canvas"
                                    style={{cursor: "pointer"}}
                                    value={INST_URL + "/qr/" + data.index3Pk.substring(3)}
                                    onClick={async () => {
                                        downloadQR();
                                    }}
                                />
                                {isHovered &&
                                    <div
                                        style={{
                                            position: "absolute", top: 0, left: 0, width: "100%", height: "100%",
                                            display: "flex", justifyContent: "center", alignItems: "center",
                                            cursor: "pointer", backgroundColor: "rgba(0, 0, 0, 0.6)"
                                        }}
                                    >
                                        <DOWNLOAD_BIG
                                            className="downloadIcon"
                                            style={{
                                                width: 64,
                                                height: 64,
                                            }}
                                            onClick={downloadQR}
                                        />
                                    </div>

                                }
                            </div>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                <CopyToClipboard text={data.index3Pk.substring(3)} onCopy={() => {
                                    notifySuccess(CLIPBOARD_COPY_SUCCESS.replace(UNIQUECODE_TEXT, data.index3Pk.substring(3)))
                                }}>
                                    <YuJaButton style={{width: 120, padding: 6}}>
                                        {data.index3Pk.substring(3)}
                                        <CopyIcon style={{marginLeft: 10, display: "block"}}/>
                                    </YuJaButton>

                                </CopyToClipboard>
                                <div style={{marginTop: 20}}></div>
                                <CopyToClipboard
                                    text={`${INST_URL}/qr/${data.index3Pk.substring(3)}`}
                                    onCopy={() => notifySuccess(RESPONSE_LINK_SUCCESS)}
                                >
                                    <YuJaButton style={{width: 120, padding: 6}}>
                                        <span style={{textDecoration: "underline"}}>Link</span>
                                        <CopyIcon style={{marginLeft: 10, display: "block"}}/>
                                    </YuJaButton>
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

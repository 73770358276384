import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { MdArrowBackIosNew } from "react-icons/md";
import { useParams } from "react-router-dom";
import { PollApiCalls } from "../../apis/PollApiCalls";
import { Spin } from "../../components/standardization/YuJaLoading";
import { usePPTWebView } from "../../hooks/usePPTWebView";
import { ReactComponent as RKIcon } from "../../images/RankingIcon.svg";
import { ReactComponent as CIIcon } from "../../images/clickable_purple.svg";
import { ReactComponent as FITBIcon } from "../../images/fitb_purple.svg";
import { ReactComponent as MHIcon } from "../../images/matching_purple.svg";
import { ReactComponent as MCSSIcon } from "../../images/mcss_purple.svg";
import { ReactComponent as SAIcon } from "../../images/sa_purple.svg";
import { ReactComponent as TFIcon } from "../../images/tf_purple.svg";
import { ReactComponent as WCIcon } from "../../images/wcloud_purple.svg";
import { ReactComponent as OEIcon } from "../../images/OpenEnded.svg";
import {
    BLANK_REGEX, BLANK_REGEX_NEW, GO_BACK_POLLS_LINK, QUESTION_LABEL, QUESTION_TITLE,
    QUESTION_TYPES
} from "../../utils/constants";
import styles from "./PPTPollDetailPage.module.css";
import {stripHTMLTags} from "../../utils/helpers";
import {LatexInline} from "../../components/Tiptap/latexParser";

export default function PPTPollDetailPage() {
    const { pollKey, pollCode } = useParams();
    const {goPollsPage} = usePPTWebView();
    const {getPublishedPollDataFromKey, getPollDataFromKey} = PollApiCalls();
    const [dataReady, setDataReady] = useState(false);
    const [poll, setPoll] = useState({});
    const [questions, setQuestions] = useState([]);


    useEffect(() => {
        if (pollCode) {
            getPublishedPollDataFromKey(pollKey, pollCode).then(res => {
                setQuestions(res.questions);
                setPoll(res.poll);
            }).finally(() => {
                setDataReady(true);
            });
        } else {
            getPollDataFromKey(pollKey).then(res => {
                if (res && Object.entries(res).length > 0) {
                    const {questions=[]} = res;
                    setPoll(res);
                    setQuestions(questions);
                }
            }).finally(() => {
                setDataReady(true);
            });
        }

    }, []);

    return (
        <div className={styles.tableParentContainer}>

            <div className={styles.goBackLink} onClick={goPollsPage}>
                <MdArrowBackIosNew/> {GO_BACK_POLLS_LINK}
            </div>
            <div style={{height: 20}}/>
            <div className={styles.controllerContainer}>
                <span className={styles.title}>Poll Title: {poll.pollTitle ? poll.pollTitle : ""}</span>
            </div>
            <div style={{height: 30}}/>

            <Table style={{backgroundColor: "#f6f4fa"}}>
                <thead style={{backgroundColor: "#f6f4fa"}}>
                <tr style={{backgroundColor: "#f6f4fa"}}>
                    <th width={100} >{QUESTION_LABEL} #</th>
                    <th style={{textAlign: "start"}}>{QUESTION_TITLE}</th>
                </tr>
                </thead>
            </Table>


            <div className={styles.normalContainer} style={{position: "relative",}}>
                <div style={{position: "absolute", width: '100%', height: "100%", overflowY: "scroll"}}>
                    <Table >
                        <tbody>
                        {questions.map((item, index) => (
                            <tr key={index}>
                                <td width={100} >{item.serialNo}</td>
                                <td style={{textAlign: "start"}}>
                                    <span style={{display: "flex", alignItems: "center", gap: 20}}>
                                        {item.questionType === QUESTION_TYPES.MCSS.name &&
                                            <MCSSIcon style={{width: 20, height: 20}} title={QUESTION_TYPES.MCSS.description}/>
                                        }
                                        {item.questionType === QUESTION_TYPES.TF.name &&
                                            <TFIcon style={{width: 20, height: 20}} title={QUESTION_TYPES.TF.description}/>
                                        }
                                        {item.questionType === QUESTION_TYPES.SA.name &&
                                            <SAIcon style={{width: 20, height: 20}} title={QUESTION_TYPES.SA.description}/>
                                        }
                                        {item.questionType === QUESTION_TYPES.FITB.name &&
                                            <FITBIcon style={{width: 20, height: 20}} title={QUESTION_TYPES.FITB.description}/>
                                        }
                                        {item.questionType === QUESTION_TYPES.CI.name &&
                                            <CIIcon style={{width: 20, height: 20}} title={QUESTION_TYPES.CI.description}/>
                                        }
                                        {item.questionType === QUESTION_TYPES.WC.name &&
                                            <WCIcon style={{width: 20, height: 20}} title={QUESTION_TYPES.WC.description}/>
                                        }
                                        {item.questionType === QUESTION_TYPES.MH.name &&
                                            <MHIcon style={{width: 20, height: 20}} title={QUESTION_TYPES.MH.description}/>
                                        }

                                        {item.questionType === QUESTION_TYPES.OE.name &&
                                            <OEIcon alt="QA Icon" style={{ width: "24px", height: "24px" }} />
                                        }

                                        {item.questionType === QUESTION_TYPES.RK.name &&
                                            <RKIcon style={{width: 20, height: 20}} title={QUESTION_TYPES.RK.description}/>
                                        }
                                        <span style={{flex: 1}}>
                                            {item.questionType === QUESTION_TYPES.FITB.name
                                                ? item.queTitle.replace(item.hasBlankRestriction ? BLANK_REGEX_NEW : BLANK_REGEX, "___")
                                                : <LatexInline>{item.queTitle}</LatexInline>
                                            }
                                        </span>
                                    </span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>



                {!dataReady &&
                    <div className={styles.animationContainer}>
                        <div style={{flex: 1}}/>
                        <Spin size="large" wrapperClassName="globalSpin" spinning={true} tip="Loading..."/>
                        <div style={{flex: 3}}/>
                    </div>
                }
            </div>
        </div>
    );
}